/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Space } from "antd";
import React from "react";
import {
  FaFaceFrown,
  FaFaceMeh,
  FaFaceSadTear,
  FaFaceSmile,
} from "react-icons/fa6";
import { GoAlertFill } from "react-icons/go";

function AlertInfomation() {
  return (
    <>
      <Row gutter={[16, 8]} justify="end">
        <Col>
          <Space
            className="mt10"
            direction="vertical"
            style={{
              borderRadius: 4,
              paddingTop: 25,
              lineHeight: "16px",
            }}
          >
            <Space wrap="wrap" align="center">
              <Space align="center" className="mr5">
                <GoAlertFill style={{ color: "red", fontSize: 20 }} />
                <span>要緊急対応</span>
              </Space>
              <Space align="center" className="mr5">
                <FaFaceSadTear style={{ color: "red", fontSize: 20 }} />
                <span>危険</span>
              </Space>
              <Space align="center" className="mr5">
                <FaFaceFrown style={{ color: "orange", fontSize: 20 }} />
                <span>警戒</span>
              </Space>
              <Space align="center" className="mr5">
                <FaFaceMeh style={{ color: "gold", fontSize: 20 }} />
                <span>注意</span>
              </Space>
              <Space align="center" className="mr5">
                <FaFaceSmile style={{ color: "green", fontSize: 20 }} />
                <span>安全</span>
              </Space>
              <Space align="center">
                <FaFaceMeh style={{ color: "gray", fontSize: 20 }} />
                <span>記録なし</span>
              </Space>
            </Space>
          </Space>
        </Col>
      </Row>
    </>
  );
}

export default AlertInfomation;
