import {
  Button,
  Col,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import {
  MSG_CATCH,
  getQuerySearch,
  pushObject2Query,
  renderOption,
  saveFile,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";

import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  PAGE_SIZE,
  lang,
} from "../../constants/common.const";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { DeviceApi } from "../../api/backend/device";
import classDdl from "../../models/control/dropdownlist";
import ContentTitle from "../../component/Desktop/contentTitle";
import SelectOffice from "../../components/common/input/selectOffice";
import { onChangeTable } from "../../utils/helpers";
const { Content } = Layout;
function VitalDeviceList() {
  const history = useHistory();
  const objSearch = getQuerySearch();
  const [initLoad, setInitLoad] = useState(true);
  const PAGE_LIMT = 100;

  // const [OfficeId, setOfficeId] = useState(objSearch.OfficeId || null);
  const [ddlBaseName, setDdlBaseName] = useState(
    new classDdl(objSearch.OfficeId || null)
  );
  const [SerialNumber, setSeriaNumber] = useState(
    new classDdl(objSearch.SerialNumber || null)
  );

  // Search
  const [formSearch] = Form.useForm();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchInit, setSearchInit] = useState(false);
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const deviceKey = [
    "listDevice",
    { office: ddlBaseName.value, page, sortKey, sortOrder },
  ];

  // Quey List Device,
  const listDeviceQuery = useQuery(
    deviceKey,
    () =>
      DeviceApi.getListDevice({
        OfficeId: ddlBaseName.value,
        SerialNumber: SerialNumber.value,
        sortKey,
        sortOrder,
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
      }),
    {
      keepPreviousData: true,
      onError: () => {
        showMessage(KEY.ERROR, MSG_CATCH());
      },
      onSettled: () => {
        setLoadingSearch(false);
      },
    }
  );

  // re-call api
  const reCallApi = (currentPage, orderName, orderBy) => {
    setPage(currentPage);
    if (orderName) {
      setSortKey(orderName);
    }
    if (orderBy) {
      setSortOrder(orderBy);
    }
  };

  // List serialNumber
  const getOptionSeriaNumber = async () => {
    const res = await DeviceApi.getListDevice({
      officeId: ddlBaseName.value,
    });
    setSeriaNumber({
      ...SerialNumber,
      value: !initLoad ? null : SerialNumber.value,
      options: res.data.data.map((i) => ({
        key: i.serialNumber,
        value: i.serialNumber,
      })),
    });
  };
  useEffect(() => {
    getOptionSeriaNumber();
  }, [ddlBaseName.value]);

  useEffect(() => {
    onSearch(true);
  }, [ddlBaseName.value, SerialNumber.value]);
  // Get Office value
  const currentOfficeValue = useMemo(() => {
    if (listDeviceQuery.data) {
      const data = ddlBaseName.options;
      const current = data.find((i) => i.key === ddlBaseName.value);
      if (current) {
        return current.value;
      }
    }
    return null;
  }, [listDeviceQuery.data]);

  // Delete Device
  const deleteDeviceMutation = useMutation(
    "DeleteDevice",
    (serialNumber) => DeviceApi.deleteDevice(serialNumber),
    {
      onSuccess: () => {
        showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C004);
        onSearch(null, true);
      },
      onError: () => {
        showMessage(KEY.ERROR, MSG_CATCH());
      },
    }
  );

  // Search
  const onSearch = (resetPage = false, deleetFlag = false) => {
    pushObject2Query({
      OfficeId: ddlBaseName.value,
      SerialNumber: SerialNumber.value,
    });
    if (resetPage && page != 1) {
      setPage(1);
    } else if (deleetFlag) {
      const countAffterDelete = listDeviceQuery.data
        ? listDeviceQuery.data.data.count - 1
        : 0;
      const maxPage = Math.ceil(countAffterDelete / PAGE_SIZE);

      if (page > maxPage) {
        setPage(maxPage);
      } else {
        listDeviceQuery.refetch();
      }
    } else listDeviceQuery.refetch();
  };

  const downloadDevice = async () => {
    try {
      setLoadingSearch(true);
      const res = await DeviceApi.downloadListDevice({
        OfficeId: ddlBaseName.value,
      });
      saveFile(res);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoadingSearch(false);
    }
  };

  // Delete
  const handleDeleteDevice = (serialNumber) => {
    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.ECM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => deleteDeviceMutation.mutate(serialNumber),
    });
  };
  useEffect(() => {
    if (searchInit) {
      onSearch();
      setSearchInit(false);
    }
  }, [searchInit]);

  useEffect(() => {
    setInitLoad(false);
  }, []);

  let initColumns = [
    {
      title: "デバイス種別",
      dataIndex: "deviceTypeValue",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (value, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ maxWidth: "500px" }}
          >
            <Col span={24}>
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <div> {value}</div>

                {row.deviceType === '1'  && (
                  <>
                    <img
                      className="ml5"
                      src={require("./icon.png")}
                      style={{ maxWidth: "35%", height: "auto" }}
                      alt="Logo"
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "デバイスID",
      dataIndex: "serialNumber",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "拠点名",
      dataIndex: "officeName",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
    },
    {
      title: "設定変更",
      dataIndex: "settingChange",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            <Tooltip title={lang.CLONE}>
              <Button
                className="mr5 ml10"
                onClick={() => {
                  history.push(
                    `/vital-device/edit/${row.serialNumber}?OfficeId=${row.officeId}&OfficeValue=${row.officeName}`
                  );
                }}
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "削除",
      dataIndex: "delete",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button onClick={() => handleDeleteDevice(row.serialNumber)}>
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={lang.VITAL_DEVICE_LIST} />

        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={() => onSearch(true)}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin ">
            <Col lg={8} md={24} sm={24} xs={24}>
              <div className="ant-form-item-label">{lang.BASE_NAME}</div>

              <SelectOffice
                className="w100"
                acceptSetNull={!initLoad}
                state={ddlBaseName}
                setState={setDdlBaseName}
              />
            </Col>

            <Col lg={8} md={24} span={24}>
              <div className="ant-form-item-label">{lang.SERIAL_ID}</div>
              <Select
                showSearch
                optionFilterProp="children"
                className="w100"
                value={SerialNumber.value}
                onChange={(v) =>
                  setSeriaNumber({
                    ...SerialNumber,
                    value: v,
                  })
                }
              >
                {renderOption(SerialNumber.options)}
              </Select>
            </Col>
            <Col lg={8} md={24} span={24}>
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col></Col>
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      if (ddlBaseName.value) {
                        history.push(
                          `/vital-device/create/new?OfficeId=${ddlBaseName.value}&OfficeValue=${currentOfficeValue}`
                        );
                      } else {
                        history.push(`/vital-device/create/new`);
                      }
                    }}
                  >
                    <PlusOutlined className="" />
                    {COMMON_CONSTANT.ADD}
                  </Button>
                </Col>
                <Col>
                  <Button
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push("/vital-device/inputfile");
                    }}
                  >
                    <FileAddOutlined className="" />
                    {lang.FILE}
                  </Button>
                </Col>
                <Col>
                  <Button
                    // disabled={!OfficeId}
                    className="buttonPC button--info --todo-- wAuto "
                    onClick={() => {
                      downloadDevice();
                    }}
                  >
                    <DownloadOutlined />
                    {lang.DOWNLOAD}
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col flex="auto"></Col>
          </Row>
        </Form>
      </ContentTitle>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}></Col>
          <Col span={12} className="right" style={{ paddingRight: "20px" }}>
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {listDeviceQuery.data ? listDeviceQuery.data.data.count : 0}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.id}
          className="gridSearch "
          columns={initColumns}
          dataSource={
            listDeviceQuery.data ? listDeviceQuery.data.data.data : []
          }
          pagination={{
            ...CONFIG.paging,
            current: page || 1,
            total: listDeviceQuery.data && listDeviceQuery.data.data.count,
            pageSize: PAGE_LIMT,
            showSizeChanger: false,
          }}
         scroll={{ x: 'max-content' }}
          onChange={onChangeTable(reCallApi)}
        />
      </Content>
      <Loading show={listDeviceQuery.isLoading || loadingSearch} />
    </div>
  );
}

export default VitalDeviceList;
