/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Col, Row, Input, Button, Modal, Select } from "antd";

import TitlePage from "../../component/Desktop/titlePage";
import RowInput from "../../component/Desktop/rowInput";
import Loading from "../../component/Desktop/loading/loading";
import BreakLine from "../../component/Desktop/breakLine";
import CompayApi from "./../../api/backend/company";
import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";

import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import UserApi from "./../../api/backend/user";
import classText from "../../models/control/text";
import GroupItem from "../../component/Desktop/groupItem";
import {
  MSG_CATCH,
  checkEmail,
  checkErrorConfirmPasssword,
  checkErrorPassword,
  formatListCompanyOption,
  formatListCompanyCode,
  checkPhoneNumber,
  renderOption,
  showMessage,
  showErrorMessage,
} from "../../constants/utils";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import classDdl from "../../models/control/dropdownlist";
import { ApiOffice } from "../../api/backend/headerQuaters";
import InputUserName from "../../components/common/input/inputUserName";
import useWidthInput from "../../hooks/useWidthInput";
let FLAG_CHANGE_DATA = false;

function AddAdminUnitService() {
  const widthInput = useWidthInput();
  let { id } = useParams();
  const history = useHistory();
  let [showLoading, setShowLoading] = useState(false);
  const [modeEdit, setModeEdit] = useState(true);
  let [clicked, setClicked] = useState(false);

  // Loading
  let [txtLoginID, settxtLoginID] = useState(new classText());
  let [txtPassword, settxtPassword] = useState(new classText());
  let [txtPasswordConfirm, settxtPasswordConfirm] = useState(new classText());
  let [txtFullName, settxtFullName] = useState(new classText());
  let [txtFuriganaName, settxtFuriganaName] = useState(new classText());
  let [txtTelephone, settxtTelephone] = useState(new classText());
  let [txtEmail, settxtEmail] = useState(new classText());

  // Focus
  let loginIdEl = useRef(null);
  let txtPasswordEl = useRef(null);
  let txtPasswordConfirmEl = useRef(null);
  let txtFullNameEl = useRef(null);
  let txtFuriganaNameEl = useRef(null);
  let txtTelephoneEl = useRef(null);
  let txtEmailEl = useRef(null);

  // Validate
  const validateData = () => {
    let flagError = false;
    settxtEmail({ ...txtEmail, error: false });
    settxtFuriganaName({ ...txtFuriganaName, error: false });
    settxtFullName({ ...txtFullName, error: false });
    settxtTelephone({ ...txtTelephone, error: false });
    settxtPasswordConfirm({ ...txtPasswordConfirm, error: false });
    settxtPassword({ ...txtPassword, error: false });
    settxtLoginID({ ...txtLoginID, error: false });

    if (txtEmail.value && !checkEmail(txtEmail.value)) {
      flagError = true;
      settxtEmail({ ...txtEmail, error: true });
      txtEmailEl.current.focus();
    }

    if (!txtFuriganaName.value) {
      flagError = true;
      settxtFuriganaName({ ...txtFuriganaName, error: true });
      txtFuriganaNameEl.current.focus();
    }

    if (!txtFullName.value) {
      flagError = true;
      settxtFullName({ ...txtFullName, error: true });
      txtFullNameEl.current.focus();
    }

    if (txtTelephone.value && !checkPhoneNumber(txtTelephone.value)) {
      flagError = true;
      settxtTelephone({ ...txtTelephone, error: true });
      txtTelephoneEl.current.focus();
    }


    if (
      (!txtPasswordConfirm.value && !id) ||
      (!id &&
        checkErrorConfirmPasssword(
          txtPassword.value,
          txtPasswordConfirm.value
        ) != null) ||
      (id &&
        (txtPassword.value || txtPasswordConfirm.value) &&
        checkErrorConfirmPasssword(
          txtPassword.value,
          txtPasswordConfirm.value
        ) != null)
    ) {
      flagError = true;
      settxtPasswordConfirm({ ...txtPasswordConfirm, error: true });
      txtPasswordConfirmEl.current.focus();
    }

    if (
      (!txtPassword.value && !id) ||
      (!id && checkErrorPassword(txtPassword.value) != null) ||
      (id && txtPassword.value && checkErrorPassword(txtPassword.value) != null)
    ) {
      flagError = true;
      settxtPassword({ ...txtPassword, error: true });
      txtPasswordEl.current.focus();
    }

    if (!txtLoginID.value) {
      flagError = true;
      settxtLoginID({ ...txtLoginID, error: true });
      loginIdEl.current.focus();
    }

    return !flagError;
  };
  // Popup common
  const showConfirmMessage = (typeInfo = false) => {
    return;
  };

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA) {
      backToSearch();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      showConfirmMessage(COMMON_CONSTANT.WF_C018);
    }
  }
  // Post
  const postData = async () => {
    try {
      setShowLoading(true);
      const dataPost = {
        userName: txtLoginID.value,
        furigana: txtFuriganaName.value,
        fullName: txtFullName.value,
        email: txtEmail.value,
        phoneNumber: txtTelephone.value,
        role: RoleHeadOffice.ADMIN_UNIT_SERVICE,
        password: txtPassword.value,
        confirmPassword: txtPasswordConfirm.value,
      };
      if (id) {
        dataPost.userId = id;
      }
      await UserApi.save(dataPost, id);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
      history.goBack();
    } catch (error) {
      console.log(error.response);
      let msg;
      if (error.response) {
        if (error.response.status === 409) {
          msg = lang.TRY_LOGINID;
          settxtLoginID({ ...txtLoginID, value: error.response.data });
        } else if (error.response.status === 400) {
          msg = error.response.data;
        }
      }
      showMessage(KEY.ERROR, msg || MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  // Save Data
  const onSave = () => {
    setClicked(true);
    // Validate Data
    if (!validateData()) {
      return;
    }
    // Call Api
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: id ? COMMON_CONSTANT.ECM_C009 : COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };

  // getInit data
  const getInitData = async () => {
    setShowLoading(true);

    try {
      const [resDetail, resCompany] = await Promise.all([
        id ? UserApi.getDetailUser(id) : null
      ]);
      if (id) {
        const dataDetail = resDetail.data;
        settxtLoginID({ ...txtLoginID, value: dataDetail.userName });
        settxtTelephone({ ...txtTelephone, value: dataDetail.phoneNumber });
        settxtEmail({ ...txtEmail, value: dataDetail.email });
        settxtFuriganaName({ ...txtFuriganaName, value: dataDetail.furigana });
        settxtFullName({ ...txtFullName, value: dataDetail.fullName });
       
      } else {

      }
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    getInitData();
  }, []);
  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.ADMIN_UNIT_SERVICE_ADD} />

      {/* Group 1 */}
      <GroupItem>
        <BreakLine />
        <RowInput box name={"ログインID"} required={modeEdit}>
          {modeEdit ? (
            <>
              <InputUserName
                state={txtLoginID}
                setState={settxtLoginID}
                ref={loginIdEl}
                role={RoleHeadOffice.ADMIN_UNIT_SERVICE}
                isModeEdit={id}
              />
            </>
          ) : (
            <>
              <div className="white-space"> {txtLoginID.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.PASSWORD} required={!id}>
          <>
            <div>
              <Input
                type="password"
                autocomplete="new-password"
                aria-autocomplete="none"
                value={txtPassword.value}
                ref={txtPasswordEl}
                className={
                  txtPassword.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={255}
                onChange={(e) =>
                  settxtPassword({
                    ...txtPassword,
                    value: e.target.value,
                  })
                }
              ></Input>
              {txtPassword.error && clicked && (
                <div style={{ color: "red" }} className="fs12">
                  {checkErrorPassword(txtPassword.value)}
                </div>
              )}
            </div>
          </>
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.CONFIRM_PASSWORD} required={!id}>
          <div>
            <Input
              type="password"
              autocomplete="new-password"
              aria-autocomplete="none"
              value={txtPasswordConfirm.value}
              ref={txtPasswordConfirmEl}
              className={
                txtPasswordConfirm.error
                  ? `${STYLE.BORDER_RED} ${widthInput}`
                  : `${widthInput}`
              }
              maxLength={255}
              onChange={(e) =>
                settxtPasswordConfirm({
                  ...txtPasswordConfirm,
                  value: e.target.value,
                })
              }
            ></Input>
            {txtPasswordConfirm.error && clicked && (
              <div style={{ color: "red" }} className="fs12">
                {checkErrorConfirmPasssword(
                  txtPassword.value,
                  txtPasswordConfirm.value
                )}
              </div>
            )}
          </div>
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.FULL_NAME} required={modeEdit}>
          {modeEdit ? (
            <div>
              <Input
                value={txtFullName.value}
                ref={txtFullNameEl}
                className={
                  txtFullName.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={100}
                onChange={(e) =>
                  settxtFullName({
                    ...txtFullName,
                    value: e.target.value,
                  })
                }
              ></Input>

              {/* Please enter a full-width space between your first and last name */}
              <div className="fs12">
                姓と名の間に全角スペースを入れてください
              </div>
            </div>
          ) : (
            <>
              <div className="white-space"> {txtFullName.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.FURIGANA_NAME} required={modeEdit}>
          {modeEdit ? (
            <div>
              <Input
                value={txtFuriganaName.value}
                ref={txtFuriganaNameEl}
                className={
                  txtFuriganaName.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={100}
                onChange={(e) =>
                  settxtFuriganaName({
                    ...txtFuriganaName,
                    value: e.target.value,
                  })
                }
              ></Input>
              {/* Please enter a full-width space between your first and last name */}
              <div className="fs12">
                姓と名の間に全角スペースを入れてください
              </div>
            </div>
          ) : (
            <>
              <div className="white-space"> {txtFuriganaName.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.TELEPHONE_NUMBER}>
          {modeEdit ? (
            <div>
              <Input
                value={txtTelephone.value}
                ref={txtTelephoneEl}
                className={
                  txtTelephone.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={15}
                onChange={(e) =>
                  settxtTelephone({
                    ...txtTelephone,
                    value: e.target.value,
                  })
                }
              ></Input>
              {/* Please enter only half-width numbers and half-width hyphens (-) */}
              <div
                style={{
                  color: txtTelephone.error && clicked && "red",
                }}
                className="fs12"
              >
                半角数字と半角のハイフン（-）のみを入力してください
              </div>
            </div>
          ) : (
            <>
              <div className="white-space"> {txtTelephone.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.EMAIL_USER}>
          {modeEdit ? (
            <div>
              <Input
                value={txtEmail.value}
                ref={txtEmailEl}
                className={
                  txtEmail.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={64}
                onChange={(e) =>
                  settxtEmail({
                    ...txtEmail,
                    value: e.target.value,
                  })
                }
              ></Input>
              {/* Please enter only half-width numbers and half-width hyphens (-) */}
              <div
                style={{
                  color: txtEmail.error && clicked && "red",
                }}
                className="fs12"
              >
                半角英数字と半角記号のみを入力してください
              </div>
            </div>
          ) : (
            <>
              <div className="white-space"> {txtEmail.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          <Button
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              onSave();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={cancelSaveData}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default AddAdminUnitService;
