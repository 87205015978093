/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Row,
  Select,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";

import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";

import { Link, useHistory } from "react-router-dom";

import {
  getQuerySearch,
  renderOption,
} from "../../constants/utils";

import { CONFIG, STYLE, lang, DEVICE_TYPE } from "../../constants/common.const";

import classDdl from "../../models/control/dropdownlist";
import MapImageMarker from "./map-image-marker";
import { useParams } from "react-router-dom";

import { LocationApi as API } from "@src/api/backend/location";
import API_BASE from "@src/api/backend/kbn";
import dayjs from 'dayjs';

import { initColumns } from "@src/page/alert-list/list.jsx";
import { removeItemArr } from "@src/constants/utils.js";
import { isEmpty } from "lodash";
import ContentTitle from "../../component/Desktop/contentTitle";
import { Sun1 } from "iconsax-react";
import { HeartFilled } from "@ant-design/icons";

let objSearchOrigin = {
  title: null,
  category: null,
  startDate: null,
  endDate: null,
  status: null,
  keyWord: null,
  isProcessing: "true",
};

let objSearch = { ...objSearchOrigin };
objSearch = getQuerySearch();

function MapDetail() {
  
  const { id } = useParams();
  const [dataDetail, setDataDetail] = useState({});
  const fetchDataInit = async () => {
    try {
      setShowLoading(true);
      const res = await API.getOneMap(id);
      setDataDetail(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    fetchDataInit();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();

  const [markers, setMarkers] = useState([]);
  useEffect(() => {
    if (isEmpty(dataDetail)) return;
    const listGateWays = dataDetail.listGateways.map((item) => {
      const isMain = item.gatewayType == 1 || item.gatewayTypeValue == 1;
      return {
        ...item,
        top: item.positionY,
        left: item.positionX,
        type: isMain ? DEVICE_TYPE.MAIN_DEVICE : DEVICE_TYPE.SUB_DEVICE,
        name: item.serialNumber,
        ...isMain
        ? {
          isMainGatewayAlive: item.isMainGatewayAlive,
          waveLevel: null,
        }
        : {
          isMainGatewayAlive: null,
          waveLevel: item.waveLevel
        }
      };
    });
    const listHeatIndexWatchers = dataDetail.listHeatIndexWatchers.map((item) => ({
      ...item,
      top: item.positionY,
      left: item.positionX,
      type: DEVICE_TYPE.HEAT_WATCHER,
      name: item.serialNumber,
    }));
    setMarkers(listGateWays.concat(listHeatIndexWatchers));
  }, [dataDetail]);
  const [ddlOffice, setDdlOffice] = useState({
    ...new classDdl(),
  });
  useEffect(() => {
    if (isEmpty(dataDetail)) return;
    const fetch = async () => {
      try {
        const response = await API_BASE.getOffice();
        const data = response.data.data.map((item) => {
          return {
            value: item.officeName,
            key: item.officeId,
          };
        });
        setDdlOffice({
          ...ddlOffice,
          options: data,
          value: dataDetail.officeId,
          label: data.find((item) => item.key === dataDetail.officeId).value,
        });
      } catch (e) {
        console.log(e);
      }
    };

    fetch();
  }, [dataDetail]);

  const [ddlGroup, setddlGroup] = useState({
    ...new classDdl(),
  });
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await API_BASE.getGroup({
          OfficeId: dataDetail.officeId,
        });
        const data = response.data.data.map((item) => {
          return {
            value: item.groupName,
            key: item.id,
          };
        });
        setddlGroup({
          ...ddlGroup,
          options: data,
          value: null,
        });
      } catch (e) {
        console.log(e);
      }
    };

    fetch();
  }, [dataDetail.officeId]);

  const columnListWorker = [
    {
      title: "作業員名",
      dataIndex: "workerName",
      align: "start",
      width: "30%",
      render:(text,row) =><Link to = {"/physical-condition/detail?UserId=" + row.userId} style={{
        cursor:"pointer"
      }}>{text}</Link> 
    },
    {
      title: "年齢",
      dataIndex: "age",
      align: "start",
      width: "20%",
    },
    {
      title: "心拍数",
      dataIndex: "heartRate",
      align: "start",
      width: "25%",
      render: (value, row) => {
        return row.alertLevel != null ? (
          <div className="row-data-icon-text">
            {/* <FaFaceSadTear style={{ color: "red", fontSize: 20 }} /> */}
            <HeartFilled style={{ color: "red" }} />
            <div className="ml4"> {value}</div>
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      title: "WBGT超過度",
      dataIndex: "wbgtValue",
      align: "start",
      width: "25%",
      render: (value) => {
        return value ? (
          <div className="row-data-icon-text">
            {/* <FaFaceSadTear style={{ color: "red", fontSize: 20 }} /> */}
            <Sun1 size="20" color="#FF8A65" variant="Bold" />
            <div className="ml4"> {value}</div>
          </div>
        ) : (
          <></>
        );
      },
    },
  ];

  let thisInitColumns = [...initColumns].concat([
    {
      title: "経過時間",
      align: "start",
      dataIndex: "time",
      render: (value) => {
        return value ? dayjs.utc(value).fromNow() : "";
      },
    },
  ]);
  thisInitColumns = thisInitColumns.splice(1, thisInitColumns.length - 1);
  thisInitColumns[8] = {
    title: "棟/工区",
    align: "start",
    dataIndex: "locationName",
    render: (_) => {
      return dataDetail.locationName;
    }
  };
  thisInitColumns[9] = {
    title: "フロア/区画",
    align: "start",
    dataIndex: "locationName",
    render: (_) => {
      return dataDetail.floor;
    },
  };
  thisInitColumns = removeItemArr(thisInitColumns, thisInitColumns[10]);
  thisInitColumns[2] = {
    ...thisInitColumns[2],
    dataIndex: "workerName",
    render: (value, row) => {
      const link = `/physical-condition/detail?UserId=${row.userId || ""
        }&OfficeName=${row.baseName || dataDetail.officeName}&GroupName=${row.groupName || ""
        }`;
      return (
        <Row
          justify="space-between"
          align="middle"
          style={{ maxWidth: "500px" }}
        >
          <Col span={24}>
            <Link to={link} className="white-space">
              {value}
            </Link>
          </Col>
        </Row>
      );
    },
  };

  const [run, setRun] = useState(false);
  const [dataTableWorker,setDataTableWorker] = useState([]);
  useEffect(() => {
    let intervalId;
    if (run) {
      // Set the interval only when run is true
      intervalId = setInterval(async () => {
        await fetchDataInit();
      }, 60000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [run]);

  const [selectedGw, setSelectedGw] = useState(null);
  const listEmergencies = selectedGw
    ? (dataDetail.listEmergencies || []).filter(item => {
      return item.gwExtensionId === selectedGw.serialNumber;
    })
    : [];
  // Render

  // CLick NAME PIN
  const onClickNamePin =(pin) => {
    const listWorkers = dataDetail ? dataDetail.listWorkers : [];
    const dataTable = listWorkers.filter(worker => worker.gwExtensionId === pin.serialNumber );
    setDataTableWorker(dataTable)
  }
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={lang.MAP_DETAIL} />

        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          className="formStyle"
        >
          {/* 1 */}
          <Row
            gutter={[16, 8]}
            align="bottom"
            justify="space-between"
            className="rowNotMargin"
          >
            <Col lg={8} xs={24}>
              <div className="ant-form-item-label">拠点名</div>
              <Select
                value={dataDetail.officeName}
                className="w100"
                disabled
              />
            </Col>
            <Col lg={8} xs={24}>
              <div className="ant-form-item-label">{"グループ名"}</div>
              <Select
                showSearch
                value={ddlGroup.value}
                className={ddlGroup.error ? `${STYLE.BORDER_RED} w100` : "w100"}
                onChange={(value, option) => {
                  setddlGroup({
                    ...ddlGroup,
                    value,
                  });
                }}
                filterOption={(inputValue, option) =>
                  option.children && option.children
                    .toUpperCase()
                    .indexOf(inputValue.trim().toUpperCase()) !== -1}
                >
                {renderOption(ddlGroup.options, true)}
              </Select>
            </Col>
            {/* Button Search/Clear */}
            <Col lg={8} xs={24} className="right">
              <Flex
                align="center"
                justify="flex-end"
                gap={6}
              >
                <Checkbox checked={run} onChange={() => setRun(!run)} >
                  {lang.AUTOMATIC_UPDATE}
                </Checkbox>
                <Button
                  onClick={() => fetchDataInit()}
                  className="buttonPC button--info wAuto"
                >
                  {lang.UPDATE_DISPLAY}
                </Button>
                <Button
                  className="buttonPC button--info --todo-- wAuto ml10"
                  onClick={() => { history.push("/map/list"); }}
                >
                  マップ
                </Button>
              </Flex>
            </Col>

            <Col lg={8} xs={24}>
            <div className="ant-form-item-label bold">棟/工区</div>
            <Select
              value={dataDetail.locationName}
              className="w100"
              disabled
            />
          </Col>
          <Col lg={8} xs={24}>
            <div className="ant-form-item-label bold">フロア/区画</div>
            <Select
              value={dataDetail.floor}
              className="w100"
              disabled
            />
          </Col>
          <Col lg={8} xs={24} className="right">
            <Button
              className="buttonPC button--outline --todo-- wAuto ml10 mt20"
              onClick={() => {
                history.push(`/map/${id}/device`);
              }}
            >
              {lang.MAP_DEVICE}
            </Button>
          </Col>


          </Row>
        </Form>
      </ContentTitle>

      <div className="site-layout-background">
        <Row gutter={[16, 8]} justify="space-between " className="rowNotMargin">
         
          <Col lg={6} xs={24} className="right mt20">
            <Table
              rowKey={(record) => record.id}
              className="gridSearch "
              columns={columnListWorker}
              dataSource={dataTableWorker}
              pagination={false}
            />
          </Col>
          <Col lg={18} xs={24} className="mt20 pl20 m-pl-4 m-pr-4">
            <MapImageMarker
              markers={markers}
              image={dataDetail.mapImageData}
              setMarkers={setMarkers}
              isScale={true}
              isShowLabel={true}
              onClickMarker={setSelectedGw}
              onClickNamePin={onClickNamePin}
            />
          </Col>
        </Row>

        {!isEmpty(listEmergencies) && <Row gutter={[16, 8]} className="mt20">
          <Col span={24}>
            <Table
              rowKey={(record) => record.id}
              className="gridSearch "
              columns={thisInitColumns}
              dataSource={listEmergencies}
              pagination={{
                ...CONFIG.paging,
                current: currentPage,
                onChange: (curr) => setCurrentPage(curr),
                showSizeChanger: false,
              }}
             scroll={{ x: 'max-content' }}
            />
          </Col>
        </Row>}
      </div>
      <Loading show={showLoading} />
    </div>
  );
}
export default MapDetail;
