import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "@src/api/backend/auth";
import axios from "axios";
import { getInfoUSerFromToken, handleLogOut, setToken } from "../api/config";
import { LOCALSTORAGE_KEY, lang } from "../constants/common.const";
import { MSG_CATCH } from "../constants/utils";
// Login action
export const loginAction = createAsyncThunk(
  "auth/login",
  async ({ loginId, password }, { rejectWithValue, dispatch }) => {
    try {
      const response = await API.loginApi({
        username: loginId,
        password,
      });
       
      const data = response.data;
      if(data.isLockedOut){
        throw new Error(lang.ACC_LOCK)
      }
      localStorage.setItem(LOCALSTORAGE_KEY.IS_LOGIN, "true");
      setToken(data.token,data.refreshToken)
      dispatch(setInfoUser(getInfoUSerFromToken()));
      await dispatch(getSafeInfo())

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 401) {
          // Xử lý lỗi HTTP
          return rejectWithValue("ログインID/パスワードが一致しません");
        }
        else {
          return rejectWithValue(MSG_CATCH());
        }
      } else {
        // Xử lý lỗi khác
        return rejectWithValue(error.message);

      }
    }
  }
);

// Get Info User
export const getSafeInfo = createAsyncThunk(
  "/auth/getSafe",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await API.getSafeInfo()
      const data = response.data;
      dispatch(setSafe(data));
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.message);
      } else {
        throw error;
      }
    }
  }
);

const initialState = {
  isLogin: false,
  username: "",
  displayName: "",
  userName: "",
  status: "",
  error: "",
  roles: null,
  roleKey: null,
  allowRoute: [],
  avatarUrl: null,
  isLeader: false,
  isLoading: false,
  firstPath: null,
  userId:null,
  officeId: null,
  groupId:null,
  groupName:"",
  officeName:"",
  companyId:"",
  companyName:"",
  baseCode:"",
  safetyBeltDisplaySetting:false,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLogin = false;
      state.displayName = null;
      state.roleKey = null;
      handleLogOut(false);
      localStorage.removeItem(LOCALSTORAGE_KEY.REDIRECT_PATH);
      state.firstPath = null;
      state.userId = null;
      state.groupId = null;
      state.officeId = null;
      state.groupName = "";
      state.officeName = "";
      state.companyId = "";
      state.baseCode = "";
      state.SafetyBeltDisplaySetting=""
    },
    setIsLogin: (state, { payload }) => {
      state.isLogin = payload;
    },
    setSafe: (state, { payload}) => {
      state.safetyBeltDisplaySetting = payload
    },
    setInfoUser: (state, { payload }) => {
      state.displayName = payload.FullName;
      state.userName = payload.UserName;
      state.roleKey = payload.Role;
      state.userId = payload.UserId;
      state.officeId = payload.OfficeId;
      state.groupId = payload.GroupId
      state.officeName = payload.OfficeName;
      state.groupName = payload.GroupName;
      state.companyId = payload.CompanyId;
      state.companyName = payload.CompanyName;
      state.baseCode = payload.BaseCode;
      // state.safetyBeltDisplaySetting = payload.SafetyBeltDisplaySetting;
    },
    changeMode: (state, { payload }) => {
      state.mode = payload;
    },
    changeStatusNetWork: (state, { payload }) => {
      state.statusNetwork = payload;
    },
    updateAllowRoute: (state, { payload }) => {
      state.allowRoute = payload;
    },
    changeAvatar: (state, { payload }) => {
      state.avatarUrl = payload.url;
    },
    setFirstPath: (state, { payload }) => {
      state.firstPath = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(loginAction.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Reset error khi bắt đầu request mới
      })
      .addCase(loginAction.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
        state.isLogin = true;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      }),
});
// the action
export const {
  logout,
  setFactories,
  updateSelectedFactory,
  setInfoUser,
  changeMode,
  changeStatusNetWork,
  updateAllowRoute,
  changeAvatar,
  setIsLogin,
  setFirstPath,
  setSafe
} = authSlice.actions;

// the selector
export const authSelector = (state) => state.auth;

//the reducer
export default authSlice.reducer;
