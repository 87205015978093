import React, { useState } from "react";
import { Col, Row, Layout } from "antd";
import { DownSquareOutlined, UpSquareOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../../constants/common.const";

const { Content } = Layout;

function GroupItem({
  name,
  sub,
  children,
  style,
  center = false,
  expand = false,
  initExpand = true,
  ...other
}) {
  const [show, setshow] = useState(initExpand);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const onExpand = () => {
    expand && setshow(!show);
  };

  return (
    <Content
      className={isPC && "site-layout-background"}
      style={{
        // margin: "24px 16px",
        padding: isPC ? 20 : 0,
        ...style,
      }}
    >
      <div className="group-item">
        {/* {name && <TitleGroup name={name} sub={sub} expand={expand} onExpand={onExpand} />} */}
        {name && (
          <div className="title-group mb15">
            <h3 onClick={onExpand} className="pointer">
              <Row justify="space-between">
                <Col>{name}</Col>
                <Col>
                  {expand && (
                    <span className="expand-icon nonSelect orange">
                      {show ? <UpSquareOutlined /> : <DownSquareOutlined />}
                    </span>
                  )}
                </Col>
              </Row>
            </h3>
            {sub && show ? (
              <div className="sub-title-group">{"※" + sub}</div>
            ) : (
              <div className="mb20"></div>
            )}
          </div>
        )}
        <div className={show ? "" : "d-none"}>
          {center ? (
            <Row>
              <Col span={isPC ? 2 : 0}></Col>
              <Col span={isPC ? 20 : 24}>{children}</Col>
              <Col span={isPC ? 2 : 0}></Col>
            </Row>
          ) : (
            children
          )}
        </div>
      </div>
    </Content>
  );
}

export default GroupItem;
