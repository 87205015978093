import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Layout, Card } from "antd";
import loadable from "@loadable/component";
// Side Bar
import SideBar from "@src/component/SideBar/sideBar";
import { useMediaQuery } from "react-responsive";
import PrivateRouter from "@src/routers/privateRouter";
import { withRouter } from "react-router-dom";

import { KEY, LOCALSTORAGE_KEY } from "../constants/common.const";

import FooterPage from "../component/footer/footerPage";
const { Header } = Layout;
const Headers = loadable(() => import("@src/component/Header/header"));

function Router() {
  const { collapsed } = useSelector((state) => state.sideBar);
  const isLoginInStore = localStorage.getItem(LOCALSTORAGE_KEY.IS_LOGIN);
  const isLogin = isLoginInStore === "true";

  // check end user
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const headerStyle = {
    position: "sticky",
    top: 0,
    zIndex: 3,
    width: "100%",
    padding: "0px 16px",
    borderBottom: "1px solid #B2B2B2",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    // background: '#fff',
    height: "54px",
    lineHeight: "54px",
    display: "flex",
    alignItems: "center",
    color: "black",
  };
  const routerContent = useMemo(() => {
    const styleCard = {
      margin: "0px",
      overflow: "auto",
      height: isDesktopOrLaptop ? "calc(100vh - 102px)" : "calc(100vh - 50px)",
      backgroundColor: "#fff",
      border: 0,
      borderRadius: 0,
      paddingBottom: isDesktopOrLaptop ? 0 : 50,
    };
    return (
      <div className={isDesktopOrLaptop ? "des" : "des des--white"}>
        <Card style={styleCard} bodyStyle={{ padding: 0 }}>
          <PrivateRouter />
        </Card>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopOrLaptop]);
  return (
    <Layout>
      <Header theme="light" style={headerStyle}>
        <Headers />
      </Header>
      <Layout>
        {isLogin && (
          <>
            <SideBar />
          </>
        )}
        <Layout
          className="site-layout"
          style={
            isLogin && isDesktopOrLaptop
              ? collapsed
                ? { paddingLeft: "61px" }
                : { paddingLeft: "220px" }
              : {}
          }
        >
          {routerContent}
        </Layout>
      </Layout>
      <FooterPage></FooterPage>
    </Layout>
  );
}
export default withRouter(Router);
