import { Input } from "antd";
import React, { useEffect } from "react";
import userApi from "./../../../api/backend/user.js";
import { STYLE } from "../../../constants/common.const";
import useWidthInput from "../../../hooks/useWidthInput.jsx";
const InputUserName = ({ role, state, setState, ref, isModeEdit }) => {
  const inputWidth = useWidthInput();
  const getUserName = async () => {
    const userNameRes = await userApi.getuserNameLogin({
      role,
    });
    setState({ ...state, value: userNameRes.data });
  };
  useEffect(() => {
    if (!isModeEdit) {
      getUserName();
    }
  }, [role, isModeEdit]);
  return (
    <Input
      ref={ref}
      className={
        state.error ? `${STYLE.BORDER_RED} ${inputWidth}` : `${inputWidth}`
      }
      disabled
      value={state.value}
    />
  );
};
export default InputUserName;
