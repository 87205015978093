import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Table,
  Typography,
  Modal,
} from "antd";
import React, { useRef, useState, useEffect } from "react";

import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";
import { MSG_CATCH, renderOption, showMessage } from "../../constants/utils";

import { Content } from "antd/es/layout/layout";
import { FaFolderMinus } from "react-icons/fa6";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  PlusOutlined,
  RollbackOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import classDdl from "../../models/control/dropdownlist";
import { LocationApi as API } from "@src/api/backend/location";
import API_BASE from "@src/api/backend/kbn";
import { isEmpty } from "lodash";
import ContentTitle from "../../component/Desktop/contentTitle";
import { selectSearch } from "@src/constants/utils.js";

const { Paragraph } = Typography;
const objClear = { value: null, label: null, disabled: true, checked: false };

function MapSetting() {
  const [loading, setLoading] = useState(false);

  const FILE_ERROR_TEXT = {
    ITEM_SIZE: "File Size > 30Mb",
    TOTAL_SIZE: "Total Size > 100Mb",
    TYPE_FILE:
      "ファイル形式は正しくありません。アップロードできるファイルは「JPG」もうしくは「PNG」のみです。",
  };
  const MAX_SIZE_FILE = {
    ITEM_SIZE: 30 * 1024 * 1024,
    TOTAL_SIZE: 100 * 1024 * 1024,
  };
  const history = useHistory();

  const [ddlOffice, setDdlOffice] = useState({
    ...new classDdl(),
  });
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await API_BASE.getOffice();
        const data = response.data.data.map((item) => {
          return {
            value: item.officeName,
            key: item.officeId,
          };
        });
        setDdlOffice({
          ...ddlOffice,
          options: data,
          value: data[0].key,
          label: data[0].value,
        });
      } catch (e) {
        console.log(e);
      }
    };

    fetch();
  }, []);

  const [currentRowKey, setCurrentRowKey] = useState(null);
  const [textErrorSizeFile, setTextErrorSizeFile] = useState(null);
  const [errorValue, setErrorValue] = useState({});

  // List Map

  const [listMap, setListMap] = useState([]);

  // Search
  const search_onClick = async () => {
    try {
      setLoading(true);
      const result = await API.getMap({
        OfficeId: ddlOffice.value,
        // GroupId: ddlGroup.value,
      });
      const data = result.data.data.map((item, index) => {
        return {
          ...item,
          key: item.id,
          newFile: {
            name: "",
            url: "",
            size: 0,
          },
        };
      });
      setListMap(data.sort((a, b) => a.position - b.position));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isEmpty(ddlOffice.options)) return;
    search_onClick();
  }, [ddlOffice.value]);
  const fileRef = useRef();
  const columns = [
    {
      title: (
        <>
          棟/工区<span style={{ color: "red" }}> *</span>
        </>
      ),
      dataIndex: "locationName",
      width: "15%",
      editable: true,
      render: (value, row, index) => {
        const isError = (errorValue["locationName"] || []).includes(row.key);
        return (
          <>
            <Input
              onChange={(e) => {
                handleChangeInput("locationName", row.key, e.target.value);
                if (!e.target.value || !isError) return;
                const newErrorValue = { ...errorValue };
                newErrorValue["locationName"] = newErrorValue[
                  "locationName"
                ].filter((value) => value !== row.key);
                setErrorValue(newErrorValue);
              }}
              maxLength={100}
              value={row.locationName}
              className={isError ? `${STYLE.BORDER_RED} w100` : "w100"}
            />
          </>
        );
      },
    },

    {
      title: <>フロア/区画</>,
      dataIndex: "floor",
      width: "15%",
      editable: true,
      render: (text, row) => {
        const isError = (errorValue["floor"] || []).includes(row.key);
        return (
          <Input
            onChange={(e) => {
              handleChangeInput("floor", row.key, e.target.value);
              // if (!e.target.value || !isError) return;
              const newErrorValue = { ...errorValue };
              newErrorValue["floor"] = (newErrorValue["floor"] || []).filter(
                (value) => value !== row.key
              );
              setErrorValue(newErrorValue);
            }}
            maxLength={100}
            value={row.floor}
            className={isError ? `${STYLE.BORDER_RED} w100` : "w100"}
          />
        );
      },
    },
    {
      title: "登録済ファイル名",
      dataIndex: "imageName",
      width: "20%",
      render: (text, row) => {
        return <LinkImage url={text} imageKey={row.mapImage} />;
      },
    },
    {
      title: "新規ファイル名",
      dataIndex: "newFileName",
      width: "20%",
      editable: false,
      render: (text, row) => {
        return (
          <LinkImage
            url={row.newFile ? row.newFile.name : ""}
            img={row.newFile ? row.newFile.base64 : ""}
          />
        );
      },
    },
    {
      title: "マップファイル",
      width: "10%",
      align: "center",
      render: (_, row) => (
        <Button
          className="buttonPC button--info"
          onClick={() => {
            setCurrentRowKey(row.key);
            fileRef.current.click();
          }}
          type="primary"
          icon={<FaFolderMinus style={{ color: "#fff" }} />}
        >
          ファイル選択
        </Button>
      ),
    },
    {
      title: "削除",
      align: "center",

      render: (_, row) => (
        <Button onClick={() => hanldeDelete(row.key)} responsive>
          <DeleteOutlined />
        </Button>
      ),
      width: "3%",
    },
    {
      title: "行移動",
      dataIndex: "Title7",
      width: "10%",
      align: "center",
      render: (_, row) => (
        <div style={{ justifyContent: "center" }}>
          <Button
            disabled={listMap.length <= 1}
            onClick={() => changeOrder(-1, row.key)}
            style={{ size: "10px", marginRight: 5 }}
          >
            <ArrowUpOutlined />
          </Button>
          <Button
            disabled={listMap.length <= 1}
            onClick={() => changeOrder(1, row.key)}
            style={{ size: "10px" }}
          >
            <ArrowDownOutlined />
          </Button>
        </div>
      ),
    },
  ];

  // Change Input
  const handleChangeInput = (fields, key, newValue) => {
    const index = listMap.findIndex((i) => i.key === key);
    if (index >= 0) {
      const newListMap = JSON.parse(JSON.stringify(listMap));
      if (Array.isArray(fields)) {
        newListMap[index][fields[0]][fields[1]] = newValue;
      } else {
        newListMap[index][fields] = newValue;
      }
      setListMap(newListMap);
    }
  };

  // Check Size File
  const checkSizeFile = (currentSize) => {
    // Size File >30Mb
    if (currentSize > MAX_SIZE_FILE.ITEM_SIZE) {
      showMessage(KEY.ERROR, FILE_ERROR_TEXT.ITEM_SIZE);
      return false;
    }
    const currentListMap = JSON.parse(JSON.stringify(listMap));
    const index = currentListMap.findIndex((i) => i.key === currentRowKey);
    if (index >= 0) {
      currentListMap[index]["newFile"]["size"] = currentSize;
    }
    const totalSize = currentListMap.reduce((total, item) => {
      return total + item.newFile.size;
    }, 0);

    // Total Size > 100mb
    if (totalSize > MAX_SIZE_FILE.TOTAL_SIZE) {
      showMessage(KEY.ERROR, FILE_ERROR_TEXT.TOTAL_SIZE);
      return false;
    }
    return true;
  };

  const uploadFile = async (file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("uploadFile", file);
      const res = await API_BASE.uploadFile(formData);
      const reader = new FileReader();
      reader.onload = () => {
        handleChangeInput("newFile", currentRowKey, {
          name: file.name,
          url: res.data.url,
          base64: reader.result,
        });
      };
      reader.readAsDataURL(file);

      return res.data;
    } catch (err) {
      console.log(err);
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };

  // Reset
  const resetFile = () => {
    fileRef.current.value = null;
    setTextErrorSizeFile(null);
    setCurrentRowKey(null);
  };

  // Change Input
  const handleChangeInputFile = async (e) => {
    const file = e.target.files[0];

    // only accept .png
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      showMessage(KEY.ERROR, FILE_ERROR_TEXT.TYPE_FILE);
      return;
    }

    // Check File
    if (!checkSizeFile(file.size)) {
      showMessage(KEY.ERROR, textErrorSizeFile);
    }
    // setUpFile(file);
    await uploadFile(file);
    resetFile();
  };

  // Make new row table map
  const makeRow = (counter) => {
    return {
      floor: "",
      registeredFile: {
        name: "",
        url: "",
        size: "",
      },
      newFile: {
        name: "",
        url: "",
        size: 0,
      },
      key: Date.now(),
    };
  };

  // Add new map
  const handleAdd = () => {
    setListMap([...listMap, makeRow()]);
  };

  // Delete map
  const hanldeDelete = (key) => {
    setListMap([...listMap].filter((i) => i.key !== key));
  };

  // Change order map
  const changeOrder = (step, key) => {
    const index = listMap.findIndex((i) => i.key === key);
    if (index < 0) return;
    let newListMap = [...listMap];

    // firt to last
    if (index + step < 0) {
      const firstMap = listMap[0];
      newListMap = [...listMap.slice(1)].concat([firstMap]);
    } else if (index + step >= listMap.length) {
      const lastMap = listMap[listMap.length - 1];
      newListMap = [lastMap].concat([
        ...newListMap.slice(0, listMap.length - 1),
      ]);
    } else {
      const newIndex = index + step;
      let tmp = { ...newListMap[index] };
      newListMap[index] = { ...newListMap[newIndex] };
      newListMap[newIndex] = tmp;
    }
    setListMap(newListMap);
  };
  function backToSearch() {
    history.goBack();
  }

  const validate = () => {
    let isValid = true;
    const error = {
      locationName: [],
      floor: [],
    };
    listMap.forEach((item, index) => {
      if (!item.locationName) {
        isValid = false;
        error["locationName"].push(item.key);
      }

      // if (!item.floor) {
      //   isValid = false;
      //   error['floor'].push(item.key);
      // }
    });
    setErrorValue(error);
    return isValid;
  };

  const confirmSave = () => {
    if (!validate()) return;
    // Action del
    const postData = async () => {
      try {
        const body = listMap.map((item, index) => {
          return {
            id: item.id,
            floor: item.floor,
            mapImage:
              item.newFile && item.newFile.url
                ? item.newFile.url
                : item.mapImage,
            imageName:
              item.newFile && item.newFile.name
                ? item.newFile.name
                : item.imageName,
            officeId: item.officeId ? item.officeId : ddlOffice.value,
            locationName: item.locationName ? item.locationName : "",
            position: index,
          };
        });
        const result = await API.saveMap(body, ddlOffice.value);
        if (result.status == 200) {
          showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
          search_onClick();
        }
      } catch (e) {
          let msg;
          if (e.response) {
              if (e.response.status === 400) {
                  msg = e.response.data;
              }
          }
          showMessage(KEY.ERROR, msg || MSG_CATCH());
      }
    };
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };

  return (
    <Content className="content">
      <Loading show={loading} />
      <ContentTitle>
        <TitlePage name={lang.MAP_SETTING} />
        <Form layout={"vertical"} name="searchWR" className="formStyle">
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} span={24}>
              <div className="ant-form-item-label">拠点名</div>
              <Select
                showSearch
                value={ddlOffice.label}
                className={
                  ddlOffice.error ? `${STYLE.BORDER_RED} w100` : "w100"
                }
                onChange={(value, option) => {
                  setDdlOffice({
                    ...ddlOffice,
                    value: option.key,
                    label: value,
                  });
                }}
                filterOption={(inputValue, option) => {
                  return (
                    option.children &&
                    option.children
                      .toUpperCase()
                      .indexOf(inputValue.trim().toUpperCase()) !== -1
                  );
                }}
              >
                {renderOption(ddlOffice.options, false)}
              </Select>
            </Col>
            <Col span={24}>
              <h5 style={{ margin: "10px 10px" }}>
                現場基本情報{" "}
                <Button className="ml5" onClick={handleAdd}>
                  <PlusOutlined />
                  追加
                </Button>
              </h5>
            </Col>
          </Row>
        </Form>
      </ContentTitle>
      <h5
        style={{
          float: "right",
          margin: "auto",
          marginBottom: "10px",
          paddingRight: "20px",
        }}
      >
        ※1ファイル30MB全ファイル100MB未満のjpg.png形式のファイルを指定してください。
      </h5>
      <Table
        columns={columns}
        dataSource={listMap}
        pagination={false}
        style={{ marginBottom: "10px" }}
       scroll={{ x: 'max-content' }}
        responsive
      />

      <Row justify="end" className="mt20">
        <Col>
          <Button
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              confirmSave();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={() => {
              backToSearch();
            }}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
      <input
        ref={fileRef}
        onChange={handleChangeInputFile}
        type="file"
        className="d-none"
        accept=".png, .jpg"
      />
    </Content>
  );
}

export default MapSetting;

export const LinkImage = ({ url, img: imgProp, imageKey }) => {
  const [display, setDisplay] = useState(false);
  const [img, setImg] = useState(imgProp);
  const fileName = url;

  useEffect(() => {
    setImg(imgProp);
  }, [imgProp]);

  useEffect(() => {
    if (img || !display) return;
    const fetch = async () => {
      try {
        const res = await API.getMapImage({
          imageKey: imageKey,
        });
        setImg(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    fetch();
  }, [display]);

  const base64 = (img || "").startsWith("data:image")
    ? img
    : `data:image/png;base64,${img}`;
  return (
    <>
      <div
        title={fileName}
        className="display-1-line cursor-pointer"
        onClick={() => setDisplay(true)}
        style={{
          color: "blue",
          textDecoration: "underline",
        }}
      >
        {fileName}
      </div>
      <Modal
        open={display}
        onCancel={() => setDisplay(false)}
        width={"80%"}
        footer={false}
      >
        <img width={"100%"} src={base64} alt="Base64 Image" />
      </Modal>
    </>
  );
};
