import Api from "@src/api";
export default {
  searchGroup(params={}) {
    return Api().get("/api/Group", { params });
  },

  sendEmail(params) {
    return Api().get(`/api/Group/Email/${params}`  );
  },
  getOffice() {
    return Api().get(`api/Office`);
  },
  // getGroup1(params) {
  //   return Api().get("api/Group", { params });
  // },
  getGroup(params) {
    return Api().get("api/UserRole", { params });
  },
  getAllGroup() {
    return Api().get("api/Group");
  },
  getUserGroupID(id) {
    return Api().get(`api/Group/${id}`);
  },
  
  getUpdateGroupID(id) {
    return Api().get(`api/Group/updatestatus/${id}`);
  },

  getCompany(params) {
    return Api().get("/api/Company", {params});
  },
  getDetailGroup(id) {
    return Api().get(`/api/Group/${id}`);
  },
  save(formData, id) {
    return id
      ? Api().put(`/api/Group/`,formData)
      : Api().post("/api/Group", formData);
  },
  deleteGroup(id){
    return Api().delete(`/api/Group/${id}`);
  },
  downLoadGroup(params={}){
    return Api().get(`/api/Group/Download`,{
      responseType: "blob",
      params
    });
  },
  importGroup(officeId, formData) {
    return Api().post(`/api/Group/Import/${officeId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

};
