/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect, useRef } from "react";
import { Input, Button, Select, Modal } from "antd";
import { Col, Row } from "antd";
import {
  COMMON_CONSTANT,
  KEY,
  lang,
  STYLE,
} from "../../constants/common.const";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TitlePage from "../../component/Desktop/titlePage";
import { useLocation } from "react-router-dom";
import BreakLine from "../../component/Desktop/breakLine";
import RowInput from "../../component/Desktop/rowInput";
import { useParams } from "react-router-dom";
import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import GroupItem from "../../component/Desktop/groupItem";
import classText from "../../models/control/text";
import { ApiOffice } from "../../api/backend/headerQuaters";
import CompayApi from "./../../api/backend/company";
import {
  getQuerySearch,
  renderOption,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";
import Loading from "../../component/Desktop/loading/loading";
import classDdl from "../../models/control/dropdownlist";
import useWidthInput from "../../hooks/useWidthInput";
import useAuth from "../../hooks/useAuth";

let FLAG_CHANGE_DATA = false;
function HeadQuaterAdd() {
  let { id } = useParams();
  const [initPage] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const modeView = location.pathname.includes("view");

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const { isSingleCompany, companyName, companyId } = useAuth();

  // Data
  const { companyId: companyIdQuery } = getQuerySearch();
  let [ddlCompany, setddlCompany] = useState(
    new classDdl(isSingleCompany ? companyId : companyIdQuery || null)
  );
  let [txtBaseCode, setTxtBaseCode] = useState(new classText());
  let [txtOfficeName, setTxtOffceName] = useState(new classText());
  const [txtCompanyName, setTxtCompanyName] = useState(new classText());

  // Focus
  let ddlCompanyEl = useRef(null);
  let txtBasenameEl = useRef(null);
  let txtSiteCodeEl = useRef(null);
  const widthInput = useWidthInput();

  // Change data
  useEffect(() => {
    if (!initPage) {
      FLAG_CHANGE_DATA = true;
    }
  }, [txtBasenameEl, txtSiteCodeEl]);

  const getInitData = async () => {
    if (id) {
      setShowLoading(true);
    }
    try {
      const [detailData, listCompany] = await Promise.all([
        id ? ApiOffice.getDetailOffice(id) : null,
        !isSingleCompany ? CompayApi.getCompany() : null,
      ]);

      const dataCompany =
        listCompany &&
        listCompany.data.data.map((i) => ({
          key: i.companyId,
          value: i.companyName,
        }));

      if (!isSingleCompany) {
        setddlCompany({
          ...ddlCompany,
          options: dataCompany,
        });
      }

      // Edit
      if (id) {
        setTxtBaseCode({
          ...txtBaseCode,
          value: detailData.data.baseCode,
        });
        setTxtOffceName({
          ...txtOfficeName,
          value: detailData.data.officeName,
        });

        if (modeView && !isSingleCompany) {
          const currentCompany = listCompany.data.data.find(
            (i) => i.companyId === detailData.data.companyId
          );
          if (currentCompany) {
            setTxtCompanyName({
              ...txtCompanyName,
              value: currentCompany.companyName,
            });
          }
        } else {
          if (!isSingleCompany) {
            setddlCompany({
              ...ddlCompany,
              options: dataCompany,
              value: detailData.data.companyId,
            });
          }
        }
      }
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    getInitData();
  }, [id]);

  // Popup common
    const showConfirmMessage = (typeInfo = false) => { return; };

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA) {
      backToSearch();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      showConfirmMessage(COMMON_CONSTANT.WF_C018);
    }
  }

  // Validate
  const validateData = () => {
    let flagError = false;
    txtOfficeName = { ...txtOfficeName, error: false };
    txtBaseCode = { ...txtBaseCode, error: false };
    ddlCompany = { ...ddlCompany, error: false };

    if (!txtOfficeName.value) {
      flagError = true;
      txtOfficeName = { ...txtOfficeName, error: true };
      txtBasenameEl.current.focus();
    }

    if (!txtBaseCode.value) {
      flagError = true;
      txtBaseCode = { ...txtBaseCode, error: true };
      txtSiteCodeEl.current.focus();
    }

    if (!ddlCompany.value) {
      flagError = true;
      ddlCompany = { ...ddlCompany, error: true };
      ddlCompanyEl.current.focus();
    }

    setddlCompany({ ...ddlCompany });
    setTxtBaseCode({ ...txtBaseCode });
    setTxtOffceName({ ...txtOfficeName });
    return !flagError;
  };

  // Save Data
  const onSave = async () => {
    // Call Api
    const body = {
      officeId: id,
      baseCode: txtBaseCode.value,
      officeName: txtOfficeName.value,
      companyId: ddlCompany.value,
    };
    try {
      setShowLoading(true);
      const res = await ApiOffice.save(body, id);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
      backToSearch();
    } catch (e) {
      showErrorMessage(e);
    } finally {
      setShowLoading(false);
    }
  };

  const handleSaveData = () => {
    // Validate Data
    if (!validateData()) {
      return;
    }
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: id ? COMMON_CONSTANT.ECM_C009 : COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        onSave();
      },
    });
  };

  return (
    <div className="des-content">
      <TitlePage name={lang.HEAD_QUATER_LIST} />
      <GroupItem>
        <BreakLine />
        <RowInput box name={lang.COMPANY} required={!modeView}>
          {!modeView ? (
            <div>
              {!isSingleCompany ? (
                <Select
                  showSearch
                  optionFilterProp="children"
                  value={ddlCompany.value}
                  ref={ddlCompanyEl}
                  className={
                    ddlCompany.error
                      ? `${STYLE.BORDER_RED} ${widthInput}`
                      : `${widthInput}`
                  }
                  onChange={(v) =>
                    setddlCompany({
                      ...ddlCompany,
                      value: v,
                    })
                  }
                >
                  {renderOption(ddlCompany.options, false)}
                </Select>
              ) : (
                <Input value={companyName} disabled className={widthInput} />
              )}
            </div>
          ) : (
            <>
              <div className="white-space">
                {" "}
                {isSingleCompany ? companyName : txtCompanyName.value}
              </div>
            </>
          )}
        </RowInput>
        <BreakLine />
        <RowInput box name={lang.SITE_CODE} required={!modeView}>
          {!modeView ? (
            <>
              <Input
                value={txtBaseCode.value}
                ref={txtSiteCodeEl}
                className={
                  txtBaseCode.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={100}
                onChange={(e) =>
                  setTxtBaseCode({
                    ...txtBaseCode,
                    value: e.target.value,
                  })
                }
              ></Input>
            </>
          ) : (
            <>
              <div className="white-space"> {txtBaseCode.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />
        <RowInput box name={lang.BASE_NAME} required={!modeView}>
          {!modeView ? (
            <>
              <Input
                value={txtOfficeName.value}
                ref={txtBasenameEl}
                className={
                  txtOfficeName.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={100}
                onChange={(e) =>
                  setTxtOffceName({
                    ...txtOfficeName,
                    value: e.target.value,
                  })
                }
              ></Input>
            </>
          ) : (
            <>
              <div className="white-space"> {txtOfficeName.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          {!modeView && (
            <Button
              className="buttonPC button--info wAuto ml15 mb5 mt5"
              onClick={() => {
                handleSaveData();
              }}
            >
              <SaveOutlined />
              {lang.SAVE}
            </Button>
          )}
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={cancelSaveData}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
      <Loading show={showLoading} />
    </div>
  );
}

export default HeadQuaterAdd;
