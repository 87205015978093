import Api from "@src/api";
export default {
  getAdmin() {
    return Api().get("/api/UserRole");
  },
  getOffice() {
    return Api().get(`api/Office`);
  },
  getListUserRole(params) {
    return Api().get(`/api/UserRole`, { params });
  },
  searchPeople(params) {
    return Api().get(`/api/UserRole`, { params });
  },
  searchOffice(params) {
    return Api().get("api/Office", { params });
  },

  save(formData, id) {
    return id
      ? Api().put(`/api/UserRole/${id}`,formData)
      : Api().post("/api/UserRole", formData);
  },
  deleteUSer(id) {
    return Api().delete(`/api/UserRole/${id}`)
  },
  downloadListUSer(params={}) {
    return Api().get(`/api/UserRole/Download`,{
      responseType: "blob",
      params
    })
  },
  importUser(role,formData,params={}) {
    return Api().post(`/api/UserRole/Import/${role}`,formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params
      
    });
  },
  getDetailUser(id){
    return Api().get(`/api/UserRole/${id}`)
  },
  unLockUser(id){
    return Api().put(`/api/UserRole/Unlock/${id}`)
  },
  getuserNameLogin(params){
    return Api().get("/api/UserRole/GetUserId",{
      params
    })
  },

  getByUser(params){
    return Api().get("/api/UserRole?worker=true",{
      params
    })
  }
};
