import {
  Button,
  Col,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import TitlePage from "../../component/Desktop/titlePage";

import classGrid from "../../models/control/grid";

import { DeleteOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import {
  MSG_CATCH,
  getQuerySearch,
  pushObject2Query,
  renderOption,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";

import ApiCompany from "../../api/backend/company";
import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  PAGE_SIZE,
  STYLE,
  lang,
} from "../../constants/common.const";
import { ROUTER_PAGE } from "../../constants/router.const";

import ContentTitle from "../../component/Desktop/contentTitle";
import classDdl from "../../models/control/dropdownlist";
import { onChangeTable } from "../../utils/helpers";

const { Content } = Layout;

function Company() {
  let objSearch = getQuerySearch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const openLoadingSpinner = () => {
    setShowLoading(true);
  };
  const closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const [formSearch] = Form.useForm();

  //  Grid
  const [grid, setGrid] = useState(new classGrid());
  // Input search
  // 1
  const [ddlCompanyName, setDdlCompanyName] = useState({
    ...new classDdl(objSearch.companyName),
  });
  const [ddlOfficeCode, setDdlOfficeCode] = useState({
    ...new classDdl(objSearch.officeCode),
  });

  // Step2: Sync input <-> url
  const syncInput = () => {
    objSearch.officeCode = ddlOfficeCode.value
      ? ddlOfficeCode.value.trim()
      : null;
    objSearch.companyName = ddlCompanyName.value
      ? ddlCompanyName.value.trim()
      : null;

    // Push to url
    pushObject2Query(objSearch);
  };

  // Search
  const search_onClick = async (
    currentPage = 1,
    sortKey = "",
    sortOrder = "desc"
  ) => {
    syncInput();
    setPage(currentPage);
    setSortKey(sortKey);
    setSortOrder(sortOrder);
    objSearch = getQuerySearch();

    openLoadingSpinner();
    // input to objectSearch; push url

    // Update format

    try {
      const resPeople = await ApiCompany.searchCompany({
        ...objSearch,
        offset: (currentPage - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        sortKey,
        sortOrder,
      });
      setGrid({
        ...grid,
        data: resPeople.data.data,
        count: resPeople.data.count,
      });
    } catch (error) {
      showMessageError(MSG_CATCH());
    } finally {
      closeLoadingSpinner();
    }
  };

  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  useEffect(() => {
    const fetchCompanyOption = async () => {
      try {
        const response = await ApiCompany.searchCompany();
        let data = response.data.data;
        let dataCompanyName = [...new Set(data.map((obj) => obj.companyName))];
        dataCompanyName = dataCompanyName.map((item) => {
          return {
            value: item,
            key: item,
          };
        });
        // const dataCompanyName = data.map((item) => {
        //   return {
        //     value: item.companyName,
        //     key: item.companyId,
        //   };
        // });
        setDdlCompanyName({
          ...ddlCompanyName,
          options: dataCompanyName,
        });

        let dataOfficeCode = [...new Set(data.map((obj) => obj.officeCode))];
        dataOfficeCode = dataOfficeCode.map((item) => {
          return {
            value: item,
            key: item,
          };
        });

        // const dataOfficeCode = data.map((item) => {
        //   return {
        //     value: item.officeCode,
        //     key: item.companyId,
        //   };
        // });
        setDdlOfficeCode({
          ...ddlOfficeCode,
          options: dataOfficeCode,
        });
      } catch (e) {
        console.log(e);
      }
    };
    fetchCompanyOption();
  }, []);

  useEffect(() => {
    search_onClick(1, sortKey, sortOrder);
  }, [ddlOfficeCode.value, ddlCompanyName.value]);

  const showMessageDeleteConfirm = async (officeId) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();
      try {
        Modal.destroyAll();
        await ApiCompany.deleteCompany(officeId);
        showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C004);
        search_onClick();
      } catch (ex) {
        showErrorMessage(ex);
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.ECM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  let initColumns = [
    {
      title: "企業コード",
      dataIndex: "officeCode",
      width: "33%",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "会社名",
      dataIndex: "companyName",
      align: "start",
      width: "33%",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "設定変更",
      dataIndex: "settingChange",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            <Tooltip title={lang.CLONE}>
              <Button
                onClick={() =>
                  history.push(
                    ROUTER_PAGE.COMPANY_EDIT.replace(":id", row.companyId)
                  )
                }
                className="mr5 ml10"
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: lang.DELETE,
      dataIndex: "delete",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                className=""
                onClick={() => {
                  showMessageDeleteConfirm(row.companyId);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={"取引会社"} />
        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
        >
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin ">
            <Col lg={8} md={10} sm={24} xs={24}>
              <div className="ant-form-item-label">企業コード </div>
              {/* <Input
                value={txtOfficeCode.value}
                onChange={(e) => {
                  setTxtOfficeCode({ ...txtOfficeCode, value: e.target.value });
                }}
              /> */}

              <Select
                style={{
                  width: "100%",
                }}
                showSearch
                optionFilterProp="children"
                value={ddlOfficeCode.value}
                className={
                  ddlOfficeCode.error ? `${STYLE.BORDER_RED} w400` : "w400"
                }
                onChange={(value) => {
                  setDdlOfficeCode({
                    ...ddlOfficeCode,
                    value,
                  });
                  setDdlCompanyName({
                    ...ddlCompanyName,
                    value: "",
                  });
                }}
              >
                {renderOption(ddlOfficeCode.options)}
              </Select>
            </Col>

            <Col lg={8} md={10} span={24}>
              <div className="ant-form-item-label">会社名</div>

              <Select
                style={{
                  width: "100%",
                }}
                showSearch
                optionFilterProp="children"
                value={ddlCompanyName.value}
                className={
                  ddlCompanyName.error ? `${STYLE.BORDER_RED} w400` : "w400"
                }
                onChange={(value) => {
                  setDdlCompanyName({
                    ...ddlCompanyName,
                    value,
                  });
                  setDdlOfficeCode({
                    ...ddlOfficeCode,
                    value: "",
                  });
                }}
              >
                {renderOption(ddlCompanyName.options)}
              </Select>
            </Col>
            <Col lg={8} md={4} xs={24} sm={24}>
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    onClick={() => history.push(ROUTER_PAGE.COMPANY_ADD)}
                    className="buttonPC button--info wAuto ml10"
                  >
                    <PlusOutlined className="" />
                    {COMMON_CONSTANT.ADD}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </ContentTitle>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}>
            {/* <Button type="link" className="btnAddRecord mb15">
                <AddIcon />
                {lang.REQUEST_CREATE}
              </Button> */}
          </Col>
          <Col span={12} className="right" style={{ paddingRight: "20px" }}>
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.officeId}
          className="gridSearch "
          columns={initColumns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,
            total: grid.count,
            current: page || 1,
            showSizeChanger: false,
          }}
         scroll={{ x: 'max-content' }}
          onChange={onChangeTable(search_onClick)}
        />
      </Content>
      {/* <Loading show={showLoading} /> */}
    </div>
  );
}

export default Company;
