/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Modal, Radio, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import BreakLine from "../../component/Desktop/breakLine";
import GroupItem from "../../component/Desktop/groupItem";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput";
import TitlePage from "../../component/Desktop/titlePage";

import {
  COMMON_CONSTANT,
  KEY,
  STATUS_INSTALL_GATEWAY,
  STYLE,
  lang,
} from "../../constants/common.const";

import {
  MSG_CATCH,
  formatListOptionOffice,
  getQuerySearch,
  renderOption,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";
import useWidthInput from "../../hooks/useWidthInput";

import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";

import classText from "../../models/control/text";
import classDdl from "../../models/control/dropdownlist";
import { HeatWatcherApi } from "../../api/backend/heatWatcher";
import { ApiOffice } from "../../api/backend/headerQuaters";
import useAuth from "../../hooks/useAuth";

function CreateRequest() {
  let { id } = useParams();
  const history = useHistory();
  let [showLoading, setShowLoading] = useState(false);
  const [modeEdit, setModeEdit] = useState(true);
  const objSearch = getQuerySearch();
  const initLoad = useRef(true);
  const widthInput = useWidthInput();

  // Loading
  const { officeId, isSiggleOffice, officeName, baseCode } = useAuth();

  let [ddlBasename, setddlBasename] = useState(
    new classDdl(isSiggleOffice ? officeId : objSearch.OfficeId)
  );
  let [txtSiteCode, settxtSiteCode] = useState(new classText());
  let [txtHeatWatcherId, settxtHeatWatcherId] = useState(new classText());
  let [txtInstallationLocation, settxtInstallationLocation] = useState(
    new classText()
  );
  let [installationStatus, setdInstallStatus] = useState(
    new classDdl(!id ? STATUS_INSTALL_GATEWAY.ALREADY_INSTALLED : "")
  );
  const [status, setStatus] = useState();
  // Focus
  let ddlBasenameEl = useRef(null);
  let txtSiteCodeEl = useRef(null);
  let txtHeatWatcherIdEl = useRef(null);
  let txtddlInstallationLocationEl = useRef(null);
  let installationStatusEL = useRef(null);

  // Validate
  const validateData = () => {
    let flagError = false;

    setddlBasename({ ...ddlBasename, error: false });
    settxtSiteCode({ ...txtSiteCode, error: false });
    settxtHeatWatcherId({ ...txtHeatWatcherId, error: false });
    setdInstallStatus({ ...installationStatus, error: false });

    if (!installationStatus.value) {
      flagError = true;
      setdInstallStatus({ ...installationStatus, error: true });
      installationStatusEL.current.focus();
    }

    if (!txtHeatWatcherId.value) {
      flagError = true;
      settxtHeatWatcherId({ ...txtHeatWatcherId, error: true });
      txtHeatWatcherIdEl.current.focus();
    }

    if (!txtSiteCode.value && !isSiggleOffice) {
      flagError = true;
      settxtSiteCode({ ...txtSiteCode, error: true });
      txtSiteCodeEl.current.focus();
    }

    if (!ddlBasename.value) {
      flagError = true;
      setddlBasename({ ...ddlBasename, error: true });
      ddlBasenameEl.current.focus();
    }

    return !flagError;
  };
  // Post
  const postData = async () => {
    try {
      setShowLoading(true);
      let dataPost = {
        officeId: ddlBasename.value,
        serialNumber: txtHeatWatcherId.value,
        installationStatus: installationStatus.value,
        locationName: txtInstallationLocation.value,
        status: id ? status || "0" : "0",
      };

      await HeatWatcherApi.save(dataPost, id);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
      history.goBack();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  // Save Data
  const onSave = () => {
    // Validate Data
    if (!validateData()) {
      return;
    }
    // Call Api
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: id ? COMMON_CONSTANT.ECM_C009 : COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };
  function backToSearch() {
    history.goBack();
  }

  // getInit data
  const getIniData = async () => {
    if (id) {
      setShowLoading(true);
    }
    try {
      const [resDataDetail, resListOffice] = await Promise.all([
        id ? HeatWatcherApi.getDetaiHeatWatcher(id) : null,
        !isSiggleOffice ? ApiOffice.searchOffice({}) : null,
      ]);
      const dataDetail = resDataDetail ? resDataDetail.data : null;

      const listOffice =
        resListOffice && formatListOptionOffice(resListOffice.data.data);
      if (id) {
        if (!isSiggleOffice) {
          setddlBasename({
            ...ddlBasename,
            options: listOffice,
            value: dataDetail.officeId,
          });
          const siteCode = getSiteCode(listOffice, dataDetail.officeId);
          settxtSiteCode({ ...txtSiteCode, value: siteCode });
        }
        setdInstallStatus({
          ...installationStatus,
          value: dataDetail.installationStatus,
        });

        settxtHeatWatcherId({
          ...txtHeatWatcherId,
          value: dataDetail.serialNumber,
        });
        settxtInstallationLocation({
          ...txtInstallationLocation,
          value: dataDetail.locationName,
        });
        setStatus(dataDetail.status);
      } else {
        if (!isSiggleOffice) {
          setddlBasename({
            ...ddlBasename,
            options: listOffice,
          });
          const siteCode = getSiteCode(listOffice, ddlBasename.value);
          settxtSiteCode({ ...txtSiteCode, value: siteCode });
        }
      }
    } catch (error) {
      console.log(error);
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      initLoad.current = false;
      setShowLoading(false);
    }
  };

  const getSiteCode = (listOffice, officeId) => {
    const officeItem = listOffice.find((i) => i.key === officeId);
    return officeItem ? officeItem.baseCode : "";
  };

  useEffect(() => {
    if (!initLoad.current && !isSiggleOffice) {
      const sideCode = getSiteCode(ddlBasename.options, ddlBasename.value);
      settxtSiteCode({ ...txtSiteCode, value: sideCode });
    }
  }, [ddlBasename.value]);

  useEffect(() => {
    getIniData();
  }, []);

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.HEAT_WATCHER_SETTING} />

      {/* Group 1 */}
      <GroupItem>
        <BreakLine />
        <RowInput box name={lang.BASE_NAME} required={modeEdit}>
          {modeEdit ? (
            <>
              {isSiggleOffice ? (
                <Input disabled value={officeName} className={widthInput} />
              ) : (
                <Select
                  showSearch
                  optionFilterProp="children"
                  disabled={id}
                  required
                  value={ddlBasename.options.length ? ddlBasename.value : ""}
                  ref={ddlBasenameEl}
                  className={
                    ddlBasename.error
                      ? `${STYLE.BORDER_RED} ${widthInput}`
                      : `${widthInput}`
                  }
                  onChange={(v) =>
                    setddlBasename({
                      ...ddlBasename,
                      value: v,
                    })
                  }
                >
                  {renderOption(ddlBasename.options, false)}
                </Select>
              )}
            </>
          ) : (
            <>
              <div className="white-space"> {ddlBasename.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.SITE_CODE} required={modeEdit}>
          {modeEdit ? (
            <>
              <Input
                disabled
                value={isSiggleOffice ? baseCode : txtSiteCode.value}
                ref={txtSiteCodeEl}
                className={
                  txtSiteCode.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                onChange={(e) =>
                  settxtSiteCode({
                    ...txtSiteCode,
                    value: e.target.value,
                  })
                }
              ></Input>
            </>
          ) : (
            <>
              <div className="white-space"> {txtSiteCode.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.HEAT_WATCHER_ID} required={modeEdit}>
          {modeEdit ? (
            <>
              <div>
                <Input
                  disabled={id}
                  value={txtHeatWatcherId.value}
                  ref={txtHeatWatcherIdEl}
                  className={
                    txtHeatWatcherId.error
                      ? `${STYLE.BORDER_RED} ${widthInput}`
                      : `${widthInput}`
                  }
                  maxLength={25}
                  onChange={(e) =>
                    settxtHeatWatcherId({
                      ...txtHeatWatcherId,
                      value: e.target.value,
                    })
                  }
                ></Input>
                <div className="fs12">SisMil子機前面右下のID（6桁）</div>
              </div>
            </>
          ) : (
            <>
              <div className="white-space"> {txtHeatWatcherId.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.INSTALLATION_STATUS} required={modeEdit}>
          <Radio.Group
            name="installationStatus"
            className={"w400px"}
            value={installationStatus.value}
            ref={installationStatusEL}
            maxLength={128}
            onChange={(e) =>
              setdInstallStatus({
                ...installationStatus,
                value: e.target.value,
              })
            }
          >
            <Radio value={STATUS_INSTALL_GATEWAY.ALREADY_INSTALLED}>
              {lang.ALREADY_INSTALL}
            </Radio>
            <Radio value={STATUS_INSTALL_GATEWAY.NOT_INSTALLED}>
              {lang.NOT_INSTALL}
            </Radio>
          </Radio.Group>
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.INSTALLATION_LOCATION} required={false}>
          {modeEdit ? (
            <>
              <Input
                value={txtInstallationLocation.value}
                ref={txtddlInstallationLocationEl}
                className={
                  txtInstallationLocation.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={255}
                onChange={(e) =>
                  settxtInstallationLocation({
                    ...txtInstallationLocation,
                    value: e.target.value,
                  })
                }
              ></Input>
            </>
          ) : (
            <>
              <div className="white-space">{txtInstallationLocation.value}</div>
            </>
          )}
        </RowInput>

        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          <Button
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              //confirmSaveData();
              onSave();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            onClick={() => backToSearch()}
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CreateRequest;
