export const TYPE_INPUT = {
  NUMBER: "10000",
  TEXT: "01000",
  SELECT: "00100",
  RATIO: "00010",
  TEXT_AREA: "00001",
};

export const COMMON_CONSTANT = {
  INPUT_FORMAT_NUM: "数字",
  INPUT_FORMAT_CHAR: "文字",
  INPUT_FORMAT_CHAR_1: "テキスト",
  INPUT_FORMAT_CHOI: "選択",
  INPUT_FORMAT_CHOI_1: "選択肢",
  INPUT_FORMAT_YESNO: "無/有",
  INSPECTION_TIMING_OTHER: "その他",
  // ECM_I001: "データがありません。",
  ECM_I002: "アイテムが既に点検表に追加されています。",
  ECM_I003: "機器が既に追加されています。",
  ECM_C001: "削除してもよろしいですか？", // Delete Are you sure you want to?
  ECM_C002: "正常に取り込まれました。",
  ECM_C003: "正常に保存しました。", //"Saved successfully.",
  ECM_C004: "正常に削除しました。", // "Deleted successfully.",
  ECM_C005: "正常に出力されました。",
  ECM_C006: "保存してもよろしいですか？", // Are you sure you want to save it?
  ECM_C008: "入力内容を登録してもよろしいですか？", //Are you sure you want to register the input contents?
  ECM_C009: "入力内容を更新してもよろしいですか？", //Are you sure you want to update your entry?
  ECM_C013: "この点検表を承認してもよろしいですか？", // Are you sure you want to approve this checklist?
  ECM_C018:
    "いくつかの値が変更されています。このページから離れてもよろしいですか？", // Some values have changed. Are you sure you want to leave this page?
  ECM_C019: "この点検表を拒否してもよろしいですか？",
  ECM_C021: "点検頻度を変更してもよろしいですか？", // Are you sure you want to change the inspection frequency?
  ECM_C022: "選択したレコードに変更があるため、操作が完了しませんでした。",
  ECM_C030:
    "選択した点検表は既に{user}さんによってアクセスされています。後でもう一度お試しください。",
  ECM_C032: "この機器を削除してもよろしいですか？",
  ECM_C033: "このグループを削除してもよろしいですか？",
  ECM_C035:
    "点検表にデータが既に入力されているため、この操作を実施できません。",
  ECM_C038: "この点検表を承認解除してもよろしいですか？",
  ECM_C039: "権限を変更します。よろしいですか?",
  ECM_C040: "MAPの位置を変更しますと連携されている親機/子機とシスミルの位置情報も更新されます。変更してもよろしいですか？",
  ECM_E003:
    "ファイルフォーマットが無効です。正しいフォーマットを選択してください。",
  ECM_E004:
    "インターネットに接続されていません。接続してから再度お試しください。", //Bạn không kết nối với Internet. Vui lòng kết nối và thử lại.
  ECM_E005: "出力するデータが存在していません。",
  ECM_E006: "ファイル内容が無効です。他のファイルを選択してください。",
  ECM_E007: "タイムアウトしました。もう一度お試しください。", // Hết giờ
  ECM_E008: "サーバーエラーです。もう一度お試しください。", // Server error
  ECM_E009: "入力したデータは既にシステムに存在しています。",
  ECM_E015: "カメラの起動に失敗しました。",
  ECM_E018:
    "部品コードの名前は既にシステムに存在しています。名前を変更して再度お試しください。",
  ECM_E021:
    "「出庫数量」フィールドには「在庫数量」フィールドの値より高い値を入力できません。値を変更して再度お試しください。",
  ECM_E022:
    "日付のフィールドには今日以前の日付を入力しなければなりません。再度お試しください。",
  ECM_E023: "有効期限は投稿日以降の日付を入力してください。",
  ECM_E025:
    "「製造年月日」フィールドには「入庫日」フィールドの値より高い値を入力できません。値を変更して再度お試しください。",
  ECM_E026:
    "「製造年月日」フィールドには「出庫日」フィールドの値より高い値を入力できません。値を変更して再度お試しください。",
  ECM_E027:
    "機器分類マスターの名前は既にシステムに存在しています。名前を変更して再度お試しください。",
  ECM_E028: "点検区分を変更してもよろしいですか？", // bạn có muốn thay đổi phân loại kiểm chứng không?
  ECM_E029: "サーバーエラーのため失敗しました。",
  ECM_E030: "入力データが無効です。再チェックしてください。", // Invalid input data. Please check again.
  ECM_E031: "全ての入力必須フィールドにデータを入力していません。",
  ECM_E032: "出庫数量は在庫数量以下でなければなりません。",
  ECM_E033: "入庫数量は0より大きい値でなければなりません。",
  ECM_E034: "出庫数量は0より大きい値でなければなりません。",
  EMPTY_DATA: "データがありません。",
  EDIT_OPTION: "編集",
  VIEW_OPTION: "閲覧",
  DELETE_OPTION: "削除",
  CUSTOM_LIST_CHECK_ITEM: "CheckItem",
  WORK_REPORT_STATUS_OPENED: "オープン",
  WORK_REPORT_STATUS_REJECTED: "拒否済",
  WORK_REPORT_STATUS_RESOLVED: "解決済",
  WORK_REPORT_STATUS_CONFIRMED: "確認済",
  WORK_REPORT_STATUS_APPROVED: "承認済",
  WORK_REPORT_STATUS_REOPENED: "再開済",
  NEW_DATA_TEMPLATE_CODE: "#{0}#HD{1}",
  SPECIAL_CHARACTER: {},

  REJECTED: "REJECTED",
  CONFIRMED: "CONFIRMED",
  APPROVED: "APPROVED",
  RESOLVED: "SOLVED",

  SAVE_DRAFT: "下書きとして保存",
  SAVE: "保存 ",
  CLEAR: "キャンセル",
  TITLE_UPDATE_GROUP: "グループを更新",
  TITLE_CREATE_GROUP: "グループを追加",
  TITLE_DEL_GROUP: "グループ削除",
  GROUP_NAME: "グループ名",
  GROUP_LEADER: "グループリーダー",
  CONTRACT_LEADER: "契約ユニットリーダー",

  GROUP_SUPPLEMENTER: "グループサブリーダー",
  CONTRACT_SUPPLEMENTER: "契約ユニットサプリーター",
  GROUP_TYPE: "グループ種類",
  CLOSE: "閉じる",
  ADD: "追加 ",
  UPDATE: "保存 ",
  BACK: "戻る",
  GROUP_NAME_EXISTS: "このグループ名は存在する。",
  WARNING_SETTING_CHIEF_ROLE:
    "所長権限を設定する場合は、情報システムGの承認が必要です。",
  ASSIGN_BY_ME: "自分作成",
  ASSIGN_BY_CHIEF: "所長作成",
};

export const lang = {
  COMPANY: "会社",
  SYSTEM_NAME: "GRIFFY wear.",
  HOME: "ホームページ",
  ALERT_LIST: "アラート",
  HEAD_QUATER_LIST: "拠点設定",
  PHYSICAL_CONDITION_LIST: "体調",
  PHYSICAL_CONDITION_DETAIL: "詳細な体調",
  SAFETY_BELT_LIST: "安全帯",
  MAP_LIST: "マップ",
  MAP_DETAIL: "マップ詳細",
  MAP_DEVICE: "機器設定",
  DEVICE: "デバイス",
  VITAL_DEVICE_LIST: "デバイス",
  VITAL_DEVICE_SETTING: "デバイス設定",
  VITAL_DEVICE_FILE: "デバイスファイル一括登録",
  GATEWAY_LIST: "親機/子機",
  GATEWAY_FILE: "親機/子機ファイル一括登録",
  HEAT_WATCHER_LIST: "SisMil",
  HEAT_WATCHER_SETTING: "SisMil設定",
  HEAT_WATCHER_FILE: "SisMilファイルー括登録",

  // Admin unit service
  ADMIN_UNIT_SERVICE_LIST: "サービスユニット",
  ADMIN_UNIT_SERVICE_ADD: "サービスユニット設定",
  ADMIN_UNIT_SERVICE_INPUTFILE: "サービスユニットフアイルー括登録",
  // Admin unit contract
  ADMIN_UNIT_CONTRACT_LIST: "契約ユニット",
  ADMIN_UNIT_CONTRACT_ADD: "契約ユニット設定",
  ADMIN_UNIT_CONTRACT_INPUTFILE: "契約ユニットフアイルー括登録",
  BASE_ADMINISTRATOR_LIST: "拠点管理者",
  BASE_ADMINISTRATOR_SETTING: "拠点管理者設定",
  BASE_ADMINISTRATOR_FILE: "拠点管理者フアイルー括登録",
  BASE_ADMINISTRATOR_CREATE: "拠点管理者設定",
  PARENT_CHILD_DEVICE_SETTING: "親機/子機設定",
  PARENT_CHILD_DEVICE_TYPE: "親機/子機種別",
  PARENT_CHILD_DEVICE_ID: "親機/子機ID",
  WORKER_LIST: "作業者",
  WORKER_SETTING: "作業者設定",
  WORKER_FILE: "作業者フアイルー括登録",
  GROUP_LIST: "グループ",
  GROUP_LIST_SETTING: "グループ設定",
  GROUP_FILE: "グループファイル一括登録",
  FILE: "ファイル一括登録",
  REPORT: "レポート",
  MASTER_SETTING: "拠点マスター設定",
  MAP_SETTING: "マップ設定",
  MAIN_UNIT: "親機",
  MAIN_SUB_DEVICE: "親機/子機",
  COR_HANDSET: "子機",
  HEAT_WATCHER_INDEX: "SisMil",
  CURREENT_PASSWORD: "現在のパスワード",
  NEW_PASSWORD: "新しいパスワード",
  CONFIRM_NEW_PASSWORD: "新しいパスワード(確認)",

  CONTRACT_UNIT_CODE: "契約ユニツトコード",
  CONTRACT_UNIT_NAME: "契約ユニツト名",
  UPDATE_DISPLAY: "表示を更新",
  UPLOAD: "アップロード",
  ADMIN_GROUP: "グループ管理者",
  GROUP_COMMON: "共通",
  GROUP_MEMBER: "グループメンバー",
  CONTRACT_MEMBER: "契約ユニットメンバー",
  GROUP_USER: "個人",
  NEWEST_VERSION: "最新バージョン",
  NEWEST_INFORMATION: "表示を更新",
  OFFICIAL: "正式",
  DRAFT: "下書",
  ADDED: "追加された",
  NOT_ADDED: "追加されませんでした",
  MACHINES: "機種",
  INPUT: "入力",
  FACILITY: "施設",
  BUILDING: "棟",
  FLOOR: "階",
  ROOM: "部屋",
  PROCESS: "プロセス",
  RESET: "リセット",
  MACHINE_ONLY: "機種のみ",
  REMARK_ITEM: "備考項目",
  SENDER: "送信元",

  NAME_SYSTEM: "Envital",

  UNIT_PRICE: "単価",
  AMOUNT: "金額",
  TOTAL: "合計",
  ADD_MACHINE: "機器追加",

  NEW: "新規",
  DATE: "日",
  WEEK: "週",
  MONTH: "月",
  YEAR: "年",
  COLON: "：",

  TODO_CHECKLIST: "点検実施",
  BOOKMARK: "ブックマーク",
  CHECK_RESULT: "進行中",
  FILTER: "フィルタ",
  CHECKLIST: "点検",
  DELETE_GROUP: "グループを削除",
  EDIT_GROUP: "グループエディター",
  ADD_TO_GROUP: "グループを追加",
  CHECKLIST_NAME: "点検書式名",
  CHECKLIST_TYPE: "点検表種類",
  ALL_CHECKLIST: "すべて",
  ACTION: "アクション",
  SELECT: "選択 ",
  OK: "OK",
  ERROR: "失敗",
  SUCCESS: "成功",
  WARMING: "注意",
  SETTING: "表示設定",
  ADD: "新規作成", // Create
  ADD_CHECKLIST: "点検追加",
  CANCEL: "キャンセル",
  SEARCH: "検索 ",
  // EMPTY: "データがありません。",
  CLOSE: "閉じる",
  CLEAR: "クリアー",
  EXPORT: "ファイル出力",
  DELETE: "削除",
  DELETE_ALL: "全て削除",
  APPROVE: "承認 ",
  UNAPPROVE: "承認解除",
  REJECT: "拒否 ",
  ATTACH: "関連図面ファイル",
  BACK: "キャンセル",
  DYNAMIC_REPORT: "データグラフ確認",
  LOCATION_MACHINE: "マップ表示",
  REASON_FOR_REJECT: "拒否の理由",
  REQUIRED: "必須",
  EDIT: "編集 ",
  VERSION_UP: "バージョンアップ",
  VIEW: "閲覧",
  CLONE: "設定変更",
  CONFIRM: "確認",
  APPROVED: "承認済",
  UNAPPROVED: "未承認",
  RESOLVE: "解決 ",
  LIST_VERSION: "バージョン一覧",
  VERSION: "ver.",
  SAVE: "保存",
  DISCARD: "廃棄",
  ALL: "全て",
  AM: "午前",
  PM: "午後 ",
  SEE_ALL: "全て表示",
  EXPEND_ALL: "すべて展開",
  ID: "ID",
  NUMBER_ORDER: "No",
  REPORT_NAME: "レポート",
  REPORT_DATE: "報告日",
  REPLENISHMENT: "補充量",
  ERROR_PATTERN: "属性の形式と一致していません。",
  SUPPORT_CHECK_PERSON: "補助点検者",
  PREVIEW: "プレビュー",
  FILE_NAME: "ファイル名",
  ITEM: "項目",
  TIME: "日時",
  INSPECTOR: "担当",
  SAVE_NORMAL: "保存",
  SAVE_DRAFT: "下書保存",
  SAVE_REPORT: "作業報告",
  REPAIR_METHOD: "改修方法",
  SAVE_TROUBLE_REPORT: "トラブル報告",
  REPORT_LEVEL: "報告レベル",
  PRIORITY: "優先度",
  CHECKLIST_SCREEN: "点検画面",
  REPORT_STATUS: "トラブル報告処理状況",
  EQUIPMENT_NAME: "機器名",
  CL_OVERVIEW: "点検実施状況",
  SELECT_GROUP: "グループを選択",
  NUMBER_GROUP: "グループの数",
  TYPE_CHECKLIST: "点検頻度",
  REMARK_TYPE: "備考タイプ",
  STATE: "状態",
  STATUS: "グループ名",
  REJECT_REASON: "理由拒否",
  STATUS_MACHINE: "機種ステータス",
  PERSON_IN_CHARGE: "担当者",
  DELETE_BOOKMARK: "削除ブックマーク",
  ADD_BOOKMARK: "ブックマック追加",
  DELETE_HOMEPAGE: "ホームページ削除",
  ADD_HOMEPAGE: "ホームページ追加",
  COMMON: "共通",

  BASE_NAME: "拠点名",
  PASSWORD: "パスワード",
  CONFIRM_PASSWORD: "パスワード（確認）",
  FULL_NAME: "氏名",
  FURIGANA_NAME: "ふりがな",
  TELEPHONE_NUMBER: "電話番号",
  AFFILIATION_1: "所属1",
  AFFILIATION_2: "所属2",
  AFFILIATION_3: "所属3",
  NAME: "名前",
  CODE: "コード",
  INSTALLATION_STATUS: "設置状態",
  ALREADY_INSTALL: "設置済み",
  NOT_INSTALL: "未設置",
  UNDER_MAINTANCE: "メンテナンス中",
  LOCATION_INFORMATION: "マップ表示",
  LOCATION_INFORMATION_NAME: "設置場所",
  INSTALLATION_LOCATION: "設置場所",
  GROUP_CODE: "グループコード",
  CONTRACT_CODE: "契約ユニットコード",

  HAVE: "持たない",
  NOT_HAVE: "なし",
  ALERT_EMAIL_NOTIFICATION: "アラート通知メールアドレス",
  SITE_CODE: "拠点コード",
  HEAT_WATCHER_ID: "SisMil ID",
  ONSTIE_DRAWING: "現場図面",
  EQUIPMENT_INSTALL: "機器設定",
  BUILDING_SECTION: "棟/工区",
  FLOOR_COMPARTMENT: "フロア/区画",
  ONSTIE_BASIC_INFORMATION: "現場基本情報",
  COUNTINOUS_HEART: "持続心拍数",
  
  INSTANTANEOUS_HEART: "瞬時値心拍数",
  MAXIMUM_HEART: "最大心拍数",
  URGENT_REMINDER_REQUIRED: "要緊急对忘",
  LEVEL_MESSURE_CONDITION: "状態を計測するレベル",
  SCHOOL_TIME: "蓄積時間",
  RADIO_STRENGTH: "電波強度",
  ALRET_MUTIPLE_EMAIL_ADDRESS:
    "アラート通知メールアドレス (カンマ区切りで複数指定可能です)",
  BLOCK_SEND_ALERT_AFTER_PERIOD_TIME:
    "一定期間発報抑止",
  TYPE_DEVICE: "デバイス種別",
  SERIAL_ID: "デバイスID",
  LEADER_AUTHOR: "リーダー権限",
  NUMBER_KUSHIWA: "電話番号",
  SEXUAL: "性別",
  MALE: "男性",
  WOMAN: "女性",
  OTHERS: "その他",
  DOB: "生年月日",
  TYPE: "種別",
  SERIAL_NUMBER: "製造番号",

  INCOMPLETE: "未完成",
  EDITOR: "更新者",
  TIMES: "回目",
  MORE_TIMES: "複数回",
  TIME_CHECKING: "点検時刻",
  BEGIN_CHECK: "点検開始日時",
  END_CHECK: "点検完了日時",
  HISTORY_RECORD: "記録履歴",
  MAIN_CHECKER: "主記録者",
  CHECK_TIMES: "チェック回数",
  TIMES_NOT_VALUE: "回目の点検項目しない",
  NOTE: "備考",
  PHOTO: "写真",
  COMMENT: "コメント",
  ADD_IMAGE: "写真を追加する",
  PREVIOUS_VALUE: "前回の値",
  RANGE: "範囲",
  REFER_VALUE_PREVIEW: "値を記入",
  VALUE: "値",
  NO: "無",
  YES: "有",
  HIGH: "高",
  MIDDLE: "中",
  LOW: "低",

  NAME_SHEET: "シート名",
  NUMBER_ROW: "行番号",
  MESSAGE: "メッセージ",

  PLEASE_SELECT_SITE: "サイトオフィスを選択してください!",
  PLEASE_CHOOSE_FILE: "ファイルを入力してください!",
  DO_YOU_WANT_TO_REGISTRY: "入力内容を登録してもよろしいですか",
  PLEASE_INPUT_NAME_TEMPLATE: "テンプレート名を入力してください",
  PLEASE_INPUT_CATEGORY: "カテゴリーを入力してください",
  TEMPLATE_MUST_IS_EXCEL_FILE:
    "テンプレートはExcelファイルである必要があります!",
  INFO_TEMPLATE_REPORT: "報告テンプレート情報",
  NAME_OF_TEMPLATE_HAS_EXISTED:
    "テンプレートの名前は既にシステムに存在しています",
  GROUP_CL_HAS_EXISTED: "グループ名は既に存在しています",
  REGISTRY_TEMPLATE: "報告テンプレート登録",
  EDIT_TEMPLATE: "報告テンプレート編集",
  TEMPLATE_CODE: "テンプレートコード",
  SELECT_FILE: "インポートファイル選択",
  FILE_N: " CSVファイル選択",
  NAME_OFFICE_SITE: "事業所名",
  EXPORT_REPORT_CHECKLIST: "点検情報出力",
  EXPORT_REPORT_INVENTORY: "倉庫マスターデータ出力",
  EXPORT_MASTER_DATA: "マスタデータ出力",
  EXPORT_OFFICE_SITE_DATA: "事業所サイトデータ出力",
  EXPORT_TEMPLATE_CL: "テンプレート出力",
  DYNAMIC_CHART: "動的レポート",
  IMPORT_MASTER_DATA: "マスタデータ登録",
  IMPORT_OFFICE_SITE_DATA: "事業所サイトデータ登録",
  IMPORT_TEMPLATE_CL: "ファイル入力",

  REGISTRY: "登録",
  JUDGEMENT: "判定",
  HOMEPAGE: "ホームページ",

  AUTOMATIC_UPDATE: "自動更新",

  HIDE_WORKERS: "データなしの作業者を非表示",
  CREATED: "作成する ",
  DO_YOU_WANT_TO_OVERRIDE: "上書きしてもよろしいでしょうか？",
  OPERATION_MANAGEMENT: "運転管理",
  LOG_OUT: "ログアウト",
  LIST_GROUP_CHECKLIST: "点検表一覧",
  LIST_TARGET_CHECKLIST: "点検対象一覧",
  COPYRIGHT: "株式会社GRIFFY　 &copy;GRIFFY inc.​",
  TEMPLATE_NAME: "テンプレート名",
  APPROVER: "承認者",
  INSPECTION_DATE: "点検日付",
  INSPECTION_PERSON: "点検者",
  SHEET: "シート",
  CATEGORY: "カテゴリー",
  OTHER_DATE: "日付",
  REGISTRATION_DATE: "登録日付",
  SEARCH_KEYWORD: "検索キーワード",
  EDITING_DATE: "更新日時",
  TARGET_DATE: "対象日付",
  TARGET_WEEK: "対象週付",
  TARGET_MONTH: "対象年月",
  TARGET_YEAR: "対象年度",
  ACCESS_NUMBERS: "該当件数",
  BEGIN_DATE_TO_END_DATE: "開始日 ～ 終了日",
  CASE: "件",
  PLEASE_INPUT_NAME: "名前を入力してください",
  LOADING: "読み込み中...",
  CONNECTED: "接続済み",
  DISCONNECTED: "インターネット接続なし",
  SEND: "送信中",
  UNSENT: "未送信点検表",
  DOWNLOAD: "ダウンロード",

  REJECT_CHECKLIST_SUCCESS: "正常に拒否しました。",
  CONFIRM_CHECKLIST_SUCCESS: "正常に確認しました。",
  APPROVE_CHECKLIST_SUCCESS: "正常に承認しました。",
  SOLVED_NORMALLY: "正常に解決しました。",

  QUANTITY: "数量",
  NO_INDEX: "連番",

  ACCUMULATED_VALUE: "総累計",
  CURRENT_VALUE: "現在の値",
  ADD_COMMENT: "コメントを追加",
  PLEASE_UPLOAD_WHEN_HAS_INTERNET:
    "インターネットが不安定なエリアのため写真のアップロードができません。インターネットが安定しているエリアで再度アップロードして下さい。",

  // User management
  VIEW_USER: "VIEW_USER",
  DISPLAYNAME_USER: "表示名",
  EMAIL_USER: "メールアドレス",
  STAMP_USER: "判子",
  USER_INFORMATION: "ユーザ情報",
  AVATAR_USER: "写真",
  EDIT_USER: "ユーザー情報の編集",
  CROP_IMAGE: "写真のトリミング",
  DOWNLOAD_PDF: "ダウンロード",
  ACC_LOCK:"認証が一定回数以上失敗したためアカウントをロックしました。管理者にお問い合わせください。",
  CONFIRM_UNLOCK:"このアカウントのロック解除を確認します。",
  UNLOCK_SUCCESS:"アカウントロックを解除しました。",
  EMAIL_NOT_VALIDATE: "メール形式が正しくありません",
  ACC_LOCKED: "アカウントがロックされました。",
  TRY_LOGINID:"LoginIDが既に存在しています。新しいLoginIDが自動的に生成されましたので、もう一度お試してください",
  ERROR_TYPE_DEVICE:"既に紐づけているデバイス種別のものは選択できません。もう一度選択してください。"
};

export const KEY = {
  GROUP_TYPE_COMMON: "1",
  GROUP_TYPE_USER: "2",
  LAPTOP_WIDTH: "(min-device-width: 1000px)",
  PORTRAIT_MOBILE_WIDTH: "(max-width: 500px)", // rotate screen in mobile
  NOTI: "NOTI",
  ERROR: "ERROR",
  INFO: "INFO",
  CONFIRM: "CONFIRM",
  WARMING: "WARMING",
  DISABLED: "DISABLED",
  NUMBER: "Number",
  TEXT: "Text",
  DDL: "DropDownList",
  CHECKBOX: "Checkbox",
  TEXTAREA: "TEXTAREA",
  DATEPICKER: "DATEPICKER",
  IMPORT: 1,
  EXPORT: 2,
  SORT_TABLE: "SORT_TABLE",
  IMAGE: "image",

  APPROVE: "0",
  CUSTORMER_APPROVE: "6",
  UNAPPROVE: "1",
  REJECT: "2",
  WORKING: "3",
  DONE: "4",
  DONE_AB: "5",
  TIMING_DATE: "1",
  REMARK_DEFAULT: "1",

  // Setting menu
  LOC_USER_INFO: "uInfo",
  USER_ROLE: "userRole",
  MENU_SETTING: "menuSetting",
  MENU_ADMIN_OFFICE: "2", // office admin
  MENU_ADMIN: "1", // head office
  MENU_USER: "0",

  // Format date
  DATE_DEFAULT: "YYYY/MM/DD",
  WEEK: "YYYY-WW",
  MONTH: "YYYY/MM",
  YEAR: "YYYY",
  MONTH_DATE: "MM/dd",
  MONTH_DATE_MOMENT: "MM/DD",
  DATE_WITHOUT_HOUR: "yyyy/MM/dd",
  DATE_WITH_HOUR: "yyyy/MM/dd HH:mm",
  DATE_WITH_HOUR_DESKTOP: "YYYY/MM/DD HH:mm",
  HOUR_24H: "HH:mm",
};

export const STYLE = {
  BORDER_RED: "border-red",
};

export const CONFIG = {
  paging: { position: ["bottomCenter"], pageSize: 100, hideOnSinglePage: false },
};

export const LIMIT = 30;
export const PAGE_SIZE = 100;

// Kich thuoc anh can cat
export const SIZE_IMAGE = 300;

export const ROLE_KEY = {
  ADMIN: "1",
  OPERATOR: "11",
  CHIEF: "2",
  MEMBER: "3",
  REGULAR_APPROVER: "4",
  SPECIAL_READING: "5",
};

export const DEVICE_TYPE = {
  HEAT_WATCHER: "1",
  MAIN_DEVICE: "2",
  SUB_DEVICE: "3",
};

export const REGEX_INPUT = {
  interger: /^\d+$/,
  intergerFrom0To: (max) => {
    const regexString = `^(?:[0-9]|[1-9][0-9]{1,2}|${max})$`;
    return new RegExp(regexString);
  },
  intergerFrom0:/^(0|[1-9]\d*)$/,
  generateDecimalRegex: (maxDecimalPlaces) => {
    const regexString = `^(?:\\d+(\\.\\d{1,${maxDecimalPlaces}})?|\\d+\\.)$`;
    return new RegExp(regexString);
  },
  number: /^[0-9]*\.?[0-9]*$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
};

// KEY_LOCALSTORAGE
export const LOCALSTORAGE_KEY = {
  TOKEN: "ECM_TOKEN_25-05-2001",
  REFESH_TOKEN: "ECM_REFRESH_TOKEN_25-05-2001",
  REDIRECT_PATH: "ECM_REDIRECT_PATH_25-05-2001",
  IS_LOGIN: "ECM_ISLOGGEDIN_25-05-2001",
};

export const GATEWAY_TYPE = {
  SMART_PHONE: "2",
  GATE_WAY: "1",
};
export const GATEWAY_TYPE_VALUE = {
  MAIN: "親機",
  SUB: "子機",
  "親機":"1",
  "子機":"2"
};
export const STATUS_INSTALL_GATEWAY = {
  NOT_INSTALLED: "0",
  ALREADY_INSTALLED: "1",
  UNDER_MAINTENANCE: "9",
};

export const REQUEST_PERIOD = {
  TODAY: 0,
  YESTERDAY: 1,
  TWO_DAY_BEFORE: 2,
  THREE_DAY_BEFORE: 3,
  FOUR_DAY_BEFORE: 4,
};

export const TIME_PERIOD = {
  ALL: 0,
  AM: 1,
  PM: 2,
};
