import React from "react";
import { Select, Modal, Row, Col, Space } from "antd";
import { COMMON_CONSTANT, DEVICE_TYPE, KEY, lang } from "./common.const";
import { format } from "react-string-format";
import dayjs from "dayjs";
import { isString, isObject, isEmpty as isEmptyLodash } from "lodash";
import { MdLocationPin } from "react-icons/md";
import { GoAlertFill } from "react-icons/go";
import { FaFaceFrown, FaFaceSmile } from "react-icons/fa6";

const { Option } = Select;

export const showErrorMessage = (error) => {
  if (error.response.status == 400 && error.response.data) {
    if (error.response.data) {
      if (Array.isArray(error.response.data)) {
        const message = (
          <>
            {error.response.data.map((msg, i) => (
              <div key={"x" + i}>{msg}</div>
            ))}
          </>
        );
        showMessage(KEY.ERROR, message);
      } else if (error.response.data.messages) {
        const message = (
          <>
            {Object.values(error.response.data.messages).map((msg, i) => (
              <div key={"x" + i}>
                {lang.NUMBER_ROW}
                {/* include headerline */}
                {Number(Object.keys(error.response.data.messages)[i]) + 1}:
                <br />
                {Array.isArray(msg) ? (
                  <>
                    {Object.values(msg).map((m, z) => (
                      <div key={"z" + z}>{m}</div>
                    ))}
                  </>
                ) : (
                  { msg }
                )}
              </div>
            ))}
          </>
        );
        Modal.error({
          width: 500,
          centered: true,
          title: lang.ERROR,
          content: message,
        });
      } else {
        showMessage(KEY.ERROR, error.response.data);
      }
    } else {
      showMessage(KEY.ERROR, MSG_CATCH());
    }
  } else {
    showMessage(KEY.ERROR, MSG_CATCH());
  }
};

export const showMessage = (type, msg, callbackCancel) => {
  Modal.destroyAll();
  switch (type) {
    case KEY.ERROR:
      Modal.error({
        centered: true,
        title: lang.ERROR,
        content: msg,
        onOk: callbackCancel,
      });
      break;
    case KEY.WARMING:
      Modal.warning({
        centered: true,
        title: lang.WARMING,
        content: msg,
        onOk: callbackCancel,
      });
      break;
    case KEY.INFO:
      Modal.success({
        centered: true,
        title: lang.SUCCESS,
        content: msg,
        onOk: callbackCancel,
      });
      break;
    default:
      break;
  }
};

export const showMessageChange = (
  callbackOk,
  msg = COMMON_CONSTANT.ECM_C001
) => {
  Modal.destroyAll();
  Modal.confirm({
    centered: true,
    title: lang.WARMING,
    content: msg, // default message confirm delete?
    okText: lang.OK,
    cancelText: lang.CANCEL,
    onOk: callbackOk,
  });
};

export const enterSearch = (funcSearch) => {
  return {
    onKeyDown: (e) => {
      if (e.keyCode === 13) {
        funcSearch();
      }
    },
  };
};

export const selectSearch = {
  filterOption: (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
};

export const encodeSearchText = (value) => {
  return value;
};

export const checkCharacter = (input) => {
  return checkSpecialCharacterSingle(input);
};

export const checkSpecialCharacterSingle = (input) => {
  const regEx = /^[^!@#$%^&*+=?><~"",|}{]+$/;

  if (!regEx.test(input.value)) {
    return false;
  }
  return true;
};
// check checklist exist
export const isChecklistDeleted = (CHECKLIST_ID) => {
  const changedFlag = false;
  //is checklist deleted ?
  // $.ajax({
  //     url: _spPageContextInfo.webServerRelativeUrl + "/_api/web/lists/getbytitle('Checklist')/Items?$filter=" + query,
  //     type: "GET",
  // }).done(function (data) {
  // if (data.d.results && data.d.results.length > 0) {
  //     changedFlag = true;
  // } else {
  //     changedFlag = false;
  // }
  // }).fail(function (xhr) {
  //     console.log(xhr.status + ': ' + xhr.statusText);
  // });
  return changedFlag;
};

export const validateGroup = (input) => {
  // HTML
  const newGroupName = input.value;
  if (!newGroupName || newGroupName.length > 255) {
    return false;
  } else {
    return checkSpecialCharacterSingle(input);
  }
};

// Loading
export const openLoadingSpinner = (funcShow) => {
  funcShow(true);
};

export const closeLoadingSpinner = (funcShow) => {
  setTimeout(() => {
    funcShow(false);
  }, 500);
};

//  Render Option for Search
export const renderOptionForSearch = (options) => {
  if (options && options.length > 0) {
    return options.map((e) => (
      <Option value={e.key || e.id} key={e.key || e.id}>
        {e.value || e.name}
      </Option>
    ));
  }
};

//  Render Option
export const renderOption = (options, optionBlank = true, lengthText) => {
  if (options && options.length > 0) {
    return (
      <>
        {optionBlank && <Option value="" key=""></Option>}
        {options.map((e) => {
          let optionText = e.value || e.name;
          if (lengthText && typeof lengthText === "number") {
            if (optionText.length > lengthText) {
              optionText = optionText.substring(0, lengthText) + "...";
            }
          }
          return (
            <Option value={e.key || e.id} key={e.key || e.id}>
              {optionText}
            </Option>
          );
        })}
      </>
    );
  }
};

//  Render Option one Check
export const renderOptionOneCheck = (
  options,
  optionBlank = true,
  { value, list = [] }
) => {
  if (options && options.length > 0) {
    return (
      <>
        {optionBlank && <Option value="" key="blank"></Option>}
        {options.map((e) => {
          const key = e.key || e.id || e.code;
          return (
            <Option
              value={key}
              key={key}
              disabled={list.includes(key) && key !== value}
            >
              {e.value || e.name}
            </Option>
          );
        })}
      </>
    );
  }
};

//  Render Option
export const renderOptionHTML = (options, optionBlank = true) => {
  if (options && options.length > 0) {
    return (
      <>
        {optionBlank && <option value="" key="blank"></option>}
        {options.map((e) => (
          <option value={e.key || e.id} key={e.key || e.id}>
            {e.value || e.name}
          </option>
        ))}
      </>
    );
  }
};

export const renderOptionInputCL = (item) => {
  const arrayOption = item.split(";");
  const array = [];
  array.push(<Select.Option key={0} value={null} />);
  arrayOption.forEach((item, index) =>
    array.push(
      <Select.Option key={index + 1} value={item}>
        {item}
      </Select.Option>
    )
  );
  return array;
};

// Case response
export const statusRes = (res) => {
  const data = {
    success: false,
    msg: "",
  };
  // Case success
  switch (res.status) {
    case 200:
      data.success = true;
      break;
    case 400:
      data.success = false;
      break;
    case 401:
      data.success = false;
      break;
    default:
      data.success = false;
      break;
  }

  return data.success;
};
// Case catch
export const MSG_CATCH = (msg) =>
  window.navigator.onLine
    ? msg || COMMON_CONSTANT.ECM_E008
    : COMMON_CONSTANT.ECM_E004;

// Input dateTime
const INPUT_TIME = (e, type) => {
  let lengthInput;
  switch (type) {
    case "week":
      lengthInput = 7;
      break;
    case "year":
      lengthInput = 4;
      break;
    default:
      lengthInput = 10; // DATE
  }
  const val = e.target.value;

  if (
    e.keyCode === 13 || //  enter
    e.keyCode === 9 || //  tab
    e.keyCode === 46 || //  delete
    e.keyCode === 8 || //  backspace
    (e.keyCode >= 37 && e.keyCode <= 40) || // Up down ...
    ((e.ctrlKey || e.metaKey) &&
      (e.which === "65" || e.which === "88" || e.which === "86"))
  ) {
    // Always
  } else if (
    // Typing
    ((e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) || // 0-9
      e.keyCode === 111 ||
      e.keyCode === 191) && // slash "/"
    val.length < lengthInput &&
    !e.shiftKey
  ) {
    if (val.length === 4 || val.length === 7) {
      /// Date
      e.target.value = val + "/";
      if (e.keyCode === 111 || e.keyCode === 191) {
        // slash "/")
        e.preventDefault();
        return false;
      }
    } else {
      // Typing
      if (e.keyCode === 111 || e.keyCode === 191) {
        e.preventDefault();
        return false;
      }
    }
  } else {
    e.preventDefault();
    return false;
  }
};

export const INPUT_DATE = {
  onKeyDown: (e) => {
    INPUT_TIME(e);
  },
  onBlur: (e) => {
    //   // console.log('blur')
    //   // console.log(e);
    //   // e.target.value = '1111/01/01';
    //   // e.value = '1111/01/01';
    //   // var ev2 = new Event('change', e);
    //   // e.target.dispatchEvent(ev2);
    //   // // debugger;
    //   // e.preventDefault();
    //   // return false;
  },
};

export const INPUT_MONTH = {
  onKeyDown: (e) => {
    INPUT_TIME(e, "week");
  },
};

export const INPUT_YEAR = {
  onKeyDown: (e) => {
    INPUT_TIME(e, "year");
  },
};

// Remove item from array
export const removeItemArr = (array, item) => {
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
};

// Format Column CheckItemCode
export const formatCheckItemCode = (text, cOrder = 0) => {
  let temp = "";
  if (text) {
    let timeCheck = "";
    if (cOrder && cOrder !== 0) {
      timeCheck = format("#T{0}", cOrder);
    }
    temp = format(COMMON_CONSTANT.NEW_DATA_TEMPLATE_CODE, text, timeCheck);
  }
  return temp;
};

export const isImage = (fileName) => {
  const dotExt = getDotFile(fileName);
  if (
    dotExt === "png" ||
    dotExt === "jpg" ||
    dotExt === "jpeg" ||
    dotExt === "bmp" ||
    dotExt === "gif" ||
    dotExt === "ico"
  ) {
    return true;
  }
  return false;
};

export const hasPreview = (fileName) => {
  const dotExt = getDotFile(fileName);
  if (
    dotExt === "pdf" ||
    dotExt === "txt" ||
    dotExt === "sql" ||
    dotExt === "html" ||
    dotExt === "js"
  ) {
    return true;
  }
  return false;
};

export const getNameFile = (name) => {
  return name.split(".").slice(0, -1).join(".");
};

export const getDotFile = (name) => {
  return name.split(".").pop();
};

export const saveFile = (res) => {
  // Get file name
  const blob = new Blob([res.data], {});

  const headerLine = res.headers["content-disposition"];
  const startFileNameIndex = headerLine.indexOf("=") + 1;
  const endFileNameIndex = headerLine.lastIndexOf(";");
  const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
  let fileNameO = decodeURIComponent(filename);
  // Create link
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.target = "_blank";
  link.download = fileNameO;
  link.click();
};

export const hwEnabled = (dataRow) => {
  return !dataRow.workingStatus || Number(dataRow.workingStatus) === 1;
};

// Show mark
export const sttMachine = (dataRow) => {
  let sttTabLeft;
  if (hwEnabled(dataRow)) {
    switch (dataRow.status) {
      case KEY.ERROR:
        sttTabLeft = "error";
        break;
      case KEY.WARMING:
        sttTabLeft = "warning";
        break;
      case KEY.DONE:
        sttTabLeft = "done";
        break;
      default:
        sttTabLeft = "info";
        break;
    }
  } else {
    sttTabLeft = "disabled"; // Disable machine
  }

  return sttTabLeft;
};

export const isHeadOffice = () => {
  const j = JSON.parse(localStorage.getItem(KEY.LOC_USER_INFO));
  return Number(j.role) === Number(KEY.MENU_ADMIN);
};
export const isAdminOffice = () => {
  const j = JSON.parse(localStorage.getItem(KEY.LOC_USER_INFO));
  return Number(j.role) === Number(KEY.MENU_ADMIN_OFFICE);
};
export const isGroupManager = () => {
  const j = JSON.parse(localStorage.getItem(KEY.LOC_USER_INFO));
  return Boolean(j.isGroupManager);
};

export const sttCellInput = (val, err) => {
  return err ? "red" : "";
};

// Url search to object param
export const getQuerySearch = () => {
  const search = new URLSearchParams(window.location.search);
  const objParam = {};
  for (const [k, v] of search.entries()) {
    objParam[k] = v;
  }
  return objParam;
};

export const int2DateString = (int, format = KEY.DATE_DEFAULT) => {
  return int ? dayjs(int).format(format) : "";
};

export const getTxtValue = (str) => (str ? str : null);
export const getDateValue = (str) =>
  getTxtValue(str) ? dayjs(Number(getTxtValue(str))) : null;
export const getUserValue = (str) => (str ? str.split(",") : []);
export const getCheckboxValue = (str) => str === "true";
// Push object to url
export const pushObject2Query = (objSearch) => {
  // Object to string query
  const paramString = Object.keys(objSearch)
    .filter((k) => objSearch[k] !== null)
    .map((key) => {
      if (dayjs.isDayjs(objSearch[key])) {
        // Date
        return (
          key + "=" + (objSearch[key] === null ? "" : objSearch[key].valueOf())
        );
      } else {
        // Other
        return (
          key +
          "=" +
          (objSearch[key] === null ? "" : encodeURIComponent(objSearch[key]))
        );
      }
    })
    .join("&");
  // push to url
  window.history.replaceState(
    null,
    null,
    paramString ? "?" + paramString : "?"
  );
};

// push or replace key
export const pushObject2QueryReplace = (obj) => {
  const queySearch = getQuerySearch();
  const objSearch = { ...queySearch, ...obj };
  // Object to string query
  const paramString = Object.keys(objSearch)
    .filter((k) => objSearch[k] !== null)
    .map((key) => {
      if (dayjs.isDayjs(objSearch[key])) {
        // Date
        return (
          key + "=" + (objSearch[key] === null ? "" : objSearch[key].valueOf())
        );
      } else {
        // Other
        return (
          key +
          "=" +
          (objSearch[key] === null ? "" : encodeURIComponent(objSearch[key]))
        );
      }
    })
    .join("&");
  // push to url
  window.history.replaceState(
    null,
    null,
    paramString ? "?" + paramString : "?"
  );
};
export const inputNumberRequired = (txt) => {
  return (
    txt === undefined ||
    txt === null ||
    isNaN(txt) ||
    String(txt).trim().length === 0 ||
    Number(String(txt).trim()) === 0
  );
};
export const txtRequired = (txt) => {
  return txt === undefined || txt === null || String(txt).trim().length === 0;
};

export const isEmpty = (s) => {
  return s === undefined || s === null || s.length === 0;
};

export const getUserName = () => {
  const j = JSON.parse(localStorage.getItem(KEY.LOC_USER_INFO));
  return j.userName;
};

// Render sort
export const renderSortColumn = (arr, onSortColumn) => {
  return arr.map((col, index, arr) => {
    //
    col.title = (
      <div className="white-space" style={{ whiteSpace: "pre" }}>
        {/* Select */}
        <Select
          size="small"
          className="mr10"
          value={index}
          onChange={(v) => {
            onSortColumn(v, index);
          }}
        >
          {arr.map((l, i) => {
            return <Select.Option value={i}>{i + 1}</Select.Option>;
          })}
        </Select>
        {/* Name */}
        <span className="white-space" style={{ whiteSpace: "pre" }}>
          {col.name}
        </span>
      </div>
    );
    return col;
  });
};
// Event sort column
export const onSortColumn = (
  indexNew,
  indexOld,
  [columnsItem_draft, setColumnsItem_draft]
) => {
  const col = columnsItem_draft[indexOld];
  // First column: 0 -> indexOld
  const firstCol = columnsItem_draft.slice(0, indexOld);
  // Last column: indexOld -> End
  const lastCol = columnsItem_draft.slice(indexOld + 1);
  // Push to indexNew
  const newCols = [...firstCol, ...lastCol];
  newCols.splice(indexNew, 0, col);
  // Set
  setColumnsItem_draft(newCols);
};
// Render default
export const renderDefaultColumn = (arr) => {
  return arr.map((col) => {
    col.title = col.name;
    return col;
  });
};

export const saveSortColumn_local = (key, arr) => {
  const userName = getUserName();
  const obj = JSON.parse(localStorage.getItem(KEY.SORT_TABLE)) || {};
  obj[userName] = { ...obj[userName] };
  obj[userName][key] = arr;
  localStorage.setItem(KEY.SORT_TABLE, JSON.stringify(obj));
};
export const getSortColumn_local = (key) => {
  const userName = getUserName();
  const obj = JSON.parse(localStorage.getItem(KEY.SORT_TABLE)) || {};
  return obj && obj[userName] && obj[userName][key] ? obj[userName][key] : null;
};
export const checkInteger = (v, callback) => {
  if (("" + v).includes(".")) {
    return;
  }
  callback();
};

export const handleCatchError = (err) => {
  let msg = MSG_CATCH();
  if (
    !isEmptyLodash(err.response) &&
    isString(err.response.data) &&
    !isEmptyLodash(err.response.data)
  ) {
    msg = err.response.data;
  }
  if (
    !isEmptyLodash(err.response) &&
    isObject(err.response.data) &&
    isString(err.response.data.msg)
  ) {
    msg = err.response.data.msg;
  }
  showMessage(KEY.ERROR, msg);
};
export function objectToQueryString(obj) {
  const keyValuePairs = [];

  // Duyệt qua từng thuộc tính của đối tượng
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Kiểm tra nếu giá trị không phải là null hoặc undefined
      if (value !== null && value !== undefined) {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value);
        keyValuePairs.push(`${encodedKey}=${encodedValue}`);
      }
    }
  }

  const queryString = keyValuePairs.join("&");

  // Kiểm tra xem chuỗi query string có dữ liệu hay không
  if (queryString) {
    return `?${queryString}`;
  }

  // Nếu không có dữ liệu, trả về chuỗi rỗng
  return "";
}
export const pushFullPath = (path, objSearch, history) => {
  const fullpath = path + objectToQueryString(objSearch);
  history.push(fullpath);
};

// format list option Office
export const formatListOptionOffice = (listOffice) => {
  return listOffice.map((i) => ({
    key: i.officeId,
    value: i.officeName,
    baseCode: i.baseCode,
  }));
};

// List company
export const formatListCompanyOption = (listCompany) => {
  return listCompany.map((i) => ({
    key: i.companyId,
    value: i.companyName,
    companyCode: i.officeCode,
  }));
};

// List company
export const formatListCompanyCode = (listCompany) => {
  return listCompany.map((i) => ({
    key: i.companyId,
    value: i.officeCode,
  }));
};

// CheckPassword
export const checkErrorPassword = (pass, passConfirm) => {
  if (pass.trim().length === 0 && passConfirm !== undefined && passConfirm.trim().length === 0) {
    return null;
  }
  let err = null;
  if (pass.trim().length < 8) {
    err = "パスワードは8文字以上で設定してください";
  }
  if (!/\d/.test(pass) || !/[A-Z]/.test(pass) || !/[a-z]/.test(pass)) {
    err =
      "半角英小文字('a'-'z')、半角英大文字('A'-'Z')、半角数字をそれぞれ１文字以上混在させてください。";
  }
  return err;
};

// Check Confirm pass
export const checkErrorConfirmPasssword = (pass, passConfirm) => {
  return pass !== passConfirm
    ? "新しいパスワードと新しいパスワード（確認）は一致する必要があります"
    : null;
};

// Chekc EMail
export const checkEmail = (email) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
};
export const checkPhoneNumber = (phoneNumber) => {
  const phoneRegex = /^\(?([0-9]{3,4})\)?[-. ]?([0-9]{3,4})[-. ]?([0-9]{3,4})$/;
  return phoneRegex.test(phoneNumber);
};
export const dowloadFilePdfByBase64 = async (base64, name) => {
  const link = document.createElement("a");
  link.href = `data:application/pdf;base64,${base64}`;
  link.download = name; // Tên tệp khi tải xuống
  document.body.appendChild(link);
  link.click();
  link.remove();
};
export const renderMapMarker = (props) => {
  return (
    <Row className="custom-marker">
      <Col span={4}>
        {props.type == DEVICE_TYPE.HEAT_WATCHER && (
          <MdLocationPin className="map-marker heat-watcher" />
        )}
        {props.type == DEVICE_TYPE.MAIN_DEVICE && (
          <MdLocationPin className="map-marker main-device" />
        )}
        {props.type == DEVICE_TYPE.SUB_DEVICE && (
          <MdLocationPin className="map-marker sub-device" />
        )}
      </Col>
      <Col span={20}>
        <Row className="marker-content">
          <Col
            span={24}
            style={{
              fontWeight: 600,
              textAlign: "left",
              marginLeft: 10,
              marginBottom: 10,
            }}
          >
            {props.name}
          </Col>
          <Col span={24} style={{ textAlign: "left", marginLeft: 10 }}>
            <Space>
              <Col>
                <Row>
                  <Col span={24} className="center">
                    <GoAlertFill style={{ color: "red", fontSize: 20 }} />
                  </Col>
                  <Col span={24} className="center">
                    1
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col span={24} className="center">
                    <FaFaceFrown style={{ color: "red", fontSize: 20 }} />
                  </Col>
                  <Col span={24} className="center">
                    0
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col span={24} className="center">
                    <FaFaceFrown style={{ color: "orange", fontSize: 20 }} />
                  </Col>
                  <Col span={24} className="center">
                    0
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row>
                  <Col span={24} className="center">
                    <FaFaceFrown style={{ color: "gold", fontSize: 20 }} />
                  </Col>
                  <Col span={24} className="center">
                    0
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row>
                  <Col span={24} className="center">
                    <FaFaceSmile style={{ color: "green", fontSize: 20 }} />
                  </Col>
                  <Col span={24} className="center">
                    0
                  </Col>
                </Row>
              </Col>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const CustomMarker = (props) => {
  const { content } = props;
  return (
    <>
      <div>{content}</div>
    </>
  );
};

// Unlock ACC
export const confirmApi = async (
  api,
  callback = () => {},
  titleConfirm,
  textConfirm,
  textSuccess,
  textErrror,
  setLoading = () => {}
) => {
  Modal.confirm({
    title: titleConfirm,
    centered: true,
    content: textConfirm,
    okText: lang.OK,
    cancelText: lang.CANCEL,
    onOk: async () => {
      try {
        setLoading(true);
        await api;
        showMessage(KEY.INFO, textSuccess);
        callback();
      } catch (error) {
        showMessage(textErrror || KEY.ERROR);
      }
    },
  });
};

export const confirmApiModal = async (
  api,
  callback = () => {},
  titleConfirm,
  textConfirm,
  textSuccess,
  textErrror,
  setLoading = () => {}
) => {
      try {
        setLoading(true);
        await api;
        showMessage(KEY.INFO, textSuccess);
        callback();
      } catch (error) {
        showMessage(textErrror || KEY.ERROR);
      }
 
};
