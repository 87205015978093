/* eslint-disable jsx-a11y/anchor-is-valid */

import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { AiTwotoneLock } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { getInfoUSerFromToken } from "../../api/config";
import TitlePage from "../../component/Desktop/titlePage";
import { KEY, LOCALSTORAGE_KEY } from "../../constants/common.const";
import { loginAction, setFirstPath } from "../../redux/auth";
import CONFIG_ROUTER from "../../routers/roles/configRoles";
import "./Login.scss";

function Login() {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const redirectPath = localStorage.getItem(LOCALSTORAGE_KEY.REDIRECT_PATH);
  // Get text err login from redux
  const [loading, setloading] = useState(false);
  const [error, setErr] = useState(null);
  // Handle Submit
  const handleSubmit = async (data) => {
    try {
      setloading(true);
      const result = await dispatch(loginAction(data));
      const originalResult = unwrapResult(result);
      const role = getInfoUSerFromToken(originalResult.token).Role;
      let path = "";
      let menuItem = CONFIG_ROUTER.find(
        (i) => i.show && i.path && (!i.roleHides || !i.roleHides.includes(role))
      );
      if (menuItem) {
        path = menuItem.path;
      }
      dispatch(setFirstPath(path));
      history.push(redirectPath || path);
      if (redirectPath) {
        localStorage.removeItem(LOCALSTORAGE_KEY.REDIRECT_PATH);
      }
    } catch (error) {
      setErr(error);
    } finally {
      setloading(false);
    }
  };

  return (
    <div style={{ padding: "0 10px" }} className="des-content">
      <TitlePage name={""} />

      <Row>
        <Col offset={isPC && 6} span={isPC ? 12 : 24}>
          <h1 className="title-login">ログイン</h1>
          <section className="content container-fluid">
            <div
              style={{ background: error ? "#dd4b39" : "#4C5054" }}
              className="alert alert-info"
            >
              <span className="bold">
                {error || "ログインIDとパスワードを入力してください"}
              </span>
            </div>
            <div className="box">
              <Form onFinish={handleSubmit} layout="vertical">
                <Form.Item
                  name="loginId"
                  rules={[
                    {
                      required: true,
                      message: (
                        <>
                          <span className="notiRequied">
                            ログインID{" "}
                            <span className="notiRequied__mark">
                              このフィールドは必須入力項目です。
                            </span>
                          </span>
                        </>
                      ),
                    },
                  ]}
                  label="ログインID"
                  required
                >
                  <Input addonBefore={<BsFillPersonFill />} />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: (
                        <>
                          <span className="notiRequied">
                            パスワード{" "}
                            <span className="notiRequied__mark">
                              このフィールドは必須入力項目です。
                            </span>
                          </span>
                        </>
                      ),
                    },
                  ]}
                  label="パスワード"
                  required
                >
                  <Input type="password" addonBefore={<AiTwotoneLock />} />
                </Form.Item>
                <div className="right">
                  <Form.Item>
                    <Button
                      loading={loading}
                      disabled={loading}
                      htmlType="submit"
                      type="primary"
                    >
                      ログイン
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </section>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
