/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";

import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FormOutlined,
  PlusOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import classGrid from "../../models/control/grid";

import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  PAGE_SIZE,
  lang,
} from "../../constants/common.const";

import UserApi from "../../api/backend/user";
import ContentTitle from "../../component/Desktop/contentTitle";
import { ROUTER_PAGE } from "../../constants/router.const";
import {
  MSG_CATCH,
  confirmApiModal,
  confirmApi,
  getQuerySearch,
  pushObject2Query,
  renderOption,
  saveFile,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";
import classDdl from "../../models/control/dropdownlist";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import { onChangeTable } from "../../utils/helpers";
const { Content } = Layout;

function ListAdminUnitService() {
  const history = useHistory();

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  const objSearch = getQuerySearch();
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  //  Grid
  const [grid, setGrid] = useState({
    ...new classGrid(),
  });
  // Input search
  // 1

  let [fullName, setFullName] = useState({
    ...new classDdl(objSearch.fullName),
  });

  // Step2: Sync input <-> url

  // Search
  const search_onClick = (resetPage = false, deleetFlag = false) => {
    pushObject2Query({ fullName: fullName.value });
    if (resetPage && page !== 1) {
      setPage(1);
    } else if (deleetFlag) {
      const countAffterDelete = grid.count - 1;
      const maxPage = Math.ceil(countAffterDelete / PAGE_SIZE);

      if (page > maxPage) {
        setPage(maxPage);
      } else {
        listAdminSeviceQuery.refetch();
      }
    } else {
      listAdminSeviceQuery.refetch();
    }
  };

  // Delete
  const handleDeleteServiceAdmin = (id) => {
    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.ECM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: async () => {
        try {
          setShowLoading(true);
          await UserApi.deleteUSer(id);
          showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C004);
          search_onClick(null, true);
          await getListOptionUser();
        } catch (error) {
          showErrorMessage(error);
        } finally {
          setShowLoading(false);
        }
      },
    });
  };
  let initColumns = [
    {
      title: "ログインID",
      dataIndex: "userName",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ maxWidth: "500px" }}
          >
            <Col span={24}>{row.userName}</Col>
          </Row>
        );
      },
    },

    {
      title: "氏名",
      dataIndex: "fullName",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
    },

    {
      title: "ロック解除",
      dataIndex: "unlock",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {row.isLockedOut && (
              <Button
                className="mr5 ml10"
                style={{ backgroundColor: "green", color: "#fff" }}
                onClick={() => {
                  confirmApiModal(
                    UserApi.unLockUser(row.userId),
                    search_onClick,
                    lang.CONFIRM,
                    lang.CONFIRM_UNLOCK,
                    lang.UNLOCK_SUCCESS
                  );
                }}
              >
                <UnlockOutlined />
              </Button>
            )}
          </>
        );
      },
    },
    {
      noSort: true,
      title: "設定変更",
      dataIndex: "settingChange",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            <Tooltip title={"設定変更"}>
              <Button
                className="mr5 ml10"
                onClick={() => {
                  history.push(
                    ROUTER_PAGE.ADMIN_UNIT_SERVICE_EDIT.replace(
                      ":id",
                      row.userId
                    )
                  );
                }}
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      noSort: true,
      title: "削除",
      dataIndex: "delete",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={"削除"}>
              <Button
                onClick={() => {
                  handleDeleteServiceAdmin(row.userId);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // Query list
  const listAdminSeviceQuery = useQuery(
    [
      "listAdminService",
      {
        page,
        sortKey,
        sortOrder,
      },
    ],
    () =>
      UserApi.getListUserRole({
        role: RoleHeadOffice.ADMIN_UNIT_SERVICE,
        exactFullName: fullName.value,
        sortKey,
        sortOrder,
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
      }),
    {
      onSuccess: (data) => {
        setGrid({ ...grid, data: data.data.data, count: data.data.count });
      },
      keepPreviousData: true,
      retry: 0,
    }
  );

  // get list option
  const getListOptionUser = async () => {
    const data = await UserApi.getListUserRole({
      role: RoleHeadOffice.ADMIN_UNIT_SERVICE,
    });
    const listFullName = data.data.data.map((i) => i.fullName);
    const listOption = [...new Set(listFullName)];

    setFullName({
      ...fullName,
      options: listOption.map((i) => ({
        key: i,
        value: i,
      })),
    });
  };
  useEffect(() => {
    getListOptionUser();
  }, []);
  // DOMNLOAD
  const handleDownload = async () => {
    try {
      setShowLoading(true);
      const res = await UserApi.downloadListUSer({
        Role: RoleHeadOffice.ADMIN_UNIT_SERVICE,
        fullName: fullName.value,
      });
      saveFile(res);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    search_onClick(true);
  }, [fullName.value]);

  // re-call api
  const reCallApi = (currentPage, orderName, orderBy) => {
    setPage(currentPage);
    if (orderName) {
      setSortKey(orderName);
    }
    if (orderBy) {
      setSortOrder(orderBy);
    }
  };
  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={lang.ADMIN_UNIT_SERVICE_LIST} />

        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} span={24}>
              <div className="ant-form-item-label">{lang.FULL_NAME}</div>

              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
                className="w100"
                value={fullName.value}
                onChange={(e) => setFullName({ ...fullName, value: e })}
              >
                {renderOption(fullName.options)}
              </Select>
            </Col>
            <Col xl={16} lg={24} md={24} sm={24} xs={24}>
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col></Col>
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push(ROUTER_PAGE.ADMIN_UNIT_SERVICE_ADD);
                    }}
                  >
                    <PlusOutlined className="" />
                    {COMMON_CONSTANT.ADD}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push(ROUTER_PAGE.ADMIN_UNIT_SERVICE_INPUTFILE);
                    }}
                  >
                    <FileAddOutlined className="" />
                    {lang.FILE}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => handleDownload()}
                    className="buttonPC button--info --todo-- wAuto "
                  >
                    <DownloadOutlined />
                    {lang.DOWNLOAD}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </ContentTitle>
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}>
            {/* <Button type="link" className="btnAddRecord mb15">
                <AddIcon />
                {lang.REQUEST_CREATE}
              </Button> */}
          </Col>
          <Col span={12} className="right" style={{ paddingRight: "20px" }}>
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.id}
          className="gridSearch "
          columns={initColumns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,
            total: grid.count,
            current: page || 1,
            showSizeChanger: false,
          }}
         scroll={{ x: 'max-content' }}
          onChange={onChangeTable(reCallApi)}
        />
      </Content>
      <Loading show={showLoading} />
    </div>
  );
}
export default ListAdminUnitService;
