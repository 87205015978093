import Api from "@src/api";
export default {
  
  getUserInfoWithSiteOffice(userName, siteOffice) {
    return Api().get(
      `UserInfo?userName=${userName}&siteOfficeMasterCode=${siteOffice}`
    );
  },
  loginApi(data) {
    return Api().post("/api/Authenticate/Login",data);
  },
  changePassword(data) {
    return Api().post("/api/Authenticate/ChangePassword",data);
  },
  refreshToken(data) {
    return Api().post("/api/Authenticate/refresh",data);
  },

  getUserInfo() {
    return Api().get("/api/UserInfo");
  },
  getSafeInfo(){
    return Api().get("api/Kbn/SafetyBeltDisplay");
  }
};
