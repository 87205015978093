import React from "react";
import "./customIcon.scss";

import battery00 from "@src/styles/image/battery/battery00.png";
import battery01 from "@src/styles/image/battery/battery01.png";
import battery02 from "@src/styles/image/battery/battery02.png";
import battery03 from "@src/styles/image/battery/battery03.png";
import battery04 from "@src/styles/image/battery/battery04.png";
import battery05 from "@src/styles/image/battery/battery05.png";

const BatteryIcon = ({ level }) => {
  return (
    <div style={{ paddingTop: 5 }}>
      {level === 0 && (
        <>
          <img width={30} src={battery00} alt="" />
        </>
      )}
      {level === 1 && (
        <>
          <img width={30} src={battery01} alt="" />
        </>
      )}
      {level === 2 && (
        <>
          <img width={30} src={battery02} alt="" />
        </>
      )}
      {level === 3 && (
        <>
          <img width={30} src={battery03} alt="" />
        </>
      )}
      {level === 4 && (
        <>
          <img width={30} src={battery04} alt="" />
        </>
      )}
      {level === 5 && (
        <>
          <img width={30} src={battery05} alt="" />
        </>
      )}
    </div>
  );
};
export default BatteryIcon;
