import React from "react";
import { Col, Row } from "antd";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../../constants/common.const";

function RowInput({
  box,
  text,
  required,
  labelCol = 6,
  multipleCol = false,
  ...props
}) {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  return (
    <div
      className={`${box ? "row-input-box" : "row-input"} ${
        isPC ? "" : "mobile"
      } `}
    >
      <Row>
        <Col
          span={isPC ? labelCol : 24}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          className={text ? "" : "row-input-col-first"}
        >
          <Row justify="space-between">
            <Col span={24} className="">
              <label className="row-input-label ">{props.name}</label>
              {required && <label className="blod ml5 mark-required">*</label>}
            </Col>
          </Row>
        </Col>
        <Col
          span={isPC ? 24 - labelCol : 24}
          className={`${!multipleCol ? "row-input-col-2 flex-start" : ""} `}
        >
          <div
            style={{ textAlign: "start", width: "100%" }}
            className={`${props.class} `}
          >
            {props.children}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default RowInput;
