import Api from "@src/api";
export const settingApi =  {
  getmasterSetting(companyId,params={}) {
    return Api().get(`/api/MasterSetting/Company/${companyId}`,{
      params
    });
  },
  postmasterSetting(companyId,data) {
    return Api().post(`/api/MasterSetting/Company/${companyId}`,data);
  },
  getOfficeSetting(officeId,params={}) {
    return Api().get(`/api/MasterSetting/Office/${officeId}`,{
      params
    });
  },
  postOfficeSetting(officeId,data) {
    return Api().post(`/api/MasterSetting/Office/${officeId}`,data);
  },
  getUrlSisMil() {
    return Api().get("/api/MasterSetting/GetSismilHost");
  },
  getDefaultUrlSisMil() {
    return Api().get("");
  },
  updateUrlSisMil(params){
  
    return Api().put("/api/MasterSetting/UpdateSismilHost",{},{
      params
    })
  }
};
