/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Row,
  Table,
  Space,
  Button,
  Form,
  Layout,
  Tooltip,
  Modal,
} from "antd";
import React, { useState, useEffect } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import API_BASE from "@src/api/backend/user";
import classGrid from "../../models/control/grid";

import {
  DownloadOutlined,
  UnlockOutlined,
  FileAddOutlined,
  DeleteOutlined,
  PlusOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import {
  confirmApiModal,
  confirmApi,
  getQuerySearch,
  pushObject2Query,
  saveFile,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";

import {
  lang,
  CONFIG,
  COMMON_CONSTANT,
  KEY,
  PAGE_SIZE,
} from "../../constants/common.const";

import classDdl from "../../models/control/dropdownlist";
import { ROUTER_PAGE } from "../../constants/router.const";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import ContentTitle from "../../component/Desktop/contentTitle";
import SelectCompany from "../../components/common/input/selectCompany";
import { onChangeTable } from "../../utils/helpers";

const { Content } = Layout;

function PhysicalCondition_list() {
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const [initLoad, setInitLoad] = useState(true);
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  const objectSearch = getQuerySearch();
  const [ddlCompany, setddlCompany] = useState({
    ...new classDdl(objectSearch.companyId),
  });
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  //  Grid
  const [grid, setGrid] = useState({
    ...new classGrid(),
  });

  // Step2: Sync input <-> url
  // Search
  const search_onClick = async (page = 1, sortKey = "", sortOrder = "desc") => {
    setCurrentPage(page);
    setSortKey(sortKey);
    setSortOrder(sortOrder);
    pushObject2Query({
      companyId: ddlCompany.value,
    });
    try {
      const result = await API_BASE.searchPeople({
        CompanyId: ddlCompany.value,
        role: RoleHeadOffice.ADMIN_HEADQUATER,
        offset: (page - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        sortKey,
        sortOrder,
      });

      const data = result.data;
      if (data) {
        setGrid(data);
      } else {
        setGrid({ ...grid, data: [] });
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };

  // Delete
  const handleDeleteBaseAdmin = (id) => {
    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.ECM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: async () => {
        try {
          setShowLoading(true);
          await API_BASE.deleteUSer(id);
          showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C004);
          search_onClick();
        } catch (error) {
          showErrorMessage(error);
        } finally {
          setShowLoading(false);
        }
      },
    });
  };

  // DOMNLOAD
  const handleDownload = async () => {
    try {
      setShowLoading(true);
      const res = await API_BASE.downloadListUSer({
        CompanyId: ddlCompany.value,
        Role: RoleHeadOffice.ADMIN_HEADQUATER,
      });
      saveFile(res);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    if (initLoad) {
      setInitLoad(false);
    }
    search_onClick(1, sortKey, sortOrder);
  }, [ddlCompany.value]);

  let initColumns = [
    {
      title: "ログインID",
      dataIndex: "userName",
      render: (text, row) => {
        //
        return <>{text}</>;
      },
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      title: "氏名",
      dataIndex: "fullName",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: lang.COMPANY,
      dataIndex: "companyName",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      title: "拠点名",
      dataIndex: "officeName",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "ロック解除",
      align: "center",
      dataIndex: "userId",
      render: (_, row) => {
        //
        return (
          <>
            {row.isLockedOut && (
              <Button
                className="mr5 ml10"
                style={{ backgroundColor: "green", color: "#fff" }}
                onClick={() => {
                  confirmApiModal(
                    API_BASE.unLockUser(row.userId),
                    search_onClick,
                    lang.CONFIRM,
                    lang.CONFIRM_UNLOCK,
                    lang.UNLOCK_SUCCESS
                  );
                }}
              >
                <UnlockOutlined />
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: "設定変更",
      align: "center",
      dataIndex: "settingChange",
      render: (_, row) => {
        //
        return (
          <>
            <Tooltip title={"設定変更"}>
              <Button
                className="mr5 ml10"
                onClick={() => {
                  history.push(
                    ROUTER_PAGE.BASE_ADMINISTRATOR_UPDATE.replace(
                      ":id",
                      row.userId
                    )
                  );
                }}
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "削除",
      align: "center",
      dataIndex: "delete",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={"削除"}>
              <Button
                onClick={() => {
                  handleDeleteBaseAdmin(row.userId);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={lang.BASE_ADMINISTRATOR_LIST} />

        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} span={24}>
              <div className="ant-form-item-label">{lang.COMPANY}</div>

              <SelectCompany
                className={"w100"}
                acceptSetNull={!initLoad}
                state={ddlCompany}
                setState={setddlCompany}
              />
            </Col>
            <Col xl={16} lg={24} md={24} sm={24} xs={24}>
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push("/base-admin/create/new");
                    }}
                  >
                    <PlusOutlined className="" />
                    {COMMON_CONSTANT.ADD}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push(
                        `/base-admin/inputfile?OfficeId=${
                          ddlCompany.value || ""
                        }`
                      );
                    }}
                  >
                    <FileAddOutlined className="" />
                    {lang.FILE}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--info --todo-- wAuto "
                    onClick={() => handleDownload()}
                  >
                    <DownloadOutlined />
                    {lang.DOWNLOAD}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </ContentTitle>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}></Col>
          <Col span={12} className="right" style={{ paddingRight: "20px" }}>
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.id}
          className="gridSearch "
          columns={initColumns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,
            total: grid.count,
            current: currentPage || 1,
            showSizeChanger: false,
          }}
         scroll={{ x: 'max-content' }}
          onChange={onChangeTable(search_onClick)}
        />
      </Content>
      <Loading show={showLoading} />
    </div>
  );
}
export default PhysicalCondition_list;
