/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { LocationApi as API } from "@src/api/backend/location";
import {
  DeleteOutlined,
  FormOutlined,
  RollbackOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  COMMON_CONSTANT,
  DEVICE_TYPE,
  STYLE,
  lang,
  KEY,
} from "../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../constants/utils";
import MapDeviceSettingModal from "../../components/modal/map-device-setting";
import classDdl from "../../models/control/dropdownlist";
import MapImageMarker from "./map-image-marker";
import ContentTitle from "../../component/Desktop/contentTitle";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { GatewayApi } from "@src/api/backend/gateway";
import { HeatWatcherApi } from "@src/api/backend/heatWatcher";

function DeviceSetting() {
  const { id } = useParams();
  const [dataDetail, setDataDetail] = useState({});
  const fetchDataInit = async () => {
    try {
      setShowLoading(true);
      const res = await API.getOneMap(id);
      setDataDetail(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    fetchDataInit();
  }, []);

  const history = useHistory();
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();

  const [showMapSettingModal, setShowMapSettingModal] = useState(false);
  const [placeDeviceEditMode, setPlaceDeviceEditMode] = useState(false);
  const [deviceType, setDeviceType] = useState("");

  const [markers, setMarkers] = useState([]);

  const columnHeatIndexWatchers = [
    {
      title: "SisMil ID ",
      dataIndex: "serialNumber",
      width: "30%",
    },
    {
      title: "位置情報名",
      dataIndex: "locationName",
      width: "30%",
      align: "center",
    },
    {
      title: lang.CLONE,
      dataIndex: "change",
      align: "center",
      render: (row) => {
        //
        return (
          <>
            <Tooltip title={lang.CLONE}>
              <Button
                className="mr5 ml10"
                onClick={() => {
                  showDeviceSetting(true, DEVICE_TYPE.HEAT_WATCHER);
                }}
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "削除",
      dataIndex: "serialNumber",
      align: "center",
      render: (_) => {
        //
        return (
          <>
            <Tooltip title={lang.DELETE}>
              <Button
                className=" btnDelete"
                onClick={() => {
                  const thisMarker = markers.find(
                    (marker) =>
                      marker.name === _ &&
                      marker.type == DEVICE_TYPE.HEAT_WATCHER
                  );
                  thisMarker.top = "";
                  thisMarker.left = "";
                  thisMarker.hide = true;
                  setMarkers([...markers]);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const columnMainDevices = [
    {
      title: "ID",
      dataIndex: "serialNumber",
      width: "30%",
    },
    {
      title: "タイプ",
      dataIndex: "type",
      align: "center",
      render: (value, row) => {
        const isMain = row.gatewayType == 1 || row.gatewayTypeValue == 1;
        return isMain ? "親機" : "子機";
      },
    },
    {
      title: "位置情報名",
      dataIndex: "locationName",
      width: "30%",
      align: "center",
    },
    {
      title: lang.CLONE,
      dataIndex: "change",
      align: "center",
      render: (row) => {
        //
        return (
          <>
            <Tooltip title={lang.CLONE}>
              <Button
                className="mr5 ml10"
                onClick={() => {
                  showDeviceSetting(true, DEVICE_TYPE.MAIN_DEVICE);
                }}
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "削除",
      dataIndex: "serialNumber",
      align: "center",
      render: (_, row) => {
        return (
          <>
            <Tooltip title={lang.DELETE}>
              <Button
                className=" btnDelete"
                onClick={() => {
                  const thisMarker = markers.find(
                    (marker) =>
                      marker.name === _ &&
                      marker.type != DEVICE_TYPE.HEAT_WATCHER &&
                      marker.gatewayType === row.gatewayType
                  );
                  thisMarker.top = "";
                  thisMarker.left = "";
                  thisMarker.hide = true;
                  setMarkers([...markers]);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const showDeviceSetting = (editMode, deviceType) => {
    setDeviceType(deviceType);
    setPlaceDeviceEditMode(editMode);
    setShowMapSettingModal(true);
  };

  const mapListGateways = (item, index) => {
    const isMain = item.gatewayType == 1 || item.gatewayTypeValue == 1;
    return {
      ...item,
      top: item.positionY,
      left: item.positionX,
      type: isMain ? DEVICE_TYPE.MAIN_DEVICE : DEVICE_TYPE.SUB_DEVICE,
      name: item.serialNumber,
      hide:
        item.positionX === "" ||
        item.positionX === null ||
        item.positionY === "" ||
        item.positionY === null,
      ...(isMain
        ? {
            isMainGatewayAlive: item.isMainGatewayAlive,
            waveLevel: null,
          }
        : {
            isMainGatewayAlive: null,
            waveLevel: item.waveLevel,
          }),
    };
  };

  const mapListHeatIndexWatchers = (item, index) => {
    return {
      ...item,
      top: item.positionY,
      left: item.positionX,
      type: DEVICE_TYPE.HEAT_WATCHER,
      name: item.serialNumber,
      hide:
        item.positionX === "" ||
        item.positionX === null ||
        item.positionY === "" ||
        item.positionY === null,
    };
  };

  const listHeatIndexWatchers = markers.filter(
    (item) => !item.hide && item.type === DEVICE_TYPE.HEAT_WATCHER
  );
  const listDevice = markers.filter(
    (item) =>
      !item.hide
      && (item.type === DEVICE_TYPE.MAIN_DEVICE ||
        item.type === DEVICE_TYPE.SUB_DEVICE)
  );

  const [allDevice, setAllDevice] = useState([]);
  const [allHeatIndex, setAllHeatIndex] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      if (!dataDetail.officeId) return;
      try {
        const data = (dataDetail.listGateways || [])
        setAllDevice(data);
      } catch (e) {
        console.log(e);
      }
    };

    fetch();
  }, [dataDetail]);

  useEffect(() => {
    const fetch = async () => {
      if (!dataDetail.officeId) return;
      try {
        const data = (dataDetail.listHeatIndexWatchers || []);
        setAllHeatIndex(data);
      } catch (e) {
        console.log(e);
      }
    };

    fetch();
  }, [dataDetail]);

  const resetMarkers = () => {
    const newListGetways = (allDevice || []).map(mapListGateways);
    const newListHeatIndexWatchers = (allHeatIndex || []).map(
      mapListHeatIndexWatchers
    );
    setMarkers(newListGetways.concat(newListHeatIndexWatchers));
  };
  useEffect(() => {
    resetMarkers();
  }, [allDevice, allHeatIndex]);
  const handleOk = async () => {
    try {
      setShowLoading(true);
      const data = allDevice.map((item) => {
        const thisMarker = markers.find((marker) => {
          return (
            marker.name === item.serialNumber &&
            marker.gatewayType == item.gatewayType &&
            marker.type !== DEVICE_TYPE.HEAT_WATCHER
          );
        });
        const gatewayType =
          item.gatewayType == "1" || item.gatewayTypeValue == "1" ? "1" : "2";
        return {
          positionX: !thisMarker.hide ? `${thisMarker.left}` : "",
          positionY: !thisMarker.hide ? `${thisMarker.top}` : "",
          officeId: dataDetail.officeId,
          serialNumber: item.serialNumber,
          gatewayType: gatewayType,
          installationStatus: item.installationStatusValue,
          status: item.statusValue,
          locationName: item.locationName,
          mapId: !thisMarker.hide ? dataDetail.id : null,
        };
      });
      // const listPromiseGateways = [GatewayApi.setGatewayToMap(data)];

      const data2 = allHeatIndex.map((item) => {
        const thisMarker = markers.find((marker) => {
          return (
            marker.name === item.serialNumber &&
            marker.type === DEVICE_TYPE.HEAT_WATCHER
          );
        });
        return {
          serialNumber: item.serialNumber,
          gatewayType: null,
          positionX: !thisMarker.hide ? `${thisMarker.left}` : "",
          positionY: !thisMarker.hide ? `${thisMarker.top}` : "",
          locationName: item.locationName,
          mapId: !thisMarker.hide ? dataDetail.id : null,
          officeId: dataDetail.officeId,
        };
      });
      // const listPromiseHeatWatcher = [HeatWatcherApi.setHeatWatcherToMap(data2)];

      // await Promise.all(
      //   listPromiseGateways.concat(listPromiseHeatWatcher)
      // );
      await API.setDeviceToMap({
        officeId: dataDetail.officeId,
        listGatewayDto: data,
        listSismilDto: data2,
      })
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
    } catch (error) {
      let msg = MSG_CATCH();
      if (error.response && error.response.data) {
          msg = error.response.data;
      }
      showMessage(KEY.ERROR, msg);
    } finally {
      await fetchDataInit();
      setShowLoading(false);
    }
  };

  const confirmSaveData = () => {
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.ECM_C006,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        handleOk();
      },
    });
  };

  // Render
  return (
    <>
      <div className="des-content role-setting">
        <ContentTitle>
          <TitlePage name={lang.EQUIPMENT_INSTALL} />
          <Form
            layout={"vertical"}
            name="searchWR"
            form={formSearch}
            className="formStyle"
          >
            {/* 1 */}
            <Row
              gutter={[16, 8]}
              align="bottom"
              justify="space-between"
              className="rowNotMargin"
            >
              <Col lg={8} xs={24}>
                <div className="ant-form-item-label">{lang.BASE_NAME}</div>
                {/* <SelectBoxOffice state={ddlOffice} setState={setDdlOffice} /> */}
                <Select
                  value={dataDetail.officeName}
                  className="w100"
                  disabled
                />
              </Col>
              <Col lg={8} xs={24}>
                <div className="ant-form-item-label bold">棟/工区</div>
                <Select
                  value={dataDetail.locationName}
                  className="w100"
                  disabled
                />
              </Col>
              <Col lg={8} xs={24}>
                <div className="ant-form-item-label bold">フロア/区画</div>
                <Select value={dataDetail.floor} className="w100" disabled />
              </Col>
            </Row>
          </Form>
        </ContentTitle>

        <div className="site-layout-background">
          <Row
            gutter={[16, 8]}
            justify="space-between "
            className="rowNotMargin"
          >
            <Col span={24} className="mt20 pl20 m-pl-4 m-pr-4">
              {dataDetail.mapImage && (
                <MapImageMarker
                  markers={markers}
                  image={dataDetail.mapImageData || ""}
                  setMarkers={setMarkers}
                  isScale={false}
                  isShowLabel={false}
                  isDrag={false}
                />
              )}
            </Col>

            <Divider />

            <Col span={24} className="mt20">
              <Space>
                {lang.HEAT_WATCHER_INDEX}
                <Button
                  onClick={() => {
                    showDeviceSetting(true, DEVICE_TYPE.HEAT_WATCHER);
                  }}
                >
                  <PlusOutlined />
                  {COMMON_CONSTANT.ADD}
                </Button>
              </Space>
            </Col>
            <Col span={24}>
              <Table
                rowKey={(record) => record.id}
                className="gridSearch "
                columns={columnHeatIndexWatchers}
                dataSource={listHeatIndexWatchers}
                pagination={false}
                scroll={{ x: "max-content" }}
                locale={{
                  emptyText: "登録されていません",
                }}
              />
            </Col>
            <Col span={24} className="mt20">
              <Space>
                {lang.MAIN_SUB_DEVICE}
                <Button
                  onClick={() => {
                    showDeviceSetting(true, DEVICE_TYPE.MAIN_DEVICE);
                  }}
                >
                  <PlusOutlined />
                  {COMMON_CONSTANT.ADD}
                </Button>
              </Space>
            </Col>
            <Col span={24} className="">
              <Table
                rowKey={(record) => record.id}
                className="gridSearch "
                columns={columnMainDevices}
                dataSource={listDevice}
                pagination={false}
                scroll={{ x: "max-content" }}
                locale={{
                  emptyText: "登録されていません",
                }}
              />
            </Col>

            <Col span={24} className="right "></Col>
          </Row>

          <Row justify="end" className="mt20">
            <Col>
              <Button
                className="buttonPC button--info wAuto ml15 mb5 mt5"
                onClick={() => {
                  confirmSaveData();
                }}
              >
                <SaveOutlined />
                {COMMON_CONSTANT.SAVE}
              </Button>
              <Button
                className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
                onClick={() => {
                  history.goBack();
                }}
              >
                <RollbackOutlined />
                {lang.BACK}
              </Button>
            </Col>
          </Row>
        </div>
        <Loading show={showLoading} />
      </div>
      <MapDeviceSettingModal
        allDevice={allDevice}
        allHeatIndex={allHeatIndex}
        markersProps={markers}
        setMarkersProps={setMarkers}
        dataDetail={dataDetail}
        mapId={id}
        editMode={placeDeviceEditMode}
        deviceType={deviceType}
        handleCancel={() => {
          setShowMapSettingModal(false);
        }}
        visible={showMapSettingModal}
      />
    </>
  );
}
export default DeviceSetting;
