import {
  Col,
  Row,
  Table,
  Button,
  DatePicker,
  Radio,
  Form,
  Layout,
  Select,
  Flex,
} from "antd";
import React, { useEffect, useState, useRef, useMemo } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import classGrid from "../../models/control/grid";
import {
  DownloadOutlined,
  FileOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import GroupReport from "../../components/modal/group-report";
import { debounce, isArray } from "lodash";
import {
  MSG_CATCH,
  showMessage,
  getQuerySearch,
  pushObject2Query,
  saveFile,
} from "../../constants/utils";

import { lang, KEY } from "../../constants/common.const";
import dayjs from "dayjs";

import classDdl from "../../models/control/dropdownlist";
import "./report.scss";
import ContentTitle from "../../component/Desktop/contentTitle";
import API from "@src/api/backend/report.js";
import { isEmpty } from "lodash";
import UserApi from "@src/api/backend/user";
import { dowloadFilePdfByBase64 } from "@src/constants/utils.js";
import SelectOffice from "../../components/common/input/selectOffice";

const { Content } = Layout;
function Report() {
  const objSearch = getQuerySearch();
  const [init, setInit] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedWorkers, setSelectedWorkers] = useState([
    ...(objSearch.Workers
      ? objSearch.Workers.split(",").map((item) => ({ value: item }))
      : []),
  ]);
  const [thisWorker, setThisWorker] = useState({});
  const [reportType, setReportType] = useState(+objSearch.ReportType || 2);
  const [period, setPeriod] = useState(+objSearch.Period || 2);
  const [selectedDate, setSelectedDate] = useState(
    objSearch.RequestDate ? dayjs(objSearch.RequestDate) : dayjs()
  );
  // const [formSearch] = Form.useForm();s
  const [grid, setGrid] = useState({
    ...new classGrid(),
    data: [],
  });
  const [showGroupReportModal, setShowGroupReportModal] = useState(false);
  const [ddlBaseName, setDdlBaseName] = useState({
    ...new classDdl(),
    value: objSearch.officeId,
  });

  const validate = () => {
    let isValid = true;

    if (!ddlBaseName.value) {
      isValid = false;
      setDdlBaseName({ ...ddlBaseName, error: true });
    } else {
      setDdlBaseName({ ...ddlBaseName, error: false });
    }
    return isValid;
  };

  // Search
  const search_onClick = async (isDownload) => {
    if (!validate()) return;
    pushObject2Query({
      officeId: ddlBaseName.value || "",
      Workers:
        period == 2
          ? selectedWorkers.map((item) => item.value)
          : isArray(selectedWorkers)
          ? [selectedWorkers[0].value]
          : [selectedWorkers.value],
      ReportType: reportType,
      Period: period,
      RequestDate: dayjs(selectedDate).format("YYYY-MM-DD"),
    });
    try {
      setShowLoading(true);
      const param = {
        Workers:
          period == 2
            ? selectedWorkers.map((item) => item.value)
            : isArray(selectedWorkers)
            ? [selectedWorkers[0].value]
            : [selectedWorkers.value],
        ReportType: reportType,
        Period: period,
        RequestDate: dayjs(selectedDate).format("YYYY-MM-DD"),
        OfficeId: ddlBaseName.value,
      };
      if (isDownload) {
        const res = await API.getDownload(param);
        saveFile(res);
      }
      const res = await API.get(param);
      let data = [];
      if (res.data.dailyData) {
        data = res.data.dailyData.map((item, index) => {
          return {
            ...item,
            ...item.data,
          };
        });

        if (isEmpty(selectedWorkers)) {
          setSelectedWorkers(
            res.data.dailyData.map((item) => ({
              label: item.workerName,
              value: item.workerId,
            }))
          );
        }
      }

      if (res.data.monthlyData) {
        data = Object.values(res.data.monthlyData).map((item, index) => {
          return {
            ...item,
            ...(index === 0
              ? {
                  workerName: res.data.workerName,
                  groupName: res.data.groupName,
                }
              : {}),
            date: index + 1,
          };
        });
        setThisWorker(
          options.find((item) => item.value === param.Workers[0]) || {}
        );
      }

      const thisOffice = ddlBaseName.options.find(
        (x) => x.key == ddlBaseName.value
      );
      setGrid({
        ...grid,
        ...res.data,
        data: data,
        period: res.data.dailyData ? 2 : 1,
        reportDate: dayjs(res.data.reportDate).format(
          res.data.dailyData ? "YYYY/MM/DD" : "YYYY/MM"
        ),
        officeName: res.data.officeName || thisOffice.value,
      });
    } catch (e) {
      showMessage("error", MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  const dataSource = grid.data;
  const isMonthlyReport = grid.period === 1;
  const renderCell = (_, index) => {
    if (isEmpty(grid.data)) return {};
    return {
      rowSpan: !isMonthlyReport ? {} : index === 0 ? 31 : 0,
    };
  };
  const columns = [
    {
      title: "氏名",
      dataIndex: "workerName",
      key: "workerName",
      className: "vertical-align-top",
      onCell: renderCell,
    },
    {
      title: "グループ ",
      dataIndex: "groupName",
      key: "groupName",
      className: "vertical-align-top",
      onCell: renderCell,
    },
    {
      title: "日",
      dataIndex: "date",
      key: "date",
      align: "center",
      hide: isMonthlyReport ? false : true,
    },
    {
      title: "作業時間",
      align: "center",
      children: new Array(24).fill(0).map((item, index) => {
        return {
          title: index,
          dataIndex: index,
          key: index,
          align: "center",
          className: "report-cell-date",
        };
      }),
    },
  ];

  const fetchWorkers = async (value) => {
    try {
      const res = await UserApi.getListUserRole({
        officeId: ddlBaseName.value,
        fullName: value,

        worker: true,
      });
      if (res && !isEmpty(res.data)) {
        return res.data.data.map((item) => ({
          ...item,
          label: item.fullName,
          value: item.userId,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (isEmpty(options)) return;
    if (isEmpty(grid.data)) return;
    setThisWorker(
      options.find((item) => item.userName === grid.userName) || {}
    );
  }, [grid, options]);

  useEffect(() => {
    setInit(false);
    if (isEmpty(selectedWorkers)) return;
    search_onClick();
  }, []);
  useEffect(() => {
    const fetchOptions = async () => {
      const list = await fetchWorkers();
      setOptions(list);
    };

    !init && setSelectedWorkers([]);
    fetchOptions();
  }, [ddlBaseName.value]);

  useEffect(() => {
    !init && setSelectedWorkers([]);
  }, [period]);

  const info = ["拠点名", "年月日"].concat(
    isMonthlyReport ? ["氏名", "グループ"] : []
  );
  const hours = new Array(24).fill(1).map((item, index) => {
    return `${index < 10 ? "0" : ""}${index} - ${index < 9 ? "0" : ""}${
      index + 1
    }時`;
  });
  const header = []
    .concat(isMonthlyReport ? ["日"] : ["氏名", "グループ"])
    .concat(hours);
  const csvData = [
    [...info],
    [grid.officeName || "", grid.reportDate || ""].concat(
      isMonthlyReport
        ? [dataSource[0].workerName || "", dataSource[0].groupName || ""]
        : []
    ),
    [...header],
    ...dataSource.map((item, index) => {
      if (!isMonthlyReport) {
        return [item.workerName || "", item.groupName || ""].concat(
          Object.values(item.data || {})
        );
      }
      const date = index + 1 < 10 ? `0${index + 1}` : `${index + 1}`;
      const dateData = Object.values(item || {}).slice(0, 23);
      return [grid.reportDate + "/" + date].concat(dateData);
    }),
  ];

  const TYPE_REPORT_NAME = {
    1: "WBGTValue",
    2: "ExcessWBGT",
    3: "TotalAlert",
    4: "HeartRate",
  };
  const thisBaseName =
    ddlBaseName.options.find((option) => option.key == ddlBaseName.value) || {};
  const fileName =
    (isMonthlyReport ? "MonthReport" : "DayReport") +
    TYPE_REPORT_NAME[reportType] +
    "_" +
    (isMonthlyReport ? thisWorker.userName : thisBaseName.baseCode) +
    "_" +
    (isMonthlyReport ? thisWorker.label : thisBaseName.value) +
    "_" +
    (isMonthlyReport
      ? dayjs().format("YYYYMMDD")
      : dayjs(grid.reportDate || null).format("YYYYMMDD"));
  return (
    <div className="des-content role-setting">
      <Loading show={showLoading} />
      <GroupReport
        visible={showGroupReportModal}
        handleCancel={() => {
          setShowGroupReportModal(false);
        }}
      />
      <ContentTitle>
        <TitlePage name={lang.REPORT_NAME} />
        <Form
          layout={"vertical"}
          name="searchWR"
          // form={formSearch}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 16]} align="bottom" className="rowNotMargin">
            <Col xl={8} span={24}>
              <div className="ant-form-item-label">
                拠点名<span style={{ color: "red" }}> *</span>
              </div>
              <SelectOffice
                className={"w100"}
                state={ddlBaseName}
                setState={setDdlBaseName}
                acceptSetNull={false}
              />
            </Col>
            <Col xl={8} span={24}>
              <div className="ant-form-item-label">作業者氏名</div>
              <DebounceSelect
                mode={period === 2 ? "multiple" : "single"}
                style={{ width: "100%" }}
                maxTagCount="responsive"
                value={selectedWorkers}
                placeholder="作業者氏名"
                defaultOptions={options}
                fetchOptions={fetchWorkers}
                onChange={(newValue) => setSelectedWorkers(newValue)}
                allowClear
                forceFetch={ddlBaseName}
              />
            </Col>
            <Col span={24}>
              <Radio.Group
                name="reportType"
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
              >
                <Radio value={3}>総合アラート</Radio>
                <Radio value={4}>心拍数</Radio>
                <Radio value={1}>WBGT値</Radio>
                <Radio value={2}>WBGT超過度</Radio>
              </Radio.Group>
            </Col>

            <Col md={12} span={24}>
              <Flex gap={16}>
                <div>
                  <div className="ant-form-item-label"></div>
                  <Radio.Group
                    name="period"
                    defaultValue={period}
                    className="mt25"
                    onChange={(e) => setPeriod(e.target.value)}
                  >
                    <Radio value={2}>日報</Radio>
                    <Radio value={1}>月報</Radio>
                  </Radio.Group>
                </div>
                <div>
                  <div className="ant-form-item-label">年月日選択</div>
                  <DatePicker
                    picker={period === 2 ? "date" : "month"}
                    defaultValue={selectedDate}
                    value={
                      dayjs(selectedDate).isValid() ? dayjs(selectedDate) : null
                    }
                    allowClear={false}
                    onChange={(date, dateString) =>
                      dayjs(dateString).isValid()
                        ? setSelectedDate(dateString)
                        : setSelectedDate(dayjs().format("YYYY-MM-DD"))
                    }
                  />
                </div>
              </Flex>
            </Col>
            <Col md={12} span={24}>
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    disabled={!ddlBaseName.value}
                    onClick={() => {
                      if (period === 1 && isEmpty(selectedWorkers)) {
                        showMessage(
                          KEY.WARMING,
                          "月次レポートについては、最初に誰かを選択してください"
                        );
                        return;
                      }
                      search_onClick();
                    }}
                  >
                    検索
                  </Button>
                </Col>
                <Col>
                  <ExportCSVButton
                    data={csvData}
                    isDisabled={
                      isEmpty(dataSource) || !dataSource[0].workerName
                    }
                    filename={fileName}
                  />
                </Col>

                <Col>
                  <Button
                    disabled={isEmpty(dataSource) || !dataSource[0].workerName}
                    className="buttonPC button--info wAuto"
                    onClick={() => search_onClick(true)}
                  >
                    <FileOutlined />
                    PDF
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </ContentTitle>

      <Content
        id="printArea"
        className="site-layout-background"
        style={{
          padding: 30,
        }}
      >
        {/* Count */}
        <Row justify="space-between">
          <Col span={12}>
            {grid.officeName && (
              <p className="mt0 mb0">拠点名：{grid.officeName}</p>
            )}
            {grid.reportDate && (
              <p className="mt0 mb0">年月日：{grid.reportDate}</p>
            )}
          </Col>
          {/* <Col span={12} className="right">
            {lang.ACCESS_NUMBERS}
            <span>
              {dataSource.dailyData ? dataSource.dailyData.length : 1}
              {lang.CASE}
            </span>
          </Col> */}
        </Row>

        <Table
          className="custom-table"
          dataSource={dataSource}
          columns={columns.filter((col) => !col.hide)}
          pagination={false}
          scroll={{ x: 1000 }}
        />
      </Content>
    </div>
  );
}

export default Report;

export function DebounceSelect({
  fetchOptions,
  debounceTimeout = 400,
  forceFetch,
  defaultOptions,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const fetchRef = useRef(0);
  const loadOptions = (value) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setFetching(true);
    setIsFirstTime(false);
    fetchOptions(value).then((newOptions) => {
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }
      setOptions(newOptions);
      setFetching(false);
    });
  };

  const debounceFetcher = useMemo(() => {
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  return (
    <Select
      showSearch
      labelInValue
      filterOption={false}
      onClick={() => isFirstTime && debounceFetcher("")}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <LoadingOutlined size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

function ExportCSVButton({ data, filename, isDisabled }) {
  const convertToCSV = (data) => {
    const BOM = "\uFEFF";
    return (
      BOM +
      data.map((row) => row.map((item) => `"${item}"`).join(",")).join("\n")
    );
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename + ".csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      className="buttonPC button--info --todo-- wAuto"
      onClick={downloadCSV}
      disabled={isDisabled}
    >
      <DownloadOutlined className="" />
      ダウンロード
    </Button>
  );
}
