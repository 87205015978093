// import external libs
import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  Table,
  Divider,
} from "antd";
import { DEVICE_TYPE, lang } from "../../constants/common.const";
import MapImageMarker from "../../page/map/map-image-marker";

function MapDeviceSettingModal({ allDevice, allHeatIndex, markersProps, setMarkersProps, visible, handleCancel, editMode, deviceType, mapId, dataDetail }) {
  const [markers, setMarkers] = useState([]);
  useEffect(() => {
    setMarkers(markersProps);
    setSelectedRowKeys((markersProps || [])
      .filter((item) => !item.hide)
      .map((item) => item.serialNumber  + "--type--" + item.gatewayType));
  }, [markersProps]);

  const listGateways = (allDevice || [])
    .map((item) => {
      return {
        ...item,
        key: item.serialNumber + "--type--" + item.gatewayType,
        officeName: dataDetail.officeName,
        floor: dataDetail.floor,
      }
    });

  const listHeatIndexWatchers = (allHeatIndex || []).map((item, index) => {
    return {
      ...item,
      key: item.serialNumber + "--type--" + item.gatewayType,
      officeName: dataDetail.officeName,
      floor: dataDetail.floor,
    }
  });

  const columnsHeatWatcher = [
    {
      title: "SisMil ID",
      dataIndex: "serialNumber",
      width: "30%",
    },
    {
      title: "拠点名",
      dataIndex: "officeName",
    },

    {
      title: "棟/工区",
      dataIndex: "locationName",
    },
    {
      title: "フロア/区画",
      dataIndex: "floor",
    },
  ];

  const columnsMainSubDevice = [
    {
      title: "ID",
      dataIndex: "serialNumber",
    },
    {
      title: "タイプ",
      dataIndex: "type",
      align: "center",
      render: (value, row) => {
        const isMain = row.gatewayType == 1 || row.gatewayTypeValue == 1
        return isMain ? "親機" : "子機"
      }
    },
    {
      title: "拠点名",
      dataIndex: "officeName",
    },

    {
      title: "棟/工区",
      dataIndex: "locationName",
    },
    {
      title: "フロア/区画",
      dataIndex: "floor",
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    type: "checkbox",
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const newMarkers = [...markers]
      .map((item) => {
        const isSelected = selectedRowKeys.includes(item.name + "--type--" + item.gatewayType)
          || (deviceType == DEVICE_TYPE.HEAT_WATCHER ? (item.type != "1" && !item.hide) : (item.type == "1" && !item.hide));
        return {
          ...item,
          top: item.top ? item.top : 50,
          left: item.left ? item.left : 50,
          hide: !isSelected
        };
      });

    setMarkers(newMarkers);
  }, [selectedRowKeys, deviceType]);

  const handleOk = async () => {
    setMarkersProps(markers);
    handleCancel();
  };

  const onCancel = () => {
    setMarkers(markersProps);
    setSelectedRowKeys((markersProps || [])
      .filter((item) => !item.hide)
      .map((item) => item.serialNumber + "--type--" + item.gatewayType));
    handleCancel();
  };

  return (
    <Modal
      footer={null}
      title={"機器の配置場所を設定してください"}
      open={visible}
      maskClosable={false}
      onCancel={onCancel}
      width={1000}
      centered
      className="map-device-setting"
    >
      <Divider />
      <div className="site-layout-background">
        <Row gutter={[16, 8]} justify="space-between " className="rowNotMargin">
          <Col span={24} className="pl20 m-pl-0 m-pr-0">
            <Row>
              <MapImageMarker
                isDrag={true}
                markers={markers}
                image={dataDetail.mapImageData}
                setMarkers={setMarkers}
                newItem={
                  !editMode
                    ? {
                      type: deviceType,
                      name: selectedRowKeys[0],
                    }
                    : null
                }
                deviceType={deviceType}
              />
              <>
                {deviceType == DEVICE_TYPE.HEAT_WATCHER && (
                  <>
                    <Row
                      gutter={[16, 8]}
                      justify="space-between "
                      className="rowNotMargin mt20"
                    >
                      <Col span={24}>
                        SisMilを選択してください
                      </Col>
                      <Col span={24} className="right ">
                        <Table
                          rowSelection={rowSelection}
                          className="gridSearch "
                          columns={columnsHeatWatcher}
                          dataSource={listHeatIndexWatchers}
                          pagination={false}
                         scroll={{ x: 'max-content' }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {deviceType == DEVICE_TYPE.MAIN_DEVICE && (
                  <>
                    <Row
                      gutter={[16, 8]}
                      justify="space-between "
                      className="rowNotMargin mt20"
                    >
                      <Col span={24}>機器を選択してください</Col>
                      <Col span={24} className="right ">
                        <Table
                          rowSelection={rowSelection}
                          className="gridSearch "
                          columns={columnsMainSubDevice}
                          dataSource={listGateways}
                          pagination={false}
                         scroll={{ x: 'max-content' }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            </Row>
          </Col>
        </Row>
      </div>
      <Divider />
      <Row justify="end" className="rowNotMargin">
        {/* Button Search/Clear */}
        <Col flex="none" className="right">
          <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
            <Col>
              <Button
                className="buttonPC button--info wAuto"
                onClick={handleOk}
              >
                {lang.OK}
              </Button>
            </Col>
            <Col>
              <Button
                className="buttonPC button--outline --todo-- wAuto "
                onClick={onCancel}
              >
                {lang.BACK}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

export default MapDeviceSettingModal;
