import { combineReducers } from "redux";
import navBarReducer from "./navBar";
import loadingReducer from "./loading";
import confirmReducer from "./confirm";
import authReducer from "./auth";
import registrationReducer from "./registration";
import sideBarReducer from "./sideBar";
import userReducer from "./user";
import groupReducer from "./group";
import companyReducer from "./company";
const rootReducer = combineReducers({
  auth: authReducer,
  navBar: navBarReducer,
  loading: loadingReducer,
  confirm: confirmReducer,
  registration: registrationReducer,
  sideBar: sideBarReducer,
  user: userReducer,
  group : groupReducer,
  company: companyReducer,
});

export default rootReducer;
