/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Modal, Row, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";

import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FormOutlined,
  PlusOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import {
  MSG_CATCH,
  getQuerySearch,
  pushFullPath,
  pushObject2Query,
  saveFile,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";

import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  PAGE_SIZE,
  lang,
} from "../../constants/common.const";

import { useQuery } from "react-query";
import { HeatWatcherApi } from "../../api/backend/heatWatcher";
import RegisterGatewayModal from "../../components/modal/register-gateway";
import classDdl from "../../models/control/dropdownlist";
import { useLocation } from "react-router-dom";
import { ROUTER_PAGE } from "../../constants/router.const";
import dayjs from "dayjs";
import ContentTitle from "../../component/Desktop/contentTitle";
import SelectOffice from "../../components/common/input/selectOffice";
import { onChangeTable } from "../../utils/helpers";

function HeatWatcher_List() {
  const history = useHistory();
  const location = useLocation();
  const objSearch = getQuerySearch();
  const [heatWatcherId, setHearWatcherId] = useState(null);
  const [officeId, setOfficeId] = useState(null);
  const [currentLocation, setCurrentLocation] = useState("");

  const LIMIT_PAGE = 100;

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  const [initLoad, setInitLoad] = useState(true);
  const [showRegisterGatewayModal, setShowRegisterGatewayModal] =
    useState(false);
  let [ddlBaseName, setDdlBaseName] = useState({
    ...new classDdl(),
    value: objSearch.OfficeId || "",
  });

  let initColumns = [
    {
      title: "SisMil ID",
      dataIndex: "serialNumber",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ maxWidth: "500px" }}
          >
            <Col span={24}>
              {/* <Link
                to={"/ViewGateway/" + row.serialNumber}
                className="white-space"
              > */}
              {text}
              {/* </Link> */}
            </Col>
          </Row>
        );
      },
    },
    {
      title: "拠点名",
      dataIndex: "officeName",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      title: "設置場所",
      dataIndex: "locationName",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "設置状態",
      dataIndex: "installationStatusValue",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "ステータス",
      dataIndex: "statusValue",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "経過時間",
      dataIndex: "elapsedTime",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (value) => {
        return value ? dayjs.utc(value).fromNow() : "";
      },
    },
    {
      title: "親機/子機登録",
      dataIndex: "gatewayRegistration",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            <Tooltip title={"親機/子機登録"}>
              <Button
                className="mr5 ml10"
                onClick={() => {
                  setShowRegisterGatewayModal(true);
                  setHearWatcherId(row.serialNumber);
                  setOfficeId(row.officeId);
                  setCurrentLocation(row.locationName);
                }}
              >
                <WifiOutlined />
                <PlusOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "設定変更",
      dataIndex: "settingChange",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            <Tooltip title={"設定変更"}>
              <Button
                className="mr5 ml10"
                onClick={() => {
                  history.push(
                    ROUTER_PAGE.HEAT_WATCHER_EDIT.replace(
                      ":id",
                      row.serialNumber
                    )
                  );
                }}
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "削除",
      dataIndex: "delete",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={"削除"}>
              <Button
                onClick={() => {
                  handleDeleteHeatWatcher(row.serialNumber);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // Delete
  const handleDeleteHeatWatcher = (id) => {
    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.ECM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: async () => {
        try {
          await HeatWatcherApi.deleteHeatWatcher(id);
          showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C004);
          onSearch(false, true);
        } catch (error) {
          showErrorMessage(error);
        }
      },
    });
  };
  useEffect(() => {
    if (initLoad) {
      setInitLoad(false);
    }
    onSearch(true);
    pushObject2Query({ ...objSearch, OfficeId: ddlBaseName.value || "" });
  }, [ddlBaseName.value]);
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  // Query list heatWatcher
  const listWatcherKey = [
    "listWatcher",
    {
      office: ddlBaseName.value,
      page,
      sortKey,
      sortOrder,
    },
  ];
  const listHeatWatcherQuery = useQuery(
    listWatcherKey,
    () =>
      HeatWatcherApi.getListHeatWatcher({
        officeId: ddlBaseName.value,
        Limit: LIMIT_PAGE,
        Offset: LIMIT_PAGE * (page - 1),
        sortKey,
        sortOrder,
      }),
    {
      onError: () => {
        showMessage(KEY.ERROR, MSG_CATCH());
      },
      onSettled: () => {
        setShowLoading(false);
      },
      keepPreviousData: true,
    }
  );
  const dataGrid = listHeatWatcherQuery.data
    ? listHeatWatcherQuery.data.data.data
    : [];
  const onSearch = (resetPage, deleetFlag = false) => {
    if (resetPage && page != 1) {
      setPage(1);
    } else if (deleetFlag) {
      const countAffterDelete = listHeatWatcherQuery.data
        ? listHeatWatcherQuery.data.data.count - 1
        : 0;
      const maxPage = Math.ceil(countAffterDelete / PAGE_SIZE);

      if (page > maxPage) {
        setPage(maxPage);
      } else {
        listHeatWatcherQuery.refetch();
      }
    } else listHeatWatcherQuery.refetch();
  };
  // re-call api
  const reCallApi = (currentPage, orderName, orderBy) => {
    setPage(currentPage);
    if (orderName) {
      setSortKey(orderName);
    }
    if (orderBy) {
      setSortOrder(orderBy);
    }
  };
  // DOMNLOAD
  const handleDownload = async () => {
    try {
      setShowLoading(true);
      const res = await HeatWatcherApi.downloadHeatWatcher({
        OfficeId: ddlBaseName.value,
      });
      saveFile(res);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  // Render
  return (
    <>
      <RegisterGatewayModal
        setShowRegisterGatewayModal={setShowRegisterGatewayModal}
        officeId={officeId}
        heatWatcherId={heatWatcherId}
        currentLocation={currentLocation}
        visible={showRegisterGatewayModal}
        handleCancel={() => {
          setShowRegisterGatewayModal(false);
          setHearWatcherId(null);
          setCurrentLocation("");
        }}
      />
      <div className="des-content role-setting">
        <ContentTitle>
          <TitlePage name={lang.HEAT_WATCHER_LIST} />

          <Form
            layout={"vertical"}
            name="searchWR"
            form={formSearch}
            className="formStyle"
          >
            {/* 1 */}
            <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
              <Col lg={8} span={24}>
                <div className="ant-form-item-label">拠点名</div>

                <SelectOffice
                  className={"w100"}
                  acceptSetNull={!initLoad}
                  state={ddlBaseName}
                  setState={setDdlBaseName}
                />
              </Col>
              <Col xl={16} lg={24} md={24} sm={24} xs={24}>
                <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                  <Col>
                    <Button
                      htmlType="submit"
                      className="buttonPC button--info wAuto"
                      onClick={() => {
                        history.push(
                          "/heat-index-watcher/create/new" +
                            `?OfficeId=${ddlBaseName.value || ""}`
                        );
                      }}
                    >
                      <PlusOutlined className="" />
                      {COMMON_CONSTANT.ADD}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      htmlType="submit"
                      className="buttonPC button--info wAuto"
                      onClick={() => {
                        history.push(
                          `/heat-index-watcher/inputfile?OfficeId=${
                            ddlBaseName.value || ""
                          }`
                        );
                      }}
                    >
                      <FileAddOutlined className="" />
                      {lang.FILE}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        setShowLoading(true);
                        listHeatWatcherQuery.refetch();
                      }}
                      className="buttonPC button--info wAuto"
                    >
                      {lang.NEWEST_INFORMATION}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => handleDownload()}
                      className="buttonPC button--info --todo-- wAuto "
                    >
                      <DownloadOutlined />
                      {lang.DOWNLOAD}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </ContentTitle>

        <div
          className="site-layout-background"
          style={{
            margin: "24px 0px",
          }}
        >
          {/* Count */}
          <Row justify="space-between ">
            <Col span={12}></Col>
            <Col span={12} className="right" style={{ paddingRight: "20px" }}>
              {/* Count */}
              <Space size={10} align="center ">
                {lang.ACCESS_NUMBERS}
                <span>
                  {listHeatWatcherQuery.data
                    ? listHeatWatcherQuery.data.data.count
                    : 0}
                  {lang.CASE}
                </span>
              </Space>
            </Col>
          </Row>
          {/* Grid  */}
          <Table
            rowKey={(record) => record.id}
            className="gridSearch "
            columns={initColumns}
            dataSource={dataGrid}
            pagination={{
              ...CONFIG.paging,
              current: page || 1,
              total: listHeatWatcherQuery.data
                ? listHeatWatcherQuery.data.data.count
                : 0,
              showSizeChanger: false,
            }}
            onChange={onChangeTable(reCallApi)}
           scroll={{ x: 'max-content' }}
          />
        </div>
        <Loading show={showLoading} />
      </div>
    </>
  );
}
export default HeatWatcher_List;
