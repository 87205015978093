/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateTitleHeaderWithoutConst } from "../../redux/sideBar";

function TitlePage({ name, ...other }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateTitleHeaderWithoutConst(name));
  }, []);

  return (
    <div className={"title-header"}>
      <h2 className="bold white-space">{name}</h2>
    </div>
  );
}
export default TitlePage;
