import Api from "@src/api";
export const ApiOffice = {
  searchOffice(params) {
    return Api().get("api/Office", { params });
  },
  getDetailOffice(id) {
    return Api().get(`/api/Office/${id}`);
  },
  deleteOffice(id) {
    return Api().delete(`/api/Office/${id}`);
  },
  // 2: Create/Update
  save(formData, id) {
    return id
      ? Api().put(`/api/Office/`, formData, {})
      : Api().post("/api/Office/", formData, {});
  },
};
