import {
  EnvironmentOutlined,
  HeartOutlined,
  TeamOutlined,
  WifiOutlined,
  ProfileOutlined,
  FundOutlined,
  SettingOutlined,
  UserSwitchOutlined,
  WarningOutlined,
  BorderOuterOutlined,
  SnippetsOutlined,
  ApiOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  SolutionOutlined ,
  UserOutlined
} from "@ant-design/icons";
import { FaTemperatureFull } from "react-icons/fa6";
import { GiBelt } from "react-icons/gi";

import { ROUTER_PAGE } from "@src/constants/router.const";
import HeadWatcher from "@src/page/heat-watcher/list";
import HeadWatcherAdd from "@src/page/heat-watcher/add";
import BaseAdmin from "@src/page/base-admin/list";
import BaseAdminAdd from "@src/page/base-admin/add";
import Worker from "@src/page/worker/list";
import PhysicalConditionList from "@src/page/physical-condition/list";
import PhysicalConditionDetail from "@src/page/physical-condition/detail";
import SafetyBeltList from "@src/page/safety-belt/list";
import GatewayList from "@src/page/gateway/list";
import GatewayAdd from "@src/page/gateway/add";
import MapList from "../../page/map/list";
import MapDetail from "../../page/map/detail";
import DeviceSetting from "@src/page/map/device-setting";
import MapSetting from "../../page/map/setting";
import React from "react";
import CreateWorker from "@src/page/worker/add";
import AddListWorker from "../../page/worker/InputFileWokerList.jsx";
import InputFileGate from "../../page/gateway/InputFile.jsx";
import InputFileHeat from "../../page/heat-watcher/InputFile.jsx";
import InputFileBaseAdmin from "../../page/base-admin/InputFile.jsx";

import MasterSetting from "../../page/master-setting/setting";
import Master from "../../page/master-setting/master-setting.jsx";
import Grouplist from "../../page/group/list";
import GroupAdd from "@src/page/group/add";
import InputFileGroup from "../../page/group/InputFile.jsx";
import VitalDeviceList from "../../page/vital-device/list";
import AddVitalDevice from "../../page/vital-device/add";
import InputFileVitalDevice from "../../page/vital-device/InputFile.jsx";
import { RoleHeadOffice } from "./roles.const.js";
import Login from "@src/page/login/Login";
import ChangePasword from "@src/page/change-password";

import Report from "../../page/report";
import Request from "../../page/request";
import AlertList from "../../page/alert-list/list.jsx";
import HeadQuaterList from "../../page/head-quaters/list.jsx";
import HeadQuaterAdd from "../../page/head-quaters/add.jsx";
import { lang } from "../../constants/common.const.js";
import ListAdminUnitService from "../../page/admin-unit-service/list.jsx";
import AddAdminUnitService from "../../page/admin-unit-service/add.jsx";
import InputFileAdminUnitService from "../../page/admin-unit-service/InputFile.jsx";
import ListAdminContractService from "../../page/admin-contract-service/list.jsx";
import AddAdminContractService from "../../page/admin-contract-service/add.jsx";
import InputFileAdminContractService from "../../page/admin-contract-service/inputFile.jsx";
import Company from "../../page/company/list.jsx";
import CreateCompany from "../../page/company/add.jsx";

const CONFIG_ROUTER = [
  {
    show: true,
    menuName: "サービスユニット管理",
    className: "custom-menu noselect",
    roleHides: [
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT
    ],
  },

  {
    show: true,
    component: Company,
    icon: <SolutionOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.COMPANY_LIST,
    menuName: "取引会社",
    exact: true,
    key: "Company",
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
    ],
  },
  {
    show: false,
    component: CreateCompany,
    icon: <BorderOuterOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.COMPANY_ADD,
    menuName: "取引会社",
    exact: true,
    key: "Company",
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
    ],
  },
  {
    show: false,
    component: CreateCompany,
    icon: <BorderOuterOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.COMPANY_EDIT,
    menuName: "取引会社",
    exact: true,
    key: "Company",
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
    ],
  },


  
  {
    show: true,
    component: ListAdminUnitService,
    icon: <UserAddOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.ADMIN_UNIT_SERVICE_LIST,
    menuName: lang.ADMIN_UNIT_SERVICE_LIST,
    exact: true,
    key: "ADMIN_UNIT_SERVICE",
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
    ],
  },
  {
    show: true,
    component: ListAdminContractService,
    icon: <UserOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.ADMIN_CONTRACT_UNIT_LIST,
    menuName: lang.ADMIN_UNIT_CONTRACT_LIST,
    exact: true,
    key: "ADMIN_UNIT_CONTRACT",
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT
    ],
  },
  {
    show: true,
    menuName: "契約ユニット",
    className: "custom-menu noselect",
    roleHides: [
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
    ],
  },
 
  {
    show: true,
    component: AlertList,
    icon: <WarningOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.ALERT_LIST,
    menuName: "アラート",
    exact: true,
    key: "ALERT_LIST",
    menuCode: "M0111",
    roleHides: [
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
    ],
  },
  {
    show: true,
    component: HeadQuaterList,
    icon: <BorderOuterOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.HEADQUATER_LIST,
    menuName: "拠点",
    exact: true,
    key: "HEADQUATER_LIST",
    menuCode: "M0111",
    roleHides: [
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
    ],
  },
  {
    show: false,
    component: HeadQuaterAdd,
    icon: <BorderOuterOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.HEADQUATER_ADD,
    menuName: "拠点",
    exact: true,
    key: "HEADQUATER_LIST",
    menuCode: "M0111",
    roleHides: [
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
    ],
  },
  {
    show: false,
    component: HeadQuaterAdd,
    icon: <BorderOuterOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.HEADQUATER_EDIT,
    menuName: "拠点",
    exact: true,
    key: "HEADQUATER_LIST",
    menuCode: "M0111",
    roleHides: [
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
    ],
  },
  {
    show: false,
    component: HeadQuaterAdd,
    icon: <BorderOuterOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.HEADQUATER_VIEW,
    menuName: "拠点",
    exact: true,
    key: "HEADQUATER_LIST",
    menuCode: "M0111",
    roleHides: [
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
    ],
  },
  {
    show: true,
    component: Master,
    icon: <SettingOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.MASTER_SETTING2,
    menuName: "マスター設定",
    exact: true,
    key: "MASTER_SETTING2",
    // menuCode: "M010",
    roleHides: [
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
    ],
  },
  {
    show: true,
    menuName: "拠点管理",
    className: "custom-menu noselect",
    roleHides: [RoleHeadOffice.USER, RoleHeadOffice.GROUP_LEADER ,RoleHeadOffice.GROUP_SUB_LEADER,],
  },
  {
    show: true,
    menuName: "グループリーダーメニュー",
    className: "custom-menu noselect",
    roleHides: [
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_UNIT_SERVICE,
    ],
  },
  {
    show: true,
    component: PhysicalConditionList,
    icon: <ProfileOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.PHYSICAL_CONDITION_LIST,
    menuName: "体調",
    exact: true,
    key: "PHYSICAL_CONDITION_LIST",
    menuCode: "M011",
    roleHides: [RoleHeadOffice.USER],
  },
  {
    show: true,
    menuName: "作業者メニュー",
    className: "custom-menu noselect",
    roleHides: [
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
      RoleHeadOffice.ADMIN_UNIT_SERVICE,
    ],
  },
  {
    show: true,
    key: "PHYSICAL_CONDITION_LIST_DETAIL",
    component: PhysicalConditionDetail,
    path: "/physical-condition/detail",
    exact: true,
    menuCode: "M011",
    menuName: "詳細な体調",
    icon: <FundOutlined style={{ fontSize: "20px" }} />,
    roleHidesSidebar: [
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
      RoleHeadOffice.ADMIN_UNIT_SERVICE,
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.USER
    ],
    roleHides:[
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
      RoleHeadOffice.ADMIN_UNIT_SERVICE,
    ]
  },
  {
    show: true,
    component: SafetyBeltList,
    icon: <GiBelt style={{ fontSize: "20px", verticalAlign: "middle" }} />,
    path: ROUTER_PAGE.SAFETY_BELT_LIST,
    menuName: "安全帯",
    exact: true,
    key: "SAFETY_BELT_LIST",
    menuCode: "B001",
    roleHides: [RoleHeadOffice.USER,RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER],
  },
  {
    show: true,
    component: MapList,
    icon: <EnvironmentOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.MAP_LIST,
    menuName: "マップ",
    exact: true,
    key: "MAP_LIST",
    menuCode: "M012s",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  // {
  //   show: false,
  //   key: "MAP_LIST",
  //   component: Chart,
  //   path: "/map/chart",
  //   exact: true,
  //   menuCode: "M011",
  //   roleHides:[RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.USER]
  // },
  {
    show: false,
    key: "MAP_LIST",
    component: MapSetting,
    path: "/map/setting",
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "MAP_LIST",
    component: MapDetail,
    path: "/map/detail/:id",
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "MAP_LIST",
    component: DeviceSetting,
    path: "/map/:id/device",
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: true,
    component: VitalDeviceList,
    icon: <HeartOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.VITAL_DEVICE_LIST,
    menuName: "デバイス",
    exact: true,
    key: "VITAL_DEVICE_LIST",
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "VITAL_DEVICE_LIST",
    component: AddVitalDevice,
    path: "/vital-device/create/new",
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "VITAL_DEVICE_LIST",
    component: AddVitalDevice,
    path: ROUTER_PAGE.VITAL_DEVICE_EDIT,
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "VITAL_DEVICE_LIST",
    component: InputFileVitalDevice,
    path: "/vital-device/inputfile",
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: true,
    component: GatewayList,
    icon: <WifiOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.GATEWAY_LIST,
    menuName: lang.GATEWAY_LIST,
    exact: true,
    key: "GATEWAY_LIST",
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "GATEWAY_LIST",
    component: GatewayAdd,
    path: "/gateway/create/new",
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "GATEWAY_LIST",
    component: GatewayAdd,
    path: ROUTER_PAGE.GATEWAY_EDIT,
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "GATEWAY_LIST",
    component: InputFileGate,
    path: "/gateway/inputfile",
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: true,
    component: HeadWatcher,
    icon: <FaTemperatureFull style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.HEAT_WATCHER_LIST,
    menuName: "SisMil",
    exact: true,
    key: "HEAT_WATCHER_LIST",
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "HEAT_WATCHER_LIST",
    component: HeadWatcherAdd,
    path: "/heat-index-watcher/create/new",
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "HEAT_WATCHER_LIST",
    component: HeadWatcherAdd,
    path: ROUTER_PAGE.HEAT_WATCHER_EDIT,
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "HEAT_WATCHER_LIST",
    component: InputFileHeat,
    path: "/heat-index-watcher/inputfile",
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
 
  {
    show: false,
    component: AddAdminUnitService,
    path: ROUTER_PAGE.ADMIN_UNIT_SERVICE_ADD,
    exact: true,
    key: "ADMIN_UNIT_SERVICE",
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
    ],
  },
  {
    show: false,
    component: AddAdminUnitService,
    path: ROUTER_PAGE.ADMIN_UNIT_SERVICE_EDIT,
    exact: true,
    key: "ADMIN_UNIT_SERVICE",
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
    ],
  },
  {
    show: false,
    component: InputFileAdminUnitService,
    path: ROUTER_PAGE.ADMIN_UNIT_SERVICE_INPUTFILE,
    exact: true,
    key: "ADMIN_UNIT_SERVICE",
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
    ],
  },
  
  {
    show: false,
    component: AddAdminContractService,
    path: ROUTER_PAGE.ADMIN_CONTRACT_UNIT_ADD,
    exact: true,
    key: "ADMIN_UNIT_CONTRACT",
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT

    ],
  },
  {
    show: false,
    key:  "ADMIN_UNIT_CONTRACT",
    component: AddAdminContractService,
    path: ROUTER_PAGE.ADMIN_CONTRACT_UNIT_EDIT,
    exact: true,
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT

    ],
  },
  {
    show: false,
    key:  "ADMIN_UNIT_CONTRACT",
    component: AddAdminContractService,
    path: ROUTER_PAGE.ADMIN_CONTRACT_UNIT_VIEW,
    exact: true,
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT

    ],
  },
  {
    show: false,
    component: InputFileAdminContractService,
    path: ROUTER_PAGE.ADMIN_CONTRACT_UNIT_INPUTFILE,
    exact: true,
    key: "ADMIN_UNIT_CONTRACT",
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_HEADQUATER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT

    ],
  },
  {
    show: true,
    component: BaseAdmin,
    icon: <TeamOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.BASE_ADMINISTRATOR_LIST,
    menuName: "拠点管理者",
    exact: true,
    key: "BASE_ADMINISTRATOR_LIST",
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER,RoleHeadOffice.ADMIN_HEADQUATER],
  },
  {
    show: false,
    key: "BASE_ADMINISTRATOR_LIST",
    component: BaseAdminAdd,
    path: "/base-admin/create/new",
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER,RoleHeadOffice.ADMIN_HEADQUATER],
  },
  {
    show: false,
    key: "BASE_ADMINISTRATOR_LIST",
    component: BaseAdminAdd,
    path: ROUTER_PAGE.BASE_ADMINISTRATOR_VIEW,
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER,RoleHeadOffice.ADMIN_HEADQUATER],
  },

  {
    show: false,
    key: "BASE_ADMINISTRATOR_LIST",
    component: BaseAdminAdd,
    path: ROUTER_PAGE.BASE_ADMINISTRATOR_UPDATE,
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER,RoleHeadOffice.ADMIN_HEADQUATER],
  },

  
  {
    show: false,
    component: BaseAdminAdd,
    icon: <TeamOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.BASE_ADMINISTRATOR_VIEW,
    menuName: "拠点管理者",
    exact: true,
    key: "BASE_ADMINISTRATOR_LIST",
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: false,
    component: BaseAdminAdd,
    icon: <TeamOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.BASE_ADMINISTRATOR_UPDATE,
    menuName: "拠点管理者",
    exact: true,
    key: "BASE_ADMINISTRATOR_LIST",
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "BASE_ADMINISTRATOR_LIST",
    component: InputFileBaseAdmin,
    path: "/base-admin/inputfile",
    exact: true,
    menuCode: "M011",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: true,
    component: Worker,
    icon: <UserSwitchOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.WORKERS_LIST,
    menuName: "作業者",
    exact: true,
    key: "WORKERS_LIST",
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "WORKERS_LIST",
    component: CreateWorker,
    path: "/worker/create",
    exact: true,
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "WORKERS_LIST",
    component: CreateWorker,
    path: "/worker/edit/:id",
    exact: true,
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "WORKERS_LIST",
    component: AddListWorker,
    path: "/worker/inputfile",
    exact: true,
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: true,
    component: Grouplist,
    icon: <UsergroupAddOutlined size={20} style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.GROUP_LIST,
    menuName: "グループ",
    exact: true,
    key: "GROUP_LIST",
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "GROUP_LIST",
    component: GroupAdd,
    path: ROUTER_PAGE.GROUP_ADD,
    exact: true,
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "GROUP_LIST",
    component: GroupAdd,
    path: ROUTER_PAGE.GROUP_LIST_EDIT,
    exact: true,
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "GROUP_LIST",
    component: GroupAdd,
    path: ROUTER_PAGE.GROUP_LIST_VIEW,
    exact: true,
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  {
    show: false,
    key: "GROUP_LIST",
    component: InputFileGroup,
    path: "/group/inputfile",
    exact: true,
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER, RoleHeadOffice.GROUP_SUB_LEADER,RoleHeadOffice.USER],
  },
  
  {
    show: true,
    component: Report,
    icon: <SnippetsOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.REPORT,
    menuName: "レポート",
    exact: true,
    key: "REPORT",
    menuCode: "M010",
    roleHides: [RoleHeadOffice.GROUP_LEADER,RoleHeadOffice.GROUP_SUB_LEADER, RoleHeadOffice.USER],
  },
  {
    show: true,
    component: MasterSetting,
    icon: <SettingOutlined style={{ fontSize: "20px" }} />,
    path: ROUTER_PAGE.MASTER_SETTING,
    menuName: "マスター設定",
    exact: true,
    key: "MASTER_SETTING",
    menuCode: "M010",
    roleHides: [
      RoleHeadOffice.GROUP_LEADER,
      RoleHeadOffice.GROUP_SUB_LEADER,
      RoleHeadOffice.USER,
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
      RoleHeadOffice.ADMIN_UNIT_SERVICE,
    ],
    roleHidesSidebar:[
      RoleHeadOffice.ADMIN_UNIT_CONTRACT,
      RoleHeadOffice.ADMIN_UNIT_SERVICE,
    ]
  },

  {
    show: false,
    component: Login,
    path: "/login",
    exact: true,
    key: "LOGIN",
  },
  {
    show: false,
    component: ChangePasword,
    path: "/change-password",
    exact: true,
    key: "CHANGE-PASSWORD",
  },
];

const splicePath = (path) => {
  const index = path.indexOf(":");
  return index >= 0 ? path.substring(0, index - 1) : path;
};
const addRouter = (routerItem, routerObj) => {
  if (routerItem.hasOwnProperty("key") && routerItem.hasOwnProperty("path")) {
    // có nhiều route cùng key thì chuyển thành mảng
    if (routerObj.hasOwnProperty(routerItem.key)) {
      if (typeof routerObj[routerItem.key] === "string") {
        routerObj[routerItem.key] = [
          routerObj[routerItem.key],
          splicePath(routerItem.path),
        ];
      } else {
        routerObj[routerItem.key] = routerObj[routerItem.key].concat([
          splicePath(routerItem.path),
        ]);
      }
    } else {
      // Chỉ có 1 key 1 route thì cho về dạng string
      routerObj[routerItem.key] = splicePath(routerItem.path);
    }
  }
};
const getRouterPage = (router, routerObj) => {
  router.forEach((routerItem) => {
    addRouter(routerItem, routerObj);
    if (
      routerItem.hasOwnProperty("children") &&
      Array.isArray(routerItem.children)
    ) {
      getRouterPage(routerItem.children, routerObj);
    }
  });
};

const finalRouter = () => {
  const routerObj = {};
  getRouterPage(CONFIG_ROUTER, routerObj);
  return routerObj;
};
export const ROUTER = finalRouter();
export default CONFIG_ROUTER;
