import {
  Button,
  Col,
  Collapse,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import classGrid from "../../models/control/grid";

import {
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";

import {
  MSG_CATCH,
  getQuerySearch,
  pushObject2Query,
  renderOption,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";

import { ApiOffice } from "../../api/backend/headerQuaters";
import ContentTitle from "../../component/Desktop/contentTitle";
import SelectCompany from "../../components/common/input/selectCompany";
import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  PAGE_SIZE,
  STYLE,
  lang,
} from "../../constants/common.const";
import { ROUTER_PAGE } from "../../constants/router.const";
import classDdl from "../../models/control/dropdownlist";
import { onChangeTable } from "../../utils/helpers";
import useAuth from "../../hooks/useAuth";

const { Content } = Layout;
const { Panel } = Collapse;

function HeadQuaterList() {
  const { isSingleCompany, companyId } = useAuth();
  const history = useHistory();
  let objSearch = getQuerySearch();
  const [initLoad, setInitLoad] = useState(true);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const openLoadingSpinner = () => {
    setShowLoading(true);
  };
  const closeLoadingSpinner = () => {
    setShowLoading(false);
  };
  const [formSearch] = Form.useForm();

  //  Grid
  const [grid, setGrid] = useState(new classGrid());
  // Input search
  // 1
  const [ddlCompany, setDdlCompany] = useState({
    ...new classDdl(isSingleCompany ? companyId : objSearch.companyId),
  });
  const [ddlBaseCode, setDdlBaseCode] = useState({
    ...new classDdl(objSearch.baseCode),
  });
  const [ddlOfficeName, setDdlOfficeName] = useState({
    ...new classDdl(objSearch.officeName),
  });
  const [page, setPage] = useState(1);
  // Step2: Sync input <-> url
  const syncInput = () => {
    objSearch.companyId = ddlCompany.value ? ddlCompany.value : null;
    objSearch.baseCode = ddlBaseCode.value ? ddlBaseCode.value : null;
    objSearch.officeName = ddlOfficeName.value ? ddlOfficeName.value : null;

    // Push to url
    pushObject2Query(objSearch);
  };

  // Search
  const search_onClick = async (
    currentPage = 1,
    sortKey = "",
    sortOrder = "desc",
    changeSelect
  ) => {
    setPage(currentPage);
    setSortKey(sortKey);
    setSortOrder(sortOrder);
    openLoadingSpinner();

    // input to objectSearch; push url
    syncInput();

    // Update format
    objSearch = getQuerySearch();

    try {
      const resOffice = await ApiOffice.searchOffice({
        ...objSearch,
        offset: (currentPage - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        sortKey,
        sortOrder,
      });
      setGrid({
        ...grid,
        data: resOffice.data.data,
        count: resOffice.data.count,
      });
    } catch (error) {
      showMessageError(MSG_CATCH());
    } finally {
      // Close loading
      closeLoadingSpinner();
    }
  };

  useEffect(() => {
    const fetchCompanyOption = async () => {
      try {
        const response = await ApiOffice.searchOffice({
          companyId: ddlCompany.value,
        });
        const data = response.data.data;

        let dataBaseCode = [...new Set(data.map((obj) => obj.baseCode))];
        dataBaseCode = dataBaseCode.map((item) => {
          return {
            value: item,
            key: item,
          };
        });

        setDdlBaseCode({
          ...ddlBaseCode,
          options: dataBaseCode,
        });

        let dataOfficeName = [...new Set(data.map((obj) => obj.officeName))];
        dataOfficeName = dataOfficeName.map((item) => {
          return {
            value: item,
            key: item,
          };
        });

        setDdlOfficeName({
          ...ddlOfficeName,
          options: dataOfficeName,
        });
      } catch (e) {
        console.log(e);
      }
    };

    fetchCompanyOption();
  }, [ddlCompany.value]);

  const showMessageError = (msg) => {
    showMessage(KEY.ERROR, msg);
  };

  // useEffect(() => {
  //   search_onClick();
  // }, []);

  useEffect(() => {
    if (initLoad) {
      setInitLoad(false);
    }
    search_onClick(1, sortKey, sortOrder, true);
  }, [ddlCompany.value, ddlBaseCode.value, ddlOfficeName.value]);

  const showMessageDeleteConfirm = async (officeId) => {
    // Action del
    const funcDel = async () => {
      openLoadingSpinner();
      try {
        Modal.destroyAll();
        await ApiOffice.deleteOffice(officeId);
        showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C004);
        search_onClick();
      } catch (ex) {
        showErrorMessage(ex);
      }
      closeLoadingSpinner();
    };

    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.ECM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: funcDel,
    });
  };

  let initColumns = [
    {
      title: "会社",
      dataIndex: "companyName",
      align: "start",
      width: "20%",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "拠点コード",
      dataIndex: "baseCode",
      width: "20%",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, row) => {
        //
        return (
          <Link
            to={ROUTER_PAGE.HEADQUATER_VIEW.replace(":id", row.officeId)}
            className="white-space"
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: "拠点名",
      dataIndex: "officeName",
      align: "start",
      width: "20%",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, row) => {
        //
        return (
          <Link
            to={`${ROUTER_PAGE.PHYSICAL_CONDITION_LIST}?OfficeId=${row.officeId}`}
            className="white-space"
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: "設定変更",
      dataIndex: "settingChange",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            <Tooltip title={lang.CLONE}>
              <Button
                onClick={() =>
                  history.push(
                    ROUTER_PAGE.HEADQUATER_EDIT.replace(":id", row.officeId)
                  )
                }
                className="mr5 ml10"
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "マスター設定",
      dataIndex: "masterSetting",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            <Tooltip title={"マスター設定"}>
              <Button
                className="mr5 ml10"
                onClick={() => {
                  history.push(
                    `/master-setting-headquaters?officeId=${row.officeId}&officeName=${row.officeName}`
                  );
                }}
              >
                <ShareAltOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: lang.DELETE,
      dataIndex: "delete",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                onClick={() => {
                  showMessageDeleteConfirm(row.officeId);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={"拠点"} />
        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
        >
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin ">
            <Col lg={6} md={10} sm={24} xs={24}>
              <div className="ant-form-item-label">{lang.COMPANY} </div>
              <SelectCompany
                acceptSetNull={!initLoad}
                state={ddlCompany}
                setState={setDdlCompany}
                className="w100"
              />
            </Col>
            <Col lg={6} md={10} sm={24} xs={24}>
              <div className="ant-form-item-label">拠点コード </div>
              <Select
                style={{
                  width: "100%",
                }}
                showSearch
                optionFilterProp="children"
                value={ddlBaseCode.value}
                className={
                  ddlBaseCode.error ? `${STYLE.BORDER_RED} w400` : "w400"
                }
                onChange={(value) => {
                  setDdlOfficeName({
                    ...ddlOfficeName,
                    value: "",
                  });
                  setDdlBaseCode({
                    ...ddlBaseCode,
                    value,
                  });
                }}
              >
                {renderOption(ddlBaseCode.options)}
              </Select>
            </Col>

            <Col lg={6} md={10} span={24}>
              <div className="ant-form-item-label">拠点名</div>
              <Select
                style={{
                  width: "100%",
                }}
                showSearch
                optionFilterProp="children"
                value={ddlOfficeName.value}
                className={
                  ddlOfficeName.error ? `${STYLE.BORDER_RED} w400` : "w400"
                }
                onChange={(value) => {
                  setDdlBaseCode({
                    ...ddlBaseCode,
                    value: "",
                  });
                  setDdlOfficeName({
                    ...ddlOfficeName,
                    value,
                  });
                }}
              >
                {renderOption(ddlOfficeName.options)}
              </Select>
            </Col>
            <Col lg={6} md={4} xs={24} sm={24}>
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                {/* <Col>
                  <Button htmlType="submit">{lang.SEARCH}</Button>
                </Col> */}

                <Col>
                  <Button
                    onClick={() => history.push(ROUTER_PAGE.HEADQUATER_ADD)}
                    className="buttonPC button--info wAuto ml10"
                  >
                    <PlusOutlined className="" />
                    {COMMON_CONSTANT.ADD}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </ContentTitle>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}>
            {/* <Button type="link" className="btnAddRecord mb15">
              <AddIcon />
              {lang.REQUEST_CREATE}
            </Button> */}
          </Col>
          <Col span={12} className="right" style={{ paddingRight: "20px" }}>
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.officeId}
          className="gridSearch "
          columns={initColumns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,
            total: grid.count,
            current: page || 1,
            showSizeChanger: false,
          }}
         scroll={{ x: 'max-content' }}
          onChange={onChangeTable(search_onClick)}
        />
      </Content>
      <Loading show={showLoading} />
    </div>
  );
}

export default HeadQuaterList;
