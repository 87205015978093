class classText {
  constructor(
    value = "",
    disabled = false,
    error = false,
    message = "",
    show,
    picker,
    format,
    users = [],
    list = [],
    init
  ) {
    this.value = value;
    this.disabled = disabled;
    this.message = message;
    this.error = error;
    this.show = show;
    this.picker = picker;
    this.format = format;
    this.users = users;
    this.list = list;
    this.init = init;
  }
}
export default classText;
