import { Col, Row, Space, Switch } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import DeviceMarker from "./deviceMarker";
import "./map.scss";
import { DEVICE_TYPE } from "../../constants/common.const";

function MapImageMarker({
  markers,
  image,
  setMarkers,
  isScale = false,
  isShowLabel,
  isDrag,
  newItem = null,
  onClickMarker,
  deviceType,
  onClickNamePin
}) {
  const [showMarker, setShowMarker] = useState(true);
  const [mapDimensions, setMapDimensions] = useState({ width: 0, height: 0 });
  const [pinSize, setPinSize] = useState(0);
  const [initDrag, setInitDrag] = useState(false);
  const listMarkers = useMemo(() => {
    return showMarker ? markers : [];
  }, [showMarker, markers]);
  const mapRef = useRef();
  const imageRef = useRef();
  const [isDraging, setIsDraging] = useState(false);
  const lastDragTime = useRef(null);
  const setDragTime = (value) => {
    lastDragTime.current = new Date().getTime();
  };
  const handleImageLoad = () => {
    if (imageRef.current) {
      const { width, height } = imageRef.current.getBoundingClientRect();
      setPinSize((width * 5) / 100);
      setMapDimensions({ width, height });
    }
  };
  const onControlledDrag = (e, position, index) => {
    const { x, y } = position;
    const newPins = [...listMarkers];
    newPins[index] = {
      ...newPins[index],
      left: (x / mapDimensions.width) * 100,
      top: (y / mapDimensions.height) * 100,
    };
    setMarkers(newPins);
  };
  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        const { width, height } = imageRef.current.getBoundingClientRect();
        setMapDimensions({
          width,
          height,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Add Item
  const handleAddItem = (event) => {
    const now = new Date().getTime();
    if (
      newItem &&
      newItem.name &&
      (!initDrag || (lastDragTime.current && now - lastDragTime.current > 100))
    ) {
      const xGlobal = event.clientX;
      const yGlobal = event.clientY;
      const { left, top } = event.currentTarget.getBoundingClientRect();
      const xLocal = xGlobal - left;
      const yLocal = yGlobal - top;
      const xLocalPer = (xLocal / mapDimensions.width) * 100;
      const yLocalPer = (yLocal / mapDimensions.height) * 100;
      setMarkers([
        ...markers,
        {
          ...newItem,
          top: yLocalPer,
          left: xLocalPer,
        },
      ]);
    }
  };

  return (
    <TransformWrapper disabled={!isScale || isDraging} initialScale={1}>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <Row>
            <Col lg={12} xs={24}>
              <Space align="center">
                <MdLocationPin style={{ color: "green", fontSize: 24 }} />
                <span>親機</span>
              </Space>

              <Space align="center" className="ml20">
                <MdLocationPin style={{ color: "blue", fontSize: 24 }} />
                <span>子機</span>
              </Space>

              <Space align="center" className="ml20">
                <MdLocationPin style={{ color: "violet", fontSize: 24 }} />
                <span>SisMil</span>
              </Space>
            </Col>

            <Col lg={12} xs={24} className="right">
              {isScale && (
                <>
                  <ZoomOutOutlined
                    className="mr10 mb10"
                    onClick={() => zoomOut(0.1)}
                    style={{
                      fontSize: 30,
                      cursor: "pointer",
                      color: "#11663f",
                    }}
                  />

                  <ZoomInOutlined
                    className="mr10 mb10"
                    onClick={() => zoomIn(0.1)}
                    style={{
                      fontSize: 30,
                      cursor: "pointer",
                      color: "#11663f",
                    }}
                  />
                </>
              )}
              {isShowLabel && (
                <Space align="center">
                  <span>ラベル表示</span>
                  <Switch
                    value={showMarker}
                    onChange={(v) => setShowMarker(v)}
                  />
                </Space>
              )}
            </Col>
            <TransformComponent>
              <Col
                onClick={handleAddItem}
                style={{
                  width: mapDimensions.width > 0 ? mapDimensions.width : "100%",
                  height: mapDimensions.height > 0 && mapDimensions.height,
                }}
                ref={mapRef}
                className=""
                span={24}
              >
                {image && (
                  <img
                    src={`data:image/png;base64,${image}`}
                    ref={imageRef}
                    onLoad={handleImageLoad}
                    alt="Loading Map..."
                    className="map-image"
                    width="100%"
                  />
                )}
                <div className="parrent-pin">
                  {listMarkers.map((pin, index) => {
                    const notSameType =
                      deviceType == DEVICE_TYPE.HEAT_WATCHER
                        ? pin.type != DEVICE_TYPE.HEAT_WATCHER
                        : pin.type == DEVICE_TYPE.HEAT_WATCHER;
                    return (
                      <DeviceMarker
                        onClickMarker={onClickMarker}
                        onClickNamePin ={onClickNamePin}
                        pin={pin}
                        type={pin.type}
                        mapWidth={mapDimensions.width}
                        mapHeight={mapDimensions.height}
                        left={pin.left}
                        top={pin.top}
                        onControlledDrag={onControlledDrag}
                        setIsDraging={setIsDraging}
                        index={index}
                        name={pin.name}
                        isDrag={!notSameType && isDrag}
                        setDragTime={setDragTime}
                        setInitDrag={setInitDrag}
                        
                      />
                    );
                  })}
                </div>
              </Col>
            </TransformComponent>
          </Row>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
}

export default MapImageMarker;
