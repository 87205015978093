import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "@src/api/backend/company";
import axios from "axios";

// Get Info company
export const getCompany = createAsyncThunk(
    "/company",
    async (_, { rejectWithValue,dispatch }) => {
      try {
        const response = await API.getCompany();
        const data = response.data;
        dispatch(setCompany(data));
        return data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return rejectWithValue(error.message);
        } else {
          throw error;
        }
      }
    }
  );


  const initialState = {
    company: [],
  };
  const companySlice = createSlice({
    name: "company",
    initialState,
    reducers: {
        setCompany: (state, { payload }) => {
        state.company = payload;
      },

    },
    extraReducers: (builder) =>{
      builder
      .addCase(getCompany.pending, (state) => {
          state.isLoading = true;
          state.error = null; // Reset error khi bắt đầu request mới
        })
      .addCase(getCompany.fulfilled, (state) => {
          state.isLoading = false;
          state.error = null;
        })
      .addCase(getCompany.rejected, (state, action) => {
          state.error = action.payload;
          state.isLoading = false;
        });
      },
    });

  

  // the action
export const {
    setCompany,
  } = companySlice.actions;
  
  // the selector
  export const companySelector = (state) => state.company;
  
  //the reducer
  export default companySlice.reducer;