// import { authContext } from "@src/api/config";
import { persistor, store } from "@src/redux/store";
import { ConfigProvider } from "antd";
import "antd-mobile/dist/antd-mobile.css";
import React from "react";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
// Lang
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

import { PersistGate } from "redux-persist/integration/react";

const queryClient = new QueryClient({
  defaultOptions:{
    queries:{
      refetchOnWindowFocus:false,
      retry:1
    }
  }
});

    ReactDOM.render(
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider >
          
              <App />
           
            </ConfigProvider>
          </PersistGate>
        </Provider>
        </QueryClientProvider>
      </BrowserRouter>,
      document.getElementById("root")
    );
  
  
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
