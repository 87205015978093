import { Col, Row, Space } from "antd";
import React, { useMemo } from "react";
import { FaFaceFrown, FaFaceSmile } from "react-icons/fa6";
import { DEVICE_TYPE } from "../../constants/common.const";
import { MdLocationPin } from "react-icons/md";
import { GoAlertFill } from "react-icons/go";
import Draggable from "react-draggable";
import { FaFaceSadTear, FaFaceMeh } from "react-icons/fa6";
import AntennaIcon from "@src/components/common/custom-icon/antennaIcon";
import iconOn from "@src/styles/image/antenna/on.png";
import iconOff from "@src/styles/image/antenna/off.png";

import "./map.scss";
import { isBoolean, isNumber } from "lodash";
function DeviceMarker({
  pin,
  mapWidth,
  mapHeight,
  type,
  left,
  top,
  onControlledDrag,
  setIsDraging,
  index,
  name,
  isDrag = false,
  setDragTime = () => { },
  setInitDrag = (v) => { },
  onClickMarker = () => { },
  onClickNamePin = () => { }
}) {
  const pinColor = useMemo(() => {
    switch (String(type)) {
      case DEVICE_TYPE.HEAT_WATCHER: {
        return "#ee82ee";
      }
      case DEVICE_TYPE.MAIN_DEVICE: {
        return "#008000";
      }
      case DEVICE_TYPE.SUB_DEVICE: {
        return "#0000ff";
      }
      default: {
        return "";
      }
    }
  }, [type]);
  // CLick Name Pin
  const isSubGw = pin && pin.gatewayType === "2"

  const handleClickNamePin = (e) => {
    if (!isSubGw) {
      return;
    }
    onClickNamePin(pin);
  };
  return (
    <>
      {pin.top !== "" &&
        pin.top !== null &&
        pin.left !== "" &&
        pin.left !== "" &&
        !pin.hide && (
          <Draggable
            disabled={!isDrag}
            onStart={(e) => {
              setInitDrag(true);
            }}
            bounds="parent"
            position={{
              x: (left / 100) * mapWidth,
              y: (top / 100) * mapHeight,
            }}
            onStop={(e, data) => {
              onControlledDrag(e, data, index);
              setDragTime();
            }}
          >
            <div
              style={{
                height: 25,
                width: 50,

                cursor: "pointer",
                display: "flex",
                alignItems: "flex-end",
                position: "absolute",
              }}
              onClick={(e) => {
                onClickMarker(pin)
                handleClickNamePin()
              }}
            >
              <MdLocationPin
                style={{
                  cursor: "pointer",
                  color: pinColor,
                  fontSize: 24,
                }}
              />
              <div
                style={{
                  top: 0,
                  left: 33,
                  padding: `10px`,
                }}
                className="map-description"
              >
                <Row
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="w100"
                >
                  <div  className={isSubGw && "pinName"} style={
                    isBoolean(pin.isMainGatewayAlive)
                      ? { width: "100%", display: "flex", textWrap: "nowrap", alignItems: "center", }
                      : { width: 'calc(100% - 22px)', textWrap: "nowrap" }
                  }>
                    {pin.locationName || name}
                    {isBoolean(pin.isMainGatewayAlive) &&
                      <img width={14} height={14} src={pin.isMainGatewayAlive ? iconOn : iconOff} alt="" className="ml6" />
                    }
                  </div>

                  {isNumber(pin.waveLevel) && (
                    <AntennaIcon level={pin.waveLevel} width={20} wrapStyle={{ padding: 0 }} />
                  )}
                  {/* </div> */}
                </Row>
                {type == DEVICE_TYPE.SUB_DEVICE && (
                  <div
                    style={{
                      marginTop: 6,
                      gap: 10,
                    }}
                    className="map-content"
                  >
                    <Row>
                      <Col span={24} className="center">
                        <GoAlertFill
                          style={{ color: "red", fontSize: 18 }}
                        />
                      </Col>
                      <Col
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                        span={24}
                        className="center"
                      >
                        {pin.countEmergency || 0}
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24} className="center">
                        <FaFaceSadTear
                          style={{ color: "red", fontSize: 18 }}
                        />
                      </Col>
                      <Col
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                        span={24}
                        className="center"
                      >
                        {pin.countAlertLevel3 || 0}
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24} className="center">
                        <FaFaceFrown
                          style={{ color: "orange", fontSize: 18 }}
                        />
                      </Col>
                      <Col
                        style={{
                          fontSize: 12,
                        }}
                        span={24}
                        className="center"
                      >
                        {pin.countAlertLevel2 || 0}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="center">
                        <FaFaceMeh
                          style={{ color: "gold", fontSize: 18 }}
                        />
                      </Col>
                      <Col
                        style={{
                          fontSize: 12,
                        }}
                        span={24}
                        className="center"
                      >
                        {pin.countAlertLevel1 || 0}
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24} className="center">
                        <FaFaceSmile
                          style={{ color: "green", fontSize: 18 }}
                        />
                      </Col>
                      <Col
                        style={{
                          fontSize: 12,
                        }}
                        span={24}
                        className="center"
                      >
                        {pin.countAlertLevel0 || 0}
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          </Draggable>
        )}
    </>
  );
}

export default DeviceMarker;
