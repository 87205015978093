/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ApiOffice } from "../../api/backend/headerQuaters";

import BreakLine from "../../component/Desktop/breakLine";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput";
import TitlePage from "../../component/Desktop/titlePage";
import CompayApi from "./../../api/backend/company";

import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";

import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import UserApi from "@src/api/backend/user";
import GroupItem from "../../component/Desktop/groupItem";
import {
  MSG_CATCH,
  checkEmail,
  checkErrorConfirmPasssword,
  checkErrorPassword,
  checkPhoneNumber,
  formatListCompanyOption,
  formatListOptionOffice,
  renderOption,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";
import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import InputUserName from "../../components/common/input/inputUserName";
import useWidthInput from "../../hooks/useWidthInput";
import useAuth from "../../hooks/useAuth";

function CreateBaseAddmin() {
  const { isSingleCompany, companyId, companyName } = useAuth();
  const widthInput = useWidthInput();
  let { id } = useParams();
  const history = useHistory();
  let [initPage] = useState(true);
  let [showLoading, setShowLoading] = useState(false);
  const initLoad = useRef(true);
  const [clicked, setClicked] = useState(false);
  const [modeEdit] = useState(true);
  // Loading

  const [ddlBaseName, setddlBaseName] = useState(new classDdl());

  let [txtLoginID, settxtLoginID] = useState(new classText());
  let [txtPassword, settxtPassword] = useState(new classText());
  let [txtPasswordConfirm, settxtPasswordConfirm] = useState(new classText());

  let [txtFullName, settxtFullName] = useState(new classText());
  let [txtFuriganaName, settxtFuriganaName] = useState(new classText());
  let [txtTelephone, settxtTelephone] = useState(new classText());
  let [txtEmail, settxtEmail] = useState(new classText());
  const [ddlCompany, setddlCompany] = useState(
    new classDdl(isSingleCompany ? companyId : null)
  );

  // Focus
  let ddlBaseNameEl = useRef(null);
  let txtLoginIDEl = useRef(null);
  let txtPasswordEl = useRef(null);
  let txtPasswordConfirmEl = useRef(null);
  let txtFullNameEl = useRef(null);
  let txtFuriganaNameEl = useRef(null);
  let txtTelephoneEl = useRef(null);
  let txtEmailEl = useRef(null);
  const ddlCompanyEl = useRef(null);

  const getIniData = async () => {
    if (id) {
      setShowLoading(true);
    }
    try {
      const [resDetail, resCompany, resListOffice] = await Promise.all([
        id ? UserApi.getDetailUser(id) : null,
        !isSingleCompany ? CompayApi.getCompany({}) : null,
        !id ? ApiOffice.searchOffice({}) : null,
      ]);
      const listCompany =
        resCompany && formatListCompanyOption(resCompany.data.data);

      // edit
      if (id) {
        const dataDetail = resDetail.data;
        if (!isSingleCompany) {
          setddlCompany({
            ...ddlCompany,
            value: dataDetail.companyId,

            options: listCompany,
          });
        }
        const resOffice = await ApiOffice.searchOffice({
          companyId: dataDetail.companyId,
        });

        setddlBaseName({
          ...ddlBaseName,
          value: dataDetail.officeId,
          options: formatListOptionOffice(resOffice.data.data),
        });
        settxtLoginID({ ...txtLoginID, value: dataDetail.userName });

        settxtFullName({ ...txtFullNameEl, value: dataDetail.fullName });
        settxtFuriganaName({
          ...txtFuriganaNameEl,
          value: dataDetail.furigana,
        });
        settxtEmail({ ...txtEmailEl, value: dataDetail.email });
        settxtTelephone({
          ...txtTelephoneEl,
          value: dataDetail.phoneNumber,
        });
      } else {
        const listOffice = formatListOptionOffice(resListOffice.data.data);
        if (!isSingleCompany) {
          setddlCompany({
            ...ddlCompany,
            options: listCompany,
          });
        }
        setddlBaseName({ ...ddlBaseName, options: listOffice });
      }
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
      initLoad.current = false;
    }
  };
  useEffect(() => {
    getIniData();
  }, []);

  const getListOffice = async () => {
    const listOffice = await ApiOffice.searchOffice({
      CompanyId: ddlCompany.value,
    });

    setddlBaseName({
      ...ddlBaseName,
      options: formatListOptionOffice(listOffice.data.data),
      value: null,
    });
  };
  useEffect(() => {
    if (!initLoad.current && ddlCompany.value) {
      getListOffice();
    }
  }, [ddlCompany.value]);

  // Validate
  const validateData = () => {
    let flagError = false;
    settxtEmail({ ...txtEmail, error: false });
    settxtTelephone({ ...txtTelephone, error: false });
    settxtFuriganaName({ ...txtFuriganaName, error: false });
    settxtFullName({ ...txtFullName, error: false });
    settxtPasswordConfirm({ ...txtPasswordConfirm, error: false });
    settxtPassword({ ...txtPassword, error: false });
    settxtLoginID({ ...txtLoginID, error: false });
    setddlBaseName({ ...ddlBaseName, error: false });
    setddlCompany({ ...ddlCompany, error: false });

    if (txtEmail.value && !checkEmail(txtEmail.value)) {
      flagError = true;
      settxtEmail({ ...txtEmail, error: true });
      txtEmailEl.current.focus();
    }

    if (txtTelephone.value && !checkPhoneNumber(txtTelephone.value)) {
      flagError = true;
      settxtTelephone({ ...txtTelephone, error: true });
      txtTelephoneEl.current.focus();
    }

    if (!txtFuriganaName.value) {
      flagError = true;
      settxtFuriganaName({ ...txtFuriganaName, error: true });
      txtFuriganaNameEl.current.focus();
    }

    if (!txtFullName.value) {
      flagError = true;
      settxtFullName({ ...txtFullName, error: true });
      txtFullNameEl.current.focus();
    }

    if (
      (!txtPasswordConfirm.value && !id) ||
      (!id &&
        checkErrorConfirmPasssword(
          txtPassword.value,
          txtPasswordConfirm.value
        ) != null) ||
      (id &&
        (txtPassword.value || txtPasswordConfirm.value) &&
        checkErrorConfirmPasssword(
          txtPassword.value,
          txtPasswordConfirm.value
        ) != null)
    ) {
      flagError = true;
      settxtPasswordConfirm({ ...txtPasswordConfirm, error: true });
      txtPasswordConfirmEl.current.focus();
    }

    if (
      (!txtPassword.value && !id) ||
      (!id && checkErrorPassword(txtPassword.value) != null) ||
      (id && txtPassword.value && checkErrorPassword(txtPassword.value) != null)
    ) {
      flagError = true;
      settxtPassword({ ...txtPassword, error: true });
      txtPasswordEl.current.focus();
    }

    if (!txtLoginID.value) {
      flagError = true;
      settxtLoginID({ ...txtLoginID, error: true });
      txtLoginIDEl.current.focus();
    }

    if (!ddlBaseName.value) {
      flagError = true;
      setddlBaseName({ ...ddlBaseName, error: true });
      ddlBaseNameEl.current.focus();
    }

    if (!ddlCompany.value) {
      flagError = true;
      setddlCompany({ ...ddlCompany, error: true });
      ddlCompanyEl.current.focus();
    }

    return !flagError;
  };

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    backToSearch();
  }

  const postData = async () => {
    try {
      setShowLoading(true);
      const dataPost = {
        officeId: ddlBaseName.value,
        userName: txtLoginID.value,
        password: txtPassword.value,
        confirmPassword: txtPasswordConfirm.value,
        furigana: txtFuriganaName.value,
        fullName: txtFullName.value,
        phoneNumber: txtTelephone.value,
        email: txtEmail.value,
        companyId: ddlCompany.value,
        role: RoleHeadOffice.ADMIN_HEADQUATER,
      };
      await UserApi.save(dataPost, id);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
      history.goBack();
    } catch (error) {
      let msg;
      if (error.response) {
        if (error.response.status === 409) {
          msg = lang.TRY_LOGINID;
          settxtLoginID({ ...txtLoginID, value: error.response.data });
        } else if (error.response.status === 400) {
          msg = error.response.data;
        } else {
          msg = error.response.data;
        }
      }
      showMessage(KEY.ERROR, msg || MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  // Save Data
  const onSave = () => {
    setClicked(true);
    // Validate Data
    if (!validateData()) {
      return;
    }
    // Call Api
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: id ? COMMON_CONSTANT.ECM_C009 : COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.BASE_ADMINISTRATOR_CREATE} />

      {/* Group 1 */}
      <GroupItem>
        <BreakLine />
        <RowInput required box name={lang.COMPANY}>
          <>
            {isSingleCompany ? (
              <Input value={companyName} disabled className={widthInput} />
            ) : (
              <Select
                showSearch
                optionFilterProp="children"
                disabled={isSingleCompany}
                value={ddlCompany.value}
                ref={ddlCompanyEl}
                className={
                  ddlCompany.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                onChange={(v) =>
                  setddlCompany({
                    ...ddlCompany,
                    value: v,
                  })
                }
              >
                {renderOption(ddlCompany.options, false)}
              </Select>
            )}
          </>

          <></>
        </RowInput>
        <BreakLine />
        <RowInput box name={lang.BASE_NAME} required={modeEdit}>
          <Select
            showSearch
            optionFilterProp="children"
            disabled={!ddlCompany.value}
            value={ddlBaseName.value}
            ref={ddlBaseNameEl}
            className={
              ddlBaseName.error
                ? `${STYLE.BORDER_RED}  ${widthInput}`
                : ` ${widthInput}`
            }
            onChange={(v) =>
              setddlBaseName({
                ...ddlBaseName,
                value: v,
              })
            }
          >
            {renderOption(ddlBaseName.options, false)}
          </Select>
        </RowInput>
        <BreakLine />
        <RowInput box name={"ログインID"}>
          <>
            <InputUserName
              state={txtLoginID}
              setState={settxtLoginID}
              ref={txtLoginIDEl}
              role={RoleHeadOffice.ADMIN_HEADQUATER}
              isModeEdit={id}
            />
          </>
          {/* <>
            <div className="white-space"> {ddlBaseName.value}</div>
          </> */}
        </RowInput>
        <BreakLine />
        <RowInput box name={lang.PASSWORD} required={!id}>
          <>
            <div>
              <Input
                type="password"
                autocomplete="new-password"
                aria-autocomplete="none"
                value={txtPassword.value}
                ref={txtPasswordEl}
                className={
                  txtPassword.error
                    ? `${STYLE.BORDER_RED}  ${widthInput}`
                    : ` ${widthInput}`
                }
                maxLength={100}
                onChange={(e) =>
                  settxtPassword({
                    ...txtPassword,
                    value: e.target.value,
                  })
                }
              ></Input>
              {txtPassword.error && clicked && (
                <div style={{ color: "red" }} className="fs12">
                  {checkErrorPassword(txtPassword.value)}
                </div>
              )}
            </div>
          </>
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.CONFIRM_PASSWORD} required={!id}>
          <div>
            <Input
              type="password"
              autocomplete="new-password"
              aria-autocomplete="none"
              value={txtPasswordConfirm.value}
              ref={txtPasswordConfirmEl}
              className={
                txtPasswordConfirm.error
                  ? `${STYLE.BORDER_RED}  ${widthInput}`
                  : ` ${widthInput}`
              }
              maxLength={100}
              onChange={(e) =>
                settxtPasswordConfirm({
                  ...txtPasswordConfirm,
                  value: e.target.value,
                })
              }
            ></Input>
            {txtPasswordConfirm.error && clicked && (
              <div style={{ color: "red" }} className="fs12">
                {checkErrorConfirmPasssword(
                  txtPassword.value,
                  txtPasswordConfirm.value
                )}
              </div>
            )}
          </div>
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.FULL_NAME} required={modeEdit}>
          {modeEdit ? (
            <div>
              <Input
                value={txtFullName.value}
                ref={txtFullNameEl}
                className={
                  txtFullName.error
                    ? `${STYLE.BORDER_RED}  ${widthInput}`
                    : ` ${widthInput}`
                }
                maxLength={100}
                onChange={(e) =>
                  settxtFullName({
                    ...txtFullName,
                    value: e.target.value,
                  })
                }
              ></Input>

              {/* Please enter a full-width space between your first and last name */}
              <div className="fs12">
                姓と名の間に全角スペースを入れてください
              </div>
            </div>
          ) : (
            <>
              <div className="white-space"> {txtFullName.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.FURIGANA_NAME} required={modeEdit}>
          {modeEdit ? (
            <div>
              <Input
                value={txtFuriganaName.value}
                ref={txtFuriganaNameEl}
                className={
                  txtFuriganaName.error
                    ? `${STYLE.BORDER_RED}  ${widthInput}`
                    : ` ${widthInput}`
                }
                maxLength={100}
                onChange={(e) =>
                  settxtFuriganaName({
                    ...txtFuriganaName,
                    value: e.target.value,
                  })
                }
              ></Input>
              {/* Please enter a full-width space between your first and last name */}
              <div className="fs12">
                姓と名の間に全角スペースを入れてください
              </div>
            </div>
          ) : (
            <>
              <div className="white-space"> {txtFuriganaName.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.TELEPHONE_NUMBER}>
          {modeEdit ? (
            <div>
              <Input
                value={txtTelephone.value}
                ref={txtTelephoneEl}
                className={
                  txtTelephone.error
                    ? `${STYLE.BORDER_RED}  ${widthInput}`
                    : ` ${widthInput}`
                }
                maxLength={15}
                onChange={(e) =>
                  settxtTelephone({
                    ...txtTelephone,
                    value: e.target.value,
                  })
                }
              ></Input>
              {/* Please enter only half-width numbers and half-width hyphens (-) */}
              <div
                style={{
                  color: txtTelephone.error && clicked && "red",
                }}
                className="fs12"
              >
                半角数字と半角のハイフン（-）のみを入力してください
              </div>
            </div>
          ) : (
            <>
              <div className="white-space"> {txtTelephone.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.EMAIL_USER}>
          {modeEdit ? (
            <div>
              <Input
                value={txtEmail.value}
                ref={txtEmailEl}
                className={
                  txtEmail.error
                    ? `${STYLE.BORDER_RED}  ${widthInput}`
                    : ` ${widthInput}`
                }
                maxLength={64}
                onChange={(e) =>
                  settxtEmail({
                    ...txtEmail,
                    value: e.target.value,
                  })
                }
              ></Input>
              {/* Please enter only half-width numbers and half-width hyphens (-) */}
              <div
                style={{
                  color: txtEmail.error && clicked && "red",
                }}
                className="fs12"
              >
                半角英数字と半角記号のみを入力してください
              </div>
            </div>
          ) : (
            <>
              <div className="white-space"> {txtEmail.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          <Button
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              //confirmSaveData();
              onSave();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={cancelSaveData}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CreateBaseAddmin;
