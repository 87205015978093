/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Modal, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import BreakLine from "../../component/Desktop/breakLine";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput";
import TitlePage from "../../component/Desktop/titlePage";

import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";

import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import ApiCompany from "../../api/backend/company";
import GroupItem from "../../component/Desktop/groupItem";
import {
  MSG_CATCH,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";
import classText from "../../models/control/text";
import useWidthInput from "../../hooks/useWidthInput";

function CreateCompany() {
  let { id } = useParams();
  const history = useHistory();
  let [showLoading, setShowLoading] = useState(false);
  const initLoad = useRef(true);
  const [modeEdit] = useState(true);

  // Loading
  let [txtOfficeCode, setTxtOfficeCode] = useState(new classText());
  let [txtCompanyName, setTxtCompanyName] = useState(new classText());

  // Focus
  let txtOfficeCodeIDE = useRef(null);
  let txtCompanyNameIDE = useRef(null);
  const widthInput = useWidthInput();

  const getIniData = async () => {
    if (id) {
      setShowLoading(true);
    }
    try {
      const [data] = await Promise.all([
        id ? ApiCompany.getDetailCompany(id) : null,
      ]);
      // edit
      if (id) {
        setTxtOfficeCode({ ...txtOfficeCode, value: data.data.officeCode });
        setTxtCompanyName({ ...txtCompanyName, value: data.data.companyName });
      } else {
      }
    } catch (error) {
      console.log(error);
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
      initLoad.current = false;
    }
  };

  useEffect(() => {
    getIniData();
  }, [id]);

  // Validate
  const validateData = () => {
    let flagError = false;
    setTxtCompanyName({ ...txtCompanyName, error: false });
    setTxtOfficeCode({ ...txtOfficeCode, error: false });

    if (!txtCompanyName.value) {
      flagError = true;
      setTxtCompanyName({ ...txtCompanyName, error: true });
      txtCompanyNameIDE.current.focus();
    }

    if (!txtOfficeCode.value) {
      flagError = true;
      setTxtOfficeCode({ ...txtOfficeCode, error: true });
      txtOfficeCodeIDE.current.focus();
    }

    return !flagError;
  };

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    backToSearch();
  }

  const postData = async () => {
    try {
      setShowLoading(true);
      const dataPost = {
        officeCode: txtOfficeCode.value,
        companyName: txtCompanyName.value,
      };
      if (id) {
        dataPost.companyId = id;
      }
      await ApiCompany.save(dataPost, id);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
      history.goBack();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  // Save Data
  const onSave = () => {
    // Validate Data
    if (!validateData()) {
      return;
    }
    // Call Api
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: id ? COMMON_CONSTANT.ECM_C009 : COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={"取引会社設定"} />

      {/* Group 1 */}
      <GroupItem>
        <BreakLine />
        <RowInput box name={"企業コード"} required={modeEdit}>
          <>
            <Input
              required
              box
              value={txtOfficeCode.value}
              ref={txtOfficeCodeIDE}
              className={
                txtOfficeCode.error
                  ? `${STYLE.BORDER_RED} ${widthInput} `
                  : `${widthInput}`
              }
              maxLength={100}
              onChange={(e) =>
                setTxtOfficeCode({
                  ...txtOfficeCode,
                  value: e.target.value,
                })
              }
            ></Input>
          </>
          {/* <>
            <div className="white-space"> {ddlBaseName.value}</div>
          </> */}
        </RowInput>
        <BreakLine />
        <RowInput box name={"会社名"} required={modeEdit}>
          <>
            <Input
              required
              box
              value={txtCompanyName.value}
              ref={txtCompanyNameIDE}
              className={
                txtCompanyName.error
                  ? `${STYLE.BORDER_RED} ${widthInput}`
                  : `${widthInput}`
              }
              maxLength={100}
              onChange={(e) =>
                setTxtCompanyName({
                  ...txtCompanyName,
                  value: e.target.value,
                })
              }
            ></Input>
          </>
        </RowInput>
        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          <Button
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              //confirmSaveData();
              onSave();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={cancelSaveData}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CreateCompany;
