import Api from "@src/api";
export const LocationApi = {
  getListLocation(params) {
    return Api().get("/api/Location", {
      params
    });
  },
  getOne(id) {
    return Api().get(`/api/Location/${id}`);
  },
  save(body) {
    return Api().post("/api/Location", body);
  },
  getMap(params) {
    return Api().get("/api/Map", {
      params
    });
  },
  getOneMap(id) {
    return Api().get(`/api/Map/${id}`);
  },
  saveMap(body, officeId) {
    return Api().post(`/api/Map/${officeId}`, body);
  },
  getMapImage(params) {
    return Api().get(`/api/Map/GetMapImageData`, { params });
  },
  setDeviceToMap(formData) {
    return Api().put("/api/Map/SetDeviceToMap", formData, {});
  },
};
