import React, { useEffect, useRef, useState } from "react";
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row } from "antd";

import { DeleteOutlined, ToTopOutlined } from "@ant-design/icons";
import { COMMON_CONSTANT, lang } from "../../../constants/common.const";
import { getDotFile, getNameFile } from "../../../constants/utils";

const formDataAttach = new FormData();
let indexFile = 0;
const InputFile = ({ tblAttach, setTblAttach, modeEdit = true }) => {
  // File
  const fileEl = useRef(null);
  let [fileAttachErrorMessage, setFileAttachErrorMessage] = useState("");
  //   let [tblAttach, setTblAttach] = useState([]);

  function readAttachFileUrl(ev) {
    let el = ev.target;
    if (el.files && el.files[0]) {
      if (el.files[0].size > 102400000) {
        setFileAttachErrorMessage(COMMON_CONSTANT.WF_E001);
        el.value = ""; // Clear file
        return;
      }

      formDataAttach.append(indexFile++, el.files[0]);
      el.value = ""; // Clear file
      // Render
      renderTableAttach();
    }
  }

  function renderTableAttach() {
    let list = [...tblAttach];
    let id = indexFile - 1;
    let file = formDataAttach.get(id);
    let row = {};
    row["IndexOrder"] = id; // Key
    row["FileName"] = getNameFile(file.name);
    row["DotName"] = getDotFile(file.name);
    row["Description"] = "";
    row["file"] = file; // File
    list.push(row);
    setTblAttach(list);
  }
  return (
    <Row>
      <Col span={24}>
        <input
          type="file"
          className="d-none"
          accept=".csv"
          ref={fileEl}
          onChange={readAttachFileUrl}
        />
        <Button
          disabled={tblAttach.length > 0}
          type="link"
          onClick={() => {
            setFileAttachErrorMessage("");
            fileEl.current.click();
          }}
          className="buttonPC button--outline --todo-- wAuto"
          style={{
            backgroundColor: "#f7f9fc",
            marginBottom: 10,
            borderColor: "lightgray",
          }}
        >
          <ToTopOutlined />
          {lang.FILE_N}
        </Button>

        <div className="red fs14 mb10">{fileAttachErrorMessage}</div>
      </Col>
      <Col span={24}>
        {tblAttach.length > 0 &&
          tblAttach.map((item, index) => {
            let urlFile = item.file
              ? URL.createObjectURL(item.file)
              : item.attachmentUrl;

            let fileName = item.file
              ? item.file.name
              : item.FileName + "." + item.DotName;

            return (
              <Row
                className="mb10"
                key={index}
                style={{ alignItems: "center" }}
              >
                {modeEdit && (
                  <>
                    <Col flex={"50px"}>
                      <Button
                        onClick={() => {
                          let list = [...tblAttach].filter(
                            (x) => x["IndexOrder"] !== item["IndexOrder"]
                          );
                          setTblAttach(list);
                        }}
                        className="btn-deletefile mr8"
                        style={{
                          background: "#f7f9fc",
                          minWidth: "unset",
                          height: 30,
                        }}
                        icon={<DeleteOutlined />}
                      />
                    </Col>
                  </>
                )}
                <Col flex={"auto"}>
                  <a href={urlFile} download={fileName} target="_blank">
                    {fileName}
                  </a>
                </Col>
              </Row>
            );
          })}
      </Col>
    </Row>
  );
};
export default InputFile;
