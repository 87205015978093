// import external libs
import { Button, Col, Divider, Modal, Row, Space, Table } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";

import { GatewayApi } from "../../api/backend/gateway";
import Loading from "../../component/Desktop/loading/loading";
import {
  CONFIG,
  GATEWAY_TYPE_VALUE,
  KEY,
  lang,
} from "../../constants/common.const";
import {
  showErrorMessage,
} from "../../constants/utils";
import { useMediaQuery } from "react-responsive";

function RegisterGatewayModal(props) {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { visible, handleCancel, officeId, heatWatcherId, currentLocation } =
    props;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled:
        record.heatIndexWatcherId &&
        record.heatIndexWatcherId !== heatWatcherId,
    }),
  };

  const handleResgistrationGateway = async () => {
    try {
      setLoading(true);
      await GatewayApi.gatewayRegistration(heatWatcherId, selectedRowKeys);
      props.setShowRegisterGatewayModal(false);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };
  // List gatewya
  const listGatewayQuery = useQuery(
    "listgatewayRegistion",
    () =>
      GatewayApi.listGatewayRegistration({
        officeId,
        heatIndexWatcherId: heatWatcherId,
      }),
    {
      enabled: visible,
      onSuccess: (data) => {
        const listGateway = data.data.data.filter(
          (i) => i.gatewayType !== GATEWAY_TYPE_VALUE.MAIN
        );
        const listCheckedInit = listGateway
          .filter(
            (i) => i.heatIndexWatcherId && i.heatIndexWatcherId == heatWatcherId
          )
          .map((i) => i.gatewayID);
        setSelectedRowKeys(listCheckedInit);
      },
    }
  );
  const grid = listGatewayQuery.data
    ? {
        data: listGatewayQuery.data.data.data.filter(
          (i) => i.gatewayType !== GATEWAY_TYPE_VALUE.MAIN
        ),
        count: listGatewayQuery.data.data.data.filter(
          (i) => i.gatewayType !== GATEWAY_TYPE_VALUE.MAIN
        ).length,
      }
    : {};

  let initColumns = [
    {
      title: "親機/子機種別",
      dataIndex: "gatewayType",
    },

    {
      title: "親機/子機ID",
      dataIndex: "gatewayID",
    },

    {
      title: "親機/子機位置情報名",
      dataIndex: "gatewayLocation",
    },
    {
      title: "登録済みのSisMilID",
      dataIndex: "heatIndexWatcherId",
    },
    {
      title: "紐付けられたSisMilの設",
      dataIndex: "heatIndexWatcherLocation",
    },
  ];

  const onCancel = () => {
    handleCancel();
  };
  return (
    <>
      <Modal
        footer={null}
        title={"親機/子機登録"}
        open={visible}
        maskClosable={false}
        onCancel={onCancel}
        width={1100}
        centered
      >
        <div>
          <Divider />

          <Row className="rowNotMargin">
            <Col span={24}>
              <Row>
                <Col span={16}>親機/子機を選択してください</Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={!isPC ? 16 : 6}>SisMil ID</Col>
                {/* {isPC && <Col span={8}></Col>} */}
                <Col span={8}>{heatWatcherId}</Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={!isPC ? 16 : 6}>設置場所</Col>
                <Col span={8}>{currentLocation}</Col>
              </Row>
            </Col>
          </Row>

          <Divider />

          <div
            className="site-layout-background"
            style={{
              margin: "24px 0px",
            }}
          >
            {/* Count */}
            <Row justify="space-between ">
              <Col span={12}></Col>
              <Col span={12} className="right">
                {/* Count */}
                <Space size={10} align="center ">
                  {lang.ACCESS_NUMBERS}
                  <span>
                    {grid.count}
                    {lang.CASE}
                  </span>
                </Space>
              </Col>
            </Row>
            {/* Grid  */}
            <Table
              rowKey={(record) => record.gatewayID}
              rowSelection={rowSelection}
              className="gridSearch "
              columns={initColumns}
              dataSource={grid.data}
              pagination={{
                ...CONFIG.paging,
                total: grid.count || 0,
                pageSize: 10,
                showSizeChanger: false,
              }}
             scroll={{ x: 'max-content' }}
            />
          </div>

          <Row justify="end" className="rowNotMargin">
            {/* Button Search/Clear */}
            <Col flex="none" className="right">
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                    onClick={() => handleResgistrationGateway()}
                  >
                    {lang.OK}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto "
                    onClick={handleCancel}
                  >
                    {lang.BACK}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
      <Loading show={loading} />
    </>
  );
}

export default RegisterGatewayModal;
