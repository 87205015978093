import { lang } from "@src/constants/common.const";

export const ROUTER_PAGE = {
  DASHBOARD: "/home",
  USER_INFO: "/user",
  PHYSICAL_CONDITION_LIST: "/physical-condition/list",
  SAFETY_BELT_LIST: "/safety-belt/list",
  MAP_LIST: "/map/list",
  VITAL_DEVICE_LIST: "/vital-device/list",
  VITAL_DEVICE_EDIT: "/vital-device/edit/:id",
  GATEWAY_LIST: "/gateway/list",
  GATEWAY_EDIT: "/gateway/edit/:id",
  HEAT_WATCHER_LIST: "/heat-index-watcher/list",
  HEAT_WATCHER_EDIT: "/heat-index-watcher/edit/:id",
  ADMIN_UNIT_SERVICE_LIST:"/admin-unit-service/list",
  ADMIN_UNIT_SERVICE_ADD:"/admin-unit-service/create/new",
  ADMIN_UNIT_SERVICE_EDIT:"/admin-unit-service/edit/:id",
  ADMIN_UNIT_SERVICE_INPUTFILE:"/admin-unit-service/inputFile",

  ADMIN_CONTRACT_UNIT_LIST:"/admin-unit-contract/list",
  ADMIN_CONTRACT_UNIT_ADD:"/admin-unit-contract/create/new",
  ADMIN_CONTRACT_UNIT_EDIT:"/admin-unit-contract/edit/:id",
  ADMIN_CONTRACT_UNIT_VIEW:"/admin-unit-contract/view/:id",
  ADMIN_CONTRACT_UNIT_INPUTFILE:"/admin-unit-contract/inputFile",

  BASE_ADMINISTRATOR_LIST: "/base-admin/list",
  BASE_ADMINISTRATOR_ADD: "/base-admin/create/new",
  BASE_ADMINISTRATOR_UPDATE: "/base-admin/edit/:id",
  BASE_ADMINISTRATOR_VIEW: "/base-admin/view/:id",
  
  WORKERS_LIST: "/worker/list",
  GROUP_LIST: "/group/list",
  GROUP_ADD:"/group/create/new",
  GROUP_LIST_EDIT:"/group/edit/:id",
  GROUP_LIST_VIEW:"/group/view/:id",
  //group leader
  GROUP_LEADER: "/grouplead/list",
  REPORT: "/report",
  REQUEST: "/request",
  MASTER_SETTING: "/master-setting-headquaters",
  MASTER_SETTING2: "/master-setting-unit-contract",
  ALERT_LIST:"/alert/list",
  HEADQUATER_LIST:"/header-quaters/list",
  HEADQUATER_ADD:"/header-quaters/create/new",
  HEADQUATER_EDIT:"/header-quaters/edit/:id",
  HEADQUATER_VIEW:"/header-quaters/view/:id",

  COMPANY_LIST: "/company/list",
  COMPANY_ADD:"/company/create/new",
  COMPANY_EDIT:"/company/edit/:id",
};

export const NAME_PAGE = {
  DASHBOARD: lang.HOMEPAGE,
  PHYSICAL_CONDITION_LIST: lang.PHYSICAL_CONDITION_LIST,
  MAP_LIST: lang.MAP_LIST,
  VITAL_DEVICE_LIST: lang.VITAL_DEVICE_LIST,
  GATEWAY_LIST: lang.GATEWAY_LIST,
  HEAT_WATCHER_LIST: lang.HEAT_WATCHER_LIST,
  BASE_ADMINISTRATOR_LIST: lang.BASE_ADMINISTRATOR_LIST,
  WORKERS_LIST: lang.WORKERS_LIST,
  GROUP_LIST: lang.GROUP_LIST,
  REPORT: lang.REPORT,
  MASTER_SETTING: lang.MASTER_SETTING,
};
export default {
  ROUTER_PAGE,
  NAME_PAGE,
};
