import React from "react";

import {
  FaFaceFrown,
  FaFaceMeh,
  FaFaceSadTear,
  FaFaceSmile,
} from "react-icons/fa6";

function StatusByCode({ statusCode }) {
  const status = {
    0: <FaFaceSmile style={{ color: "green", fontSize: 20 }} />,
    1: <FaFaceMeh style={{ color: "gold", fontSize: 20 }} />,
    2: <FaFaceFrown style={{ color: "orange", fontSize: 20 }} />,
    3: <FaFaceSadTear style={{ color: "red", fontSize: 20 }} />,
  };

  if (!Object.keys(status).some((item) => item == statusCode)) {
    if (Number(statusCode) == -1) {
      return "";
    } else {
      return <FaFaceMeh style={{ color: "gray", fontSize: 20 }} />;
    }
  }
  return status[statusCode];
}

export default StatusByCode;
