import { KEY, lang } from "@src/constants/common.const";
import { collapsedSideBar } from "@src/redux/sideBar";
import { Col, Drawer, Layout, Menu, Row } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./sideBar.scss";

// icon
import { openConfirm } from "@src/redux/confirm";
import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";

import CONFIG_ROUTER, { ROUTER } from "../../routers/roles/configRoles";
import { LOCALSTORAGE_KEY } from "../../constants/common.const";
import { isArray } from "lodash";
import { RoleHeadOffice } from "../../routers/roles/roles.const";

const { Sider } = Layout;

function SideBar() {
  const { roleKey, safetyBeltDisplaySetting } = useSelector(
    (state) => state.auth
  );
  const isLoginInStore = localStorage.getItem(LOCALSTORAGE_KEY.IS_LOGIN);
  const isLoggedIn = isLoginInStore === "true";
  const listSideBar = isLoggedIn ? CONFIG_ROUTER : [];
  const { collapsed } = useSelector((state) => state.sideBar);
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, updateCurrentPage] = useState(null);
  const { isUpdated } = useSelector((state) => state.user);
  const toggleButton = () => {
    dispatch(collapsedSideBar(!collapsed));
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  // Current router
  const currentRouter = (route) => {
    if (isUpdated && route) {
      dispatch(
        openConfirm({
          open: true,
          data: { left: "OK", right: "cancel" },
          typeConfirm: "error",
          redirectUrl: route,
        })
      );
    } else {
      const routers = ROUTER;
      const routerPath = history.location.pathname;
      let keyCurrent = currentPage;
      let isUpdatedRouter = false;
      for (const [key, value] of Object.entries(routers)) {
        if (key !== keyCurrent && Array.isArray(value)) {
          let isUpdated1 = false;
          value.forEach((item) => {
            if (routerPath.includes(item)) {
              isUpdated1 = true;
            }
          });
          if (isUpdated1) {
            keyCurrent = key;
            isUpdatedRouter = true;
          }
        } else {
          if (routerPath.includes(value) && key !== keyCurrent) {
            keyCurrent = key;
            isUpdatedRouter = true;
          }
        }
      }
      if (isUpdatedRouter) {
        updateCurrentPage(keyCurrent);
      }
    }
  };

  useEffect(() => {
    currentRouter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  const renderMenuItem = (menuItem, isSub) => {
    if (!menuItem.children && menuItem.show) {
      if (!menuItem.icon && isDesktopOrLaptop && collapsed) {
        return <></>;
      }
      return !isSub ? (
        <Fragment key={menuItem.key}>
          <Menu.Item
            key={menuItem.key}
            icon={menuItem.icon}
            // className="customMenuItemMain"
            className={`${menuItem.className ? menuItem.className : ""}`}
            onClick={() => currentRouter(menuItem.path)}
          >
            {!isUpdated && menuItem.path ? (
              <Link to={menuItem.path}>
                <span>{menuItem.menuName}</span>
              </Link>
            ) : (
              <span>{menuItem.menuName}</span>
            )}
          </Menu.Item>
        </Fragment>
      ) : (
        <Menu.Item
          key={menuItem.key}
          icon={menuItem.icon}
          // className="customMenuItemMain"
          className={`${menuItem.className ? menuItem.className : ""}`}
          onClick={() => currentRouter(menuItem.path)}
        >
          {!isUpdated && menuItem.path ? (
            <Link to={menuItem.path}>
              <span>{menuItem.menuName}</span>
            </Link>
          ) : (
            <span>{menuItem.menuName}</span>
          )}
        </Menu.Item>
      );
    }
    if (menuItem.children && Array.isArray(menuItem.children)) {
      return (
        <Fragment key={menuItem.key}>
          <Menu.SubMenu
            key={menuItem.key}
            icon={menuItem.icon}
            title={menuItem.menuName}
            className={`${
              isDesktopOrLaptop
                ? `customSubMenu ${menuItem.className}`
                : `customSubMenu--mobile ${menuItem.className}`
            }`}
          >
            {menuItem.children.map((item) => renderMenuItem(item, true))}
          </Menu.SubMenu>
        </Fragment>
      );
    }
  };

  // filter
  const filterItem = (arr) =>
    arr.filter((i) => {
      if (!isArray(i.roleHides)) return true;
      if (
        i.key === "SAFETY_BELT_LIST" &&
        roleKey === RoleHeadOffice.ADMIN_HEADQUATER &&
        safetyBeltDisplaySetting != "1"
      ) {
        return false;
      }
      return Array.isArray(i.roleHides) && !i.roleHides.includes(roleKey);
    });
  const filterRoute = (routes) => {
    const finalRoutes = filterItem(routes).map((item) => {
      if (item.children) {
        item.children = filterItem(item.children);
      }
      return item;
    });
    return finalRoutes;
  };
  const renderMenu = useMemo(
    () =>
      filterRoute(listSideBar).map((item) => {
        if (isLoggedIn) return renderMenuItem(item, false);
      }),
    [isLoggedIn, isDesktopOrLaptop, collapsed, safetyBeltDisplaySetting]
  );

  const styleSider = {
    overflow: "auto",
    height: "calc(100vh - 150px)",
    position: "fixed",
    zIndex: 0,
    left: 0,
    paddingTop: 5,
    background: "transparent",
    // borderRight: "1px solid rgba(5, 5, 5, 0.06)",
    borderRadius: 0,
  };

  const gapFlexStatus = collapsed ? 4 : 6;

  return isDesktopOrLaptop ? (
    <Sider
      theme="dark"
      collapsible
      collapsed={collapsed}
      width={220}
      onCollapse={toggleButton}
      collapsedWidth={60}
      style={styleSider}
    >
      {/* <div className="logo">
        {collapsed ? (
          <></>
        ) : (
          <div className="systemName">"{lang.SYSTEM_NAME}"</div>
        )}
      </div> */}

      <Menu
        mode="vertical"
        theme="dark"
        selectedKeys={[currentPage]}
        style={{
          width: "100%",
          // marginTop: collapsed ? "10px" : "20px",
          overflowX: "hidden",
        }}
      >
        {renderMenu}
      </Menu>
    </Sider>
  ) : (
    <>
      <Drawer
        placement="left"
        className="custom-drawer"
        onClose={toggleButton}
        closable={false}
        open={collapsed}
        key={"left"}
      >
        <div className="logo">
          <Row className="rowNotMargin" align="middle" justify="center">
            <Col span={20} className="text-center">
              <h1 className="systemName">{lang.SYSTEM_NAME}</h1>
            </Col>
            <Col span={4}>
              <button className="hamburger opened" onClick={toggleButton}>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </Col>
          </Row>
        </div>

        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={[currentPage]}
          style={{ width: "100%", marginTop: collapsed ? "10px" : "20px" }}
          onClick={() => toggleButton()}
        >
          {renderMenu}
        </Menu>
      </Drawer>
    </>
  );
}

export default SideBar;
