import Api from "@src/api";
export const GatewayApi = {
  getListgateway(params) {
    return Api().get("/api/Gateway", { params });
  },
  getDetailGateway(SerialNumber,type) {
    return Api().get(`/api/Gateway/${type}/${SerialNumber}`);
  },
  deleteGateway(id,type) {
    return Api().delete(`/api/Gateway/${type}/${id}`);
  },
  // 2: Create/Update Devide
  save(formData, id) {
    return id
      ? Api().put(`/api/Gateway`, formData, {})
      : Api().post("/api/Gateway", formData, {});
  },
  importGateway(OfficeId, formData) {
    return Api().post(`/api/Gateway/Import/${OfficeId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  downloadListGateway(params = {}) {
    return Api().get(`/api/Gateway/Download`, {
      responseType: "blob",
      params,
    });
  },
  listGatewayRegistration(params) {
    return Api().get("/api/Gateway/GatewayRegistration", {
      params,
    });
  },
  gatewayRegistration(idheatWatcher, listgatewayId) {
    return Api().post(
      `/api/Gateway/GatewayRegistration?HeatIndexWatcherId=${idheatWatcher}&GatewayIDs=${listgatewayId}`,
      null,
      {}
    );
  },
  getListgatewayType(params) {
    return Api().get("/api/Kbn/GatewayType", { params });
  },
  setGatewayToMap(formData) {
    return Api().put("/api/Gateway/SetGatewayToMap", formData, {});
  },
};
