/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Modal, Radio, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import BreakLine from "../../component/Desktop/breakLine";
import GroupItem from "../../component/Desktop/groupItem";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput";
import TitlePage from "../../component/Desktop/titlePage";

import {
  COMMON_CONSTANT,
  KEY,
  STATUS_INSTALL_GATEWAY,
  STYLE,
  lang,
} from "../../constants/common.const";

import {
  getQuerySearch,
  renderOption,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";

import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";

import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";

import { GatewayApi } from "../../api/backend/gateway";
import { ApiOffice } from "../../api/backend/headerQuaters";
import useWidthInput from "../../hooks/useWidthInput";
import useAuth from "../../hooks/useAuth";

function CreateRequest() {
  let { id } = useParams();
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [modeEdit, setModeEdit] = useState(true);
  const initLoad = useRef(true);
  const objectSearch = getQuerySearch();
  const { officeId, isSiggleOffice, officeName } = useAuth();

  const [txtBasename, settxtBasename] = useState(
    new classDdl(isSiggleOffice ? officeId : objectSearch.OfficeId || "")
  );
  const [txtGatewayID, settxtGatewayID] = useState(new classText());
  const [ddlLocationInformationName, settxtLocationInformationName] = useState(
    new classText()
  );
  const [rdInstallationStatus, setRdInstallStatus] = useState(new classDdl());
  const [rdLocationInfomation, setRdLocationInfomation] = useState(
    new classText()
  );
  const [ddlGatewayType, setDdlGatewayType] = useState(new classDdl(null));
  const widthInput = useWidthInput();

  // Focus
  let txtBasenameEl = useRef(null);
  let txtGatewayIDEl = useRef(null);
  const rdLocationInfomationEl = useRef(null);
  let txtLocationInformationNameEl = useRef(null);
  let ddlGatewayTypeEl = useRef(null);
  let ddlInstallationStatusEL = useRef(null);
  const type = getQuerySearch().type;
  // init data
  const getIniData = async () => {
    if (id) {
      setShowLoading(true);
    }
    try {
      const [detailData, listOffice, resgatewayType] = await Promise.all([
        id ? GatewayApi.getDetailGateway(id, type) : null,
        !isSiggleOffice ? ApiOffice.searchOffice({}) : null,
        GatewayApi.getListgatewayType(),
      ]);
      // edit
      if (id) {
        if (!isSiggleOffice) {
          settxtBasename({
            ...txtBasename,
            options: listOffice.data.data.map((i) => ({
              key: i.officeId,
              value: i.officeName,
            })),
            value: detailData.data.officeId,
          });
        }

        setRdLocationInfomation({
          ...rdLocationInfomation,
          value: detailData.data.status,
        });
        setRdInstallStatus({
          ...rdInstallationStatus,
          value: detailData.data.installationStatus,
        });
        settxtGatewayID({
          ...txtGatewayID,
          value: detailData.data.serialNumber,
        });
        setDdlGatewayType({
          ...ddlGatewayType,
          value: detailData.data.gatewayType,
          options: resgatewayType.data.data,
        });

        settxtLocationInformationName({
          ...ddlLocationInformationName,
          value: detailData.data.locationName,
        });
      } else {
        setDdlGatewayType({
          ...ddlGatewayType,
          options: resgatewayType.data.data,
        });
        if (!isSiggleOffice) {
          settxtBasename({
            ...txtBasename,
            options: listOffice.data.data.map((i) => ({
              key: i.officeId,
              value: i.officeName,
            })),
          });
        }
        setRdLocationInfomation({
          ...rdLocationInfomation,
          value: "1",
        });
        setRdInstallStatus({
          ...rdInstallationStatus,
          value: "1",
        });
      }
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
      initLoad.current = false;
    }
  };

  useEffect(() => {
    getIniData();
  }, []);

  // Validate
  const validateData = () => {
    let flagError = false;

    setRdInstallStatus({ ...rdInstallationStatus, error: false });
    settxtGatewayID({ ...txtGatewayID, error: false });
    setDdlGatewayType({ ...ddlGatewayType, error: false });
    settxtBasename({ ...txtBasename, error: false });

    if (!rdInstallationStatus.value) {
      flagError = true;
      setRdInstallStatus({ ...rdInstallationStatus, error: true });
      ddlInstallationStatusEL.current.focus();
    }

    if (!txtGatewayID.value) {
      flagError = true;
      settxtGatewayID({ ...txtGatewayID, error: true });
      txtGatewayIDEl.current.focus();
    }

    if (!ddlGatewayType.value) {
      flagError = true;
      setDdlGatewayType({ ...ddlGatewayType, error: true });
      ddlGatewayTypeEl.current.focus();
    }

    if (!txtBasename.value) {
      flagError = true;
      settxtBasename({ ...txtBasename, error: true });
      txtBasenameEl.current.focus();
    }

    return !flagError;
  };

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    backToSearch();
  }
  const postData = async () => {
    try {
      setShowLoading(true);
      const dataPost = {
        officeId: txtBasename.value,
        serialNumber: txtGatewayID.value,
        gatewayType: ddlGatewayType.value,
        installationStatus: rdInstallationStatus.value,
        status: rdLocationInfomation.value,
        locationName: ddlLocationInformationName.value,
        keepMapInfo: true,
      };

      await GatewayApi.save(dataPost, id);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
      history.goBack();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  // Save Data
  const onSave = () => {
    // Validate Data
    if (!validateData()) {
      return;
    }
    // Call Api
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: id ? COMMON_CONSTANT.ECM_C009 : COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };
  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.PARENT_CHILD_DEVICE_SETTING} />

      {/* Group 1 */}
      <GroupItem>
        <BreakLine />
        <RowInput box name={lang.BASE_NAME} required={modeEdit}>
          {isSiggleOffice ? (
            <Input value={officeName} disabled className={widthInput} />
          ) : (
            <Select
              showSearch
              optionFilterProp="children"
              disabled={id}
              value={txtBasename.options.length ? txtBasename.value : ""}
              ref={txtBasenameEl}
              className={
                txtBasename.error
                  ? `${STYLE.BORDER_RED} ${widthInput}`
                  : `${widthInput}`
              }
              onChange={(v) =>
                settxtBasename({
                  ...txtBasename,
                  value: v,
                })
              }
            >
              {renderOption(txtBasename.options, false)}
            </Select>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.PARENT_CHILD_DEVICE_TYPE} required={modeEdit}>
          {modeEdit ? (
            <>
              <Select
                showSearch
                optionFilterProp="children"
                disabled={id}
                ref={ddlGatewayTypeEl}
                value={ddlGatewayType.value}
                className={
                  ddlGatewayType.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                onChange={(value, option) => {
                  setDdlGatewayType({
                    ...ddlGatewayType,
                    value,
                  });
                }}
              >
                {renderOption(ddlGatewayType.options)}
              </Select>
            </>
          ) : (
            <>
              <div className="white-space"> {ddlGatewayType.label}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={"親機/子機ID"} required={modeEdit}>
          {modeEdit ? (
            <>
              <Input
                disabled={id}
                value={txtGatewayID.value}
                ref={txtGatewayIDEl}
                className={
                  txtGatewayID.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={20}
                onChange={(e) =>
                  settxtGatewayID({
                    ...txtGatewayID,
                    value: e.target.value,
                  })
                }
              ></Input>
            </>
          ) : (
            <></>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.INSTALLATION_STATUS} required={modeEdit}>
          <Radio.Group
            name="rdInstallationStatus"
            required
            value={rdInstallationStatus.value}
            className={
              rdInstallationStatus.error
                ? `${STYLE.BORDER_RED} w400px`
                : "w400px"
            }
            onChange={(e) =>
              setRdInstallStatus({
                ...rdInstallationStatus,
                value: e.target.value,
              })
            }
          >
            <Radio value={STATUS_INSTALL_GATEWAY.ALREADY_INSTALLED}>
              {lang.ALREADY_INSTALL}
            </Radio>
            <Radio value={STATUS_INSTALL_GATEWAY.NOT_INSTALLED}>
              {lang.NOT_INSTALL}
            </Radio>
          </Radio.Group>
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.LOCATION_INFORMATION}>
          <Radio.Group
            name="rdLocationInfomation"
            value={rdLocationInfomation.value}
            ref={rdLocationInfomationEl}
            className={
              rdLocationInfomation.error
                ? `${STYLE.BORDER_RED} w400px`
                : "w400px"
            }
            onChange={(e) =>
              setRdLocationInfomation({
                ...rdLocationInfomation,
                value: e.target.value,
              })
            }
          >
            <Radio value={"1"}>{"あり"}</Radio>
            <Radio value={"0"}>{lang.NOT_HAVE}</Radio>
          </Radio.Group>
        </RowInput>
        <BreakLine />
        <RowInput required={false} box name={lang.LOCATION_INFORMATION_NAME}>
          <Input
            value={ddlLocationInformationName.value}
            ref={txtLocationInformationNameEl}
            className={
              ddlLocationInformationName.error
                ? `${STYLE.BORDER_RED} ${widthInput}`
                : `${widthInput}`
            }
            maxLength={256}
            onChange={(e) =>
              settxtLocationInformationName({
                ...ddlLocationInformationName,
                value: e.target.value,
              })
            }
          ></Input>
        </RowInput>

        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          <Button
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              onSave();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={cancelSaveData}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CreateRequest;
