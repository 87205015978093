import React from "react";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../../constants/common.const";

function BreakLine({ ...other }) {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  return <div className={isPC ? "break-line" : ""} {...other}></div>;
}

export default BreakLine;
