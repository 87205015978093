/* eslint-disable react-hooks/exhaustive-deps */
import { DownloadOutlined, HeartFilled } from "@ant-design/icons";
import { Button, Checkbox, Col, Flex, Form, Row, Space, Table } from "antd";
import dayjs from "dayjs";

import React, { useEffect, useState } from "react";
import { GoAlertFill } from "react-icons/go";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";

import {
  MSG_CATCH,
  getQuerySearch,
  pushObject2Query,
  saveFile,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";

import API from "@src/api/backend/alert";
import { Sun1 } from "iconsax-react";
import BatteryIcon from "../../components/common/custom-icon/batteryIcon";
import WifiIcon from "../../components/common/custom-icon/wifiIcon";
import { CONFIG, KEY, PAGE_SIZE, lang } from "../../constants/common.const";

import { useQuery } from "react-query";
import { settingApi } from "../../api/backend/setting";

import AlertInfomation from "../../component/Desktop/alertInfomation";
import ContentTitle from "../../component/Desktop/contentTitle";
import StatusByCode from "../../component/Icon/alertIcon";
import SelectCompany from "../../components/common/input/selectCompany";
import classDdl from "../../models/control/dropdownlist";
import { onChangeTable } from "../../utils/helpers";

let objSearchOrigin = {
  title: null,
  category: null,
  startDate: null,
  endDate: null,
  status: null,
  keyWord: null,
  isProcessing: "true",
};

let objSearch = { ...objSearchOrigin };
const ACTION_KEY = {
  SEARCH: "SEARCH",
  REFRESH: "REFRESH",
};

let columnAlert = [
  {
    key: "DATETIME_OF_OCCURRENCE",
    title: "発生日時",
    dataIndex: "alertTime",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    align: "start",
    render: (value) => {
      return dayjs.utc(value).local().format("YYYY/MM/DD HH:mm");
    },
  },
  {
    key: "EMERGENCY",
    title: "緊急",
    dataIndex: "emergency",
    align: "center",
    render: (_, row) => {
      return row.emergency == 1 ? (
        <div className="row-data-icon">
          <GoAlertFill style={{ color: "red", fontSize: 20 }} />
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "COMPREHENSIVE_ALERT",
    title: "総合アラート",
    dataIndex: "alertLevel",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    align: "center",
    render: (_, row) => {
      return (
        <div className="row-data-icon">
          <StatusByCode statusCode={row.alertLevel} />
        </div>
      );
    },
  },
  {
    key: "NAME",
    title: "氏名",
    dataIndex: "fullName",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    align: "start",
    render: (value, row) => {
      const hours = dayjs.utc(row.alertTime).local().hour();
      const day = dayjs.utc(row.alertTime).local().format('YYYY-MM-DD');
      return (
        <Row
          justify="space-between"
          align="middle"
          style={{ maxWidth: "500px" }}
        >
          <Col span={24}>
            <Link
              to={`/physical-condition/detail?UserId=${
                row.userId || ""
              }&OfficeName=${row.baseName || ""}&GroupName=${
                row.groupName || ""
              }&timeAlert=${hours}&dayAlert=${day}`}
              className="white-space"
            >
              {value}
            </Link>
          </Col>
        </Row>
      );
    },
  },

  {
    key: "AGE",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    title: "年齢",
    dataIndex: "age",
    align: "start",
  },
  {
    key: "LOCATION/BASE_NAME",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    title: "拠点名",
    dataIndex: "baseName",
    align: "start",
  },
  {
    key: "GROUP",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    title: "グループ",
    dataIndex: "groupName",
    align: "start",
  },
  {
    key: "HEART_RATE",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    title: "心拍数",
    align: "start",
    dataIndex: "heartRate",
    render: (value, row) => {
      return row.alertLevel != null ? (
        <div className="row-data-icon-text">
          {/* <FaFaceSadTear style={{ color: "red", fontSize: 20 }} /> */}
          <HeartFilled style={{ color: "red" }} />
          <div className="ml4"> {value}</div>
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "WBGT_EXCESSIVE",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    title: "WBGT超過度",
    dataIndex: "excess",
    align: "start",
    render: (value) => {
      return value ? (
        <div className="row-data-icon-text">
          {/* <FaFaceSadTear style={{ color: "red", fontSize: 20 }} /> */}
          <Sun1 size="20" color="#FF8A65" variant="Bold" />
          <div className="ml4"> {value}</div>
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "WBGT_VALUE",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    title: "WBGT値",
    dataIndex: "wbgtValue",
    align: "start",
    render: (value) => {
      return value ? (
        <div className="row-data-icon-text">
          {/* <FaFaceFrown style={{ color: "red", fontSize: 20 }} /> */}
          <Sun1 size="20" color="#FF8A65" variant="Bold" />
          <div className="ml10"> {value}</div>
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "WBGT_MEASUREMENT_POINT",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    title: "WBGT測定地点",
    dataIndex: "wbgtMeasurementPoint",
    align: "start",
    render: (value) => {
      return value ? (
        <div className="row-data-icon-text">
          {/* <FaFaceFrown style={{ color: "red", fontSize: 20 }} /> */}
          <Sun1 size="20" color="#FF8A65" variant="Bold" />
          <div className="ml10"> {value}</div>
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "BATTERY",
    title: "バッテリ",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    align: "center",
    dataIndex: "bateryLevel",
    render: (_, row) => {
      return row.alertLevel != null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BatteryIcon level={row.bateryLevel} />
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "RADIO_FIELD_STRENGTH",
    title: "電波強度",
    align: "center",
    dataIndex: "waveLevel",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],

    render: (_, row) => {
      return row.alertLevel != null ? (
        <div>
          <WifiIcon level={row.waveLevel} />
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "LOCATION_INFORMATION",
    title: "位置情報",
    align: "start",
    dataIndex: "locationName",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
];

function AlertList() {
  const history = useHistory();
  objSearch = getQuerySearch();

  const [triggleSearch, setTriggleSearch] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const PAGE_LIMT = 15;
  // Loading

  const objectSearch = getQuerySearch();
  const [formSearch] = Form.useForm();
  const [initLoad, setInitLoad] = useState(true);

  const [ddlCompany, setDdlCompany] = useState({
    ...new classDdl(objSearch.companyId || null),
  });

  const [autoRefresh, setAutoRefresh] = useState(
    objectSearch.autoUpdate === "true" ? true : false
  );
  const objPushQuery = {
    companyId: ddlCompany.value,
    autoUpdate: autoRefresh,
  };
  const [columns, setColumns] = useState([]);
  // Alert List Query
  const queryKey = [
    "AlertList",
    {
      page,
      sortKey,
      sortOrder,
      companyId: ddlCompany.value,
    },
  ];
  // re-call api
  const reCallApi = (currentPage, orderName, orderBy) => {
    setPage(currentPage);
    if (orderName) {
      setSortKey(orderName);
    }
    if (orderBy) {
      setSortOrder(orderBy);
    }
  };
  const alertListQuery = useQuery(
    queryKey,
    (variable) => {
      const listParams = variable.queryKey[1];
      return API.getListAlert({
        CompanyId: ddlCompany.value,
        sortKey,
        sortOrder,
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
        allTime: 1,
      });
    },

    {
      keepPreviousData: true,
      retry: 1,
      onSettled: () => {
        setTriggleSearch(false);
        setLoadingSearch(false);
      },
      onError: () => {
        showMessage(KEY.ERROR, MSG_CATCH());
      },
    }
  );

  // Downloaf Alert
  const handleDownloadAlert = async () => {
    setLoadingSearch(true);
    try {
      const res = await API.downloadAlert(ddlCompany.value || "");
      saveFile(res);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    onSearch(true);
  }, [ddlCompany.value]);

  // set Column Display
  const getColumnDisplay = async () => {
    if (!ddlCompany.value) {
      setColumns(columnAlert);
      return;
    }

    try {
      const res = await settingApi.getmasterSetting(ddlCompany.value, {
        type: "ALERT_LIST_DISPLAY_ITEM_SETTING",
      });
      const currentColumn = columnAlert.filter((item) => {
        const key = item.key;
        if (!key) {
          return true;
        }
        const currItem = res.data.find((i) => i.key === key);
        if (!currItem) {
          return false;
        }
        return currItem.value === "1";
      });
      setColumns(currentColumn);
    } catch (error) {
      setColumns(columnAlert);
    }
  };
  useEffect(() => {
    getColumnDisplay();
  }, [ddlCompany.value]);
  // Seacrch
  const onSearch = (resetPage = null) => {
    if (resetPage && page != 1) {
      setPage(1);
    } else alertListQuery.refetch();
  };

  // Auto refresh
  useEffect(() => {
    let refreshInterval;
    if (autoRefresh) {
      refreshInterval = setInterval(() => {
        onSearch(ACTION_KEY.REFRESH);
      }, 60 * 1000);
    }
    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, [autoRefresh]);

  useEffect(() => {
    pushObject2Query(objPushQuery);
    if (initLoad) {
      setInitLoad(false);
    }
  }, [ddlCompany.value, autoRefresh]);
  // Render
  return (
    <div>
      <ContentTitle>
        <TitlePage name={lang.ALERT_LIST} />
        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          className="formStyle"
          onFinish={() => onSearch(ACTION_KEY.SEARCH)}
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} md={24} sm={24} xs={24}>
              <div className="ant-form-item-label">{lang.COMPANY} </div>
              <SelectCompany
                className="w100"
                acceptSetNull={!initLoad}
                state={ddlCompany}
                setState={setDdlCompany}
              />
            </Col>

            <Col lg={8} md={24} sm={24} xs={24}></Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Checkbox
                    style={{
                      marginTop: 7,
                    }}
                    checked={autoRefresh}
                    onChange={(e) => setAutoRefresh(e.target.checked)}
                    className="mb5"
                  >
                    {lang.AUTOMATIC_UPDATE}
                  </Checkbox>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      onSearch(ACTION_KEY.REFRESH);
                    }}
                    className="buttonPC button--info wAuto"
                  >
                    {lang.UPDATE_DISPLAY}
                  </Button>
                </Col>
                <Col>
                  <Button
                    disabled={!ddlCompany.value}
                    onClick={() => handleDownloadAlert()}
                    className="buttonPC button--info --todo-- wAuto "
                  >
                    <DownloadOutlined />
                    {lang.DOWNLOAD}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <AlertInfomation />
            </Col>
          </Row>
        </Form>
      </ContentTitle>

      <div
        className="site-layout-background"
        style={{
          marginBottom: 24,
        }}
      >
        {/* Count */}
        <Flex justify="space-between">
          {/* Count */}
          <Col className="ml8"></Col>
          <Space size={10} align="center " style={{ paddingRight: "20px" }}>
            {lang.ACCESS_NUMBERS}
            <span>
              {alertListQuery.data && alertListQuery.data.data.count}
              {lang.CASE}
            </span>
          </Space>
          {/* </Col> */}
        </Flex>
        {/* Grid  */}
        <Table
          onChange={onChangeTable(reCallApi)}
          rowKey={(record) => record.id}
          className="gridSearch "
          columns={columns}
          dataSource={alertListQuery.data && alertListQuery.data.data.data}
          pagination={{
            ...CONFIG.paging,
            total: alertListQuery.data && alertListQuery.data.data.count,
            showSizeChanger: false,
          }}
         scroll={{ x: 'max-content' }}
        />
      </div>
      <Loading show={alertListQuery.isLoading || loadingSearch} />
    </div>
  );
}
export default AlertList;

export const initColumns = [
  {
    title: "発生日時",
    dataIndex: "alertTime",
    align: "start",
    render: (value) => {
      return dayjs.utc(value).local().format("YYYY/MM/DD HH:mm");
    },
  },
  {
    title: "緊急",
    dataIndex: "emergency",
    align: "center",
    render: (_, row) => {
      return row.emergency == 1 ? (
        <div className="row-data-icon">
          <GoAlertFill style={{ color: "red", fontSize: 20 }} />
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    title: "総合アラート",
    dataIndex: "generalAlert",
    sorter: true,
    align: "center",
    render: (_, row) => {
      return (
        <div className="row-data-icon">
          <StatusByCode statusCode={row.alertLevel} />
        </div>
      );
    },
  },
  {
    title: "氏名",
    dataIndex: "fullName",
    sorter: true,
    align: "start",
    render: (value, row) => {
      const link = `/physical-condition/detail?UserId=${
        row.userId || ""
      }&OfficeName=${row.baseName || ""}&GroupName=${row.groupName || ""}`;
      return (
        <Col>
          <Link to={link} className="white-space">
            {value}
          </Link>
        </Col>
      );
    },
  },

  {
    title: "年齢",
    dataIndex: "age",
    sorter: true,
    align: "start",
  },
  {
    title: "拠点名",
    dataIndex: "baseName",
    sorter: true,
    align: "start",
  },
  {
    title: "グループ",
    dataIndex: "groupName",
    sorter: true,
    align: "start",
  },
  {
    title: "心拍数",
    sorter: true,
    align: "start",
    dataIndex: "heartRate",
    render: (value, row) => {
      return row.alertLevel != null ? (
        <div className="row-data-icon-text">
          {/* <FaFaceSadTear style={{ color: "red", fontSize: 20 }} /> */}
          <HeartFilled style={{ color: "red" }} />
          <div className="ml4"> {value}</div>
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    title: "WBGT超過度",
    sorter: true,
    dataIndex: "excess",
    align: "start",
    render: (value) => {
      return value ? (
        <div className="row-data-icon-text">
          {/* <FaFaceSadTear style={{ color: "red", fontSize: 20 }} /> */}
          <Sun1 size="20" color="#FF8A65" variant="Bold" />
          <div className="ml4"> {value}</div>
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    title: "バッテリ",
    sorter: true,
    align: "center",
    dataIndex: "bateryLevel",
    render: (_, row) => {
      return row.alertLevel != null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BatteryIcon level={row.bateryLevel} />
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    title: "電波強度",
    sorter: true,
    align: "center",
    dataIndex: "waveLevel",

    render: (_, row) => {
      return row.alertLevel != null ? (
        <div>
          <WifiIcon level={row.waveLevel} />
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    title: "位置情報",
    sorter: true,
    align: "start",
    dataIndex: "locationName",
  },
];
