/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Collapse,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";

import classGrid from "../../models/control/grid";
import UserApi from "././../../api/backend/user";

import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FormOutlined,
  PlusOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import {
  confirmApiModal,
  MSG_CATCH,
  confirmApi,
  formatListOptionOffice,
  getQuerySearch,
  pushObject2Query,
  renderOption,
  saveFile,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";

import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  PAGE_SIZE,
  lang,
} from "../../constants/common.const";

import { ApiOffice } from "../../api/backend/headerQuaters";
import ContentTitle from "../../component/Desktop/contentTitle";
import SelectOffice from "../../components/common/input/selectOffice";
import classDdl from "../../models/control/dropdownlist";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import { onChangeTable } from "../../utils/helpers";

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;
function PhysicalCondition_list() {
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const history = useHistory();
  const objSearch = getQuerySearch();
  const [initLoad, setInitLoad] = useState(true);

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  const [currenListUser, setCurrentListUser] = useState([]);
  //  Grid
  const [grid, setGrid] = useState({
    ...new classGrid(),
  });

  // Input
  let [ddlBaseName, setDdlBaseName] = useState({
    ...new classDdl(objSearch.officeId),
  });

  // Input
  let [ddlRole, setDdlRole] = useState([
    {
      value: RoleHeadOffice.USER_JP,
      key: RoleHeadOffice.USER,
    },
    {
      value: RoleHeadOffice.GROUP_LEADER_JP,
      key: RoleHeadOffice.GROUP_LEADER,
    },
    {
      value: RoleHeadOffice.GROUP_SUB_LEADER_JP,
      key: RoleHeadOffice.GROUP_SUB_LEADER,
    },
  ]);
  const [selectedUser, selectedRole] = useState(objSearch.role || "");

  const [userName, setUserName] = useState({
    ...new classDdl(objSearch.userName || ""),
  });

  // Search
  const search_onClick = async (
    currentPage = 1,
    sortKey = "",
    sortOrder = "desc"
  ) => {
    setPage(currentPage);
    setSortKey(sortKey);
    setSortOrder(sortOrder);
    pushObject2Query({
      officeId: ddlBaseName.value,
      role: selectedUser,
      userName: userName.value,
    });
    setShowLoading(true);
    try {
      const listWorker = await UserApi.getByUser({
        officeId: ddlBaseName.value,
        role: selectedUser,
        offset: (currentPage - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        sortKey,
        sortOrder,
        exactFullName: userName.value,
      });
      setGrid({
        ...grid,
        data: listWorker.data.data,
        count: listWorker.data.count,
      });
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };

  // get listOption
  const getListOptionFullName = async () => {
    const listWorker = await UserApi.getByUser({
      officeId: ddlBaseName.value,
      role: selectedUser,
    });
    const listFullName = listWorker.data.data.map((i) => i.fullName);
    const listOption = [...new Set(listFullName)];

    setUserName({
      ...userName,
      value: !initLoad ? "" : userName.value,
      options: listOption.map((i) => ({
        key: i,
        value: i,
      })),
    });
  };

  useEffect(() => {
    getListOptionFullName();
  }, [ddlBaseName.value, selectedUser]);
  // Delete
  const handleDeleteWorker = (id) => {
    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.ECM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: async () => {
        try {
          setShowLoading(true);
          await UserApi.deleteUSer(id);
          showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C004);
          search_onClick();
        } catch (error) {
          showErrorMessage(error);
        } finally {
          setShowLoading(false);
        }
      },
    });
  };
  let initColumns = [
    {
      title: "ログインID",
      dataIndex: "userName",
      sorter: true,
      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ maxWidth: "500px" }}
          >
            <Col span={24}>{text}</Col>
          </Row>
        );
      },
    },

    {
      title: "氏名",
      dataIndex: "fullName",
      sorter: true,
    },

    {
      title: "拠点名",
      dataIndex: "officeName",
      sorter: true,
    },
    {
      title: "グループ名",
      dataIndex: "groupName",
      sorter: true,
    },

    {
      title: "デバイス種別①",
      dataIndex: "meditagType",
      sorter: true,
    },
    {
      title: "デバイスID①",
      dataIndex: "meditagId",
      sorter: true,
    },
    {
      title: "デバイス種別②",
      dataIndex: "safetyBeltType",
      sorter: true,
    },
    {
      title: "デバイスID②",
      dataIndex: "safetyBeltId",
      sorter: true,
    },
    {
      title: "役割",
      dataIndex: "roleJp",
    },
    {
      title: "ロック解除",
      dataIndex: "unlock",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {row.isLockedOut && (
              <Button
                className="mr5 ml10"
                style={{ backgroundColor: "green", color: "#fff" }}
                onClick={() => {
                  confirmApiModal(
                    UserApi.unLockUser(row.userId),
                    search_onClick,
                    lang.CONFIRM,
                    lang.CONFIRM_UNLOCK,
                    lang.UNLOCK_SUCCESS
                  );
                }}
              >
                <UnlockOutlined />
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: "設定変更",
      dataIndex: "settingChange",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            <Tooltip title={"設定変更"}>
              <Button
                className="mr5 ml10"
                onClick={() => {
                  history.push("/worker/edit/" + row.userId);
                }}
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "削除",
      dataIndex: "delete",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                onClick={() => {
                  handleDeleteWorker(row.userId);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // Get list Office
  const getListOffice = async () => {
    try {
      setShowLoading(true);
      const res = await ApiOffice.searchOffice({});
      const listOfficeOption = formatListOptionOffice(res.data.data);
      setDdlBaseName({ ...ddlBaseName, options: listOfficeOption });
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  // DOMNLOAD
  const handleDownload = async () => {
    try {
      setShowLoading(true);
      const res = await UserApi.downloadListUSer({
        OfficeId: ddlBaseName.value,
        Role: RoleHeadOffice.USER,
      });
      saveFile(res);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    getListOffice();
  }, []);

  const handleChangeRole = (role) => {
    selectedRole(role);
  };

  useEffect(() => {
    if (initLoad) {
      setInitLoad(false);
    }
    search_onClick(1, sortKey, sortOrder);
  }, [ddlBaseName.value, selectedUser, userName.value]);
  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={lang.WORKER_LIST} />

        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} md={24} sm={24} xs={24}>
              <div className="ant-form-item-label">役割</div>
              <Select
                showSearch
                optionFilterProp="children"
                value={selectedUser}
                onChange={handleChangeRole}
                className="w100"
              >
                <Option value=""></Option>
                {ddlRole.map((role) => (
                  <Option value={role.key} key={role.key}>
                    {role.value}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col lg={8} md={24} sm={24} xs={24}>
              <div className="ant-form-item-label">拠点名</div>

              <SelectOffice
                className={"w100"}
                acceptSetNull={!initLoad}
                state={ddlBaseName}
                setState={setDdlBaseName}
              />
            </Col>
            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col></Col>
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push(
                        "/worker/create" +
                          "?OfficeId=" +
                          (ddlBaseName.value ? ddlBaseName.value : "")
                      );
                    }}
                  >
                    <PlusOutlined className="" />
                    {COMMON_CONSTANT.ADD}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push(
                        `/worker/inputfile?OfficeId=${ddlBaseName.value || ""}`
                      );
                    }}
                  >
                    <FileAddOutlined className="" />
                    {lang.FILE}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => handleDownload()}
                    className="buttonPC button--info --todo-- wAuto "
                  >
                    <DownloadOutlined />
                    {lang.DOWNLOAD}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} md={24} sm={24} xs={24}>
              <div className="ant-form-item-label mt10">{lang.FULL_NAME}</div>
              <Select
                className="w100"
                showSearch
                value={userName.value}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
                onChange={(e) => setUserName({ ...userName, value: e })}
              >
                {renderOption(userName.options)}
              </Select>
            </Col>
          </Row>
        </Form>
      </ContentTitle>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}>
            {/* <Button type="link" className="btnAddRecord mb15">
              <AddIcon />
              {lang.REQUEST_CREATE}
            </Button> */}
          </Col>
          <Col span={12} className="right" style={{ paddingRight: "20px" }}>
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.id}
          className="gridSearch "
          columns={initColumns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,
            total: grid.count,
            current: page || 1,
            showSizeChanger: false,
          }}
          scroll={{ x: "max-content" }}
          onChange={onChangeTable(search_onClick)}
        />
      </Content>
    </div>
  );
}
export default PhysicalCondition_list;
