import { Input, Select } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import groupApi from "../../../api/backend/group";
import { STYLE } from "../../../constants/common.const";
import { renderOption } from "../../../constants/utils";
import { RoleHeadOffice } from "../../../routers/roles/roles.const";
import { widthInput } from "../../../utils/helpers";

const SelectGroup = ({
  state,
  setState,
  officeId,
  acceptSetNull = true,
  className = "",
}) => {
  const { roleKey, groupId, groupName } = useSelector((state) => state.auth);
  const isSiggleGroup = [
    RoleHeadOffice.USER,
    RoleHeadOffice.GROUP_LEADER,
    RoleHeadOffice.GROUP_SUB_LEADER,
  ].includes(roleKey);
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await groupApi.searchGroup({
          OfficeId: officeId,
        });
        const data = response.data.data.map((item) => {
          return {
            value: item.groupName,
            key: item.id,
          };
        });
        setState({
          ...state,
          options: data,
          value: acceptSetNull ? null : state.value,
        });
      } catch (e) {
        console.log(e);
      }
    };

    if (!isSiggleGroup) {
      fetch();
    }
  }, [isSiggleGroup, officeId]);

  useEffect(() => {
    if (isSiggleGroup) {
      setState({ ...state, value: groupId });
    }
  }, [isSiggleGroup]);

  return isSiggleGroup ? (
    <Input className={`w100 ${widthInput}`} value={groupName} disabled />
  ) : (
    <Select
      showSearch
      optionFilterProp="children"
      disabled={!officeId}
      value={state.options.length ? state.value : ""}
      className={
        state.error
          ? `${STYLE.BORDER_RED} ${widthInput} ${className}`
          : `${widthInput} ${className}`
      }
      onChange={(value) => {
        setState({
          ...state,
          value,
        });
      }}
    >
      {renderOption(state.options)}
    </Select>
  );
};
export default SelectGroup;
