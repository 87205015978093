import React from "react";
import "./customIcon.scss";

import radiowave00 from "@src/styles/image/radiowave/radiowave00.png";
import radiowave01 from "@src/styles/image/radiowave/radiowave01.png";
import radiowave02 from "@src/styles/image/radiowave/radiowave02.png";
import radiowave03 from "@src/styles/image/radiowave/radiowave03.png";
import radiowave04 from "@src/styles/image/radiowave/radiowave04.png";

function WifiIcon({ level, width = 24, wrapStyle = {}, style = {}, wrapClassName, classNameImg }) {
  const IMG_BY_LEVEL = {
    0: radiowave00,
    1: radiowave01,
    2: radiowave02,
    3: radiowave03,
    4: radiowave04,
  };
  return (
    <div style={{ paddingTop: 5, ...wrapStyle }} className={'wifi-wrapper-default ' + wrapClassName}>
      <img width={width} style={{ ...style }} className={classNameImg} src={IMG_BY_LEVEL[level]} alt="" />
    </div>
  );
}

export default WifiIcon;
