import React from "react";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  currentRouter: "/",
  isSearch: false,
  isUpdated: false,
  isUpdatedTime: false,
};
const userRouterSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    updateCurrentRouter: (state, { payload }) => {
      state.currentRouter = payload;
    },
    updateCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
  },
});
// the action in redux ~ the commit in vuex
export const { updateCurrentUser, updateCurrentRouter } =
  userRouterSlice.actions;

// the selector
export const userRouterSelector = (state) => state.user;

// the reducer ~ the mutation in vuex
export default userRouterSlice.reducer;
