import React from "react";
import "./loading.css";
function Loading(props) {
  return (
    <div
      className={`content-loader ` + (props.show ? `d-block` : `d-none`)}
    ></div>
  );
}

export default Loading;
