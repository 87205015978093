/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Modal } from "antd";

import TitlePage from "../../component/Desktop/titlePage";
import RowInput from "../../component/Desktop/rowInput";
import Loading from "../../component/Desktop/loading/loading";
import BreakLine from "../../component/Desktop/breakLine";

import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";

import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";

import classDdl from "../../models/control/dropdownlist";
import {
  getQuerySearch,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";
import GroupItem from "../../component/Desktop/groupItem";
import { HeatWatcherApi } from "../../api/backend/heatWatcher";
import { ApiOffice } from "../../api/backend/headerQuaters";
import { useQuery } from "react-query";
import InputFile from "../../components/common/input/inputFile";
import SelectOffice from "../../components/common/input/selectOffice";

let FLAG_CHANGE_DATA = false;

function InputFileHeat() {
  const history = useHistory();
  let [initPage, setInitPage] = useState(true);
  let [showLoading, setShowLoading] = useState(false);

  const [modeEdit, setModeEdit] = useState(true);

  const objectSearch = getQuerySearch();

  let [ddlBaseName, setddlBaseName] = useState(
    new classDdl(objectSearch.OfficeId)
  );
  // Focus
  let ddlBaseNameEl = useRef(null);
  let txtSerialNumberEl = useRef(null);
  let ddlHeatTypeEl = useRef(null);

  // File
  let [tblAttach, setTblAttach] = useState([]);

  // Change data
  useEffect(() => {
    if (!initPage) {
      FLAG_CHANGE_DATA = true;
    }
  }, [ddlBaseNameEl, txtSerialNumberEl, ddlHeatTypeEl]);

  // Popup common
  const showConfirmMessage = (typeInfo = false) => {
    return;
  };

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA) {
      backToSearch();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      showConfirmMessage(COMMON_CONSTANT.WF_C018);
    }
  }

  const postData = async () => {
    try {
      const formData = new FormData();
      const fileUp = tblAttach[0]["file"];
      formData.append("file", fileUp);
      setShowLoading(true);
      await HeatWatcherApi.importHeatWatcher(ddlBaseName.value, formData);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
      history.goBack();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  // Confirm
  const confirmSaveData = () => {
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };

  // getListOffice
  const listOfficeQuery = useQuery("listOffice", () =>
    ApiOffice.searchOffice({})
  );
  //Format list option Office
  const formatListOptionOffice = () => {
    if (listOfficeQuery.data) {
      const data = listOfficeQuery.data.data.data;
      return data.map((i) => ({
        key: i.officeId,
        value: i.officeName,
      }));
    }
    return [];
  };
  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.HEAT_WATCHER_FILE} />

      {/* Group 1 */}
      <GroupItem>
        <BreakLine />
        <RowInput box name={lang.BASE_NAME}>
          {modeEdit ? (
            <>
              <SelectOffice
                state={ddlBaseName}
                setState={setddlBaseName}
                refer={ddlBaseNameEl}
                isNot100
              />
            </>
          ) : (
            <>
              <div className="white-space"> {ddlBaseName.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.FILE} class="w100">
          <>
            <InputFile
              setTblAttach={setTblAttach}
              tblAttach={tblAttach}
              modeEdit={modeEdit}
            />
          </>
        </RowInput>
        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          <Button
            disabled={!ddlBaseName.value || tblAttach.length === 0}
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              confirmSaveData();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={cancelSaveData}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default InputFileHeat;
