// import external libs
import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from "antd";

import classGrid from "../../models/control/grid";

import { useQuery } from "react-query";
import { DeviceApi } from "../../api/backend/device";
import { CONFIG, KEY, lang } from "../../constants/common.const";
import { MSG_CATCH, renderOption, showMessage } from "../../constants/utils";
const TYPE_SHOW_MODAL = {
  DEVICE: 1,
  SAFE_DEVICE: 2,
};
function WokerList(props) {
  const {
    visible,
    handleCancel,
    OfficeId,
    device,
    setDevice,
    currentUser,
    listDevice,
    type,
  } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([device.serialNumber]);
  const [deviceTypeSelect, setDeviceTypeSelect] = useState(device.deviceType);
  const [deviceTypeIdSelect, setDeviceTypeIdSelect] = useState();

  const [deviceType, setDeviceType] = useState();
  const [serialNumber, setSeriaNumber] = useState();
  const onSelectChange = (newSelectedRowKeys, row) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setDeviceTypeSelect(row[0].deviceTypeValue);
    setDeviceTypeIdSelect(row[0].deviceType);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.userName && record.userName !== currentUser,
    }),
  };
  //  Grid
  const [grid, setGrid] = useState({
    ...new classGrid(),
  });

  let initColumns = [
    {
      title: "デバイス種別",
      dataIndex: "deviceTypeValue",
    },

    {
      title: "デバイス製造番号",
      dataIndex: "serialNumber",
    },
    {
      title: "ログインID",
      dataIndex: "userName",
    },
    {
      title: "氏名",
      dataIndex: "fullName",
    },
  ];

  const onCancel = () => {
    handleCancel();
  };
  const onOk = () => {
    setDevice({
      serialNumber: selectedRowKeys[0],
      deviceType: deviceTypeSelect,
      deviceTypeId: deviceTypeIdSelect,
      type: type,
    });
    onCancel();
  };

  // Query
  const listDeviceTypeQuery = useQuery(
    "listDeviceType",
    () => DeviceApi.getListDeviceType({}),
    {
      enabled: visible ? true : false,
    }
  );
  const listDeviceOption = listDeviceTypeQuery.data
    ? listDeviceTypeQuery.data.data.data
    : [];

  const listDeviceQuery = useQuery(
    "listDevice",
    () =>
      DeviceApi.getListDevice({
        OfficeId,
        SerialNumber: serialNumber,
        DeviceType: deviceType,
      }),
    {
      enabled: visible ? true : false,
      keepPreviousData: true,
      onError: () => {
        showMessage(KEY.ERROR, MSG_CATCH());
      },
      onSuccess: (res) => {
        setGrid({
          ...grid,
          data: res.data.data,
          count: res.data.count,
        });
      },
    }
  );
  // Search
  const handleSearch = () => {
    listDeviceQuery.refetch();
  };
  useEffect(() => {
    if (!device.serialNumber) {
      setSelectedRowKeys([]);
    }
  }, [device.serialNumber]);
  return (
    <Modal
      footer={null}
      title={"デバイスを進択してください "}
      open={visible}
      maskClosable={false}
      onCancel={onCancel}
      width={1100}
      centered
    >
      <div>
        <Col span={24} className="bold"></Col>
        <Divider />

        <Row gutter={[12, 12]} className="rowNotMargin">
          <Col span={8}>
            デバイス種別{" "}
            <Select
              showSearch
              optionFilterProp="children"
              value={deviceType}
              className={"w100"}
              placeholder={"選択してください"}
              maxLength={255}
              onChange={(v) => setDeviceType(v)}
            >
              {renderOption(listDeviceOption, false)}
            </Select>
          </Col>
          <Col span={8}>
            デバイス製造番号{" "}
            <Input
              value={serialNumber}
              onChange={(e) => setSeriaNumber(e.target.value)}
            />
          </Col>
          <Col span={8}>
            <Row className="rowNotMargin mt20" gutter={[12, 12]} justify="end">
              <Col>
                <Button
                  onClick={() => handleSearch()}
                  className="buttonPC button--info wAuto"
                >
                  {lang.SEARCH}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Divider /> */}

        <div
          className="site-layout-background"
          style={{
            margin: "24px 0px",
          }}
        >
          {/* Count */}
          <Row justify="space-between ">
            <Col span={12}></Col>
            <Col span={12} className="right">
              {/* Count */}
              <Space size={10} align="center ">
                {lang.ACCESS_NUMBERS}
                <span>
                  {grid.count}
                  {lang.CASE}
                </span>
              </Space>
            </Col>
          </Row>
          {/* Grid  */}
          <Table
            rowKey={(record) => record.serialNumber}
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            className="gridSearch "
            columns={initColumns}
            dataSource={grid.data}
            pagination={{
              ...CONFIG.paging,

              pageSize: 5,
              total: grid.count,
              showSizeChanger: false,
            }}
            scroll={{ x: "max-content" }}
          />
        </div>

        <Row justify="end" className="rowNotMargin">
          {/* Button Search/Clear */}
          <Col flex="none" className="right">
            <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
              <Col>
                <Button
                  htmlType="submit"
                  className="buttonPC button--info wAuto"
                  onClick={onOk}
                >
                  {lang.OK}
                </Button>
              </Col>
              <Col>
                <Button
                  className="buttonPC button--outline --todo-- wAuto "
                  onClick={handleCancel}
                >
                  {lang.BACK}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default WokerList;
