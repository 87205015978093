
export const RoleHeadOffice = {
  ADMIN_UNIT_SERVICE:"AdminUnitService",
  ADMIN_UNIT_CONTRACT:"AdminUnitContract",
  ADMIN_HEADQUATER:"HeadquartersAdmin",
  GROUP_LEADER:"GroupLeader",
  USER:"User",
  GROUP_SUB_LEADER : "GroupSubLeader",
  USER_JP:"ユーザー",
  GROUP_LEADER_JP:"グループリーダー",
  GROUP_SUB_LEADER_JP:"グループサプリーター"
};
