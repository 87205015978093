import Api from "@src/api";
export default {
  get(params) {
    const workersUrl = params.Workers.join("&Workers=");
    return Api().post(
      `/api/Report`,
      {
        OfficeId: params.OfficeId,
        Workers: params.Workers,
        ReportType: params.ReportType,
        Period: params.Period,
        RequestDate: params.RequestDate,
      },
    );
  },

  getDownload(params) {
    const workersUrl = params.Workers.join("&Workers=");
    return Api().post(
      `/api/Report/Download`,
      {
        OfficeId: params.OfficeId,
        Workers: params.Workers,
        ReportType: params.ReportType,
        Period: params.Period,
        RequestDate: params.RequestDate,
      },
      {
        responseType: "blob",
      }

    );
  },
};
