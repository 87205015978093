// import external libs
import React, { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import { useQuery } from "react-query";
import classGrid from "../../models/control/grid";

import { KEY, lang } from "../../constants/common.const";
import { MSG_CATCH, showMessage } from "../../constants/utils";
import UserApi from "./../../api/backend/user";

import classText from "../../models/control/text";
import { TYPE_MODAL_LEADER } from "../../page/group/add";
function GroupLeaderModal(props) {
  const {
    visible,
    handleCancel,
    currentListGroupLeader,
    officeId,
    typeLeader,
    listUser,
    setListUser,
    currentListSubLeader,
    currentListSubMember,
  } = props;
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  const [hideUserHaveGroup, setHidesUerHaveGroup] = useState(false);
  let [txtWorkerCode, setWorkerCode] = useState(new classText());
  let [txtFullname, setFullname] = useState(new classText());
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [exactSelectedRowKeys, setExactSelectedRowKeys] = useState([]);
  const [
    currentListSelectSubLeaderOrMember,
    setCurrentListSelectSubLeaderOrMember,
  ] = useState(new classGrid());
  const [listAllUSer, setListAllUSer] = useState([]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    // Filter out selected row keys that belong to hidden groups if hideUserHaveGroup is true
    const listAllUserNoGroup = listAllUSer
      .filter((i) => !i.groupId)
      .map((i) => i.userId);
    let finalRows = [...newSelectedRowKeys];
    let exactFinalRows = [...newSelectedRowKeys];
    // Handle select all - hide
    if (hideUserHaveGroup && newSelectedRowKeys.length === listAllUSer.length) {
      exactFinalRows = Array.from(
        new Set([...selectedRowKeys, ...listAllUserNoGroup])
      );
    }
    if (hideUserHaveGroup && newSelectedRowKeys.length === 0) {
      const current = [...exactSelectedRowKeys].filter(
        (i) => !listAllUserNoGroup.includes(i)
      );
      finalRows = [...current];
      exactFinalRows = [...current];
    }
    // Update selected row keys state

    setSelectedRowKeys(finalRows);
    setExactSelectedRowKeys(exactFinalRows);

    const newListUSer = listAllUSer.filter((i) =>
      exactFinalRows.includes(i.userId)
    );
    // Update currentListSelectSubLeaderOrMember state
    setCurrentListSelectSubLeaderOrMember({
      ...currentListSelectSubLeaderOrMember,
      data: newListUSer,
      count: newListUSer.length,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  //  Grid
  const [grid, setGrid] = useState({
    ...new classGrid(),
  });

  let initColumns = [
    {
      title: "ログインID",
      dataIndex: "userName",
    },

    {
      title: "作業者コード",
      dataIndex: "workerCode",
    },

    {
      title: "氏名",
      dataIndex: "fullName",
    },
    {
      title: "グループ名",
      dataIndex: "groupName",
    },
  ];
  // Hide user if cheked others modal
  const filterByCurentRoleModal = (listUser) => {
    if (typeLeader === TYPE_MODAL_LEADER.LEADER) {
      listUser = listUser.filter(
        (item) =>
          !currentListSubLeader.includes(item.userId) &&
          !currentListSubMember.includes(item.userId)
      );
    } else if (typeLeader === TYPE_MODAL_LEADER.SUB_LEADER) {
      listUser = listUser.filter(
        (item) =>
          !currentListSubMember.includes(item.userId) &&
          !currentListGroupLeader.includes(item.userId)
      );
    } else {
      listUser = listUser.filter(
        (item) =>
          !currentListSubLeader.includes(item.userId) &&
          !currentListGroupLeader.includes(item.userId)
      );
    }
    return listUser;
  };

  // Query list User
  const listUserQuery = useQuery(
    "listUser",
    () =>
      UserApi.getListUserRole({
        officeId,
        worker: true,
        workerCode: txtWorkerCode.value,
        fullName: txtFullname.value,
      }),
    {
      onSuccess: (data) => {
        let listUser = data.data.data;
        listUser = filterByCurentRoleModal(listUser);
        const listHide = listUser.filter((i) => !i.groupId);

        setGrid({
          ...grid,
          data: listUser,
          count: hideUserHaveGroup ? listHide.length : listUser.length,
        });
        setListAllUSer(listUser);
      },
      onError: () => {
        showMessage(KEY.ERROR, MSG_CATCH());
      },
      onSettled: () => {
        setShowLoading(false);
      },
      enabled: visible,
      keepPreviousData: true,
      retry: 1,
    }
  );
  useEffect(() => {
    if (!hideUserHaveGroup) {
      setSelectedRowKeys(exactSelectedRowKeys);
    }
  }, [hideUserHaveGroup]);

  const onCancel = () => {
    handleCancel();
  };
  const onOk = () => {
    setListUser({ ...currentListSelectSubLeaderOrMember });
    onCancel();
  };

  // Init checked
  useEffect(() => {
    setSelectedRowKeys(listUser.data.map((i) => i.userId));
    setExactSelectedRowKeys(listUser.data.map((i) => i.userId));
    setCurrentListSelectSubLeaderOrMember({ ...listUser });
  }, [visible, typeLeader]);

  useEffect(() => {
    const listHide = listAllUSer.filter((i) => !i.groupId);
    if (!hideUserHaveGroup) {
      setGrid({ ...grid, count: listAllUSer.length });
    } else {
      setGrid({ ...grid, count: listHide.length });
    }
  }, [hideUserHaveGroup]);
  const hideUserHadGroup = (record) => {
    if (!hideUserHaveGroup) return;
    return record.groupId ? "d-none" : "";
  };
  return (
    <Modal
      footer={null}
      title={
        typeLeader === TYPE_MODAL_LEADER.MEMBER
          ? "作業者を選択してください"
          : "グループリーダーまたはグループサブリーダーを選択してください"
      }
      open={visible}
      maskClosable={false}
      form={formSearch}
      onCancel={onCancel}
      width={1100}
      centered
    >
      <div>
        <Form
          onFinish={() => {
            setShowLoading(true);
            listUserQuery.refetch();
          }}
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
        >
          <Divider />
          <Row gutter={[12, 12]} className="rowNotMargin">
            <Col span={8}>
              作業者コード{" "}
              <Input
                value={txtWorkerCode.value}
                onChange={(e) => {
                  setWorkerCode({ ...txtWorkerCode, value: e.target.value });
                }}
              />
            </Col>
            <Col span={8}>
              氏名{" "}
              <Input
                value={txtFullname.value}
                onChange={(e) => {
                  setFullname({ ...txtFullname, value: e.target.value });
                }}
              />
            </Col>
            <Col span={8}>
              <Row
                className="rowNotMargin mt20"
                gutter={[12, 12]}
                justify="end"
              >
                <Col>
                  <Button
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                  >
                    {lang.SEARCH}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              グループ設定済み作業者を非表示{" "}
              <Checkbox
                checked={hideUserHaveGroup}
                onChange={(e) => {
                  setHidesUerHaveGroup(e.target.checked);
                  // listUserQuery.refetch();
                }}
              />
            </Col>
          </Row>
        </Form>
        {/* <Divider /> */}

        <div
          className="site-layout-background"
          style={{
            margin: "24px 0px",
          }}
        >
          {/* Count */}
          <Row justify="space-between ">
            <Col span={12}></Col>
            <Col span={12} className="right">
              {/* Count */}
              <Space size={10} align="center ">
                {lang.ACCESS_NUMBERS}
                <span>
                  {grid.count}
                  {lang.CASE}
                </span>
              </Space>
            </Col>
          </Row>
          {/* Grid  */}
          {listUserQuery.isLoading || showLoading ? (
            <div style={{ textAlign: "center" }}>
              <Spin></Spin>
            </div>
          ) : (
            <div
              style={{
                maxHeight: 300,
                overflowY: "auto",
              }}
            >
              <Table
                rowKey={(record) => record.userId}
                rowSelection={{
                  ...rowSelection,
                  type: "checkbox",
                }}
                className="gridSearch "
                columns={initColumns}
                dataSource={grid.data}
                pagination={false}
               scroll={{ x: 'max-content' }}
                rowClassName={hideUserHadGroup}
              />
            </div>
          )}
        </div>

        <Row justify="end" className="rowNotMargin">
          {/* Button Search/Clear */}
          <Col flex="none" className="right">
            <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
              <Col>
                <Button
                  htmlType="submit"
                  className="buttonPC button--info wAuto"
                  onClick={onOk}
                >
                  {lang.OK}
                </Button>
              </Col>
              <Col>
                <Button
                  className="buttonPC button--outline --todo-- wAuto "
                  onClick={handleCancel}
                >
                  {lang.BACK}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
export default GroupLeaderModal;
