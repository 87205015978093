import axios from "axios";
import API from "@src/api/backend/auth";
import { config, handleLogOut, getToken, setToken } from "./config";
let unauthen = false;
export default (timeout) => {
  const apiClient = axios.create({
    baseURL: config.backendEndpoint,
    timeout:
      timeout !== undefined && timeout !== null
        ? timeout
        : config.timeoutDefault,
  });
  apiClient.interceptors.request.use(
    async (config) => {
      const token =  getToken().token;
      config.headers.Authorization = `Bearer ${token}`;
      return { ...config };
    },
    (error) => {
      if (error.request.status === 401) {
        // handleLogOut();
      }
      return Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(
    (response) => {
      unauthen = false;
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const { token, refreshToken } = getToken();
      if (!token) {
        handleLogOut();
        return Promise.reject(error);
      }
    
      // Kiểm tra  là do token hết hạn
      if (error.response && error.response.status === 401 && !unauthen) {
       
       unauthen = true;
        try {

          // Gọi lại token
          const newToken = await API.refreshToken({
            accessToken: token,
            refreshToken,
          });
          setToken(newToken.data.token, refreshToken)
          originalRequest.headers["Authorization"] = `Bearer ${newToken.data.accessToken}`;
          // Thực hiện lại request gốc
          return apiClient(originalRequest);
        } catch (refreshError) {
          // Xử lý lỗi khi không thể gọi lại token
          console.log("Error refreshing access token:");
          handleLogOut();
         
        }
      }

      // Nếu không phải lỗi do token hết hạn, trả về lỗi gốc
      return Promise.reject(error);
    }
  );
  return apiClient;
};
