import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { KEY } from "../constants/common.const";

function useWidthInput() {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  // State and setters for debounced value
  const [widthInput, setWidthInput] = useState("w400px");

  useEffect(() => {
    if (isPC) {
      setWidthInput("w400px");
    } else {
      setWidthInput("w100");
    }
  }, [isPC]);
  return widthInput;
}

export default useWidthInput;
