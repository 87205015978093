import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "@src/api/backend/group";
import axios from "axios";

// Get Info Group
export const getGroup = createAsyncThunk(
  "/group",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await API.searchGroup();
      const data = response.data;
      dispatch(setGroup(data));
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.message);
      } else {
        throw error;
      }
    }
  }
);

const initialState = {
  group: [],
  selectedOfficeCode: "",
};
const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setGroup: (state, { payload }) => {
      state.group = payload;
    },
    setSelectedOfficeCode: (state, { payload }) => {
      state.selectedOfficeCode = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Reset error khi bắt đầu request mới
      })
      .addCase(getGroup.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getGroup.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

// the action
export const { setGroup, setSelectedOfficeCode } = groupSlice.actions;

// the selector
export const groupSelector = (state) => state.group;

export const selectedOfficeCodeSelector = (state) => state.selectedOfficeCode;
//the reducer
export default groupSlice.reducer;
