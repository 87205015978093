import Api from "@src/api";
export default {
  getListAlert(params) {
    return Api().get("/api/HealthCondition/Alert", {
      params:{
        ...params,
        alertData: 1
      },
    });
  },
  downloadAlert(companyId) {
    return Api().get(`/api/HealthCondition/DownloadAlert/${companyId}`, {
      responseType: "blob",
    
    });
  },
};
