import React, { useEffect } from "react";
import "./App.scss";
import "./styles/css/common.scss";
import "./styles/css/mobile.scss";
import "./styles/css/vars.scss";
import Router from "@src/routers/router";
import { ConfigProvider, Layout } from "antd";
import Loading from "@src/component/Popup/loading";
import { useDispatch, useSelector } from "react-redux";
import { changeStatusNetWork } from "@src/redux/auth";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { KEY } from "@src/constants/common.const";
import { useMediaQuery } from "react-responsive";
import { getSafeInfo, setInfoUser, setIsLogin } from "./redux/auth";
import { getInfoUSerFromToken, handleLogOut } from "./api/config";
import { LOCALSTORAGE_KEY } from "./constants/common.const";

import jaJP from "antd/lib/locale/ja_JP";
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

dayjs.extend(relativeTime);
dayjs.locale('ja');
dayjs.extend(utc);

const { Content } = Layout;
// reset item page to select menu when reload page or destroy app
window.onbeforeunload = function () {
  localStorage.removeItem("page");
};

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  
  const { currentUser, currentRouter, changeRoute } = useSelector(
    (state) => state.user
  );
  const location = useLocation();  
  const history = useHistory();
  const dispatch = useDispatch();
  const onOffline = () => {
    dispatch(changeStatusNetWork(false));
  };
  const onOnline = () => {
    dispatch(changeStatusNetWork(true));
  };

  // Load user reload
  useEffect(() => {
    try {
      const userInfo = getInfoUSerFromToken();
      dispatch(setInfoUser(userInfo))
      dispatch(getSafeInfo())
    } catch (error) {
      handleLogOut(false);
    }
  },[])
  
  useEffect(() => {
    const isLoginInStore = localStorage.getItem(LOCALSTORAGE_KEY.IS_LOGIN);
    if(isLoginInStore === "true"){
      dispatch(setIsLogin(true))
    }
   

  },[])
  useEffect(() => {
    if (changeRoute !== null) {
      const route = history.location.pathname;
      if (changeRoute) {
        history.push("/");
      } else {
        // in case open new tab with new route below while using input checklist
        if (
          !(
            route.includes("/ViewReport") ||
            route.includes("/history-detail") ||
            route.includes("/ViewMachine")
          )
        ) {
          if (currentRouter.includes("/list")) {
            history.push(currentRouter);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeRoute]);

  const vh = window.innerHeight * 0.01;
  // set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  useEffect(() => {
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);
    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigProvider
      locale={jaJP}
      theme={{
        token: {
          fontFamily:
            "'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif",
          colorPrimary: "#828588",
        },
        components: {
          Layout: {
            colorBgHeader: isDesktopOrLaptop ? "#fff" : "#2C3531",
            colorBgBody: "#4C5054", //#008d4c
            colorBgTrigger: "#4C5054",
          },
          Menu: {
            activeBarBorderWidth: 0,
            darkItemBg: "transparent",
            itemBg: "transparent",
            borderRadius:0
          },
          Collapse: {
            colorFillAlter: "#FFCB9A",
          },
          Button: {
            colorLinkHover: "#279391",
            borderRadius:5
          },
          Switch: {
            colorPrimary: "#1677ff",
            colorPrimaryHover: "#4096ff"
          }
        },
      }}
    >
      <Layout className="layout" id="layout">
        <Content>
          <Router />
        </Content>
        <Loading />
      </Layout>
    </ConfigProvider>
  );
}
export default App;
