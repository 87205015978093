import React, { useEffect } from "react";
import { ApiOffice } from "../../../api/backend/headerQuaters";
import { Input, Select } from "antd";
import { STYLE } from "../../../constants/common.const";
import { renderOption } from "../../../constants/utils";
import { useSelector } from "react-redux";
import { RoleHeadOffice } from "../../../routers/roles/roles.const";
import { widthInput } from "../../../utils/helpers";
import { useLocation } from "react-router-dom";
const SelectOffice = ({
  state,
  setState,
  companyId = null,
  acceptSetNull = true,
  className = "",
  isSiggle = false,
  isNot100,
  refer,
  disabled,
}) => {
  const { roleKey, officeId, officeName } = useSelector((state) => state.auth);
  const isSiggleOffice = [
    RoleHeadOffice.ADMIN_HEADQUATER,
    RoleHeadOffice.USER,
    RoleHeadOffice.GROUP_LEADER,
    RoleHeadOffice.GROUP_SUB_LEADER,
  ].includes(roleKey);
  const location = useLocation();
  const isInputFilePage = location.pathname.includes("/inputfile");

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await ApiOffice.searchOffice({
          companyId,
        });
        const data = response.data.data.map((item) => {
          return {
            ...item,
            value: item.officeName,
            key: item.officeId,
          };
        });
        setState({
          ...state,
          options: data,
          value: acceptSetNull ? null : state.value,
        });
      } catch (e) {
        console.log(e);
      }
    };

    if (!isSiggleOffice) {
      fetch();
    }
  }, [isSiggleOffice, companyId]);

  useEffect(() => {
    if (isSiggleOffice) {
      setState({ ...state, value: officeId });
    }
  }, [isSiggleOffice]);

  return isSiggleOffice ? (
    <Input
      ref={refer}
      value={officeName}
      disabled
      className={`${!isInputFilePage && !isNot100 ? "w100" : ""} ${widthInput}`}
    />
  ) : (
    <Select
      showSearch
      optionFilterProp="children"
      value={state.options.length ? state.value : ""}
      ref={refer}
      className={
        state.error
          ? ` ${STYLE.BORDER_RED} ${widthInput} ${className}`
          : ` ${widthInput} ${className}`
      }
      onChange={(value) => {
        setState({
          ...state,
          value,
        });
      }}
    >
      {renderOption(state.options)}
    </Select>
  );
};
export default SelectOffice;
