import Api from "@src/api";
export const DeviceApi = {
  getListDevice(params) {
    return Api().get("/api/Device", { params });
  },
  getDetailDevice(SerialNumber) {
    return Api().get(`/api/Device/${SerialNumber}`);
  },
  deleteDevice(SerialNumber) {
    return Api().delete(`/api/Device/${SerialNumber}`);
  },
  // 2: Create/Update Devide
  save(formData, id) {
    return id
      ? Api().put(`/api/Device`, formData, {})
      : Api().post("/api/Device", formData, {});
  },
  importDevice(officeId, formData) {
    return Api().post(`/api/Device/Import/${officeId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  downloadListDevice(params = {}) {
    return Api().get(`/api/Device/Download`, {
      responseType: "blob",
      params,
    });
  },
  getListDeviceType() {
    return Api().get(`/api/Kbn/deviceType`);
  },
};
