import {
  ClearOutlined,
  SaveOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import Loading from "../../component/Desktop/loading/loading";

import React, { useEffect, useRef, useState } from "react";
import { FaFaceFrown, FaFaceMeh, FaFaceSadTear } from "react-icons/fa6";
import TitlePage from "../../component/Desktop/titlePage";
import {
  COMMON_CONSTANT,
  KEY,
  REGEX_INPUT,
  STYLE,
  lang,
} from "../../constants/common.const";

import { settingApi } from "../../api/backend/setting";
import ContentTitle from "../../component/Desktop/contentTitle";
import SelectCompany from "../../components/common/input/selectCompany";
import {
  checkEmail,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";
import classDdl from "../../models/control/dropdownlist";
import classOption from "../../models/control/option";
import classText from "../../models/control/text";
import { useSelector } from "react-redux";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
function Master() {
  const { roleKey } = useSelector((state) => state.auth);
  const isAdminService = roleKey === RoleHeadOffice.ADMIN_UNIT_SERVICE;
  let [txtCountinous, settxtCountinous] = useState(new classText());
  let [txtInstantaneous, settxtInstantaneous] = useState(new classText());
  let [txtMaximum, settxtMaximum] = useState(new classText());
  let [rdEmotion, setrdEmotion] = useState(new classOption());
  let [txtTime, settxtTime] = useState(new classText());
  let [txtTo, settxtTo] = useState(new classText());
  let [txtCc, settxtCc] = useState(new classText());
  let [txtBcc, settxtBcc] = useState(new classText());
  const [urlSismil, setUrlSismil] = useState(new classText());
  const [loading, setLoading] = useState(false);

  const [listOption, setListOption] = useState([]);
  const [listOption1, setListOption1] = useState([]);
  const [listOption2, setListOption2] = useState([]);
  const [listOption3, setListOption3] = useState([]);
  const [ddlCompany, setDdlCompany] = useState({
    ...new classDdl(),
  });
  // Focus
  let txtCountinousEl = useRef(null);
  let txtInstantaneousEl = useRef(null);
  let txtMaximumEl = useRef(null);
  let rdEmotionEl = useRef(null);
  let txtTimeEL = useRef(null);
  let cbOption1El = useRef(null);
  const refTo = useRef();
  const refcc = useRef();
  const refbcc = useRef();
  const refUrlSismil = useRef();

  // Validate
  const validateData = () => {
    let flagError = false;
    if (!txtCountinous.value) {
      flagError = true;
      settxtCountinous({ ...txtCountinous, error: true });
      txtCountinousEl.current.focus();
    }
    if (!txtInstantaneous.value) {
      flagError = true;
      settxtInstantaneous({ ...txtInstantaneous, error: true });
      txtInstantaneousEl.current.focus();
    }
    if (!txtMaximum.value) {
      flagError = true;
      settxtMaximum({ ...txtMaximum, error: true });
      txtMaximumEl.current.focus();
    }
    if (!rdEmotion.value) {
      flagError = true;
      setrdEmotion({ ...rdEmotion, error: true });
      rdEmotionEl.current.focus();
    }
    if (!txtTime.value) {
      flagError = true;
      settxtTime({ ...txtTime, error: true });
      txtTimeEL.current.focus();
    }
    const listStringEmailTo = txtTo.value.trim();
    const arrayEmailTo = listStringEmailTo.split(",");
    const listStringEmailCC = txtCc.value.trim();
    const arrayEmailCC = listStringEmailCC.split(",");
    const listStringEmailBcc = txtBcc.value.trim();
    const arrayEmailBcc = listStringEmailBcc.split(",");

    if (txtTo.value.trim() && arrayEmailTo.some((i) => !checkEmail(i))) {
      flagError = true;
      settxtTo({ ...txtTo, error: true });
      refTo.current.focus();
    } else {
      settxtTo({ ...txtTo, error: false });
    }
    if (txtCc.value.trim() && arrayEmailCC.some((i) => !checkEmail(i))) {
      flagError = true;
      refcc.current.focus();
      settxtCc({ ...txtCc, error: true });
    } else {
      settxtCc({ ...txtCc, error: false });
    }
    if (txtBcc.value.trim() && arrayEmailBcc.some((i) => !checkEmail(i))) {
      flagError = true;
      refbcc.current.focus();
      settxtBcc({ ...txtBcc, error: true });
    } else {
      settxtBcc({ ...txtBcc, error: false });
    }

    return !flagError;
  };

  const options = [
    {
      label: "発生日時 ",
      value: "DATETIME_OF_OCCURRENCE",
    },
    {
      label: "緊急",
      value: "EMERGENCY",
    },
    {
      label: "総合アラート",
      value: "COMPREHENSIVE_ALERT",
    },
    {
      label: "氏名",
      value: "NAME",
    },
    {
      label: "年齢",
      value: "AGE",
    },
    {
      label: "拠点名",
      value: "LOCATION/BASE_NAME",
    },
    {
      label: "グループ",
      value: "GROUP",
    },
  ];

  const options2 = [
    {
      label: "心拍数",
      value: "HEART_RATE",
    },
    {
      label: "WBGT超過度",
      value: "WBGT_EXCESSIVE",
    },
    {
      label: "WBGT値",
      value: "WBGT_VALUE",
    },
    {
      label: "WBGT測定地点",
      value: "WBGT_MEASUREMENT_POINT",
    },
    {
      label: "位置情報",
      value: "LOCATION_INFORMATION",
    },
  ];
  const options3 = [
    {
      label: "バッテリ",
      value: "BATTERY",
    },
    {
      label: "電波強度",
      value: "RADIO_FIELD_STRENGTH",
    },
  ];

  // Get post Data Option display alert
  const getListDataOptionAlertList = (allOption, checkedOption) =>
    allOption.map((item) => {
      return {
        type: "ALERT_LIST_DISPLAY_ITEM_SETTING",
        key: item.value,
        value: checkedOption.includes(item.value) ? "1" : "0",
      };
    });

  // Post
  const postData = async () => {
    try {
      setLoading(true);
      const dataPost = [
        {
          type: "EMAIL_ALERT_SETTING",
          key: "BCC",
          value: txtBcc.value,
        },
        {
          type: "EMAIL_ALERT_SETTING",
          key: "TO",
          value: txtTo.value,
        },
        {
          type: "COMPREHENSIVE_ALERT_DETECTION_DURATION",
          key: "INSTANTANEOUS_HEARTBEAT_LOSS",
          value: txtInstantaneous.value,
        },

        {
          type: "CUMULATIVE_TIME_FOR_EMERGENCY_ALERT_DETECTION",
          key: "LEVEL_OF_MEASURING",
          value: rdEmotion.value,
        },
        {
          type: "CUMULATIVE_TIME_FOR_EMERGENCY_ALERT_DETECTION",
          key: "ACCUMULATED_TIME",
          value: txtTime.value,
        },
        {
          type: "COMPREHENSIVE_ALERT_DETECTION_DURATION",
          key: "SUSTAINED_HEARTBEAT_LOSS",
          value: txtCountinous.value,
        },
        {
          type: "COMPREHENSIVE_ALERT_DETECTION_DURATION",
          key: "MAXIMUM_HEART_RATE",
          value: txtMaximum.value,
        },
        {
          type: "EMAIL_ALERT_SETTING",
          key: "CC",
          value: txtCc.value,
        },
      ];
      // add display alert data
      const ops = getListDataOptionAlertList(options, listOption);
      const ops2 = getListDataOptionAlertList(options2, listOption2);
      const ops3 = getListDataOptionAlertList(options3, listOption3);
      dataPost.push(...ops, ...ops2, ...ops3);

      await settingApi.postmasterSetting(ddlCompany.value, dataPost);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };
  const onSave = () => {
    // Validate Data
    if (!validateData()) {
      return;
    }
    // Call Api
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };

  // SetDataInit
  const setFieldValueInit = (data, type, key, oldValue, setvalue) => {
    const item = data.find((i) => i.key === key && i.type === type);
    if (item) {
      if (oldValue) {
        setvalue({ ...oldValue, value: item.value });
      } else {
        setvalue(item.value);
      }
    }
  };

  // Set alert Display
  const setInitAlertDisplay = (data) => {
    const listChecked = data.filter((i) => i.value === "1");
    let ops = [],
      ops1 = [],
      ops2 = [],
      ops3 = [];
    listChecked.forEach((item) => {
      if (options.find((i) => i.value === item.key)) {
        ops.push(item.key);
      } else if (options2.find((i) => i.value === item.key)) {
        ops2.push(item.key);
      } else if (options3.find((i) => i.value === item.key)) {
        ops3.push(item.key);
      }
    });
    setListOption(ops);
    setListOption1(ops1);
    setListOption2(ops2);
    setListOption3(ops3);
  };
  // get init Data
  const getInitDataMasterSetting = async () => {
    try {
      const settingRes = await settingApi.getmasterSetting(ddlCompany.value);
      const data = settingRes.data;
      // COMPREHENSIVE_ALERT_DETECTION_DURATION
      setFieldValueInit(
        data,
        "COMPREHENSIVE_ALERT_DETECTION_DURATION",
        "SUSTAINED_HEARTBEAT_LOSS",
        txtCountinous,
        settxtCountinous
      );
      setFieldValueInit(
        data,
        "COMPREHENSIVE_ALERT_DETECTION_DURATION",
        "INSTANTANEOUS_HEARTBEAT_LOSS",
        txtInstantaneous,
        settxtInstantaneous
      );
      setFieldValueInit(
        data,
        "COMPREHENSIVE_ALERT_DETECTION_DURATION",
        "MAXIMUM_HEART_RATE",
        txtMaximum,
        settxtMaximum
      );

      setFieldValueInit(
        data,
        "CUMULATIVE_TIME_FOR_EMERGENCY_ALERT_DETECTION",
        "LEVEL_OF_MEASURING",
        rdEmotion,
        setrdEmotion
      );
      setFieldValueInit(
        data,
        "CUMULATIVE_TIME_FOR_EMERGENCY_ALERT_DETECTION",
        "ACCUMULATED_TIME",
        txtTime,
        settxtTime
      );
      // EMAIL
      setFieldValueInit(data, "EMAIL_ALERT_SETTING", "TO", txtTo, settxtTo);
      setFieldValueInit(data, "EMAIL_ALERT_SETTING", "CC", txtCc, settxtCc);
      setFieldValueInit(data, "EMAIL_ALERT_SETTING", "BCC", txtBcc, settxtBcc);

      // OPtion Display
      setInitAlertDisplay(
        data.filter((i) => i.type === "ALERT_LIST_DISPLAY_ITEM_SETTING")
      );
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };
  useEffect(() => {
    if (ddlCompany.value) {
      getInitDataMasterSetting();
    }
  }, [ddlCompany.value]);

  // get url
  const getSisMilUrl = async () => {
    const res = await settingApi.getUrlSisMil();

    setUrlSismil({
      ...urlSismil,
      value: res.data.currentSismilHost,
      defaultValue: res.data.defaultSismilHost,
    });
  };
  useEffect(() => {
    getSisMilUrl();
  }, []);
  // Check URL
  function isValidUrl(url) {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  }

  // Check validate url
  const validateUrlSisMil = () => {
    let isErr = false;
    if (!isValidUrl(urlSismil.value) || !urlSismil.value.trim()) {
      setUrlSismil({ ...urlSismil, error: true });
      isErr = true;
      refUrlSismil.current.focus();
    } else {
      setUrlSismil({ ...urlSismil, error: false });
    }
    return !isErr;
  };

  const postNewUrl = async (isModal, isDefault) => {
    if (isModal) {
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
    }
    try {
      setLoading(true);
      await settingApi.updateUrlSisMil({
        newSismilUrl: !isDefault ? urlSismil.value : urlSismil.defaultValue,
      });
      if (isDefault) {
        setUrlSismil({ ...urlSismil, value: urlSismil.defaultValue });
      }
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };
  // Save URL sismil
  const onSaveUrlSismil = () => {
    if (!validateUrlSisMil()) {
      return;
    }
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: async () => {
        postNewUrl(true);
      },
    });
  };
  return (
    <div className="content">
      <ContentTitle>
        <TitlePage name={"契約ユニットマスター設定"} />
        <Row>
          {isAdminService && (
            <Col span={24}>
              <Row gutter={[10, 10]} align={"middle"}>
                <Col span={2}></Col>
                <Col span={22}></Col>
                <Col
                  span={5}
                  style={{
                    textAlign: "right",
                    marginTop: urlSismil.error && -25,
                  }}
                >
                  <h4>{"SisMil URL API"}</h4>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                  <Input
                    ref={refUrlSismil}
                    className={urlSismil.error ? `${STYLE.BORDER_RED}` : ""}
                    value={urlSismil.value}
                    onChange={(e) =>
                      setUrlSismil({ ...urlSismil, value: e.target.value })
                    }
                  />
                  {urlSismil.error && (
                    <div
                      style={{
                        marginTop: 5,
                        color: "red",
                        marginBottom: 5,
                      }}
                    >
                      無効なURLです
                    </div>
                  )}
                </Col>
                <Col span={5}></Col>
                <Col
                  style={{ marginTop: -10 }}
                  offset={5}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={8}
                >
                  <Button
                    className="buttonPC button--info wAuto mr15"
                    onClick={() => {
                      onSaveUrlSismil();
                    }}
                  >
                    <SaveOutlined />
                    {COMMON_CONSTANT.SAVE}
                  </Button>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto mr15"
                    onClick={() => postNewUrl(false, true)}
                  >
                    <RollbackOutlined />
                    {"デフォルト値に戻す"}
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={24}>
            {isAdminService && (
              <Row>
                <Col span={1}></Col>
                <Col span={22}>
                  <Divider />
                </Col>
                <Col span={1}></Col>
              </Row>
            )}
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={2}></Col>
              <Col span={22}></Col>
              <Col span={5} style={{ textAlign: "right" }}>
                <h4>{lang.COMPANY}</h4>
              </Col>
              <Col className="mt10" xs={12} sm={12} md={12} lg={12} xl={8}>
                <SelectCompany
                  className={"w100"}
                  selectFirst={true}
                  state={ddlCompany}
                  setState={setDdlCompany}
                />
              </Col>
            </Row>
          </Col>
          {/* <Col span={24}>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                <Divider />
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col> */}

          {/* LEVEL 1 */}
          <Col span={1}></Col>
          <Col span={23}>
            <h4>【1】 総合アラート判定用持続時間 </h4>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={2}></Col>
              <Col span={22}></Col>
              <Col span={5} style={{ textAlign: "right" }}>
                <h4>{lang.COUNTINOUS_HEART}</h4>
              </Col>
              <Col className="mt10" xs={12} sm={12} md={12} lg={12} xl={8}>
                <Input
                  min={0}
                  type="text"
                  value={txtCountinous.value}
                  ref={txtCountinousEl}
                  className={txtCountinous.error ? `${STYLE.BORDER_RED}` : ""}
                  maxLength={255}
                  onChange={(e) => {
                    const reg = REGEX_INPUT.intergerFrom0;
                    if (reg.test(e.target.value) || e.target.value === "") {
                      settxtCountinous({
                        ...txtCountinous,
                        value: e.target.value,
                      });
                    }
                  }}
                />
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={9}>
                <h4>分</h4>
              </Col>
              <Col span={5} style={{ textAlign: "right" }}>
                <h4> {lang.INSTANTANEOUS_HEART} </h4>
              </Col>
              <Col className="mt10" xs={12} sm={12} md={12} lg={12} xl={8}>
                <Input
                  min={0}
                  type="text"
                  value={txtInstantaneous.value}
                  ref={txtInstantaneousEl}
                  className={
                    txtInstantaneous.error ? `${STYLE.BORDER_RED}` : ""
                  }
                  maxLength={255}
                  onChange={(e) => {
                    const reg = REGEX_INPUT.intergerFrom0;
                    if (reg.test(e.target.value) || e.target.value === "") {
                      settxtInstantaneous({
                        ...txtInstantaneous,
                        value: e.target.value,
                      });
                    }
                  }}
                />
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={9}>
                {" "}
                <h4>分</h4>
              </Col>
              <Col span={5} style={{ textAlign: "right" }}>
                <h4>{lang.MAXIMUM_HEART} </h4>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={8} className="mt10">
                <Input
                  min={0}
                  type="text"
                  value={txtMaximum.value}
                  ref={txtMaximumEl}
                  className={txtMaximum.error ? `${STYLE.BORDER_RED}` : ""}
                  maxLength={255}
                  onChange={(e) => {
                    const reg = REGEX_INPUT.intergerFrom0;
                    if (reg.test(e.target.value) || e.target.value === "") {
                      settxtMaximum({
                        ...txtMaximum,
                        value: e.target.value,
                      });
                    }
                  }}
                />
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={9}>
                <h4>分</h4>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                <Divider />
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col>

          {/* LEVEL 2 */}
          <Col span={1}></Col>
          <Col span={23}>
            <h4>【2】緊急アラート判定用蓄積時間</h4>
          </Col>

          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={2}></Col>
              <Col span={22}></Col>
              <Col span={5} style={{ textAlign: "right" }}></Col>
              <Col span={19}>
                <Flex style={{ height: "100%", marginLeft: 20 }}></Flex>
              </Col>
              <Col span={5} style={{ textAlign: "right" }}>
                <h4>{lang.LEVEL_MESSURE_CONDITION}</h4>
              </Col>
              <Col span={19}>
                <Radio.Group
                  name="rdEmotion"
                  style={{ marginTop: 15 }}
                  value={rdEmotion.value}
                  ref={rdEmotionEl}
                  className={rdEmotion.error ? `${STYLE.BORDER_RED}` : ""}
                  maxLength={255}
                  onChange={(e) =>
                    setrdEmotion({
                      ...rdEmotion,
                      value: e.target.value,
                    })
                  }
                >
                  <Radio
                    value={"1"}
                    className="radio "
                    style={{ marginLeft: 20 }}
                  >
                    <Space>
                      <FaFaceMeh
                        style={{ color: "gold", fontSize: 24, marginTop: 5 }}
                      />
                      <Typography.Text style={{ verticalAlign: "middle" }}>
                        （レベル1以上）{" "}
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio
                    value={"2"}
                    className="radio"
                    style={{ marginLeft: 20 }}
                  >
                    <Space>
                      <FaFaceFrown
                        style={{ color: "orange", fontSize: 24, marginTop: 5 }}
                      />
                      <Typography.Text style={{ verticalAlign: "middle" }}>
                        {" "}
                        （レベル2以上){" "}
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio
                    value={"3"}
                    className="radio"
                    style={{ marginLeft: 20 }}
                  >
                    <Space>
                      <FaFaceSadTear
                        style={{ color: "red", fontSize: 24, marginTop: 5 }}
                      />
                      <Typography.Text style={{ verticalAlign: "middle" }}>
                        {" "}
                        （レベル3以上){" "}
                      </Typography.Text>
                    </Space>
                  </Radio>
                </Radio.Group>
              </Col>

              <Col span={5} style={{ textAlign: "right" }}>
                <h4> {lang.SCHOOL_TIME}</h4>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                <Input
                  min={0}
                  type="input"
                  value={txtTime.value}
                  ref={txtTimeEL}
                  className={txtTime.error ? `${STYLE.BORDER_RED}` : ""}
                  maxLength={255}
                  onChange={(e) => {
                    const reg = REGEX_INPUT.intergerFrom0;
                    if (reg.test(e.target.value) || e.target.value === "") {
                      settxtTime({
                        ...txtTime,
                        value: e.target.value,
                      });
                    }
                  }}
                  style={{ marginTop: 10 }}
                />
              </Col>
              <Col span={5}>
                <h4> 分 </h4>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                <Divider />
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col>

          {/* LEVEL 3 */}
          <Col span={1}></Col>
          <Col span={23}>
            <h4>【3】アラート画面表示項目設定 </h4>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={5}></Col>
              <Col span={19}>
                <Checkbox.Group
                  value={listOption}
                  onChange={(arr) => setListOption(arr)}
                  ref={cbOption1El}
                  style={{ width: "100%", display: "block" }}
                  options={options}
                >
                  {/* {renderListOptionCheckbox(options)} */}
                </Checkbox.Group>
              </Col>
              <Col span={5}></Col>
              <Col span={19}>
                <Checkbox.Group
                  style={{ width: "100%", display: "block" }}
                  value={listOption2}
                  onChange={(arr) => setListOption2(arr)}
                  options={options2}
                >
                  {/* {renderListOptionCheckbox(options2)} */}
                </Checkbox.Group>
              </Col>

              <Col span={5}></Col>
              <Col span={19}>
                <Checkbox.Group
                  style={{ width: "100%", display: "block" }}
                  value={listOption3}
                  onChange={(arr) => setListOption3(arr)}
                  options={options3}
                >
                  {/* {renderListOptionCheckbox(options3)} */}
                </Checkbox.Group>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                <Divider />
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col>

          {/* LEVEL 4 */}
          <Col span={1}></Col>
          <Col span={23}>
            <h4>【4】メールアラート設定</h4>
          </Col>

          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={2}></Col>
              <Col span={22}>
                <h4> {lang.ALRET_MUTIPLE_EMAIL_ADDRESS}</h4>
              </Col>
              <Col span={5} style={{ textAlign: "right" }}>
                <h4> To:</h4>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                <Input.TextArea
                  ref={refTo}
                  className={
                    txtTo.error ? `${STYLE.BORDER_RED} text-area` : "text-area"
                  }
                  value={txtTo.value}
                  onChange={(e) =>
                    settxtTo({ ...txtTo, value: e.target.value })
                  }
                />
                {txtTo.error && (
                  <div style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                    {lang.EMAIL_NOT_VALIDATE}
                  </div>
                )}
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={9}></Col>
              <Col span={5} style={{ textAlign: "right" }}>
                <h4> Cc:</h4>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                <Input.TextArea
                  className={
                    txtCc.error ? `${STYLE.BORDER_RED} text-area` : "text-area"
                  }
                  ref={refcc}
                  value={txtCc.value}
                  onChange={(e) =>
                    settxtCc({ ...txtCc, value: e.target.value })
                  }
                />
                {txtCc.error && (
                  <div style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                    {lang.EMAIL_NOT_VALIDATE}
                  </div>
                )}
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={9}></Col>
              <Col span={5} style={{ textAlign: "right" }}>
                <h4> Bcc:</h4>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                <Input.TextArea
                  className={
                    txtBcc.error ? `${STYLE.BORDER_RED} text-area` : "text-area"
                  }
                  ref={refbcc}
                  value={txtBcc.value}
                  onChange={(e) =>
                    settxtBcc({ ...txtBcc, value: e.target.value })
                  }
                />
                {txtBcc.error && (
                  <div style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                    {lang.EMAIL_NOT_VALIDATE}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentTitle>

      <Row justify={"end"} className="mt20 mb20">
        <Col>
          <Button
            className="buttonPC button--info wAuto mr15"
            onClick={() => {
              onSave();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          {/* <Button
            className="buttonPC button--outline --todo-- wAuto mr15 mt5"
            onClick={getInitDataMasterSetting}
          >
            <ClearOutlined />
            {lang.CLEAR}
          </Button> */}
        </Col>
      </Row>
      <Loading show={loading} />
    </div>
  );
}

export default Master;
