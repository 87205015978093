/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import BreakLine from "../../component/Desktop/breakLine";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput";
import TitlePage from "../../component/Desktop/titlePage";

import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";

import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";

import { DeviceApi } from "../../api/backend/device";
import { ApiOffice } from "../../api/backend/headerQuaters";
import GroupItem from "../../component/Desktop/groupItem";
import {
  MSG_CATCH,
  getQuerySearch,
  renderOption,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";
import useAuth from "../../hooks/useAuth";
import useWidthInput from "../../hooks/useWidthInput";
import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";

let FLAG_CHANGE_DATA = false;

function AddVitalDevice() {
  let { id } = useParams();
  const history = useHistory();
  let [showLoading, setShowLoading] = useState(false);
  const [modeEdit] = useState(true);
  const { OfficeId } = getQuerySearch();
  const { officeId, isSiggleOffice, officeName } = useAuth();
  // Loading
  let [ddlBaseName, setddlBaseName] = useState(
    new classDdl(isSiggleOffice ? officeId : OfficeId || null)
  );
  let [txtSerialNumber, settxtSerialNumber] = useState(new classText());
  let [ddlVitalDeviceType, setDdlVitalDeviceType] = useState(new classDdl());

  // Focus
  let ddlBaseNameEl = useRef(null);
  let txtSerialNumberEl = useRef(null);
  let ddlVitalDeviceTypeEl = useRef(null);
  const widthInput = useWidthInput();

  const getInitData = async () => {
    if (id) {
      setShowLoading(true);
    }
    try {
      const [detailData, listDevice, listOffice] = await Promise.all([
        id ? DeviceApi.getDetailDevice(id) : null,
        DeviceApi.getListDeviceType(),
        !isSiggleOffice ? ApiOffice.searchOffice({}) : null,
      ]);
      // Edit
      if (id) {
        settxtSerialNumber({
          ...txtSerialNumber,
          value: detailData.data.serialNumber,
        });
        setDdlVitalDeviceType({
          ...ddlVitalDeviceType,
          options: listDevice.data.data,
          value: String(detailData.data.deviceType),
        });
      } else {
        setDdlVitalDeviceType({
          ...ddlVitalDeviceType,
          options: listDevice.data.data,
        });
      }
      if (!isSiggleOffice) {
        setddlBaseName({
          ...ddlBaseName,
          options: listOffice.data.data.map((i) => ({
            key: i.officeId,
            value: i.officeName,
          })),
        });
      }
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    getInitData();
  }, [id]);
  // Popup common
  const showConfirmMessage = (typeInfo = false) => {
    return;
  };

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA) {
      backToSearch();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      showConfirmMessage(COMMON_CONSTANT.WF_C018);
    }
  }

  // Validate
  const validateData = () => {
    let flagError = false;
    settxtSerialNumber({ ...txtSerialNumber, error: false });
    setDdlVitalDeviceType({ ...ddlVitalDeviceType, error: false });
    setddlBaseName({ ...ddlBaseName, error: false });
    if (!txtSerialNumber.value || isNaN(txtSerialNumber.value)) {
      flagError = true;
      settxtSerialNumber({ ...txtSerialNumber, error: true });
      txtSerialNumberEl.current.focus();
    }

    if (!ddlVitalDeviceType.value) {
      flagError = true;
      setDdlVitalDeviceType({ ...ddlVitalDeviceType, error: true });
      ddlVitalDeviceTypeEl.current.focus();
    }
    if (
      ddlVitalDeviceType.value === "1" &&
      (Number(txtSerialNumber.value) < 0 ||
        Number(txtSerialNumber.value) > 32767)
    ) {
      flagError = true;
      settxtSerialNumber({ ...txtSerialNumber, error: true });
      txtSerialNumberEl.current.focus();
    }
    if (
      ddlVitalDeviceType.value === "2" &&
      (Number(txtSerialNumber.value) < 32768 ||
        Number(txtSerialNumber.value) > 65535)
    ) {
      flagError = true;
      settxtSerialNumber({ ...txtSerialNumber, error: true });
      txtSerialNumberEl.current.focus();
    }
    if (!ddlBaseName.value) {
      flagError = true;
      setddlBaseName({ ...ddlBaseName, error: true });
      ddlBaseNameEl.current.focus();
    }
    return !flagError;
  };
  const postData = async () => {
    try {
      setShowLoading(true);
      const dataPost = {
        serialNumber: Number(txtSerialNumber.value) + "",
        deviceType: ddlVitalDeviceType.value,
        officeId: ddlBaseName.value,
      };
      await DeviceApi.save(dataPost, id);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003, history.goBack());
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  // Save Data
  const onSave = () => {
    // Validate Data
    if (!validateData()) {
      return;
    }
    // Call Api
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: id ? COMMON_CONSTANT.ECM_C009 : COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };

  return (
    <div className="des-content">
      <Loading show={showLoading} />
      <TitlePage name={lang.VITAL_DEVICE_SETTING} />

      {/* Group 1 */}
      <GroupItem>
        <BreakLine />
        <RowInput box name={lang.BASE_NAME}>
          <>
            {isSiggleOffice ? (
              <Input disabled value={officeName} className={`${widthInput}`} />
            ) : (
              <Select
                showSearch
                optionFilterProp="children"
                disabled={id}
                value={ddlBaseName.options.length ? ddlBaseName.value : ""}
                ref={ddlBaseNameEl}
                className={
                  ddlBaseName.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                onChange={(v) =>
                  setddlBaseName({
                    ...ddlBaseName,
                    value: v,
                  })
                }
              >
                {renderOption(ddlBaseName.options, false)}
              </Select>
            )}
          </>
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.TYPE_DEVICE} required={modeEdit}>
          {modeEdit ? (
            <>
              <Select
                showSearch
                optionFilterProp="children"
                ref={ddlVitalDeviceTypeEl}
                value={ddlVitalDeviceType.value}
                className={
                  ddlVitalDeviceType.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                onChange={(value) => {
                  setDdlVitalDeviceType({
                    ...ddlVitalDeviceType,
                    value,
                  });
                }}
              >
                {renderOption(ddlVitalDeviceType.options)}
              </Select>
            </>
          ) : (
            <>
              <div className="white-space"> {ddlVitalDeviceType.label}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.SERIAL_ID} required={modeEdit}>
          <Input
            disabled={id}
            value={txtSerialNumber.value}
            ref={txtSerialNumberEl}
            className={
              txtSerialNumber.error
                ? `${STYLE.BORDER_RED} ${widthInput}`
                : `${widthInput}`
            }
            maxLength={16}
            onChange={(e) =>
              settxtSerialNumber({
                ...txtSerialNumber,
                value: e.target.value,
              })
            }
          ></Input>
        </RowInput>
        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          <Button
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              onSave();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={cancelSaveData}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default AddVitalDevice;
