import { useSelector } from "react-redux";
import { RoleHeadOffice } from "../routers/roles/roles.const";

const useAuth = () => {
  const {
    roleKey,
    officeId,
    officeName,
    companyId,
    companyName,
    baseCode,
    groupId,
    groupName,
  } = useSelector((state) => state.auth);
  const isSiggleOffice = [
    RoleHeadOffice.ADMIN_HEADQUATER,
    RoleHeadOffice.USER,
    RoleHeadOffice.GROUP_LEADER,
    RoleHeadOffice.GROUP_SUB_LEADER,
  ].includes(roleKey);
  const isSingleCompany = [
    RoleHeadOffice.ADMIN_HEADQUATER,
    RoleHeadOffice.USER,
    RoleHeadOffice.GROUP_LEADER,
    RoleHeadOffice.ADMIN_UNIT_CONTRACT,
    RoleHeadOffice.GROUP_SUB_LEADER,
  ].includes(roleKey);
  const isSiggleGroup = [
    RoleHeadOffice.USER,
    RoleHeadOffice.GROUP_LEADER,
    RoleHeadOffice.GROUP_SUB_LEADER,
  ].includes(roleKey);
  return {
    isSiggleOffice,
    isSingleCompany,
    officeId,
    officeName,
    companyId,
    companyName,
    baseCode,
    groupId,
    groupName,
    isSiggleGroup,
  };
};
export default useAuth;
