import jwt from "jsonwebtoken";
import { LOCALSTORAGE_KEY } from "../constants/common.const";

export const config = {
  backendEndpoint: "https://wear.griffy.ai/",
  defaultEndpoint: "https://wear.griffy.ai/",
  clientEndpoint: "https://wear.griffy.ai/",
  timeoutDefault: 600000,
};

export const getToken = () => {
  const token = localStorage.getItem(LOCALSTORAGE_KEY.TOKEN) || "";
  const refreshToken = localStorage.getItem(LOCALSTORAGE_KEY.REFESH_TOKEN) || "";
  return {
    token,
    refreshToken,
  };
};

export const getUser = () => {};

export const setToken = (token,refreshToken) => {
  localStorage.setItem(LOCALSTORAGE_KEY.TOKEN, token);
  localStorage.setItem(LOCALSTORAGE_KEY.REFESH_TOKEN, refreshToken);
}

export const handleLogOut = (isRedirect=true) => {
  localStorage.removeItem(LOCALSTORAGE_KEY.IS_LOGIN);
  localStorage.removeItem(LOCALSTORAGE_KEY.TOKEN);
  localStorage.removeItem(LOCALSTORAGE_KEY.REFESH_TOKEN);
  if(isRedirect && !window.location.pathname.includes("login")){
    window.location.href = '/login';
  }
};

// Get infoUSerFromToken
export const getInfoUSerFromToken = () => {
  try {
    const token = localStorage.getItem(LOCALSTORAGE_KEY.TOKEN);
    if (!token) throw new Error("No token");
    const infoUser = jwt.decode(token);
    return infoUser;
  } catch (error) {
    console.log(error);
    handleLogOut(false);
  }
};
