import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  btnBookMark: false,
  btnBack: false,
  isBookMark: false,
  btnSearch: false,
  btnList: false,
  isSearch: false,
  isShowNav: false,
  status: null,
  color: null,
  isShowMenu: false,
  backLink: null,
  btnIcon: false,
};
const navBarSlice = createSlice({
  name: "navBar",
  initialState,
  reducers: {
    changeNavBar: (state, { payload }) => {
      state.btnIcon = !!payload.btnIcon;
      state.btnBack = !!payload.btnBack;
      state.title = payload.title ? payload.title : null;
      state.btnBookMark = !!payload.btnBookMark;
      state.isBookMark = !!payload.isBookMark;
      state.btnSearch = !!payload.btnSearch;
      state.btnList = !!payload.btnList;
      state.status = payload.status;
      state.color = payload.color;
      state.backLink = payload.backLink ? payload.backLink : null;
    },
    showHistorySearch: (state, { payload }) => {
      state.isSearch = payload;
    },
    showNavBar: (state, { payload }) => {
      state.isShowNav = payload;
    },
    showMenu: (state, { payload }) => {
      state.isShowMenu = payload;
    },
  },
});

export const {
  changeNavBar,
  showHistorySearch,
  showNavBar,
  showMenu,
} = navBarSlice.actions;

export default navBarSlice.reducer;

export const navBarSelector = (state) => state.navBar;
