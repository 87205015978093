/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Modal } from "antd";
import TitlePage from "../../component/Desktop/titlePage";
import RowInput from "../../component/Desktop/rowInput";
import Loading from "../../component/Desktop/loading/loading";
import BreakLine from "../../component/Desktop/breakLine";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import classDdl from "../../models/control/dropdownlist";
import { showErrorMessage, showMessage } from "../../constants/utils";
import GroupItem from "../../component/Desktop/groupItem";
import { DeviceApi } from "../../api/backend/device";
import InputFile from "../../components/common/input/inputFile";
import SelectOffice from "../../components/common/input/selectOffice";

let FLAG_CHANGE_DATA = false;

function AddListDevice() {
  const history = useHistory();
  let [showLoading, setShowLoading] = useState(false);
  const [modeEdit, setModeEdit] = useState(true);
  let [ddlBaseName, setddlBaseName] = useState(new classDdl());

  // Focus
  let ddlBaseNameEl = useRef(null);

  // File
  let [tblAttach, setTblAttach] = useState([]);

  // Popup common
  const showConfirmMessage = (typeInfo = false) => {
    return;
  };

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA) {
      backToSearch();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      showConfirmMessage(COMMON_CONSTANT.WF_C018);
    }
  }

  function validateSave() {
    let flagErr = false;

    return !flagErr;
  }

  //Save function: 1
  async function confirmSaveData() {
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: async () => {
        if (validateSave()) {
          // Create Form data
          const formData = new FormData();
          // FILE
          if (tblAttach.length > 0) {
            // Add list file to FormDATA
            tblAttach.forEach((v, i) => {
              formData.append(`file[${i}]`, v.file);
            });
          }
          formData.append("data", "");

          try {
            setShowLoading(true);
            await DeviceApi.importDevice(ddlBaseName.value, formData);
            showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
            history.goBack();
          } catch (error) {
            showErrorMessage(error);
          } finally {
            setShowLoading(false);
          }
        }
      },
    });
  }

  // End: Tbl Attach

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.VITAL_DEVICE_FILE} />

      {/* Group 1 */}
      <GroupItem>
        <BreakLine />
        <RowInput box name={lang.BASE_NAME}>
          <>
            <SelectOffice
              state={ddlBaseName}
              setState={setddlBaseName}
              refer={ddlBaseNameEl}
              isNot100={true}
            />
          </>
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.FILE} class="w100">
          <InputFile
            setTblAttach={setTblAttach}
            tblAttach={tblAttach}
            modeEdit={modeEdit}
          />
        </RowInput>
        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          <Button
            disabled={tblAttach.length == 0 || !ddlBaseName.value}
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              confirmSaveData();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={cancelSaveData}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default AddListDevice;
