import Api from "@src/api";
export default {
  getCompany(params={}) {
    return Api().get("/api/Company",{
      params
    });

   
  },
  deleteCompany(id){
    return Api().delete(`/api/Company/${id}`);
  },

  searchCompany(params) {
    return Api().get(`/api/Company`, { params });
  },
  getDetailCompany(id) {
    return Api().get(`/api/Company/${id}`);
  },

  save(formData, id) {
    return id
      ? Api().put(`/api/Company/`,formData)
      : Api().post("/api/Company", formData);
  },
};
