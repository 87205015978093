// import external libs
import React, { useState } from "react";

import { Row, Col, Button, Modal, Table, Divider, Space, Input } from "antd";

import classGrid from "../../models/control/grid";

import { lang } from "../../constants/common.const";

function GroupReport(props) {
  const { textHeader, visible, handleCancel, handleOk } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  //  Grid
  const [grid, setGrid] = useState({
    ...new classGrid(),
    data: [
      {
        loginID: "user123456",
        creater: "",
        fullName: "グループリーダー",
        group: "運用説明グループ",
      },
      {
        loginID: "user789456",
        creater: "",
        fullName: "グループリーダー",
        group: "運用説明グループ",
      },
    ],
  });

  let initColumns = [
    {
      title: "ログインID",
      dataIndex: "loginID",
    },

    {
      title: "作業者コード",
      dataIndex: "creater",
    },

    {
      title: "氏名",
      dataIndex: "fullName",
    },
    {
      title: "グループ名",
      dataIndex: "group",
    },
  ];

  const onCancel = () => {
    handleCancel();
  };

  return (
    <Modal
      footer={null}
      title={"作業者を選択してください"}
      open={visible}
      maskClosable={false}
      onCancel={onCancel}
      width={1100}
      centered
    >
      <div>
        <Divider />
        <Row gutter={[12, 12]} className="rowNotMargin">
          <Col span={8}>
            作業者コード <Input />
          </Col>
          <Col span={8}>
            氏名 <Input />
          </Col>
          <Col span={8}>
            <Row className="rowNotMargin mt20" gutter={[12, 12]} justify="end">
              <Col>
                <Button
                  htmlType="submit"
                  className="buttonPC button--info wAuto"
                >
                  追加
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Divider /> */}

        <div
          className="site-layout-background"
          style={{
            margin: "24px 0px",
          }}
        >
          {/* Count */}
          <Row justify="space-between ">
            <Col span={12}></Col>
            <Col span={12} className="right">
              {/* Count */}
              <Space size={10} align="center ">
                {lang.ACCESS_NUMBERS}
                <span>
                  {grid.count}
                  {lang.CASE}
                </span>
              </Space>
            </Col>
          </Row>
          {/* Grid  */}
          <Table
            rowKey={(record) => record.id}
            rowSelection={rowSelection}
            className="gridSearch "
            columns={initColumns}
            dataSource={grid.data}
            pagination={false}
           scroll={{ x: 'max-content' }}
          />
        </div>

        <Row justify="end" className="rowNotMargin">
          {/* Button Search/Clear */}
          <Col flex="none" className="right">
            <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
              <Col>
                <Button
                  htmlType="submit"
                  className="buttonPC button--info wAuto"
                  onClick={handleCancel}
                >
                  {lang.OK}
                </Button>
              </Col>
              <Col>
                <Button
                  className="buttonPC button--outline --todo-- wAuto "
                  onClick={handleCancel}
                >
                  {lang.BACK}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default GroupReport;
