/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Modal, Row, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";

import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import {
  MSG_CATCH,
  getQuerySearch,
  objectToQueryString,
  pushObject2Query,
  saveFile,
  showMessage,
} from "../../constants/utils";

import {
  COMMON_CONSTANT,
  CONFIG,
  GATEWAY_TYPE_VALUE,
  KEY,
  PAGE_SIZE,
  lang,
} from "../../constants/common.const";

import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { GatewayApi } from "../../api/backend/gateway";
import classDdl from "../../models/control/dropdownlist";
import ContentTitle from "../../component/Desktop/contentTitle";
import SelectOffice from "../../components/common/input/selectOffice";
import { onChangeTable } from "../../utils/helpers";

function Gateway_List() {
  const history = useHistory();
  // Loading
  const [formSearch] = Form.useForm();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [initLoad, setInitLoad] = useState(true);
  const objSearch = getQuerySearch();
  const location = useLocation();
  let [ddlBaseName, setDdlBaseName] = useState({
    ...new classDdl(),
    value: objSearch.OfficeId || null,
  });
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const PAGE_LIMT = 15;

  const gatewayKey = [
    "listGateway",
    {
      page,
      sortKey,
      sortOrder,
      OfficeId: ddlBaseName.value,
    },
  ];
  // Quey List gateway,
  const listGatewayQuery = useQuery(
    gatewayKey,
    () => {
      return GatewayApi.getListgateway({
        OfficeId: ddlBaseName.value,
        sortKey,
        sortOrder,
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
      });
    },
    {
      keepPreviousData: true,
      onError: () => {
        showMessage(KEY.ERROR, MSG_CATCH());
      },
      onSettled: () => {
        setLoadingSearch(false);
      },
    }
  );
  // re-call api
  const reCallApi = (currentPage, orderName, orderBy) => {
    setPage(currentPage);
    if (orderName) {
      setSortKey(orderName);
    }
    if (orderBy) {
      setSortOrder(orderBy);
    }
  };
  // Get officeValue
  const getOfficeValue = () => {
    const op = ddlBaseName.options || [];
    const currentOffice = op.find((i) => i.key === ddlBaseName.value);
    if (currentOffice) {
      return currentOffice.value;
    }
    return null;
  };

  // Search
  const onSearch = (resetPage = false, deleetFlag = false) => {
    pushObject2Query({
      OfficeId: ddlBaseName.value,
    });
    if (resetPage && page != 1) {
      setPage(1);
    } else if (deleetFlag) {
      const countAffterDelete = listGatewayQuery.data
        ? listGatewayQuery.data.data.count - 1
        : 0;
      const maxPage = Math.ceil(countAffterDelete / PAGE_SIZE);

      if (page > maxPage) {
        setPage(maxPage);
      } else {
        listGatewayQuery.refetch();
      }
    } else {
      listGatewayQuery.refetch();
    }
  };

  // DELETE
  const handleDelete = async (id, type) => {
    try {
      const currentCount = listGatewayQuery.data.data.count - 1;
      await GatewayApi.deleteGateway(id, type);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C004);

      onSearch(null, true);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    }
  };
  // Confirm Delete
  const onDelete = (id, type) => {
    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.ECM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => handleDelete(id, type),
    });
  };

  // DOMNLOAD
  const handleDownload = async () => {
    try {
      setLoadingSearch(true);
      const res = await GatewayApi.downloadListGateway({
        OfficeId: ddlBaseName.value,
      });
      saveFile(res);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoadingSearch(false);
    }
  };
  let initMainDeviceColumns = [
    {
      title: "親機/子機種別",
      dataIndex: "gatewayType",
      sorter: true,
      align: "start",
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text, row) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ maxWidth: "500px" }}
          >
            <Col span={24}>{text}</Col>
          </Row>
        );
      },
    },
    {
      title: "親機/子機ID",
      dataIndex: "serialNumber",
      align: "start",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "拠点名",
      dataIndex: "officeName",
      align: "start",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      title: "設置場所",
      dataIndex: "locationName",
      align: "start",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "設置状態",
      dataIndex: "installationStatus",
      align: "start",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "マップ表示",
      dataIndex: "status",
      align: "start",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (value) => {
        return <span>{value === "持つ" ? "あり" : "なし"}</span>;
      },
    },
    {
      title: "設定変更",
      dataIndex: "EDIT",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            <Tooltip title={lang.CLONE}>
              <Button
                className="mr5 ml10"
                onClick={() => {
                  history.push(
                    `/gateway/edit/${row.serialNumber}?OfficeId=${
                      ddlBaseName.value
                    }&&OfficeValue=${getOfficeValue()}&type=${
                      GATEWAY_TYPE_VALUE[row.gatewayType]
                    }`
                  );
                }}
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "削除",
      align: "center",
      dataIndex: "delete",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={lang.DELETE}>
              <Button
                onClick={() => {
                  onDelete(
                    row.serialNumber,
                    GATEWAY_TYPE_VALUE[row.gatewayType]
                  );
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    onSearch(true);
    if (initLoad) {
      setInitLoad(false);
    }
  }, [ddlBaseName.value]);
  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={lang.GATEWAY_LIST} />

        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col xl={8} lg={8} span={24}>
              <div className="ant-form-item-label">{lang.BASE_NAME}</div>
              <SelectOffice
                className={"w100"}
                state={ddlBaseName}
                setState={setDdlBaseName}
                acceptSetNull={!initLoad}
              />
            </Col>
            <Col xl={16} lg={16} md={24} sm={24} xs={24}>
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push(
                        `/gateway/create/new?OfficeId=${
                          ddlBaseName.value || ""
                        }&&OfficeValue=${getOfficeValue()}`
                      );
                    }}
                  >
                    <PlusOutlined className="" />
                    {COMMON_CONSTANT.ADD}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push(
                        `/gateway/inputfile?OfficeId=${
                          ddlBaseName.value || ""
                        }&&OfficeValue=${getOfficeValue()}`
                      );
                    }}
                  >
                    <FileAddOutlined className="" />
                    {lang.FILE}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => handleDownload()}
                    className="buttonPC button--info --todo-- wAuto "
                  >
                    <DownloadOutlined />
                    {lang.DOWNLOAD}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </ContentTitle>

      <div
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}></Col>
          <Col span={12} className="right" style={{ paddingRight: "20px" }}>
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {listGatewayQuery.data ? listGatewayQuery.data.data.count : 0}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          onChange={onChangeTable(reCallApi)}
          rowKey={(record) => record.id}
          className="gridSearch "
          columns={initMainDeviceColumns}
          dataSource={
            listGatewayQuery.data ? listGatewayQuery.data.data.data : []
          }
          pagination={{
            ...CONFIG.paging,
            total: listGatewayQuery.data && listGatewayQuery.data.data.count,
            current: page || 1,
            showSizeChanger: false,
          }}
         scroll={{ x: 'max-content' }}
        />
      </div>

      <div
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
      </div>
      <Loading show={listGatewayQuery.isLoading || loadingSearch} />
    </div>
  );
}
export default Gateway_List;
