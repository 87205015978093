import Api from "@src/api";

export default {
  // no.1
  getOffice() {
    return Api().get(`api/Office`);
  },
  getGroup(params) {
    return Api().get(`api/Group`, { params });
  },
  uploadFile(formData) {
    return Api().post(`api/FileUpload/Image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  
};
