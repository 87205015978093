import {
  Button,
  Col,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";

import classGrid from "../../models/control/grid";
import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FormOutlined,
  PlusOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import UserApi from "@src/api/backend/user";
import { ApiContract } from "../../api/backend/contractList";
import {
  confirmApiModal,
  confirmApi,
  getQuerySearch,
  pushObject2Query,
  renderOption,
  saveFile,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";

import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  PAGE_SIZE,
  STYLE,
  lang,
} from "../../constants/common.const";

import { ROUTER_PAGE } from "../../constants/router.const";
import classDdl from "../../models/control/dropdownlist";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import ContentTitle from "../../component/Desktop/contentTitle";
import { onChangeTable } from "../../utils/helpers";

const { Content } = Layout;

function ListAdminContractService() {
  const [page, setPage] = useState(1);
  const history = useHistory();

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  const objSearch = getQuerySearch();
  const [ddlCompany, setDdlCompany] = useState({
    ...new classDdl(objSearch.companyId),
  });
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await ApiContract.getCompany();
        const data = response.data.data.map((item) => {
          return {
            value: item.companyName,
            key: item.companyId,
          };
        });
        setDdlCompany({
          ...ddlCompany,
          options: data,
        });
      } catch (e) {
        console.log(e);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  Grid
  const [grid, setGrid] = useState({
    ...new classGrid(),
    data: [],
  });

  // Search
  const search_onClick = async (
    currentPage = 1,
    sortKey = "",
    sortOrder = "desc",
    changeSelect = false
  ) => {
    pushObject2Query({ companyId: ddlCompany.value });
    if (changeSelect) {
      setPage(1);
    } else {
      setPage(currentPage);
    }
    try {
      const result = await ApiContract.getAdminContract({
        companyId: ddlCompany.value,
        role: RoleHeadOffice.ADMIN_UNIT_CONTRACT,
        offset: changeSelect ? 0 : (currentPage - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        sortKey,
        sortOrder,
      });
      const data = result.data;

      setGrid({ ...grid, data: data.data, count: data.count });
    } catch (e) {
      setGrid({ ...grid, data: [] });
    }
  };
  useEffect(() => {
    search_onClick(null, null, null, true);
  }, [ddlCompany.value]);

  // Delete
  const handleDeleteContractAdmin = (id) => {
    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.ECM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: async () => {
        try {
          setShowLoading(true);
          await UserApi.deleteUSer(id);
          showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C004);
          search_onClick();
        } catch (error) {
          showErrorMessage(error);
        } finally {
          setShowLoading(false);
        }
      },
    });
  };
  let initColumns = [
    {
      title: "ログインID",
      dataIndex: "userName",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      title: "契約ユニットリーダー",
      dataIndex: "fullName",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "会社名",
      dataIndex: "companyName",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "ロック解除",
      dataIndex: "unlock",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {row.isLockedOut && (
              <Button
                className="mr5 ml10"
                style={{ backgroundColor: "green", color: "#fff" }}
                onClick={() => {
                  confirmApiModal(
                    UserApi.unLockUser(row.userId),
                    search_onClick,
                    lang.CONFIRM,
                    lang.CONFIRM_UNLOCK,
                    lang.UNLOCK_SUCCESS
                  );
                }}
              >
                <UnlockOutlined />
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: "設定変更",
      dataIndex: "userId",
      align: "center",
      render: (_, row) => {
        //

        return (
          <>
            <Tooltip title={"設定変更"}>
              <Button
                className="mr5 ml10"
                onClick={() =>
                  history.push(
                    ROUTER_PAGE.ADMIN_CONTRACT_UNIT_EDIT.replace(
                      ":id",
                      row.userId
                    )
                  )
                }
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "削除",
      dataIndex: "delete",
      align: "center",
      render: (_, row) => {
        //

        return (
          <>
            {/* Delete */}
            <Tooltip title={"削除"}>
              <Button
                onClick={() => {
                  handleDeleteContractAdmin(row.userId);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // DOMNLOAD
  const handleDownload = async () => {
    try {
      setShowLoading(true);
      const res = await UserApi.downloadListUSer({
        Role: RoleHeadOffice.ADMIN_UNIT_CONTRACT,
        companyId: ddlCompany.value,
      });
      saveFile(res);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={lang.ADMIN_UNIT_CONTRACT_LIST} />
        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col xl={8} span={24}>
              <div className="ant-form-item-label">{lang.COMPANY}</div>
              <Select
                showSearch
                optionFilterProp="children"
                value={ddlCompany.value}
                className={
                  ddlCompany.error ? `${STYLE.BORDER_RED} w100` : "w100"
                }
                onChange={(value, option) => {
                  setDdlCompany({
                    ...ddlCompany,
                    value,
                  });
                }}
              >
                {renderOption(ddlCompany.options)}
              </Select>
            </Col>
            <Col xl={16} lg={24} md={24} sm={24} xs={24}>
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push(ROUTER_PAGE.ADMIN_CONTRACT_UNIT_ADD);
                    }}
                  >
                    <PlusOutlined className="" />
                    {COMMON_CONSTANT.ADD}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push(ROUTER_PAGE.ADMIN_CONTRACT_UNIT_INPUTFILE);
                    }}
                  >
                    <FileAddOutlined className="" />
                    {lang.FILE}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => handleDownload()}
                    className="buttonPC button--info --todo-- wAuto "
                  >
                    <DownloadOutlined />
                    {lang.DOWNLOAD}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </ContentTitle>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}>
            {/* <Button type="link" className="btnAddRecord mb15">
                <AddIcon />
                {lang.REQUEST_CREATE}
              </Button> */}
          </Col>
          <Col span={12} className="right" style={{ paddingRight: "20px" }}>
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.userId}
          className="gridSearch "
          columns={initColumns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,
            total: grid.count,
            current: page || 1,
            showSizeChanger: false,
          }}
         scroll={{ x: 'max-content' }}
          onChange={onChangeTable(search_onClick)}
        />
      </Content>
      <Loading show={showLoading} />
    </div>
  );
}

export default ListAdminContractService;
