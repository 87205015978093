/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import UserApi from "../../api/backend/user";
import BreakLine from "../../component/Desktop/breakLine";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput";
import TitlePage from "../../component/Desktop/titlePage";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import { RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import GroupItem from "../../component/Desktop/groupItem";
import { showErrorMessage, showMessage } from "../../constants/utils";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import InputFile from "../../components/common/input/inputFile";

let FLAG_CHANGE_DATA = false;

function InputFileAdminUnitService() {
  const history = useHistory();
  let [initPage, setInitPage] = useState(true);
  let [showLoading, setShowLoading] = useState(false);

  const [modeEdit, setModeEdit] = useState(true);

  // Focus
  let txtBasenameEl = useRef(null);
  let txtSerialNumberEl = useRef(null);
  let ddlHeatTypeEl = useRef(null);

  // File
  let [tblAttach, setTblAttach] = useState([]);

  // Change data
  useEffect(() => {
    if (!initPage) {
      FLAG_CHANGE_DATA = true;
    }
  }, [txtBasenameEl, txtSerialNumberEl, ddlHeatTypeEl]);

  // Popup common
  const showConfirmMessage = (typeInfo = false) => {
    return;
  };

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA) {
      backToSearch();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      showConfirmMessage(COMMON_CONSTANT.WF_C018);
    }
  }

  const postData = async () => {
    try {
      const formData = new FormData();
      const fileUp = tblAttach[0]["file"];
      formData.append("file", fileUp);
      setShowLoading(true);
      await UserApi.importUser(RoleHeadOffice.ADMIN_UNIT_SERVICE, formData);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
      history.goBack();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
    }
  };
  // Confirm
  const confirmSaveData = () => {
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };

  return (
    <div className="des-content">
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.ADMIN_UNIT_SERVICE_INPUTFILE} />

      {/* Group 1 */}
      <GroupItem>
        <BreakLine />
        {/* <RowInput box name={lang.BASE_NAME}>
          {modeEdit ? (
            <>
              <Input
                value={txtBasename.value}
                ref={txtBasenameEl}
                disabled
                className={
                  txtBasename.error ? `${STYLE.BORDER_RED} w400px` : "w400px"
                }
                maxLength={255}
                onChange={(e) =>
                  settxtBasename({
                    ...txtBasename,
                    value: e.target.value,
                  })
                }
              ></Input>
            </>
          ) : (
            <>
              <div className="white-space"> {txtBasename.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine /> */}

        <RowInput box name={lang.FILE} class="w100">
          <InputFile
            setTblAttach={setTblAttach}
            tblAttach={tblAttach}
            modeEdit={modeEdit}
          />
        </RowInput>
        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          <Button
            disabled={tblAttach.length === 0}
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              confirmSaveData();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={cancelSaveData}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default InputFileAdminUnitService;
