import Api from "@src/api";
export const HeatWatcherApi = {
  getListHeatWatcher(params) {
    return Api().get("/api/HeatIndexWatcher", { params });
  },
  getDetaiHeatWatcher(id) {
    return Api().get(`/api/HeatIndexWatcher/${id}`);
  },
  deleteHeatWatcher(id) {
    return Api().delete(`/api/HeatIndexWatcher/${id}`);
  },
  // 2: Create/Update Devide
  save(formData, id) {
    return id
      ? Api().put(`/api/HeatIndexWatcher`, formData, {})
      : Api().post("/api/HeatIndexWatcher", formData, {});
  },
  importHeatWatcher(officeId, formData) {
    return Api().post(`/api/HeatIndexWatcher/Import/${officeId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  downloadHeatWatcher(params = {}) {
    return Api().get(`/api/HeatIndexWatcher/Download`, {
      responseType: "blob",
      params,
    });
  },
  setHeatWatcherToMap(formData) {
    return Api().put("/api/HeatIndexWatcher/SetSismilToMap", formData, {});
  },
};
