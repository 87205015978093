/* eslint-disable react-hooks/exhaustive-deps */
import { HeartFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Segmented,
  Select,
  Space,
  Table,
} from "antd";
import { Sun1 } from "iconsax-react";

import React, { useEffect, useState } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";

import classGrid from "../../models/control/grid";

import dayjs from "dayjs";
import { GoAlertFill } from "react-icons/go";

import ConditionChart from "@src/component/chartjs/ConditionChart";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  COMMON_CONSTANT,
  CONFIG,
  REQUEST_PERIOD,
  lang,
} from "../../constants/common.const";
import { getQuerySearch, renderOption } from "../../constants/utils";
import PhysicalConditionApi from "./../../api/backend/physicalCondition";
import UserApi from "./../../api/backend/user";

import ContentTitle from "../../component/Desktop/contentTitle";
import classDdl from "../../models/control/dropdownlist";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import StatusByCode from "../../component/Icon/alertIcon";

const { Option } = Select;

function PhysicalCondition_detail() {
  const { timeAlert, dayAlert } = getQuerySearch();
  const getPeriod = () => {
    let period = 0;
    if (dayAlert) {
      const specificDate = dayjs(dayAlert);

      // Kiểm tra xem ngày có hợp lệ không
      if (!specificDate.isValid()) {
        return REQUEST_PERIOD.TODAY;
      } else {
        // Lấy ngày hiện tại
        const today = dayjs();

        // So sánh số ngày giữa hai ngày
        const differenceInDays = today.diff(specificDate, "day");
        if (differenceInDays == 0) {
          period = REQUEST_PERIOD.TODAY;
        } else if (differenceInDays == 1) {
          period = REQUEST_PERIOD.YESTERDAY;
        } else {
          period = REQUEST_PERIOD.TWO_DAY_BEFORE;
        }
      }
      return period;
    }
    return REQUEST_PERIOD.TODAY;
  };
  const { OfficeName, GroupName, UserId } = getQuerySearch();
  const [officeNameApi, setOfficeNameApi] = useState("");
  const [groupNameApi, setGroupNameApi] = useState("");
  const [ddlUser, setDddlUser] = useState(new classDdl());
  const history = useHistory();
  const {
    userId: userIdInfo,
    groupName: groupIdInfo,
    officeName: officeIdInfo,
    officeId,
    groupId,
    roleKey,
  } = useSelector((state) => state.auth);
  const isUser = roleKey === RoleHeadOffice.USER;
  const isGroupLeader =
    roleKey === RoleHeadOffice.GROUP_LEADER ||
    roleKey === RoleHeadOffice.GROUP_SUB_LEADER;
  const currentOfifice =
    isUser || isGroupLeader ? officeIdInfo : OfficeName || officeNameApi;
  const currentGroup =
    isUser || isGroupLeader ? groupIdInfo : GroupName || groupNameApi;
  let userParamsApi = null;
  if (isUser) {
    userParamsApi = userIdInfo;
  } else if (isGroupLeader) {
    userParamsApi = ddlUser.value;
  } else {
    userParamsApi = UserId;
  }
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  const [fullName, setFullName] = useState("");
  const [link1, setLink1] = useState("");
  const [Conjunction, setConjunction] = useState("");
  const [leadername, setLeadername] = useState("");
  const [groupName, setGroupName] = useState("");
  const [leaderPhone, setLeaderPhone] = useState("");
  const [period, setPeriod] = useState(getPeriod());
  const [ddlTimePeriod, setddlTimePeriod] = useState({
    ...new classDdl(),
    value: 0,
  });
  const [heartRateData, setHeartrateData] = useState([]);
  const [wbgtData, setWbgtData] = useState([]);
  const [emailAlertData, setEmailAlertData] = useState([]);
  const [labels, setLabel] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [date, setDate] = useState(dayjs().format("YYYY/MM/DD"));
  //  Grid
  const [grid, setGrid] = useState({
    ...new classGrid(),
  });
  // Input search
  // 1

  // init house Select
  // Get list User
  const getListUser = async () => {
    const res = await UserApi.getListUserRole({
      officeId,
      groupId,
      worker: true,
    });
    const listOptionUser = res.data.data.map((i) => ({
      key: i.userId,
      value: i.fullName,
    }));
    setDddlUser({
      ...ddlUser,
      options: listOptionUser,
      value: UserId || (listOptionUser.length ? listOptionUser[0].key : null),
    });
  };
  useEffect(() => {
    if (isGroupLeader) {
      getListUser();
    }
  }, [roleKey]);

  useEffect(() => {
    if (isGroupLeader) {
      search_onClick();
    }
  }, [ddlUser.value]);
  // Search
  useEffect(() => {
    search_onClick();
  }, [period]);

  function createChartData(data) {
    setLabel(
      data.map((i) => {
        return i.time;
      })
    );
    setHeartrateData(data.map((i) => i.heartRate));
    setWbgtData(data.map((i) => i.wbgtValue));
    setEmailAlertData(
      data.map((i) => (i.alertMail == 1 ? i.heartRate + 20 : null))
    );
  }

  // Search
  useEffect(() => {
    if (dataChart.length > 0 && ddlTimePeriod.value != null) {
      let dataChartWithTimeLocal = JSON.parse(JSON.stringify(dataChart));
      dataChartWithTimeLocal = dataChartWithTimeLocal.map((item) => ({
        ...item,
        time: dayjs.utc(item.time).local().format("HH:mm"),
      }));
      const dataShowChart = dataChartWithTimeLocal.filter((item) => {
        const hour = item.time.split(":")[0];
        return Number(hour) == ddlTimePeriod.value;
      });
      createChartData(dataShowChart);
    } else {
      setLabel([]);
      setHeartrateData([]);
      setWbgtData([]);
      setEmailAlertData([]);
    }
  }, [dataChart, ddlTimePeriod.value]);

  // INIT DAY
  useEffect(() => {
    let period = 0;
    if (dayAlert) {
      const period = getPeriod();
      setDate(dayjs().subtract(period, "day").format("YYYY/MM/DD"));
    }
  }, [dayAlert]);

  // Search
  const search_onClick = async () => {
    try {
      setShowLoading(true);
      setDataChart([]);

      const data = await PhysicalConditionApi.getDetailHealthCondition(
        userParamsApi,
        // "e8e1d33e-1870-4100-bbc0-e8c936c7bdd3",
        period
      );

      setDataChart(data.data.listData);

      setGrid({
        ...grid,
        data: data.data.listAlert.reverse(),
        count: data.data.listAlert.length,
      });
      setFullName(data.data.fullName);
      setLink1(data.data.affiliation1);
      // setConjunction
      setLeadername(data.data.leaderName);
      setGroupName(data.data.groupName);
      setLeaderPhone(data.data.leaderContact);
      setOfficeNameApi(data.data.officeName);
      setGroupNameApi(data.data.groupName);
    } catch (error) {
      // showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  let initColumns = [
    {
      title: "発生日時",
      dataIndex: "time",
      align: "start",
      render: (value) => {
        return dayjs.utc(value).local().format("YYYY/MM/DD HH:mm");
      },
    },
    {
      title: "緊急",
      dataIndex: "emergency",
      align: "center",
      render: (_, row) => {
        return row.emergency == 1 ? (
          <div className="row-data-icon">
            <GoAlertFill style={{ color: "red", fontSize: 20 }} />
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      title: "総合アラート",
      dataIndex: "generalAlert",
      align: "center",
      render: (_, row) => {
        return (
          <div className="row-data-icon">
            <StatusByCode statusCode={row.alertLevel} />
          </div>
        );
      },
    },

    {
      title: "心拍数",
      align: "start",
      dataIndex: "heartRate",
      render: (value) => {
        return (
          <div className="row-data-icon-text">
            {/* <FaFaceSadTear style={{ color: "red", fontSize: 20 }} /> */}
            <HeartFilled style={{ color: "red" }} />
            <div className="ml4"> {value}</div>
          </div>
        );
      },
    },
    {
      title: "WBGT超過度",
      dataIndex: "wbgtValue",
      align: "start",
      render: (value) => {
        return value ? (
          <div className="row-data-icon-text">
            {/* <FaFaceSadTear style={{ color: "red", fontSize: 20 }} /> */}
            <Sun1 size="20" color="#FF8A65" variant="Bold" />
            <div className="ml4"> {value}</div>
          </div>
        ) : (
          <></>
        );
      },
    },
  ];

  // Set CurrentTime
  useEffect(() => {
    let hour = 0;
    if (
      timeAlert !== "" &&
      !isNaN(timeAlert) &&
      Number(timeAlert) >= 0 &&
      Number(timeAlert) <= 24
    ) {
      hour = timeAlert;
    } else {
      var now = new Date();
      hour = now.getHours();
    }

    setddlTimePeriod({ ...ddlTimePeriod, value: Number(hour) });
  }, [timeAlert]);
  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={lang.PHYSICAL_CONDITION_DETAIL} />

        <div className="site-layout-background">
          <Form
            layout={"vertical"}
            name="searchWR"
            form={formSearch}
            onFinish={search_onClick}
            className="formStyle"
          >
            {/* 1 */}
            <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
              <Col lg={8} span={24}>
                <div className="ant-form-item-label">{lang.BASE_NAME}</div>
                <Input value={currentOfifice} disabled></Input>
              </Col>
              <Col lg={8} span={24}>
                <div className="ant-form-item-label">
                  {COMMON_CONSTANT.GROUP_NAME}
                </div>
                <Input value={currentGroup} disabled />
              </Col>
              {isGroupLeader && (
                <Col lg={8} span={24}>
                  <div className="ant-form-item-label">{"氏名"}</div>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    value={ddlUser.value}
                    onChange={(v) => setDddlUser({ ...ddlUser, value: v })}
                    className="w100"
                  >
                    {renderOption(ddlUser.options, false)}
                  </Select>
                </Col>
              )}
              {!isUser && !isGroupLeader && (
                <Col lg={8} span={24}>
                  <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                    <Col>
                      <Button
                        className="buttonPC button--outline --todo-- wAuto"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        {/* <RollbackOutlined className="" /> */}
                        戻る
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={16}>
                <Row
                  className="rowNotMargin mt10"
                  gutter={[12, 12]}
                  justify="start"
                >
                  <Col span={8}>氏名：{fullName} </Col>
                  <Col span={8}>連格先：{Conjunction}</Col>
                  <Col span={8}></Col>
                  <Col span={8}>リーダー名：{leadername}</Col>
                  <Col span={8}>グループ名：{groupName}</Col>
                  <Col span={8}>リーダー連絡先：{leaderPhone}</Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </ContentTitle>

      <div className="site-layout-background">
        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col span={4}></Col>
            <Col span={5}></Col>
            {/* Button Search/Clear */}
            <Col span={15} className="right">
              <Segmented
                value={period}
                onChange={(v) => {
                  setddlTimePeriod({
                    ...ddlTimePeriod,
                    value: 0,
                  });
                  setPeriod(v);
                  setDate(dayjs().subtract(v, "day").format("YYYY/MM/DD"));
                }}
                size="large"
                it
                options={[
                  {
                    label: "今日",
                    value: REQUEST_PERIOD.TODAY,
                  },
                  {
                    label: "昨日",
                    value: REQUEST_PERIOD.YESTERDAY,
                  },
                  {
                    label: "一昨日",
                    value: REQUEST_PERIOD.TWO_DAY_BEFORE,
                  },
                  // {
                  //   label: "2日間",
                  //   value: REQUEST_PERIOD.THREE_DAY_BEFORE,
                  // },
                  // {
                  //   label: " 3日間",
                  //   value: REQUEST_PERIOD.FOUR_DAY_BEFORE,
                  // },
                ]}
              />
            </Col>

            <Col span={24} className=" right">
              <span style={{ fontWeight: "bold" }}>時</span>{" "}
              <Select
                showSearch
                optionFilterProp="children"
                value={ddlTimePeriod.value}
                onChange={(value) => {
                  setddlTimePeriod({
                    ...ddlTimePeriod,
                    value,
                  });
                }}
              >
                {new Array(24).fill(1).map((item, index) => {
                  return (
                    <Option value={index} key={index}>
                      {index < 10 ? "0" : ""}
                      {index} - {index < 9 ? "0" : ""}
                      {index + 1}時
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Form>
      </div>

      <div className="des-content role-setting">
        <Row className="rowNotMargin" gutter={[12, 12]}>
          <Col span={2}></Col>
          <Col span={20}>
            <Row>
              <Col span={24}>
                <ConditionChart
                  heartRateData={heartRateData}
                  wbgtData={wbgtData}
                  emailAlertData={emailAlertData}
                  date={date}
                  labels={labels}
                />
              </Col>
              <Col span={24} className="mt10 center">
                時刻(時) <br /> {date}
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
      </div>

      <Divider />

      <div
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}></Col>
          <Col span={12} className="right">
            {/* Count */}
            <Space size={10} align="center " style={{ paddingRight: "20px" }}>
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.id}
          className="gridSearch "
          columns={initColumns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,
            showSizeChanger: false,
          }}
          scroll={{ x: "max-content" }}
        />
      </div>
      <Loading show={showLoading} />
    </div>
  );
}
export default PhysicalCondition_detail;
