import { updateAllowRoute } from "@src/redux/auth";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { KEY, LOCALSTORAGE_KEY } from "../constants/common.const";
import Login from "../page/login/Login";
import CONFIG_ROUTER from "./roles/configRoles";
import useAuth from "../hooks/useAuth";
import { RoleHeadOffice } from "./roles/roles.const";

function PrivateRouter() {
  const isDesktopOrLaptop = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const { listMenuCode } = useSelector((state) => state.sideBar);
  const { roleKey, firstPath, safetyBeltDisplaySetting } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [allowedRoutes, updateAllowedRoutes] = React.useState([]);
  const isLoginInStore = localStorage.getItem(LOCALSTORAGE_KEY.IS_LOGIN);
  const ecm_isLoggedIn = isLoginInStore && isLoginInStore === "true";

  useEffect(() => {
    if (!location.pathname.includes("login") && ecm_isLoggedIn != "true") {
      localStorage.setItem(
        LOCALSTORAGE_KEY.REDIRECT_PATH,
        location.pathname + location.search
      );
    }
  }, [location.pathname]);
  React.useEffect(() => {
    const array = [];
    CONFIG_ROUTER.forEach((item) => {
      if (item.component) {
        if (item.children) {
          item.children.forEach((child) => {
            if (
              !Array.isArray(child.roleHides) ||
              !child.roleHides.includes(roleKey) ||
              (Array.isArray(child.roleHidesSidebar) &&
                child.roleHidesSidebar.includes(roleKey))
            )
              array.push(child);
          });
        } else {
          if (
            item.key === "SAFETY_BELT_LIST" &&
            roleKey === RoleHeadOffice.ADMIN_HEADQUATER &&
            safetyBeltDisplaySetting != "1"
          ) {
            return;
          }
          if (
            !Array.isArray(item.roleHides) ||
            !item.roleHides.includes(roleKey) ||
            (Array.isArray(item.roleHidesSidebar) &&
              item.roleHidesSidebar.includes(roleKey))
          )
            array.push(item);
        }
      }
    });
    updateAllowedRoutes(array);
    dispatch(updateAllowRoute(array));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listMenuCode, roleKey, safetyBeltDisplaySetting]);
  return allowedRoutes.length > 0 ? (
    <Switch>
      <Route
        exact={true}
        path={"/login"}
        render={(props) =>
          !ecm_isLoggedIn ? <Login {...props} /> : <Redirect to={firstPath} />
        }
      ></Route>
      {allowedRoutes.map((route) => {
        const { path, component: Component } = route;
        return (
          <Route
            exact={true}
            key={path}
            path={path}
            render={(props) =>
              ecm_isLoggedIn ? (
                <Component {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          ></Route>
        );
      })}
      {/* Error */}
      <Route path="/">
        {ecm_isLoggedIn ? (
          <Redirect to={firstPath} />
        ) : (
          <Redirect to="/login" />
        )}
      </Route>
    </Switch>
  ) : (
    <Switch></Switch>
  );
}

export default PrivateRouter;
