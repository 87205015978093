/* eslint-disable react-hooks/exhaustive-deps */
import { HeartFilled } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Layout,
  Modal,
  Row,
  Space,
  Table,
} from "antd";
import React, { useEffect, useRef, useState } from "react";

import TitlePage from "../../component/Desktop/titlePage";

import classGrid from "../../models/control/grid";

import { GoAlertFill } from "react-icons/go";
import { Link } from "react-router-dom";
import { Sun1 } from "iconsax-react";

import {
  getQuerySearch,
  pushObject2Query,
  showMessage,
} from "../../constants/utils";

import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  PAGE_SIZE,
  lang,
} from "../../constants/common.const";

import dayjs from "dayjs";

import API from "@src/api/backend/physicalCondition";
import { settingApi } from "../../api/backend/setting";
import AlertInfomation from "../../component/Desktop/alertInfomation";
import ContentTitle from "../../component/Desktop/contentTitle";
import StatusByCode from "../../component/Icon/alertIcon";
import BatteryIcon from "../../components/common/custom-icon/batteryIcon";
import WifiIcon from "../../components/common/custom-icon/wifiIcon";
import SelectGroup from "../../components/common/input/selectGroup";
import SelectOffice from "../../components/common/input/selectOffice";
import classDdl from "../../models/control/dropdownlist";
import { onChangeTable } from "../../utils/helpers";

const columnPhysic = [
  {
    key: "EMERGENCY",
    title: "緊急",
    dataIndex: "emergency",
    align: "center",
    render: (value, row) => {
      if (value == "1") {
        return (
          <div className="row-data-icon">
            <GoAlertFill style={{ color: "red", fontSize: 20 }} />
          </div>
        );
      }
    },
  },
  {
    key: "GENERAL_ALERT",
    title: "総合アラート",
    dataIndex: "alertLevel",
    sorter: true,
    align: "center",
    render: (value, row) => {
      if (row.hideData) {
        return (
          <div className="row-data-icon">
            <StatusByCode statusCode={null} />
          </div>
        );
      } else {
        return (
          <div className="row-data-icon">
            <StatusByCode statusCode={value} />
          </div>
        );
      }
    },
  },
  {
    key: "FULL_NAME",
    sorter: true,
    title: "氏名",
    align: "start",
    dataIndex: "fullName",
    render: (value, row) => {
      return (
        <Row
          justify="space-between"
          align="middle"
          style={{ maxWidth: "500px" }}
        >
          <Col span={24}>
            <Link
              to={`/physical-condition/detail?UserId=${
                row.userId || ""
              }&OfficeName=${row.baseName || ""}&GroupName=${
                row.groupName || ""
              }`}
              className="white-space"
            >
              {value}
            </Link>
          </Col>
        </Row>
      );
    },
  },

  {
    key: "AGE",
    title: "年齢",
    dataIndex: "age",
    align: "start",
    sorter: true,
  },
  {
    key: "BASE_NAME",
    title: "拠点名",
    dataIndex: "baseName",
    align: "start",
    sorter: true,
  },
  {
    key: "GROUP",
    title: "グループ",
    dataIndex: "groupName",
    align: "start",
    sorter: true,
  },
  {
    key: "HEART_BEATS",
    title: "心拍数",
    align: "start",
    sorter: true,
    dataIndex: "heartRate",
    render: (value, row) => {
      return row.alertLevel != null ? (
        <div className="row-data-icon-text">
          {/* <FaFaceFrown style={{ color: "red", fontSize: 20 }} /> */}
          <HeartFilled style={{ color: "red" }} />
          <div className="ml10"> {value}</div>
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "WBGT_EXCEEDS",
    title: "WBGT超過度",
    dataIndex: "excess",
    sorter: true,
    align: "start",
    render: (value) => {
      return value ? (
        <div className="row-data-icon-text">
          {/* <FaFaceFrown style={{ color: "red", fontSize: 20 }} /> */}
          <Sun1 size="20" color="#FF8A65" variant="Bold" />
          <div className="ml10"> {value}</div>
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "WBGT_VALUE",
    title: "WBGT値",
    dataIndex: "wbgtValue",
    align: "start",
    sorter: true,
    render: (value) => {
      return value ? (
        <div className="row-data-icon-text">
          {/* <FaFaceFrown style={{ color: "red", fontSize: 20 }} /> */}
          <Sun1 size="20" color="#FF8A65" variant="Bold" />
          <div className="ml10"> {value}</div>
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "WBGT_MEASUREMENT_LOCATION",
    title: "WBGT測定地点",
    dataIndex: "wbgtMeasurementPoint",
    align: "start",
    sorter: true,
    render: (value) => {
      return value ? (
        <div className="row-data-icon-text">
          {/* <FaFaceFrown style={{ color: "red", fontSize: 20 }} /> */}
          <Sun1 size="20" color="#FF8A65" variant="Bold" />
          <div className="ml10"> {value}</div>
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "BATTERY",
    title: "バッテリ",
    align: "center",
    sorter: true,
    dataIndex: "bateryLevel",
    render: (_, row) => {
      return row.alertLevel != null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BatteryIcon level={row.bateryLevel} />
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "RADIO_FIELD_STRENGTH",
    title: "電波強度",
    align: "center",
    sorter: true,
    dataIndex: "waveLevel",
    render: (_, row) => {
      return row.alertLevel != null ? (
        <div>
          <WifiIcon level={row.waveLevel} />
        </div>
      ) : (
        <></>
      );
    },
  },
  {
    key: "LOCATION_INTELLIGENCE",
    title: "位置情報",
    align: "start",
    dataIndex: "locationName",
    sorter: true,
  },
  {
    key: "ELAPSED_TIME",
    title: "経過時間",
    align: "start",
    dataIndex: "alertTime",
    sorter: true,

    render: (value, row) => {
      return row.alertLevel != null ? dayjs.utc(value).fromNow() : "";
    },
  },
];

const { Content } = Layout;
let isHideUnknowAlert = true;
function PhysicalCondition_list() {
  const [page, setPage] = useState(1);
  const objSearch = getQuerySearch();

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  const [columns, setColumns] = useState([]);
  const [initLoad, setInitLoad] = useState(true);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  //  Grid
  const [grid, setGrid] = useState({
    ...new classGrid(),
    data: [],
  });
  // Input search
  // 1

  const [ddlOffice, setDdlOffice] = useState(
    new classDdl(objSearch.OfficeId || null)
  );
  const [ddlGroup, setddlGroup] = useState(
    new classDdl(objSearch.GroupId || null)
  );

  const officeRef = useRef(ddlOffice.value);
  const groupRef = useRef(ddlGroup.value);
  const [run, setRun] = useState(
    objSearch.isAutoUpdate
      ? objSearch.isAutoUpdate === "true"
        ? true
        : false
      : false
  );

  const [hideUnknowAlert, setHideUnknowAlert] = useState(
    Boolean(objSearch.isHide === "false" ? false : true)
  );
  // Step2: Sync input <-> url
  const objectPushSearch = {
    OfficeId: ddlOffice.value,
    GroupId: ddlGroup.value,
    isAutoUpdate: run,
    isHide: hideUnknowAlert,
  };
  // Search
  const search_onClick = async (
    currentPage = 1,
    sortKey = "",
    sortOrder = "desc",
    isAuto
  ) => {
    try {
      setPage(currentPage);
      setSortKey(sortKey);
      setSortOrder(sortOrder);
      const params = {
        OfficeId: isAuto ? officeRef.current : ddlOffice.value,
        GroupId: isAuto ? groupRef.current : ddlGroup.value,
        offset: (currentPage - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        sortKey,
        sortOrder,
      };
      if (isHideUnknowAlert) {
        params.healthData = 1;
      }
      const result = await API.search(params);
      const data = result.data;
      data.data = data.data.filter((item) =>
        isHideUnknowAlert
          ? item.alertLevel != null && item.alertLevel != undefined
          : true
      );
      data.count = result.data.count;
      setGrid(data);
    } catch (e) {
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    isHideUnknowAlert = Boolean(objSearch.isHide === "false" ? false : true);
  }, []);

  useEffect(() => {
    search_onClick(1, sortKey, sortOrder);
    officeRef.current = ddlOffice.value;
    groupRef.current = ddlGroup.value;
  }, [ddlOffice.value, ddlGroup.value, hideUnknowAlert]);

  useEffect(() => {
    let intervalId;
    if (run) {
      // Set the interval only when run is true
      intervalId = setInterval(async () => {
        await search_onClick(1, sortKey, sortOrder, true);
      }, 60000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [run]);
  // set Column Display
  const getColumnDisplay = async () => {
    const res = await settingApi.getOfficeSetting(ddlOffice.value, {
      type: "PHYSICAL_CONDITION_LIST_DISPLAY_ITEM_SETTING",
    });
    const currentColumn = columnPhysic.filter((item) => {
      const key = item.key;
      if (!key) {
        return true;
      }
      const currItem = res.data.find((i) => i.key === key);
      if (!currItem) {
        return false;
      }
      return currItem.value === "1";
    });
    setColumns(currentColumn);
  };
  useEffect(() => {
    if (ddlOffice.value) {
      getColumnDisplay();
    } else {
      setColumns(columnPhysic);
    }
  }, [ddlOffice.value]);
  useEffect(() => {
    pushObject2Query(objectPushSearch);
    if (initLoad) {
      setInitLoad(false);
    }
  }, [run, hideUnknowAlert, ddlGroup.value, ddlOffice.value]);
  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={lang.PHYSICAL_CONDITION_LIST} />

        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          onFinish={search_onClick}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={6} xs={24}>
              <div className="ant-form-item-label">{lang.BASE_NAME}</div>
              <SelectOffice
                className={"w100"}
                acceptSetNull={!initLoad}
                state={ddlOffice}
                setState={setDdlOffice}
              />
            </Col>
            <Col lg={6} xs={24}>
              <div className="ant-form-item-label">{"グループ名"}</div>
              <SelectGroup
                className={"w100"}
                state={ddlGroup}
                setState={setddlGroup}
                officeId={ddlOffice.value}
                acceptSetNull={!initLoad}
              />
            </Col>
            <Col lg={12} xs={24}>
              <Row
                align={"middle"}
                className="rowNotMargin"
                gutter={[8, 8]}
                justify="end"
              >
                <Col>
                  <Checkbox checked={run} onChange={() => setRun(!run)}>
                    {lang.AUTOMATIC_UPDATE}
                  </Checkbox>
                </Col>
                <Col>
                  <Checkbox
                    checked={hideUnknowAlert}
                    onChange={(e) => {
                      isHideUnknowAlert = e.target.checked;
                      setHideUnknowAlert(isHideUnknowAlert);
                    }}
                  >
                    {lang.HIDE_WORKERS}
                  </Checkbox>
                </Col>

                <Col>
                  <Button
                    onClick={() => search_onClick(page, sortKey, sortOrder)}
                    className="buttonPC button--info wAuto"
                  >
                    {lang.UPDATE_DISPLAY}
                  </Button>
                </Col>
                {/* <Col>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto "
                    // onClick={clearInputSearch}
                    onClick={() => {
                      history.push(ROUTER_PAGE.MAP_LIST);
                    }}
                  >
                    {lang.MAP_LIST}
                  </Button>
                </Col> */}
              </Row>
            </Col>
            <Col span={24}>
              <AlertInfomation />
            </Col>
          </Row>
        </Form>
      </ContentTitle>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}></Col>
          <Col span={12} className="right" style={{ paddingRight: "20px" }}>
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.id}
          className="gridSearch "
          columns={columns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,
            total: grid.count,
            current: page || 1,
            showSizeChanger: false,
          }}
          scroll={{ x: "max-content" }}
          onChange={onChangeTable(search_onClick)}
        />
      </Content>
    </div>
  );
}
export default PhysicalCondition_list;
