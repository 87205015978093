import { Input, Select } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { STYLE } from "../../../constants/common.const";
import { renderOption } from "../../../constants/utils";
import { RoleHeadOffice } from "../../../routers/roles/roles.const";
import CompanyApi from "./../../../api/backend/company";
import { widthInput } from "../../../utils/helpers";
const SelectCompany = ({
  state,
  setState,
  selectFirst,
  acceptSetNull = true,
  className = "",
  isNot100,
}) => {
  const { roleKey, companyId, companyName } = useSelector(
    (state) => state.auth
  );
  const isSingleCompany = [
    RoleHeadOffice.ADMIN_HEADQUATER,
    RoleHeadOffice.USER,
    RoleHeadOffice.GROUP_LEADER,
    RoleHeadOffice.ADMIN_UNIT_CONTRACT,
    RoleHeadOffice.GROUP_SUB_LEADER,
  ].includes(roleKey);
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await CompanyApi.searchCompany();
        const data = response.data.data.map((item) => {
          return {
            value: item.companyName,
            key: item.companyId,
          };
        });
        setState({
          ...state,
          options: data,
          value:
            selectFirst && data.length > 0
              ? data[0].key
              : acceptSetNull
              ? null
              : state.value,
        });
      } catch (e) {
        console.log(e);
      }
    };

    if (!isSingleCompany) {
      fetch();
    }
  }, [isSingleCompany]);

  useEffect(() => {
    if (isSingleCompany) {
      setState({ ...state, value: companyId });
    }
  }, [isSingleCompany]);

  return isSingleCompany ? (
    <Input
      className={`${!isNot100 ? "w100" : ""} ${widthInput}`}
      value={companyName}
      disabled
    />
  ) : (
    <Select
      showSearch
      optionFilterProp="children"
      value={state.options.length ? state.value : ""}
      className={
        state.error
          ? `${STYLE.BORDER_RED} ${widthInput} ${className}`
          : `${widthInput} ${className}`
      }
      onChange={(value) => {
        setState({
          ...state,
          value,
        });
      }}
    >
      {renderOption(state.options)}
    </Select>
  );
};
export default SelectCompany;
