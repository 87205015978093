import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { persistStore, persistReducer } from "redux-persist";
import localforage from "localforage";
const persistConfig = {
  key: "root",
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["auth"],
  
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,

  middleware: [thunk],
});

export const persistor = persistStore(store);
