import React from "react";
import "./footerPage.scss";

function FooterPage() {
  const styleFooter = {
    position: "fixed",
    left: 0,
    bottom: 0,
    //left: isPC ? (collapsed ? "61px" : "220px") : "0px",
    zIndex: 3,
    height: "48px",
    backgroundColor: "#333333",
    color: "#fff",
    // borderTop: '1px solid #dee6ec',
    // width: isPC ? `calc(100vw - ${collapsed ? "61px" : "220px"})` : "100%",
    width: "100%",
    padding: 18,
    textAlign: "center",
    whiteSpace: "pre",
    fontSize: 13,
    fontWeight: "normal",
  };

  return (
    <>
      <div className="footer-page" style={styleFooter}>
        &copy;GRIFFY inc.
      </div>
    </>
  );
}

export default FooterPage;
