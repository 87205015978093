import Api from "@src/api";

export default {
  //search
  search(params) {
    return Api().get("api/HealthCondition", { params });
  },
  deleteHealthCondition() {
    return Api().delete(`/api/HealthCondition`);
  },
  getDetailHealthCondition(userId,period,params){
    return Api().get(`api/HealthCondition/${userId}/${period}`, { params });
  },
 
};
