/* eslint-disable jsx-a11y/anchor-is-valid */

import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { AiTwotoneLock } from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";
import { COMMON_CONSTANT, KEY, lang } from "../../constants/common.const";
import "./../login/Login.scss";
// import { useMutation } from "@tanstack/react-query";
import API from "@src/api/backend/auth";
import axios from "axios";
import { useMutation } from "react-query";
import { showMessage } from "../../constants/utils";
import ContentTitle from "../../component/Desktop/contentTitle";

function ChangePassword() {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const [form] = Form.useForm();
  const changePassMutation = useMutation((data) => API.changePassword(data), {
    onSuccess: () => {
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
      setTxtError(null);
      form.resetFields();
    },
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          setTxtError("パスワードが正しくありません。");
        }
      }
    },
  });
  const [txtError, setTxtError] = useState(null);
  // Handle Submit
  const handleSubmit = async (data) => {
    const { newPassword, confirmNewPassword } = data;
    if (newPassword !== confirmNewPassword) {
      setTxtError(
        "新しいパスワードと新しいパスワード（確認）は一致する必要があります。"
      );
      return;
    }

    if (newPassword.trim().length < 8) {
      setTxtError("パスワードは8文字以上で設定してください");
      return;
    }
    if (
      !/\d/.test(newPassword) ||
      !/[A-Z]/.test(newPassword) ||
      !/[a-z]/.test(newPassword)
    ) {
      setTxtError(
        "半角英小文字('a'-'z')、半角英大文字('A'-'Z')、半角数字をそれぞれ１文字以上混在させてください。"
      );
      return;
    }

    // Call Api
    await changePassMutation.mutateAsync(data);
  };

  return (
    <div style={{ padding: "0 10px" }} className="des-content">
      <TitlePage name={"パスワード変更"} />

      <Row>
        <Col className="mt20" span={isPC ? 12 : 24}>
          <section className="content container-fluid">
            {txtError && (
              <div
                style={{ background: "#dd4b39" }}
                className="alert alert-info "
              >
                <span className="bold">{txtError}</span>
              </div>
            )}
            <div className="box">
              <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Form.Item
                  name="oldPassword"
                  rules={[
                    {
                      required: true,
                      message: (
                        <>
                          <span className="notiRequied">
                            {lang.CURREENT_PASSWORD}{" "}
                            <span className="notiRequied__mark">
                              このフィールドは必須入力項目です。
                            </span>
                          </span>
                        </>
                      ),
                    },
                  ]}
                  label={lang.CURREENT_PASSWORD}
                  required
                >
                  <Input
                    type="password"
                    maxLength={100}
                    addonBefore={<AiTwotoneLock />}
                  />
                </Form.Item>

                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: (
                        <>
                          <span className="notiRequied">
                            {lang.NEW_PASSWORD}{" "}
                            <span className="notiRequied__mark">
                              このフィールドは必須入力項目です。
                            </span>
                          </span>
                        </>
                      ),
                    },
                  ]}
                  label={lang.NEW_PASSWORD}
                  required
                >
                  <Input
                    type="password"
                    maxLength={100}
                    addonBefore={<AiTwotoneLock />}
                  />
                </Form.Item>
                <Form.Item
                  name="confirmNewPassword"
                  rules={[
                    {
                      required: true,
                      message: (
                        <>
                          <span className="notiRequied">
                            {lang.CONFIRM_NEW_PASSWORD}{" "}
                            <span className="notiRequied__mark">
                              このフィールドは必須入力項目です。
                            </span>
                          </span>
                        </>
                      ),
                    },
                  ]}
                  label={lang.CONFIRM_NEW_PASSWORD}
                  required
                >
                  <Input
                    type="password"
                    maxLength={100}
                    addonBefore={<AiTwotoneLock />}
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" type="primary">
                    {lang.SAVE}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </section>
        </Col>
      </Row>
      <Loading show={changePassMutation.isLoading} />
    </div>
  );
}

export default ChangePassword;
