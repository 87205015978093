/* eslint-disable react-hooks/exhaustive-deps */
import {
  ClearOutlined,
  RollbackOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import BreakLine from "../../component/Desktop/breakLine";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput";
import TitlePage from "../../component/Desktop/titlePage";
import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";
import {
  MSG_CATCH,
  checkEmail,
  checkErrorConfirmPasssword,
  checkErrorPassword,
  formatListCompanyOption,
  formatListOptionOffice,
  getQuerySearch,
  isEmpty,
  renderOption,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";
import CompayApi from "./../../api/backend/company";
import UserApi from "././../../api/backend/user";

import { ApiOffice } from "../../api/backend/headerQuaters";
import GroupItem from "../../component/Desktop/groupItem";
import InputUserName from "../../components/common/input/inputUserName";
import WokerList from "../../components/modal/worker-list";
import useWidthInput from "../../hooks/useWidthInput";
import classDdl from "../../models/control/dropdownlist";
import classText from "../../models/control/text";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import useAuth from "../../hooks/useAuth";

let FLAG_CHANGE_DATA = false;

function CreateBaseAddmin() {
  const TYPE_SHOW_MODAL = {
    DEVICE: 1,
    SAFE_DEVICE: 2,
  };
  const {
    isSingleCompany,
    officeId,
    officeName,
    isSiggleOffice,
    companyId,
    companyName,
  } = useAuth();
  let { id } = useParams();
  const history = useHistory();
  let [showLoading, setShowLoading] = useState(false);
  const widthInput = useWidthInput();
  const [modeEdit] = useState(true);
  const initLoad = useRef(true);
  const objSearch = getQuerySearch();

  // Loading
  const [showWorker, setShowWorker] = useState(null);

  let [ddlBaseName, setddlBaseName] = useState(
    new classDdl(isSiggleOffice ? officeId : objSearch.OfficeId)
  );
  const [ddlCompany, setddlCompany] = useState(
    new classDdl(isSingleCompany ? companyId : null)
  );
  let [txtLoginID, settxtLoginID] = useState(new classText());
  let [txtPassword, settxtPassword] = useState(new classText());
  let [txtPasswordConfirm, settxtPasswordConfirm] = useState(new classText());

  let [txtFullName, settxtFullName] = useState(new classText());
  let [txtFuriganaName, settxtFuriganaName] = useState(new classText());
  let [txtEmail, settxtEmail] = useState(new classText());
  let [txtphoneNumber, setTxtphoneNumber] = useState(new classText());
  let [txtWorkerCode, setTxtWorkercode] = useState(new classText());
  let [rdGender, setrdGender] = useState(new classDdl(!id && "1"));
  let [dbDOB, setDOB] = useState(new classText());
  const [device, setDevice] = useState({
    deviceType: "",
    serialNumber: "",
    deviceTypeId: "",
  });
  const [safeDevice, setSafeDevice] = useState({
    deviceType: "",
    serialNumber: "",
    deviceTypeId: "",
  });
  const [listDevice, setListDevice] = useState([{}, {}]);
  // Focus
  let ddlBaseNameEl = useRef(null);
  const ddlCompanyEl = useRef(null);
  let txtWorkderCodeEl = useRef(null);
  let txtLoginIDEl = useRef(null);
  let txtPasswordEl = useRef(null);
  let txtPasswordConfirmEl = useRef(null);
  let txtFullNameEl = useRef(null);
  let txtFuriganaNameEl = useRef(null);
  let txtEmailEl = useRef(null);
  let rdGenderEl = useRef(null);
  let dbDOBEL = useRef(null);
  const [clicked, setClicked] = useState(false);

  // Validate
  const validateData = () => {
    let flagError = false;

    setDOB({ ...dbDOB, error: false });
    setrdGender({ ...rdGender, error: false });
    settxtEmail({ ...txtEmail, error: false });
    settxtFuriganaName({ ...txtFuriganaName, error: false });
    settxtFullName({ ...txtFullName, error: false });
    settxtPasswordConfirm({ ...txtPasswordConfirm, error: false });
    settxtPassword({ ...txtPassword, error: false });
    settxtLoginID({ ...txtLoginID, error: false });
    setddlBaseName({ ...ddlBaseName, error: false });
    setddlCompany({ ...ddlCompany, error: false });

    if (!dbDOB.value) {
      flagError = true;
      setDOB({ ...dbDOB, error: true });
      dbDOBEL.current.focus();
    }

    if (!rdGender.value) {
      flagError = true;
      setrdGender({ ...rdGender, error: true });
      rdGenderEl.current.focus();
    }

    if (!isEmpty(txtEmail.value) && !checkEmail(txtEmail.value)) {
      flagError = true;
      settxtEmail({ ...txtEmail, error: true });
      txtEmailEl.current.focus();
    }

    if (!txtFuriganaName.value) {
      flagError = true;
      settxtFuriganaName({ ...txtFuriganaName, error: true });
      txtFuriganaNameEl.current.focus();
    }

    if (!txtFullName.value) {
      flagError = true;
      settxtFullName({ ...txtFullName, error: true });
      txtFullNameEl.current.focus();
    }

    if (!txtPasswordConfirm.value && !txtPassword.value) {
      // not required password
    } else {
      if (
        (!txtPasswordConfirm.value && !id) ||
        (!id &&
          checkErrorConfirmPasssword(
            txtPassword.value,
            txtPasswordConfirm.value
          ) != null) ||
        (id &&
          (txtPassword.value || txtPasswordConfirm.value) &&
          checkErrorConfirmPasssword(
            txtPassword.value,
            txtPasswordConfirm.value
          ) != null)
      ) {
        flagError = true;
        settxtPasswordConfirm({ ...txtPasswordConfirm, error: true });
        txtPasswordConfirmEl.current.focus();
      }

      if (
        (!txtPassword.value && !id) ||
        (!id && checkErrorPassword(txtPassword.value) != null) ||
        (id && txtPassword.value && checkErrorPassword(txtPassword.value) != null)
      ) {
        flagError = true;
        settxtPassword({ ...txtPassword, error: true });
        txtPasswordEl.current.focus();
      }
    }

    if (!txtLoginID.value) {
      flagError = true;
      settxtLoginID({ ...txtLoginID, error: true });
      txtLoginIDEl.current.focus();
    }

    if (!ddlBaseName.value) {
      flagError = true;
      setddlBaseName({ ...ddlBaseName, error: true });
      ddlBaseNameEl.current.focus();
    }

    if (!ddlCompany.value) {
      flagError = true;
      setddlCompany({ ...ddlCompany, error: true });
      ddlCompanyEl.current.focus();
    }

    return !flagError;
  };
  // Popup common
  const showConfirmMessage = (typeInfo = false) => {
    return;
  };

  function backToSearch() {
    history.goBack();
  }

  function cancelSaveData() {
    if (!FLAG_CHANGE_DATA) {
      backToSearch();
    } else {
      // Updated
      FLAG_CHANGE_DATA = false;
      showConfirmMessage(COMMON_CONSTANT.WF_C018);
    }
  }

  // Post
  const postData = async () => {
    try {
      setShowLoading(true);
      const dataPost = {
        userName: txtLoginID.value,
        furigana: txtFuriganaName.value,
        fullName: txtFullName.value,
        gender: rdGender.value,
        dateOfBirth: dbDOB.value ? dbDOB.value.format("YYYY-MM-DD") : null,
        workerCode: txtWorkerCode.value,
        officeId: ddlBaseName.value,
        email: txtEmail.value,
        phoneNumber: txtphoneNumber.value,
        canDelete: true,
        role: RoleHeadOffice.USER,
        meditagId: device.serialNumber,
        password: txtPassword.value,
        confirmPassword: txtPasswordConfirm.value,
        companyId: ddlCompany.value,
        meditagType: String(device.deviceTypeId),
        safetyBeltId: safeDevice.serialNumber,
        safetyBeltType: String(safeDevice.deviceTypeId),
      };
      if (id) {
        dataPost.userId = id;
      }
      await UserApi.save(dataPost, id);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
      history.goBack();
    } catch (error) {
      let msg;
      if (error.response) {
        if (error.response.status === 409) {
          msg = lang.TRY_LOGINID;
          settxtLoginID({ ...txtLoginID, value: error.response.data });
        } else if (error.response.status === 400) {
          msg = error.response.data;
        }
      }
      showMessage(KEY.ERROR, msg || MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  // End: Tbl Attach
  const onSave = () => {
    setClicked(true);
    // Validate Data
    if (!validateData()) {
      return;
    }
    // console.log(checkTypeDevice());
    if (!checkTypeDevice()) {
      return;
    }
    // Call Api
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: id ? COMMON_CONSTANT.ECM_C009 : COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };

  // getInit data
  const getInitData = async () => {
    if (id) {
      setShowLoading(true);
    }
    try {
      const [resDetail, resCompany, resListOffice] = await Promise.all([
        id ? UserApi.getDetailUser(id) : null,
        !isSingleCompany ? CompayApi.getCompany({}) : null,
        !id && !isSiggleOffice ? ApiOffice.searchOffice({}) : null,
      ]);
      const listCompany =
        resCompany && formatListCompanyOption(resCompany.data.data);
      if (id) {
        const dataDetail = resDetail.data;

        // API get
        // getList option Ofice

        if (!isSiggleOffice) {
          const resOffice = await ApiOffice.searchOffice({
            companyId: dataDetail.companyId,
          });
          setddlBaseName({
            ...ddlBaseName,
            value: dataDetail.officeId,
            options: formatListOptionOffice(resOffice.data.data),
          });
        }
        if (!isSingleCompany) {
          setddlCompany({
            ...ddlCompany,
            value: dataDetail.companyId,
            options: listCompany,
          });
        }
        settxtLoginID({ ...txtLoginID, value: dataDetail.userName });
        setTxtWorkercode({ ...txtWorkerCode, value: dataDetail.workerCode });

        setTxtphoneNumber({ ...txtphoneNumber, value: dataDetail.phoneNumber });
        settxtEmail({ ...txtEmail, value: dataDetail.email });
        settxtFuriganaName({ ...txtFuriganaName, value: dataDetail.furigana });
        settxtFullName({ ...txtFullName, value: dataDetail.fullName });
        setrdGender({ ...rdGender, value: dataDetail.gender });
        setDOB({ ...dbDOB, value: dayjs(dataDetail.dateOfBirth) });

        setListDevice([
          {
            ...device,
            deviceType: dataDetail.meditagType,
            serialNumber: dataDetail.meditagId,
            type: TYPE_SHOW_MODAL.DEVICE,
            deviceTypeId: dataDetail.meditagId ? "1" : "",
          },
          {
            ...safeDevice,
            deviceType: dataDetail.safetyBeltType,
            serialNumber: dataDetail.safetyBeltId,
            type: TYPE_SHOW_MODAL.SAFE_DEVICE,
            deviceTypeId: dataDetail.safetyBeltId ? "2" : "",
          },
        ]);
      }
      // Add
      else {
        if (!isSingleCompany) {
          setddlCompany({
            ...ddlCompany,
            options: listCompany,
          });
        }

        if (!isSiggleOffice) {
          const listOffice = formatListOptionOffice(resListOffice.data.data);
          setddlBaseName({ ...ddlBaseName, options: listOffice });
        }
      }
    } catch (error) {
      showErrorMessage(error);
    } finally {
      initLoad.current = false;
      setShowLoading(false);
    }
  };
  useEffect(() => {
    getInitData();
  }, []);

  const getListOffice = async () => {
    const listOffice = await ApiOffice.searchOffice({
      CompanyId: ddlCompany.value,
    });

    setddlBaseName({
      ...ddlBaseName,
      options: formatListOptionOffice(listOffice.data.data),
      value: null,
    });
  };
  useEffect(() => {}, [ddlBaseName.value]);
  useEffect(() => {
    if (!initLoad.current && ddlCompany.value) {
      getListOffice();
    }
  }, [ddlCompany.value]);

  /// set Device
  const handleSetDevice = (newDevice) => {
    if (newDevice.type === TYPE_SHOW_MODAL.DEVICE) {
      setListDevice([newDevice, listDevice[1]]);
    } else {
      setListDevice([listDevice[0], newDevice]);
    }
  };

  useEffect(() => {
    const safeDeviceItem = listDevice.find((i) => i.deviceTypeId == 2);
    const deviceItem = listDevice.find(
      (i) => i.deviceTypeId && i.deviceTypeId != 2
    );
    if (deviceItem) {
      setDevice(deviceItem);
    } else {
      setDevice({
        deviceType: "",
        serialNumber: "",
        deviceTypeId: "",
      });
    }
    if (safeDeviceItem) {
      setSafeDevice(safeDeviceItem);
    } else {
      setSafeDevice({
        deviceType: "",
        serialNumber: "",
        deviceTypeId: "",
      });
    }
  }, [listDevice]);

  // check Type device
  const checkTypeDevice = () => {
    let isErrorType = false;
    const listIdDevicde = listDevice
      .filter((i) => i.deviceTypeId)
      .map((i) => i.deviceTypeId + "");
    if (
      listIdDevicde.length > 0 &&
      Array.from(new Set(listIdDevicde)).length !== listIdDevicde.length
    ) {
      isErrorType = true;
      Modal.warning({
        title: lang.CONFIRM,
        content: lang.ERROR_TYPE_DEVICE,
        centered: true,
      });
    }

    return !isErrorType;
  };
  return (
    <div className="des-content">
      {showWorker && (
        <WokerList
          listDevice={listDevice}
          type={showWorker}
          visible={showWorker}
          OfficeId={ddlBaseName.value}
          handleCancel={() => {
            setShowWorker(null);
          }}
          device={
            showWorker === TYPE_SHOW_MODAL.DEVICE
              ? listDevice[0]
              : listDevice[1]
          }
          setDevice={handleSetDevice}
          currentUser={txtLoginID.value}
        />
      )}
      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.WORKER_SETTING} />

      {/* Group 1 */}
      <GroupItem>
        <BreakLine />
        <RowInput required box name={lang.COMPANY}>
          <>
            {!isSingleCompany ? (
              <Select
                showSearch
                optionFilterProp="children"
                value={ddlCompany.value}
                ref={ddlCompanyEl}
                className={
                  ddlCompany.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                onChange={(v) =>
                  setddlCompany({
                    ...ddlCompany,
                    value: v,
                  })
                }
              >
                {renderOption(ddlCompany.options, false)}
              </Select>
            ) : (
              <Input value={companyName} disabled className={widthInput} />
            )}
          </>

          <></>
        </RowInput>
        <BreakLine />
        <RowInput required box name={lang.BASE_NAME}>
          <>
            {!isSiggleOffice ? (
              <Select
                showSearch
                optionFilterProp="children"
                disabled={!ddlCompany.value}
                value={ddlBaseName.value}
                ref={ddlBaseNameEl}
                className={
                  ddlBaseName.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                onChange={(v) =>
                  setddlBaseName({
                    ...ddlBaseName,
                    value: v,
                  })
                }
              >
                {renderOption(ddlBaseName.options, false)}
              </Select>
            ) : (
              <Input value={officeName} disabled className={widthInput} />
            )}
          </>

          <></>
        </RowInput>
        <BreakLine />
        <RowInput box name={"ログインID"} required={true}>
          <>
            <InputUserName
              state={txtLoginID}
              setState={settxtLoginID}
              ref={txtLoginIDEl}
              role={RoleHeadOffice.USER}
              isModeEdit={id}
            />
          </>
          <></>
        </RowInput>
        <BreakLine />
        <RowInput box name={"作業者コード"}>
          <>
            <Input
              disabled={id}
              value={txtWorkerCode.value}
              ref={txtWorkderCodeEl}
              className={
                txtWorkerCode.error
                  ? `${STYLE.BORDER_RED} ${widthInput}`
                  : `${widthInput}`
              }
              maxLength={255}
              onChange={(e) =>
                setTxtWorkercode({
                  ...txtWorkerCode,
                  value: e.target.value,
                })
              }
            ></Input>
          </>
          <></>
        </RowInput>
        <BreakLine />
        <RowInput box name={lang.PASSWORD} required={false}>
          <>
            <div>
              <Input
                type="password"
                autocomplete="new-password"
                aria-autocomplete="none"
                value={txtPassword.value}
                ref={txtPasswordEl}
                className={
                  txtPassword.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={255}
                onChange={(e) =>
                  settxtPassword({
                    ...txtPassword,
                    value: e.target.value,
                  })
                }
              ></Input>
              {checkErrorPassword(txtPassword.value, txtPasswordConfirm.value) && clicked ? (
                <div style={{ color: "red" }} className="fs12">
                  {checkErrorPassword(txtPassword.value, txtPasswordConfirm.value)}
                </div>
              ) : (
                <div className="fs12">
                  {id ? "パスワードを変更する場合のみ入力してください" : ""}
                </div>
              )}
            </div>
          </>
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.CONFIRM_PASSWORD} required={false}>
          <div>
            <Input
              type="password"
              autocomplete="new-password"
              aria-autocomplete="none"
              value={txtPasswordConfirm.value}
              ref={txtPasswordConfirmEl}
              className={
                txtPasswordConfirm.error
                  ? `${STYLE.BORDER_RED} ${widthInput}`
                  : `${widthInput}`
              }
              maxLength={255}
              onChange={(e) =>
                settxtPasswordConfirm({
                  ...txtPasswordConfirm,
                  value: e.target.value,
                })
              }
            ></Input>
            {checkErrorConfirmPasssword(
              txtPassword.value,
              txtPasswordConfirm.value
            ) && clicked ? (
              <div style={{ color: "red" }} className="fs12">
                {checkErrorConfirmPasssword(
                  txtPassword.value,
                  txtPasswordConfirm.value
                )}
              </div>
            ) : (
              <span className="fs12"></span>
            )}
          </div>
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.FULL_NAME} required={modeEdit}>
          {modeEdit ? (
            <div>
              <Input
                value={txtFullName.value}
                ref={txtFullNameEl}
                className={
                  txtFullName.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={100}
                onChange={(e) =>
                  settxtFullName({
                    ...txtFullName,
                    value: e.target.value,
                  })
                }
              ></Input>
              <div className="fs12">
                姓と名の間に全角スペースを入れてください
              </div>
            </div>
          ) : (
            <>
              <div className="white-space"> {txtFullName.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.FURIGANA_NAME} required={modeEdit}>
          {modeEdit ? (
            <div>
              <Input
                value={txtFuriganaName.value}
                ref={txtFuriganaNameEl}
                className={
                  txtFuriganaName.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={100}
                onChange={(e) =>
                  settxtFuriganaName({
                    ...txtFuriganaName,
                    value: e.target.value,
                  })
                }
              ></Input>
              <div className="fs12">
                姓と名の間に全角スペースを入れてください
              </div>
            </div>
          ) : (
            <>
              <div className="white-space"> {txtFuriganaName.value}</div>
            </>
          )}
        </RowInput>
        <BreakLine />
        <BreakLine />
        <RowInput box name={lang.NUMBER_KUSHIWA}>
          {modeEdit ? (
            <div>
              <Input
                value={txtphoneNumber.value}
                className={`${widthInput}`}
                maxLength={15}
                onChange={(e) =>
                  setTxtphoneNumber({
                    ...txtphoneNumber,
                    value: e.target.value,
                  })
                }
              ></Input>
              <div className="fs12">
                半角数字と半角のハイフン(-)のみを入力してください
              </div>
            </div>
          ) : (
            <></>
          )}
        </RowInput>
        <BreakLine />
        <RowInput required={false} box name={lang.EMAIL_USER}>
          <div>
            <Input
              value={txtEmail.value}
              ref={txtEmailEl}
              className={
                txtEmail.error
                  ? `${STYLE.BORDER_RED} ${widthInput}`
                  : `${widthInput}`
              }
              maxLength={64}
              onChange={(e) =>
                settxtEmail({
                  ...txtEmail,
                  value: e.target.value,
                })
              }
            ></Input>
            <div
              style={{
                color:
                  !isEmpty(txtEmail.value) &&
                  !checkEmail(txtEmail.value) &&
                  clicked &&
                  "red",
              }}
              className="fs12"
            >
              半角英数字と半角記号のみを入力してください
            </div>
          </div>
        </RowInput>
        <BreakLine />
        <RowInput box name={lang.SEXUAL} required={modeEdit}>
          {modeEdit ? (
            <div>
              <Radio.Group
                name="rdGender"
                value={rdGender.value}
                ref={rdGenderEl}
                className={
                  rdGender.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                maxLength={255}
                onChange={(e) =>
                  setrdGender({
                    ...rdGender,
                    value: e.target.value,
                  })
                }
              >
                <Radio value={"1"}> {lang.MALE} </Radio>
                <Radio value={"2"}> {lang.WOMAN} </Radio>
                <Radio value={"3"}> {lang.OTHERS} </Radio>
              </Radio.Group>
            </div>
          ) : (
            <></>
          )}
        </RowInput>
        <BreakLine />
        <RowInput box name={lang.DOB} required={modeEdit}>
          {modeEdit ? (
            <>
              <DatePicker
                maxLength={255}
                value={dbDOB.value}
                ref={dbDOBEL}
                className={
                  dbDOB.error
                    ? `${STYLE.BORDER_RED} ${widthInput}`
                    : `${widthInput}`
                }
                onChange={(v) =>
                  setDOB({
                    ...dbDOB,
                    value: v,
                  })
                }
              ></DatePicker>
            </>
          ) : (
            <></>
          )}
        </RowInput>
        <BreakLine />
        <RowInput box name={lang.DEVICE}>
          <Row>
            <Col span={24}>
              <Button
                disabled={!ddlBaseName.value}
                className="mr10"
                onClick={() => {
                  setShowWorker(TYPE_SHOW_MODAL.DEVICE);
                }}
              >
                {lang.SELECT}
              </Button>
              <Button
                disabled={!listDevice[0].serialNumber}
                onClick={() => {
                  const list = [...listDevice];
                  list[0] = {
                    ...listDevice[0],
                    serialNumber: "",
                    deviceType: "",
                    deviceTypeId: "",
                  };
                  setListDevice(list);
                }}
              >
                <ClearOutlined />
              </Button>
            </Col>
            <Col span={24}>
              <div className="mt15">
                {modeEdit ? (
                  <>
                    <div>
                      <div>{lang.TYPE}</div>
                      <Input
                        value={listDevice[0].deviceType}
                        disabled
                        maxLength={255}
                        className={widthInput}
                      ></Input>
                    </div>
                    <div>
                      <div className="mt10">{lang.SERIAL_NUMBER}</div>
                      <Input
                        className={widthInput}
                        value={listDevice[0].serialNumber}
                        disabled
                        maxLength={255}
                      ></Input>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: 20,
            }}
          >
            <Col span={24}>
              <Button
                disabled={!ddlBaseName.value}
                className="mr10"
                onClick={() => {
                  setShowWorker(TYPE_SHOW_MODAL.SAFE_DEVICE);
                }}
              >
                {lang.SELECT}
              </Button>
              <Button
                disabled={!listDevice[1].serialNumber}
                onClick={() => {
                  const list = [...listDevice];
                  list[1] = {
                    ...listDevice[1],
                    serialNumber: "",
                    deviceType: "",
                    deviceTypeId: "",
                  };
                  setListDevice(JSON.parse(JSON.stringify(list)));
                }}
              >
                <ClearOutlined />
              </Button>
            </Col>
            <Col span={24}>
              <div className="mt15">
                {modeEdit ? (
                  <>
                    <div>
                      <div>{lang.TYPE}</div>
                      <Input
                        value={listDevice[1].deviceType}
                        disabled
                        maxLength={255}
                        className={widthInput}
                      ></Input>
                    </div>
                    <div>
                      <div className="mt10">{lang.SERIAL_NUMBER}</div>
                      <Input
                        className={widthInput}
                        value={listDevice[1].serialNumber}
                        disabled
                        maxLength={255}
                      ></Input>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
        </RowInput>

        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          <Button
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              //confirmSaveData();
              onSave();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={cancelSaveData}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CreateBaseAddmin;
