import React from "react";
import "./customIcon.scss";

import antenna00 from "@src/styles/image/antenna/antenna00.png";
import antenna01 from "@src/styles/image/antenna/antenna01.png";
import antenna02 from "@src/styles/image/antenna/antenna02.png";
import antenna03 from "@src/styles/image/antenna/antenna03.png";
import antenna04 from "@src/styles/image/antenna/antenna04.png";

function AntennaIcon({ level, width = 24, wrapStyle = {}, style = {}, wrapClassName, classNameImg }) {
  const IMG_BY_LEVEL = {
    0: antenna00,
    1: antenna01,
    2: antenna02,
    3: antenna03,
    4: antenna04,
  };
  return (
    <div style={{ paddingTop: 5, ...wrapStyle }} className={'wifi-wrapper-default ' + wrapClassName}>
      <img width={width} style={{ ...style }} className={classNameImg} src={IMG_BY_LEVEL[level]} alt="" />
    </div>
  );
}

export default AntennaIcon;
