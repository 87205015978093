/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Table,
  Checkbox,
  Modal,
  Input,
} from "antd";
import React, { useState, useEffect } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";

import classGrid from "../../models/control/grid";
import { FaFaceSadTear } from "react-icons/fa6";

import { FaFaceFrown, FaFaceSmile, FaFaceMeh } from "react-icons/fa6";
import { GoAlertFill } from "react-icons/go";

import { useHistory } from "react-router-dom";

import {
  renderOption,
  pushObject2Query,
  getQuerySearch,
} from "../../constants/utils";

import { CONFIG, STYLE, lang, PAGE_SIZE } from "../../constants/common.const";

import classDdl from "../../models/control/dropdownlist";
import { LocationApi as API } from "@src/api/backend/location";
import API_BASE from "@src/api/backend/kbn";
import API_ALERT from "@src/api/backend/alert";
import { initColumns } from "@src/page/alert-list/list.jsx";
import { removeItemArr } from "@src/constants/utils.js";
import ContentTitle from "../../component/Desktop/contentTitle";
import AlertInfomation from "../../component/Desktop/alertInfomation";
import { EyeOutlined } from "@ant-design/icons";
import AntennaIcon from "../../components/common/custom-icon/antennaIcon";
import SelectOffice from "@src/components/common/input/selectOffice.jsx";
import dayjs from "dayjs";
import { onChangeTable } from "@src/utils/helpers.js";
import { useSelector } from "react-redux";
import { RoleHeadOffice } from "@src/routers/roles/roles.const";
import { debounce } from "lodash";
import iconOn from "@src/styles/image/antenna/on.png";
import iconOff from "@src/styles/image/antenna/off.png";

// const objSearch = getQuerySearch();

function MapList() {
  const { roleKey } = useSelector((state) => state.auth);
  const isSiggleOffice = [
    RoleHeadOffice.ADMIN_HEADQUATER,
    RoleHeadOffice.USER,
    RoleHeadOffice.GROUP_LEADER,
    RoleHeadOffice.GROUP_SUB_LEADER,
  ].includes(roleKey);

  const objSearch = getQuerySearch();

  const history = useHistory();
  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  //  Grid
  const [grid, setGrid] = useState({
    ...new classGrid(),
    data: [],
  });

  const [grid1, setGrid1] = useState({
    ...new classGrid(),
    data: [],
  });
  // Input search
  // 1

  const [ddlOffice, setDdlOffice] = useState({
    ...new classDdl(),
    value: objSearch.OfficeId,
  });
  const [ddlGroup, setddlGroup] = useState({
    ...new classDdl(),
    value: "",
  });
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await API_BASE.getGroup({
          OfficeId: ddlOffice.value,
        });
        const data = response.data.data.map((item) => {
          return {
            value: item.groupName,
            key: item.id,
          };
        });
        setddlGroup({
          ...ddlGroup,
          options: data,
          value: null,
        });
      } catch (e) {
        console.log(e);
      }
    };

    if (isSiggleOffice && !ddlOffice.value) return;
    fetch();
  }, [ddlOffice.value]);
  const [ddlLocation, setDdlLocation] = useState({ ...new classDdl(), value: objSearch.MapId });

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await API.getMap({
          OfficeId: ddlOffice.value,
          limit: 0,
        });
        const data = result.data.data.map((item, index) => {
          return {
            key: item.id,
            value: item.locationName
          }
        });
        setDdlLocation({
          ...ddlLocation,
          options: data,
          value: null,
        });
      } catch (e) {
        console.log(e);
      }
    };

    if (isSiggleOffice && !ddlOffice.value) return;
    fetch();
  }, [ddlOffice.value]);
  // Search
  const search_onClick = async (
    currentPage = 1,
    sortKey = "",
    sortOrder = "desc"
  ) => {
    try {
      pushObject2Query({
        OfficeId: ddlOffice.value || "",
        GroupId: ddlGroup.value || "",
        // LocationName: ddlLocation.value,
        MapId: ddlLocation.value,
      });
      const result = await API.getMap({
        OfficeId: ddlOffice.value,
        // LocationName: ddlLocation.value,
        MapId: ddlLocation.value,
        offset: (currentPage - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        sortKey,
        sortOrder,
      });
      const data = result.data;
      setGrid1(data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchHealthCheck = async (
    currentPage = 1,
    sortKey = "",
    sortOrder = "desc"
  ) => {
    try {
      pushObject2Query({
        OfficeId: ddlOffice.value || "",
        GroupId: ddlGroup.value || "",
        MapId: ddlLocation.value,
      });
      const result = await API_ALERT.getListAlert({
        OfficeId: ddlOffice.value,
        GroupId: ddlGroup.value,
        offset: (currentPage - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        sortKey,
        sortOrder,
      });

      const data = result.data;
      setGrid(data);
    } catch (e) {
      console.log(e);
    }
  };

  const [initPage, setInitPage] = useState(true);
  useEffect(() => {
    setInitPage(false);
    if (isSiggleOffice && !ddlOffice.value) return;
    search_onClick();
    fetchHealthCheck();
  }, []);
  useEffect(() => {
    if (initPage) return;
    search_onClick();
  }, [ddlOffice.value, ddlLocation.value]);

  useEffect(() => {
    if (initPage) return;
    fetchHealthCheck();
  }, [ddlOffice.value, ddlGroup.value]);

  const columns = [
    {
      title: "棟/工区",
      dataIndex: "locationName",
      align: "left",
      sorter: true,
    },
    {
      title: "フロア/区画",
      dataIndex: "floor",
      align: "start",
      sorter: true,
    },
    {
      title: "親機",
      dataIndex: "isMainGatewayAlive",
      align: "center",
      sorter: true,
      render: (value, index, row) => {
        return value ? (
          <>
            <div
              style={{
                marginTop: 5,
                margin: "0px auto"
              }}
            >
              <img width={15} src={iconOn} alt="" />
            </div>
          </>
        ) : (
          <div
            style={{
              marginTop: 5,
              margin: "0px auto"
            }}
          >
            <img width={15} src={iconOff} alt="" />
          </div>
        );
      },
    },
    {
      title: "子機",
      dataIndex: "listSubGateways",
      align: "center",
      render: (value, index, row) => {
        return <SubDeviceWaveButtonModal listSubDevice={value} />;
      },
    },
    {
      title: (
        <>
          <GoAlertFill style={{ color: "red", fontSize: 20 }} />
        </>
      ),
      dataIndex: "countEmergency",
      sorter: true,
      align: "center",
    },
    {
      title: (
        <>
          <FaFaceSadTear style={{ color: "red", fontSize: 20 }} />
        </>
      ),
      dataIndex: "countAlertLevel3",
      sorter: true,
      align: "center",
    },
    {
      title: (
        <>
          <FaFaceFrown style={{ color: "orange", fontSize: 20 }} />
        </>
      ),
      dataIndex: "countAlertLevel2",
      sorter: true,
      align: "center",
    },
    {
      title: (
        <>
          <FaFaceMeh style={{ color: "gold", fontSize: 20 }} />
        </>
      ),
      dataIndex: "countAlertLevel1",
      sorter: true,
      align: "center",
    },
    {
      title: (
        <>
          <FaFaceSmile style={{ color: "green", fontSize: 20 }} />
        </>
      ),
      dataIndex: "countAlertLevel0",
      sorter: true,
      align: "center",
    },

    {
      title: "詳細表示",
      align: "center",
      dataIndex: "id",
      render: (value) => {
        //
        return (
          <>
            <Button
              className="mr5 ml10"
              onClick={() => {
                history.push("/map/detail/" + value);
              }}
            >
              マップ
            </Button>
          </>
        );
      },
    },
  ];

  let thisInitColumns = [...initColumns].concat([
    {
      title: "経過時間",
      align: "start",
      sorter: true,
      dataIndex: "alertTime",
      render: (value) => {
        return value ? dayjs.utc(value).fromNow() : "";
      },
    },
  ]);
  thisInitColumns = thisInitColumns.splice(1, thisInitColumns.length - 1);
  thisInitColumns[8] = {
    title: "棟/工区",
    align: "start",
    sorter: true,
    dataIndex: "mapLocationName",
    render: (value) => {
      return value;
    },
  };
  thisInitColumns[9] = {
    title: "フロア/区画",
    align: "start",
    sorter: true,
    dataIndex: "floor",
    render: (value) => {
      return value;
    },
  };
  // remove thisInitColumns[10], thisInitColumns has 12 columns
  thisInitColumns = removeItemArr(thisInitColumns, thisInitColumns[10]);

  const [run, setRun] = useState(false);
  useEffect(() => {
    let intervalId;
    if (run) {
      // Set the interval only when run is true
      intervalId = setInterval(async () => {
        await search_onClick();
      }, 60000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [run]);

  const [debouncedValue, setDebouncedValue] = useState("");

  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={lang.MAP_LIST} />

        <Form layout={"vertical"} form={formSearch}>
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col lg={8} xs={24}>
              <div className="ant-form-item-label">{lang.BASE_NAME}</div>
              <SelectOffice
                className={"w100"}
                state={ddlOffice}
                setState={setDdlOffice}
                acceptSetNull={!initPage}
              />
            </Col>
            <Col lg={8} xs={24}>
              <div className="ant-form-item-label">{"グループ名"}</div>
              <Select
                showSearch
                optionFilterProp="children"
                value={ddlGroup.value}
                className={ddlGroup.error ? `${STYLE.BORDER_RED} w100` : "w100"}
                onChange={(v) => {
                  setddlGroup({ ...ddlGroup, value: v });
                }}
              >
                {renderOption(ddlGroup.options)}
              </Select>
            </Col>
            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
              <Row className="rowNotMargin" gutter={[6, 6]} justify="end">
                <Col>
                  <Checkbox
                    style={{ lineHeight: "35px" }}
                    checked={run}
                    onChange={() => setRun(!run)}
                  >
                    {lang.AUTOMATIC_UPDATE}
                  </Checkbox>
                </Col>
                <Col>
                  <Button
                    onClick={() => search_onClick()}
                    className="buttonPC button--info wAuto"
                  >
                    {lang.UPDATE_DISPLAY}
                  </Button>
                </Col>
                {/* <Col>
                  <Button
                    className="buttonPC button--outline --todo-- wAuto "
                    onClick={() => {
                      history.push(ROUTER_PAGE.PHYSICAL_CONDITION_LIST);
                    }}
                  >
                    {lang.PHYSICAL_CONDITION_LIST}
                  </Button>
                </Col> */}
                <Col>
                  <Button
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push("/map/setting");
                    }}
                  >
                    {lang.MAP_SETTING}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
          <Col lg={8} span={24}>
            <div style={{ marginTop: 10 }} className="ant-form-item-label bold">
              棟/工区
            </div>
            <Select
              showSearch
              optionFilterProp="children"
              value={ddlLocation.value}
              className={ddlLocation.error ? `${STYLE.BORDER_RED} w100` : "w100"}
              onChange={(v) => {
                setDdlLocation({ ...ddlLocation, value: v });
              }}
            >
              {renderOption(ddlLocation.options)}
            </Select>
          </Col>
          <Col lg={16} span={24} className="right">
            <Col span={24}>
              <AlertInfomation />
            </Col>
          </Col>
        </Row>
      </ContentTitle>
      <div
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        <Row justify="space-between ">
          <Col span={24} className="right mt20">
            <Table
              rowKey={(record) => record.id}
              className="gridSearch "
              columns={columns}
              dataSource={grid1.data}
              pagination={{
                ...CONFIG.paging,
                total: grid1.count,
                showSizeChanger: false,
              }}
             scroll={{ x: 'max-content' }}
              onChange={onChangeTable(search_onClick)}
            />
          </Col>
        </Row>
        <Divider />

        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}></Col>
          <Col span={12} className="right" style={{ paddingRight: "20px" }}>
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.id}
          className="gridSearch "
          columns={thisInitColumns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,
            total: grid.count,
            showSizeChanger: false,
          }}
         scroll={{ x: 'max-content' }}
          onChange={onChangeTable(fetchHealthCheck)}
        />
      </div>
      <Loading show={showLoading} />
    </div>
  );
}
export default MapList;

export const SelectBoxOffice = ({ state, setState, acceptSetNull = false }) => {
  return (
    <SelectOffice
      state={state}
      setState={setState}
      acceptSetNull={acceptSetNull}
    />
  );
};

export const SubDeviceWaveButtonModal = ({ listSubDevice }) => {
  const [display, setDisplay] = useState(false);
  const columns = [
    {
      title: "子機",
      dataIndex: "serialNumber",
      key: "serialNumber",
      sorter: true,
    },
    {
      title: "電波強度",
      dataIndex: "waveLevel",
      key: "waveLevel",
      align: "center",
      sorter: true,
      render: (value, row) => {
        return (
          <div>
            <AntennaIcon level={value} />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Button onClick={() => setDisplay(true)}>
        <EyeOutlined />
      </Button>
      <Modal
        open={display}
        onCancel={() => setDisplay(false)}
        footer={false}
        className="modal-subdevice"
      >
        <Table
          columns={columns}
          dataSource={listSubDevice}
          pagination={false}
        />
      </Modal>
    </>
  );
};
