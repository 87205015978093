import { SaveOutlined, ClearOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Radio,
  Row,
  Space,
  Typography,
  Modal,
  Divider,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { FaFaceFrown, FaFaceMeh, FaFaceSadTear } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { settingApi } from "../../api/backend/setting";
import TitlePage from "../../component/Desktop/titlePage";
import {
  COMMON_CONSTANT,
  KEY,
  REGEX_INPUT,
  STYLE,
  lang,
} from "../../constants/common.const";
import {
  checkEmail,
  getQuerySearch,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";
import classText from "../../models/control/text";
import { RoleHeadOffice } from "../../routers/roles/roles.const";
import Loading from "../../component/Desktop/loading/loading";
import ContentTitle from "../../component/Desktop/contentTitle";
import { getSafeInfo } from "../../redux/auth";
const { TextArea } = Input;
function MasterSetting() {
  const dispatch = useDispatch();
  const { officeId, roleKey, officeName } = useSelector((state) => state.auth);
  const objSearch = getQuerySearch();
  const currentOfficeId =
    roleKey === RoleHeadOffice.ADMIN_UNIT_CONTRACT ||
    roleKey === RoleHeadOffice.ADMIN_UNIT_SERVICE
      ? objSearch.officeId
      : officeId;
  const currentOfficeName =
    roleKey === RoleHeadOffice.ADMIN_UNIT_CONTRACT ||
    roleKey === RoleHeadOffice.ADMIN_UNIT_SERVICE
      ? objSearch.officeName
      : officeName;
  let [txtTo, settxtTo] = useState(new classText());
  let [txtCc, settxtCc] = useState(new classText());
  let [txtBcc, settxtBcc] = useState(new classText());
  let [blockSendAlertTime, setBlockSendAlertTime] = useState(new classText());
  let [emailSendingTarget, setEmailSendingTarget] = useState(new classText());
  let [emergencyResponse, setEmergencyResponse] = useState(new classText());
  let [seatbelt, setSeatbelt] = useState(new classText());

  let [aliveMonitoring, setAliveMonitoring] = useState(new classText());

  const [listOption, setListOption] = useState([]);
  const [listOption2, setListOption2] = useState([]);
  const [listOption3, setListOption3] = useState([]);

  // option email alert
  const [listEmaillOption1, setListEmailOption1] = useState([]);
  const [listEmaillOption2, setListEmailOption2] = useState([]);

  const [loading, setLoading] = useState(false);
  const refTo = useRef();
  const refcc = useRef();
  const refbcc = useRef();

  const optionDisplay1 = [
    {
      label: "緊急",
      value: "EMERGENCY",
    },
    {
      label: "総合アラート",
      value: "GENERAL_ALERT",
    },
    {
      label: "氏名",
      value: "FULL_NAME",
    },
    {
      label: "年齢",
      value: "AGE",
    },
    {
      label: "拠点名",
      value: "BASE_NAME",
    },
    {
      label: "グループ",
      value: "GROUP",
    },
  ];

  const optionDisplay3 = [
    {
      label: "心拍数",
      value: "HEART_BEATS",
    },
    {
      label: "WBGT超過度",
      value: "WBGT_EXCEEDS",
    },
    {
      label: "WBGT値",
      value: "WBGT_VALUE",
    },
    {
      label: "WBGT測定地点",
      value: "WBGT_MEASUREMENT_LOCATION",
    },
    {
      label: "位置情報",
      value: "LOCATION_INTELLIGENCE",
    },
    {
      label: "經過時間",
      value: "ELAPSED_TIME",
    },
  ];
  const optionDisplay4 = [
    {
      label: "バッテリ",
      value: "BATTERY",
    },
    {
      label: "電波強度",
      value: "RADIO_FIELD_STRENGTH",
    },
  ];

  const optionEmailAlert1 = [
    {
      label: "拠点名",
      value: "BASE_NAME",
    },
    {
      label: "位置情報名",
      value: "LOCATION_INFORMATION_NAME",
    },
    {
      label: "グループ",
      value: "GROUP",
    },
  ];

  const optionEmailAlert2 = [
    {
      label: "氏名",
      value: "FULL_NAME",
    },
    {
      label: "連絡先",
      value: "CONTACT_ADDRESS",
    },
    {
      label: "リーダ一氏名",
      value: "LEADER_NAME",
    },
    {
      label: "リーダ一の連絡先",
      value: "LEADER_CONTACT_INFORMATION",
    },
  ];
  // SetDataInit
  const setFieldValueInit = (data, type, key, oldValue, setvalue) => {
    const item = data.find((i) => i.key === key && i.type === type);
    if (item) {
      if (oldValue) {
        setvalue({ ...oldValue, value: item.value });
      } else {
        setvalue(item.value);
      }
    }
  };

  // Set alert Display
  const setPhysicalDisplay = (data) => {
    const listChecked = data.filter((i) => i.value === "1");
    let ops = [],
      ops2 = [],
      ops3 = [];
    listChecked.forEach((item) => {
      if (optionDisplay1.find((i) => i.value === item.key)) {
        ops.push(item.key);
      } else if (optionDisplay3.find((i) => i.value === item.key)) {
        ops2.push(item.key);
      } else if (optionDisplay4.find((i) => i.value === item.key)) {
        ops3.push(item.key);
      }
    });
    setListOption(ops);
    setListOption2(ops2);
    setListOption3(ops3);
  };
  const setEmailDisplay = (data) => {
    const listChecked = data.filter((i) => i.value === "1");
    let ops = [],
      ops1 = [];

    listChecked.forEach((item) => {
      if (optionEmailAlert1.find((i) => i.value === item.key)) {
        ops.push(item.key);
      } else if (optionEmailAlert2.find((i) => i.value === item.key)) {
        ops1.push(item.key);
      }
    });
    setListEmailOption1(ops);
    setListEmailOption2(ops1);
  };
  // get init Data
  const getInitDataOfficeSetting = async () => {
    try {
      setLoading(true);
      const settingRes = await settingApi.getOfficeSetting(currentOfficeId);
      const data = settingRes.data;
      // COMPREHENSIVE_ALERT_DETECTION_DURATION

      // EMAIL
      setFieldValueInit(data, "EMAIL_ALERT_SETTING", "TO", txtTo, settxtTo);
      setFieldValueInit(data, "EMAIL_ALERT_SETTING", "CC", txtCc, settxtCc);
      setFieldValueInit(data, "EMAIL_ALERT_SETTING", "BCC", txtBcc, settxtBcc);
      setFieldValueInit(
        data,
        "EMAIL_ALERT_SETTING",
        "BLOCK_SEND_ALERT_AFTER_PERIOD_TIME",
        blockSendAlertTime,
        setBlockSendAlertTime
      );

      //ENERAL_ALERT_SETTING
      setFieldValueInit(
        data,
        "GENERAL_ALERT_SETTING",
        "EMAIL_SENDING_TARGET",
        emailSendingTarget,
        setEmailSendingTarget
      );
      setFieldValueInit(
        data,
        "GENERAL_ALERT_SETTING",
        "EMERGENCY_RESPONSE_ALERT",
        emergencyResponse,
        setEmergencyResponse
      );

      setFieldValueInit(
        data,
        "GENERAL_ALERT_SETTING",
        "ALIVE_MONITORING_ALERT",
        aliveMonitoring,
        setAliveMonitoring
      );
      setFieldValueInit(
        data,
        "SAFETY_BELT_DISPLAY_SETTING",
        "DISPLAY",
        seatbelt,
        setSeatbelt
      );
      // OPtion Display
      setPhysicalDisplay(
        data.filter(
          (i) => i.type === "PHYSICAL_CONDITION_LIST_DISPLAY_ITEM_SETTING"
        )
      );

      // Email display
      setEmailDisplay(
        data.filter((i) => i.type === "EMAIL_ALERT_DISPLAY_ITEM_SETTING")
      );
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getInitDataOfficeSetting();
  }, [currentOfficeId]);
  // Post
  // Get post Data Option display alert
  const getListDataOption = (allOption, checkedOption, type) =>
    allOption.map((item) => {
      return {
        type,
        key: item.value,
        value: checkedOption.includes(item.value) ? "1" : "0",
      };
    });

  const postData = async () => {
    try {
      setLoading(true);
      const dataPost = [
        {
          type: "GENERAL_ALERT_SETTING",
          key: "ALIVE_MONITORING_ALERT",
          value: aliveMonitoring.value,
        },
        {
          type: "GENERAL_ALERT_SETTING",
          key: "EMAIL_SENDING_TARGET",
          value: emailSendingTarget.value,
        },
        {
          type: "GENERAL_ALERT_SETTING",
          key: "EMERGENCY_RESPONSE_ALERT",
          value: emergencyResponse.value,
        },

        {
          type: "EMAIL_ALERT_SETTING",
          key: "BCC",
          value: txtBcc.value,
        },
        {
          type: "EMAIL_ALERT_SETTING",
          key: "CC",
          value: txtCc.value,
        },
        {
          type: "EMAIL_ALERT_SETTING",
          key: "TO",
          value: txtTo.value,
        },
        {
          type: "EMAIL_ALERT_SETTING",
          key: "BLOCK_SEND_ALERT_AFTER_PERIOD_TIME",
          value: blockSendAlertTime.value,
        },
        {
          type: "SAFETY_BELT_DISPLAY_SETTING",
          key: "DISPLAY",
          value: seatbelt.value,
        },
      ];
      // add display alert data
      const ops = getListDataOption(
        optionDisplay1,
        listOption,
        "PHYSICAL_CONDITION_LIST_DISPLAY_ITEM_SETTING"
      );
      const ops1 = getListDataOption(
        optionDisplay3,
        listOption2,
        "PHYSICAL_CONDITION_LIST_DISPLAY_ITEM_SETTING"
      );
      const ops2 = getListDataOption(
        optionDisplay4,
        listOption3,
        "PHYSICAL_CONDITION_LIST_DISPLAY_ITEM_SETTING"
      );

      const emailOps1 = getListDataOption(
        optionEmailAlert1,
        listEmaillOption1,
        "EMAIL_ALERT_DISPLAY_ITEM_SETTING"
      );
      const emailOps2 = getListDataOption(
        optionEmailAlert2,
        listEmaillOption2,
        "EMAIL_ALERT_DISPLAY_ITEM_SETTING"
      );
      dataPost.push(...ops, ...ops1, ...ops2, ...emailOps1, ...emailOps2);
      await settingApi.postOfficeSetting(currentOfficeId, dataPost);
      dispatch(getSafeInfo());

      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };
  // Save
  const validateData = () => {
    let flagError = false;
    const listStringEmailTo = txtTo.value.trim();
    const arrayEmailTo = listStringEmailTo.split(",");
    const listStringEmailCC = txtCc.value.trim();
    const arrayEmailCC = listStringEmailCC.split(",");
    const listStringEmailBcc = txtBcc.value.trim();
    const arrayEmailBcc = listStringEmailBcc.split(",");

    if (txtTo.value.trim() && arrayEmailTo.some((i) => !checkEmail(i))) {
      flagError = true;
      settxtTo({ ...txtTo, error: true });
      refTo.current.focus();
    } else {
      settxtTo({ ...txtTo, error: false });
    }
    if (txtCc.value.trim() && arrayEmailCC.some((i) => !checkEmail(i))) {
      flagError = true;
      refcc.current.focus();
      settxtCc({ ...txtCc, error: true });
    } else {
      settxtCc({ ...txtCc, error: false });
    }
    if (txtBcc.value.trim() && arrayEmailBcc.some((i) => !checkEmail(i))) {
      flagError = true;
      refbcc.current.focus();
      settxtBcc({ ...txtBcc, error: true });
    } else {
      settxtBcc({ ...txtBcc, error: false });
    }

    return !flagError;
  };
  const onSave = () => {
    // Validate Data
    if (!validateData()) {
      return;
    }
    // Call Api
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };
  return (
    <div className="content">
      <ContentTitle>
        <TitlePage name={lang.MASTER_SETTING} />

        <Row>
          <Col span={24} style={{ marginLeft: 5 }}>
            <h3>拠点名:{currentOfficeName}</h3>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                <Divider />
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col>

          {/* LEVEL 1 */}
          <Col span={1}></Col>
          <Col span={23}>
            <h4>【1】体調一覧画面表示項目設定</h4>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={5}></Col>
              <Col span={19}>
                <Checkbox.Group
                  value={listOption}
                  onChange={(arr) => setListOption(arr)}
                  options={optionDisplay1}
                />
              </Col>
              <Col span={5}></Col>
              <Col span={19}>
                <Checkbox.Group
                  value={listOption2}
                  onChange={(arr) => setListOption2(arr)}
                  options={optionDisplay3}
                />
              </Col>
              <Col span={5}></Col>
              <Col span={19}>
                <Checkbox.Group
                  value={listOption3}
                  onChange={(arr) => setListOption3(arr)}
                  options={optionDisplay4}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                <Divider />
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col>
          {/* LEVEL 2 */}
          <Col span={1}></Col>
          <Col span={23}>
            <h4>【2】メールアラート設定</h4>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={2}></Col>
              <Col span={22}>{lang.ALRET_MUTIPLE_EMAIL_ADDRESS}</Col>
              <Col span={5} style={{ textAlign: "right" }}>
                To:
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                <TextArea
                  ref={refTo}
                  className={
                    txtTo.error ? `${STYLE.BORDER_RED} text-area` : "text-area"
                  }
                  value={txtTo.value}
                  onChange={(e) =>
                    settxtTo({ ...txtTo, value: e.target.value })
                  }
                />
                {txtTo.error && (
                  <div style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                    {lang.EMAIL_NOT_VALIDATE}
                  </div>
                )}
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={9}></Col>
              <Col span={5} style={{ textAlign: "right" }}>
                Cc:
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                <TextArea
                  className={
                    txtCc.error ? `${STYLE.BORDER_RED} text-area` : "text-area"
                  }
                  ref={refcc}
                  value={txtCc.value}
                  onChange={(e) =>
                    settxtCc({ ...txtCc, value: e.target.value })
                  }
                />
                {txtCc.error && (
                  <div style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                    {lang.EMAIL_NOT_VALIDATE}
                  </div>
                )}
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={9}></Col>
              <Col span={5} style={{ textAlign: "right" }}>
                Bcc:
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                <TextArea
                  className={
                    txtBcc.error ? `${STYLE.BORDER_RED} text-area` : "text-area"
                  }
                  ref={refbcc}
                  value={txtBcc.value}
                  onChange={(e) =>
                    settxtBcc({ ...txtBcc, value: e.target.value })
                  }
                />
                {txtBcc.error && (
                  <div style={{ color: "red", fontSize: 12, marginTop: 5 }}>
                    {lang.EMAIL_NOT_VALIDATE}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                <Divider />
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col>
          {/* LEVEL 2 */}
          <Col span={1}></Col>
          <Col span={23}>
            <h4>【2-1】総合アラート設定</h4>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={2}></Col>
              <Col span={22}>
                メール送信対象（設定したレベルに上がったときに送します。レベルダウンは対象となりません。）
              </Col>
              <Col span={5}></Col>
              <Col span={19}>
                <Radio.Group
                  name="emailSendingTarget"
                  value={emailSendingTarget.value}
                  onChange={(e) =>
                    setEmailSendingTarget({
                      ...emailSendingTarget,
                      value: e.target.value,
                    })
                  }
                >
                  <Radio value={"0"} className="radio">
                    <Space>
                      <FaFaceMeh
                        style={{ color: "gray", fontSize: 24, marginTop: 5 }}
                      />
                      <Typography.Text style={{ verticalAlign: "middle" }}>
                        （メール送信無し）
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={"1"} className="radio">
                    <Space>
                      <FaFaceMeh
                        style={{ color: "gold", fontSize: 24, marginTop: 5 }}
                      />
                      <Typography.Text style={{ verticalAlign: "middle" }}>
                        （レベル1以上）
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={"2"} className="radio">
                    <Space>
                      <FaFaceFrown
                        style={{ color: "orange", fontSize: 24, marginTop: 5 }}
                      />
                      <Typography.Text style={{ verticalAlign: "middle" }}>
                        （レベル2以上）
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={"3"} className="radio">
                    <Space>
                      <FaFaceSadTear
                        style={{ color: "red", fontSize: 24, marginTop: 5 }}
                      />
                      <Typography.Text style={{ verticalAlign: "middle" }}>
                        （レベル3以上）
                      </Typography.Text>
                    </Space>
                  </Radio>
                </Radio.Group>
              </Col>
              <Col span={2}></Col>
              <Col span={22}>メールアラート表示項目</Col>

              <Col span={5}></Col>
              <Col span={19}>
                <Checkbox.Group
                  value={listEmaillOption1}
                  onChange={(arr) => setListEmailOption1(arr)}
                  options={optionEmailAlert1}
                />
              </Col>
              <Col span={5}></Col>
              <Col span={19}>
                <Checkbox.Group
                  value={listEmaillOption2}
                  onChange={(arr) => setListEmailOption2(arr)}
                  options={optionEmailAlert2}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                <Divider />
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col>
          {/* LEVEL 2.2 */}
          <Col span={1}></Col>
          <Col span={23}>
            <h4>【2-2】要緊急対応アラート設定</h4>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={2}></Col>
              <Col span={22}>
                心拍数が設定レベルを超過している時間が一定時間蓄積すると要緊急対応アラートのメールを送信します。
              </Col>
              <Col span={5}></Col>
              <Col span={19}>
                <Radio.Group
                  name="emergencyResponse"
                  value={emergencyResponse.value}
                  onChange={(e) =>
                    setEmergencyResponse({
                      ...emergencyResponse,
                      value: e.target.value,
                    })
                  }
                >
                  <Radio value={"1"} className="radio">
                    オン
                  </Radio>
                  <Radio value={"0"} className="radio">
                    オフ
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                <Divider />
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col>
          {/* LEVEL 2.3 */}
          <Col span={1}></Col>
          <Col span={23}>
            <h4>【2-3】 死活監視アラート設定</h4>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={2}></Col>
              <Col span={22}>
                親機/子機とSisMilのステータスが異常の場合に死活監視アラートのメールを送信します。
              </Col>
              <Col span={5}></Col>
              <Col span={19}>
                <Radio.Group
                  name="aliveMonitoring"
                  value={aliveMonitoring.value}
                  onChange={(e) =>
                    setAliveMonitoring({
                      ...aliveMonitoring,
                      value: e.target.value,
                    })
                  }
                >
                  <Radio value={"1"} className="radio">
                    オン
                  </Radio>
                  <Radio value={"0"} className="radio">
                    オフ
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
          <Col span={1}></Col>
          <Col span={22}>
            <Divider />
          </Col>
          <Col span={1}></Col>
          {/* LEVEL 2.3 */}
          <Col span={1}></Col>
          <Col span={23}>
            <h4>【2-4】 一定期間発報抑止</h4>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={2}></Col>
              <Col span={22}></Col>
              <Col span={5}></Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                <Input
                  value={blockSendAlertTime.value}
                  onChange={(e) => {
                    const reg = REGEX_INPUT.intergerFrom0;
                    if (reg.test(e.target.value) || e.target.value === "") {
                      setBlockSendAlertTime({
                        ...blockSendAlertTime,
                        value: e.target.value,
                      });
                    }
                  }}
                  type="text"
                />
              </Col>
              <Col
                style={{
                  marginTop: -8,
                }}
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={9}
              >
                <h4>分</h4>
              </Col>
            </Row>
          </Col>
          {/* LEVEL 3 */}
          <Col span={1}></Col>
          <Col span={22}>
            <Divider />
          </Col>
          <Col span={1}></Col>
          <Col offset={1} span={24}>
            <h4>【3】安全帯表示設定</h4>
          </Col>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col span={2}></Col>
              <Col span={22}></Col>
              <Col span={5}></Col>
              <Col span={19}>
                <Radio.Group
                  name="seatbelt"
                  value={seatbelt.value}
                  onChange={(e) =>
                    setSeatbelt({
                      ...seatbelt,
                      value: e.target.value,
                    })
                  }
                >
                  <Radio value={"1"} className="radio">
                    表示
                  </Radio>
                  <Radio value={"0"} className="radio">
                    非表示
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={1}></Col>
              <Col span={22}>
                <Divider />
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col>
        </Row>
      </ContentTitle>
      <Row className="mt20 mb20">
        <Col span={24} className="right">
          <Button
            className="buttonPC button--info wAuto mr15"
            onClick={() => {
              onSave();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          {/* <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={getInitDataOfficeSetting}
          >
            <ClearOutlined />
            {lang.CLEAR}
          </Button> */}
        </Col>
      </Row>
      <Loading show={loading} />
    </div>
  );
}

export default MasterSetting;
