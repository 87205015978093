/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, Modal, Row, Select, Table, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import BreakLine from "../../component/Desktop/breakLine";
import Loading from "../../component/Desktop/loading/loading";
import RowInput from "../../component/Desktop/rowInput";
import TitlePage from "../../component/Desktop/titlePage";

import {
  DeleteOutlined,
  PlusOutlined,
  RollbackOutlined,
  SaveOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import API_BASE from "@src/api/backend/group";
import { useSelector } from "react-redux";
import GroupItem from "../../component/Desktop/groupItem";
import GroupLeaderModal from "../../components/modal/group-leader";
import {
  COMMON_CONSTANT,
  KEY,
  STYLE,
  lang,
} from "../../constants/common.const";
import {
  MSG_CATCH,
  formatListOptionOffice,
  getQuerySearch,
  renderOption,
  showErrorMessage,
  showMessage,
} from "../../constants/utils";
import classDdl from "../../models/control/dropdownlist";
import classGrid from "../../models/control/grid";
import classText from "../../models/control/text";
import useWidthInput from "../../hooks/useWidthInput";
import { useMediaQuery } from "react-responsive";
import useAuth from "../../hooks/useAuth";

let FLAG_CHANGE_DATA = false;
export const TYPE_MODAL_LEADER = {
  LEADER: 1,
  SUB_LEADER: 2,
  MEMBER: 3,
};
function CreateGroup() {
  const widthInput = useWidthInput();
  const { isSiggleOffice, officeId, officeName } = useAuth();
  let { id } = useParams();
  const history = useHistory();
  let [initPage] = useState(true);
  const objSearch = getQuerySearch();
  let [showLoading, setShowLoading] = useState(false);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const initLoad = useRef(true);
  const [showGroupLeaderModal, setShowGroupLeaderModal] = useState(false);

  const [modeCreate] = useState(undefined);
  const [modeEdit] = useState(true);

  let [ddlBasename, setddlBasename] = useState(
    new classDdl(isSiggleOffice ? officeId : objSearch.OfficeId)
  );
  let [txtLoginID] = useState(new classText());
  let [txtGroupId, settxtGroupId] = useState(new classText());
  let [txtGroupName, settxtGroupName] = useState(new classText());

  let [listGroupLeader, setListGroupLeader] = useState(new classGrid());
  const { companyId } = useSelector((state) => state.auth);
  const [listSubLeader, setListSubLeader] = useState(new classGrid());
  const [listMember, setListMember] = useState(new classGrid());

  let [txtEmail, settxtEmail] = useState(new classText());
  const [listEmail, setListEmail] = useState([]);
  const [typeLeader, setTypeLeader] = useState([]);
  // Focus
  let ddlBasenameEl = useRef(null);
  let txtLoginIDEl = useRef(null);
  let txtPasswordEl = useRef(null);
  let txtGroupNameEl = useRef(null);
  let txtFuriganaNameEl = useRef(null);
  let txtTelephoneEl = useRef(null);
  let txtEmailEl = useRef(null);
  let txtGroupIDEL = useRef(null);

  const getIniData = async () => {
    if (id) {
      setShowLoading(true);
    }
    try {
      const [data, listOffice] = await Promise.all([
        id ? API_BASE.getUserGroupID(id) : null,
        !isSiggleOffice ? API_BASE.getOffice({ companyId: companyId }) : null,
      ]);
      // edit
      if (id) {
        if (!isSiggleOffice) {
          setddlBasename({
            ...ddlBasename,
            options: formatListOptionOffice(listOffice.data.data),
            value: data.data.officeId,
          });
        }

        settxtGroupId({ ...txtGroupId, value: data.data.groupCode });
        settxtGroupName({ ...txtGroupName, value: data.data.groupName });
        setListEmail(data.data.listEmailAlert.map((i) => ({ email: i })));
        // settxtGroupLeader({
        //   ...txtGroupLeader,
        //   value:
        //     data.data.groupLeader != null ? data.data.groupLeader.userId : null,
        //   name:
        //     data.data.groupLeader != null
        //       ? data.data.groupLeader.fullName
        //       : null,
        // });
        setListGroupLeader({
          ...listGroupLeader,
          data: data.data.groupLeader,
          count: data.data.groupLeader.length,
        });

        setListSubLeader({
          ...listSubLeader,
          data: data.data.listSubleader,
          count: data.data.listSubleader.length,
        });
        setListMember({
          ...listMember,
          data: data.data.listMember,
          count: data.data.listMember.length,
        });
      } else {
        if (!isSiggleOffice) {
          setddlBasename({
            ...ddlBasename,
            options: formatListOptionOffice(listOffice.data.data),
          });
        }
      }
    } catch (error) {
      console.log(error);
      showErrorMessage(error);
    } finally {
      setShowLoading(false);
      initLoad.current = false;
    }
  };
  useEffect(() => {
    getIniData();
  }, [id]);

  useEffect(() => {
    if (!initLoad.current) {
      getGroupMember();
    }
  }, [ddlBasename.value]);

  const [grid2, setGrid2] = useState({
    ...new classGrid(),
    data: [],
  });
  const getGroupMember = async () => {
    try {
      if (id && txtGroupId.value) {
        const result = await API_BASE.getGroup({
          groupId: txtGroupId.value,
        });
        const data = result.data;
        setGrid2({ ...grid2, data: data, count: data.length });
      } else {
        setGrid2({ data: [] });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getGroupMember();
    // search_onClick();
  }, [txtGroupId.value]);
  let initColumnsLeader = [
    {
      title: "ログインID",
      dataIndex: "userName",
    },

    {
      title: "氏名",
      dataIndex: "fullName",
    },

    {
      title: "削除",
      dataIndex: "delete",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={"削除"}>
              <Button
                className="mr5 btnDelete"
                onClick={async () => {
                  try {
                    setListGroupLeader({
                      ...listGroupLeader,
                      data: listGroupLeader.data.filter(
                        (i) => i.userId !== row.userId
                      ),
                    });
                  } catch (error) {
                    showErrorMessage(error);
                  }
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  let initColumnsSubLeader = [
    {
      title: "ログインID",
      dataIndex: "userName",
    },

    {
      title: "氏名",
      dataIndex: "fullName",
    },

    {
      title: "削除",
      dataIndex: "delete",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={"削除"}>
              <Button
                className="mr5 btnDelete"
                onClick={async () => {
                  try {
                    setListSubLeader({
                      ...listSubLeader,
                      data: listSubLeader.data.filter(
                        (i) => i.userId !== row.userId
                      ),
                    });
                  } catch (error) {
                    showErrorMessage(error);
                  }
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];
  let initColumnsMember = [
    {
      title: "ログインID",
      dataIndex: "userName",
    },

    {
      title: "氏名",
      dataIndex: "fullName",
    },

    {
      title: "削除",
      dataIndex: "delete",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={"削除"}>
              <Button
                className="mr5 btnDelete"
                onClick={async () => {
                  try {
                    setListMember({
                      ...listMember,
                      data: listMember.data.filter(
                        (i) => i.userId !== row.userId
                      ),
                    });
                  } catch (error) {
                    showErrorMessage(error);
                  }
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];
  let initColumn2 = [
    {
      title: "メールアドレス",
      dataIndex: "email",
    },
    {
      title: "テストメール送信",
      dataIndex: "email",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {/* send */}

            <Button
              className="mr5 btnDelete"
              onClick={async () => {
                await API_BASE.sendEmail(row.email);
                showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
              }}
            >
              テストメール送信
            </Button>
          </>
        );
      },
    },

    {
      title: "削除",
      dataIndex: "delete",
      align: "center",
      render: (_, row, index) => {
        //
        return (
          <>
            {/* Delete */}

            <Button
              className="mr5 btnDelete"
              onClick={() => {
                setListEmail([
                  ...listEmail.slice(0, index),
                  ...listEmail.slice(index + 1),
                ]);
              }}
            >
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  // Change data
  useEffect(() => {
    if (!initPage) {
      FLAG_CHANGE_DATA = true;
    }
  }, [
    txtLoginID,
    txtLoginIDEl,
    txtPasswordEl,
    txtGroupNameEl,
    txtFuriganaNameEl,
    txtTelephoneEl,
    txtEmailEl,
    txtGroupIDEL,
  ]);
  // Validate
  const validateData = () => {
    let flagError = false;
    settxtGroupName({ ...txtGroupName, error: false });
    setddlBasename({ ...ddlBasename, error: false });

    if (!txtGroupName.value) {
      flagError = true;
      settxtGroupName({ ...txtGroupName, error: true });
      txtGroupNameEl.current.focus();
    }

    if (!ddlBasename.value) {
      flagError = true;
      setddlBasename({ ...ddlBasename, error: true });
      ddlBasenameEl.current.focus();
    }

    return !flagError;
  };
  // Save Data
  const postData = async () => {
    try {
      setShowLoading(true);
      const dataPost = {
        officeId: ddlBasename.value,
        groupName: txtGroupName.value,
        groupCode: txtGroupId.value,
        leaderId: listGroupLeader.data.map((i) => i.userId),
        listSubleader: listSubLeader.data.map((i) => i.userId),
        listMember: listMember.data.map((i) => i.userId),
        listEmailAlert: listEmail.map((i) => i.email),
      };
      if (id) {
        dataPost.id = id;
      }
      await API_BASE.save(dataPost, id);
      showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C003);
      history.goBack();
    } catch (error) {
      let msg;
      if (error.response) {
        if (error.response.status === 400) {
          msg = error.response.data;
          //   console.log(msg[1]);
        }
      }
      showMessage(
        KEY.ERROR,
        msg
          ? msg.split("\n").map((line, index) => <div key={index}>{line}</div>)
          : null
      );
    } finally {
      setShowLoading(false);
    }
  };
  // Save Data
  const onSave = () => {
    // Validate Data
    if (!validateData()) {
      return;
    }
    // Call Api
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: id ? COMMON_CONSTANT.ECM_C009 : COMMON_CONSTANT.ECM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => {
        postData();
      },
    });
  };

  function backToSearch() {
    history.goBack();
  }

  // Add Email
  const handleAddEmail = () => {
    const newEmail = txtEmail.value ? txtEmail.value.trim() : "";
    const emailPatern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPatern.test(String(newEmail).toLowerCase())) {
      settxtEmail({ ...txtEmail, error: true });
      txtEmailEl.current.focus();
    } else {
      setListEmail([...listEmail, { email: newEmail }]);
      settxtEmail({ ...txtEmail, value: "" });
    }
  };
  return (
    <div className="des-content">
      <GroupLeaderModal
        visible={showGroupLeaderModal}
        handleCancel={() => {
          setShowGroupLeaderModal(false);
          setTypeLeader("");
        }}
        currentListSubLeader={listSubLeader.data.map((i) => i.userId)}
        currentListSubMember={listMember.data.map((i) => i.userId)}
        currentListGroupLeader={listGroupLeader.data.map((i) => i.userId)}
        officeId={ddlBasename.value}
        typeLeader={typeLeader}
        listUser={
          typeLeader === TYPE_MODAL_LEADER.LEADER
            ? listGroupLeader
            : typeLeader === TYPE_MODAL_LEADER.SUB_LEADER
            ? listSubLeader
            : listMember
        }
        setListUser={
          typeLeader === TYPE_MODAL_LEADER.LEADER
            ? setListGroupLeader
            : typeLeader === TYPE_MODAL_LEADER.SUB_LEADER
            ? setListSubLeader
            : setListMember
        }
      />

      <Loading show={showLoading}></Loading>
      <TitlePage name={lang.GROUP_LIST_SETTING} />

      {/* Group 1 */}

      <GroupItem>
        <BreakLine />
        <RowInput box name={lang.BASE_NAME} required={modeEdit}>
          {!isSiggleOffice ? (
            <Select
              showSearch
              optionFilterProp="children"
              value={ddlBasename.options.length ? ddlBasename.value : ""}
              ref={ddlBasenameEl}
              className={
                ddlBasename.error
                  ? `${STYLE.BORDER_RED}  ${widthInput}`
                  : ` ${widthInput}`
              }
              onChange={(v) =>
                setddlBasename({
                  ...ddlBasename,
                  value: v,
                })
              }
            >
              {renderOption(ddlBasename.options, false)}
            </Select>
          ) : (
            <Input value={officeName} disabled className={widthInput} />
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.GROUP_CODE}>
          <Input
            disabled={id}
            value={txtGroupId.value}
            className={` ${widthInput}`}
            maxLength={100}
            onChange={(e) =>
              settxtGroupId({
                ...txtGroupId,
                value: e.target.value,
              })
            }
          ></Input>
        </RowInput>
        <BreakLine />

        <RowInput box name={"グループ名"} required={modeEdit}>
          <Input
            // Because Changes cannot be made after registration
            disabled={id}
            value={txtGroupName.value}
            ref={txtGroupNameEl}
            className={
              txtGroupName.error
                ? `${STYLE.BORDER_RED}  ${widthInput}`
                : ` ${widthInput}`
            }
            maxLength={100}
            onChange={(e) =>
              settxtGroupName({
                ...txtGroupName,
                value: e.target.value,
              })
            }
          ></Input>
          <span className="fs12 ml10">登録後変更不可となります</span>
        </RowInput>
        <BreakLine />

        <RowInput
          box
          name={COMMON_CONSTANT.GROUP_LEADER}
          required={false}
          class="w100"
        >
          {modeEdit ? (
            <>
              <Row>
                <Col span={24}>
                  <Button
                    style={{
                      marginTop: !isPC && 10,
                      mariginLeft: !isPC && -10,
                    }}
                    disabled={!ddlBasename.value}
                    className="mb10"
                    onClick={() => {
                      setShowGroupLeaderModal(true);
                      setTypeLeader(TYPE_MODAL_LEADER.LEADER);
                    }}
                  >
                    <UserAddOutlined />
                  </Button>
                </Col>

                <Col span={24}>
                  <Table
                    columns={initColumnsLeader}
                    dataSource={listGroupLeader.data}
                    pagination={false}
                    scroll={{ y: 400 }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </RowInput>
        <BreakLine />

        <RowInput
          box
          name={COMMON_CONSTANT.GROUP_SUPPLEMENTER}
          required={false}
          class="w100"
        >
          {modeEdit ? (
            <>
              <Row>
                <Col span={24}>
                  <Button
                    style={{
                      marginTop: !isPC && 10,
                      mariginLeft: !isPC && -10,
                    }}
                    disabled={!ddlBasename.value}
                    className="mb10"
                    onClick={() => {
                      setShowGroupLeaderModal(true);
                      setTypeLeader(TYPE_MODAL_LEADER.SUB_LEADER);
                    }}
                  >
                    <UserAddOutlined />
                  </Button>
                </Col>

                <Col span={24}>
                  <Table
                    columns={initColumnsSubLeader}
                    dataSource={listSubLeader.data}
                    pagination={false}
                    scroll={{ y: 400 }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </RowInput>
        <BreakLine />

        <RowInput
          box
          name={lang.ALERT_EMAIL_NOTIFICATION}
          required={false}
          class="w100"
        >
          {modeEdit ? (
            <>
              <Row>
                <Col lg={12} span={24}>
                  <Input
                    ref={txtEmailEl}
                    className={
                      txtEmail.error
                        ? `${STYLE.BORDER_RED}  ${widthInput}`
                        : ` ${widthInput}`
                    }
                    onChange={(e) =>
                      settxtEmail({ ...txtEmail, value: e.target.value })
                    }
                    value={txtEmail.value}
                  />
                </Col>
                <Col lg={12} md={24}>
                  <Button
                    style={{
                      marginTop: !isPC && 10,
                      mariginLeft: !isPC && -10,
                    }}
                    onClick={() => handleAddEmail()}
                    className="mb10"
                  >
                    <PlusOutlined />
                    追加
                  </Button>
                </Col>

                <Col span={24}>
                  <Table
                    columns={initColumn2}
                    dataSource={listEmail}
                    pagination={false}
                    scroll={{ y: 400, x: 500 }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </RowInput>
        <BreakLine />

        <RowInput box name={lang.GROUP_MEMBER} required={false} class="w100">
          {modeEdit ? (
            <>
              <Row>
                <Col span={24}>
                  <Button
                    disabled={!ddlBasename.value}
                    className="mb10"
                    onClick={() => {
                      setShowGroupLeaderModal(true);
                      setTypeLeader(TYPE_MODAL_LEADER.MEMBER);
                    }}
                  >
                    <UserAddOutlined />
                  </Button>
                </Col>

                <Col span={24}>
                  <Table
                    columns={initColumnsMember}
                    dataSource={listMember.data}
                    pagination={false}
                    scroll={{ y: 400 }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </RowInput>
        <BreakLine />
      </GroupItem>

      <Row justify="end" className="mt20">
        <Col>
          <Button
            className="buttonPC button--info wAuto ml15 mb5 mt5"
            onClick={() => {
              //confirmSaveData();
              onSave();
            }}
          >
            <SaveOutlined />
            {COMMON_CONSTANT.SAVE}
          </Button>
          <Button
            className="buttonPC button--outline --todo-- wAuto ml15 mr15 mb5 mt5"
            onClick={() => backToSearch()}
          >
            <RollbackOutlined />
            {lang.BACK}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CreateGroup;
