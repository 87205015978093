import React, { useEffect, useState } from "react";
import axios from "axios";
import { Input, Button, InputNumber, Row, Col, Form, Flex, Select } from "antd";
import { isEmpty } from "lodash";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

function getRandomInt(min, max) {
  if (isNaN(min)) return max;
  if (isNaN(max)) return min;
  return Math.floor(getRandomArbitrary(min, max + 1));
}

const Request = () => {
  const [formData, setFormData] = useState({
    gwid: "0001",
    ch: "2",
    sf: "9",
    rssi: "-99",
    panid: "0001",
    id: "0001",
    major: "0001",
    minor: "9123",
    signalStrengthFrom: "A9",
    signalStrengthTo: "-1",
    heartRateFrom: "50",
    heartRateTo: "170",
    voltageFrom: "330",
    voltageTo: "420",
    heartRate2: "00",
  });
  const [result, setResult] = useState("");
  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const post = async () => {
    const signalStrengthFrom = +formData.signalStrengthFrom;
    const signalStrengthTo = +formData.signalStrengthTo;
    const signalStrength = getRandomInt(signalStrengthFrom, signalStrengthTo);

    const heartRateFrom = +formData.heartRateFrom;
    const heartRateTo = +formData.heartRateTo;
    const heartRate = getRandomInt(heartRateFrom, heartRateTo);

    const voltageFrom = +formData.voltageFrom;
    const voltageTo = +formData.voltageTo;
    const voltage = getRandomInt(voltageFrom, voltageTo);

    const dataT1 =
      signalStrength.toString(16) +
      formData.major.toString(16) +
      formData.minor.toString(16) +
      heartRate.toString(16) +
      voltage.toString(16);
    let signal = signalStrength.toString(16);
    let major = parseInt(formData.major).toString(16);
    let minor = parseInt(formData.minor).toString(16);
    let battery = voltage.toString(16);
    let rate = formData.major == "0002" ? heartRate.toString(16) : formData.heartRate2;

    let reqdata =
      formData.signalStrengthFrom.padStart(2, "0") +
      major.padStart(4, "0") +
      minor.padStart(4, "0") +
      rate.padStart(2, "0") +
      battery.padStart(4, "0");
    const data = {
      gwid: formData.gwid,
      ch: formData.ch,
      sf: formData.sf,
      rssi: formData.rssi,
      panid: formData.panid,
      id: formData.id,
      T1: reqdata,
    };
    const body = new URLSearchParams();
    Object.keys(data).forEach((key) => {
      body.append(key, data[key]);
    });

    const url =
      "https://bmd34lm3ud.execute-api.ap-northeast-1.amazonaws.com/default/ReceivedDataFunctionDev";

    try {
      const response = await axios.post(url, body, config);
      setResult(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  const [run, setRun] = useState(false);
  useEffect(() => {
    let intervalId;
    if (run) {
      postAsync();

      // Set the interval only when run is true
      intervalId = setInterval(async () => {
        await post();
      }, 60000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

    async function postAsync() {
      await post();
    }
  }, [run]);

  const [count, setCount] = useState(0);
  useEffect(() => {
    if (!run) {
      setCount(0);
      return;
    };
    // Thiết lập bộ hẹn giờ để tăng giá trị bộ đếm mỗi giây
    const timer = setInterval(() => {
      setCount(prevCount => prevCount + 1);
    }, 1000);

    // Dọn dẹp bộ hẹn giờ khi component bị gỡ bỏ
    return () => clearInterval(timer);
  }, [run]); 

  return (
    <>
      <Form labelCol={{ span: 8 }}>
        <Row gutter={16}>
          <Col span={12}>
            {["gwid", "ch", "sf"].map((item, index) => {
              return (
                <Form.Item key={index} label={item}>
                  <Input
                    value={formData[item]}
                    onChange={(e) => handleInputChange(item, e.target.value)}
                  />
                </Form.Item>
              );
            })}
          </Col>
          <Col span={12}>
            {["rssi", "panid", "id"].map((item, index) => {
              return (
                <Form.Item key={index} label={item}>
                  <Input
                    value={formData[item]}
                    onChange={(e) => handleInputChange(item, e.target.value)}
                  />
                </Form.Item>
              );
            })}
          </Col>
        </Row>

        <Row gutter={16} className="mt35">
          <Col span={12}>
            <Form.Item key={-1} label={"Major"}>
              <Select
                options={[
                  { value: '0000', label: '0000' },
                  { value: '0001', label: '0001' },
                  { value: '0002', label: '0002' },
                ]}
                value={formData["major"]}
                onChange={(value) =>
                  handleInputChange("major", value)
                }
              />
            </Form.Item>
            {[
              { key: "signalStrengthFrom", name: "Signal Strength: From" },
              formData.major == "0002" ? { key: "heartRateFrom", name: "Heart Rate: From" } : {},
              { key: "voltageFrom", name: "Voltage: From" },
            ].map((item, index) => {
              if (isEmpty(item)) return <></>;
              return (
                <Form.Item key={index} label={item.name}>
                  <Input
                    value={formData[item.key]}
                    onChange={(e) =>
                      handleInputChange(item.key, e.target.value)
                    }
                  />
                </Form.Item>
              );
            })}
            {
              formData.major == "0001" && <Form.Item key={10} label={"状態"}>
                <Select
                  options={[
                    { value: '00', label: '00' },
                    { value: '01', label: '01' },
                    { value: '02', label: '02' },
					{ value: '03', label: '03' },
                    { value: '04', label: '04' },
                    { value: '05', label: '05' },
                  ]}
                  value={formData["heartRate2"]}
                  onChange={(value) => {
                    handleInputChange("heartRate2", value);
                  }
                  }
                />
              </Form.Item>
            }
          </Col>
          <Col span={12}>
            {[
              { key: "minor", name: "Minor (Device ID)" },
              { key: "signalStrengthTo", name: "To" },
              formData.major == "0002" ? { key: "heartRateTo", name: "To" } : {},
              { key: "voltageTo", name: "To" },
            ].map((item, index) => {
              if (isEmpty(item)) return <></>;
              return (
                <Form.Item key={index} label={item.name}>
                  <Input
                    value={formData[item.key]}
                    onChange={(e) =>
                      handleInputChange(item.key, e.target.value)
                    }
                  />
                </Form.Item>
              );
            })}
          </Col>
        </Row>
        {/* Repeat similar structure for other input fields */}

        <Form.Item>
          <Flex align="flex-end" justify="flex-end">
            <Button type="primary" onClick={() => setRun(true)}>
              Start
            </Button>
            <Button
              type="default"
              onClick={() => setRun(false)}
              style={{ marginLeft: "8px" }}
            >
              Stop
            </Button>
          </Flex>
        </Form.Item>

        {/* Output */}
        <div>Status: {run ? "Running..." : "Not run"}</div>
        <div>
          Result: <br /> {result}
        </div>
        <div>
          Count: {count}s
        </div>
      </Form>
    </>
  );
};

export default Request;
