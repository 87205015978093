import {
  Button,
  Col,
  Collapse,
  Form,
  Layout,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import Loading from "../../component/Desktop/loading/loading";
import TitlePage from "../../component/Desktop/titlePage";

import classGrid from "../../models/control/grid";
import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import {
  MSG_CATCH,
  getQuerySearch,
  pushObject2Query,
  saveFile,
  showMessage,
} from "../../constants/utils";

import API_BASE from "@src/api/backend/group";
import { useSelector } from "react-redux";
import ContentTitle from "../../component/Desktop/contentTitle";
import SelectOffice from "../../components/common/input/selectOffice";
import {
  COMMON_CONSTANT,
  CONFIG,
  KEY,
  PAGE_SIZE,
  lang,
} from "../../constants/common.const";
import { ROUTER_PAGE } from "../../constants/router.const";
import classDdl from "../../models/control/dropdownlist";
import { onChangeTable } from "../../utils/helpers";

const { Content } = Layout;
const { Panel } = Collapse;

function Grouplist() {
  const objSearch = getQuerySearch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [ddlOffice, setDdlOffice] = useState({
    ...new classDdl(objSearch.officeId),
  });
  const [initLoad, setInitLoad] = useState(true);

  // Loading
  const [showLoading, setShowLoading] = useState(false);
  const [formSearch] = Form.useForm();
  //  Grid
  const [grid, setGrid] = useState({
    ...new classGrid(),
    data: [],
  });

  const { roleKey, companyId } = useSelector((state) => state.auth);
  // Search
  const search_onClick = async (
    currentPage = 1,
    sortKey = "",
    sortOrder = "desc"
  ) => {
    setPage(currentPage);
    setSortKey(sortKey);
    setSortOrder(sortOrder);
    pushObject2Query({ officeId: ddlOffice.value });
    try {
      let data = null;

      let result = null;
      result = await API_BASE.searchGroup({
        Role: roleKey,
        OfficeId: ddlOffice.value,
        companyId: companyId,
        offset: (currentPage - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        sortKey,
        sortOrder,
      });

      data = result.data;
      setGrid({ ...grid, data: data.data, count: data.count });
    } catch (e) {
      setGrid({ ...grid, data: [] });
    }
  };
  useEffect(() => {
    if (initLoad) {
      setInitLoad(false);
    }
    search_onClick(1, sortKey, sortOrder);
  }, [ddlOffice.value]);
  
  let initColumns = [
    {
      title: "グループ名",
      dataIndex: "groupName",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      title: "拠点名",
      dataIndex: "baseName",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },

    {
      title: "グループリーダー",
      dataIndex: "fullName",
      render: (text, row) => {
        return <>{row.userLeader != null ? row.userLeader.map(item => (
          <div key={item}>
          {item}
          </div>
        ))
        : null}</>;
      },
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "会社名",
      sorter: true,
      dataIndex: "companyName",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "設定変更",
      dataIndex: "settingChange",
      align: "center",
      render: (text, row) => {
        //
        return (
          <>
            <Tooltip title={"設定変更"}>
              <Button
                className="mr5 ml10"
                onClick={() =>
                  history.push(
                    ROUTER_PAGE.GROUP_LIST_EDIT.replace(":id", row.id)
                  )
                }
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "削除",
      dataIndex: "delete",
      align: "center",
      render: (_, row) => {
        //
        return (
          <>
            {/* Delete */}
            <Tooltip title={"削除"}>
              <Button
                onClick={() => {
                  handleDeleteGroup(row.id);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];
  // Delete
  const handleDeleteGroup = (id) => {
    Modal.confirm({
      title: lang.DELETE,
      centered: true,
      content: COMMON_CONSTANT.ECM_C001,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: async () => {
        try {
          await API_BASE.deleteGroup(id);
          showMessage(KEY.INFO, COMMON_CONSTANT.ECM_C004);
          search_onClick();
        } catch (error) {
          showMessage(KEY.ERROR, MSG_CATCH());
        }
      },
    });
  };
  // DOMNLOAD
  const handleDownload = async () => {
    try {
      setShowLoading(true);
      const res = await API_BASE.downLoadGroup({
        OfficeId: ddlOffice.value,
      });
      saveFile(res);
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };
  // Render
  return (
    <div className="des-content role-setting">
      <ContentTitle>
        <TitlePage name={lang.GROUP_LIST} />

        <Form
          layout={"vertical"}
          name="searchWR"
          form={formSearch}
          className="formStyle"
        >
          {/* 1 */}
          <Row gutter={[16, 8]} align="bottom" className="rowNotMargin">
            <Col xl={8} span={24}>
              <div className="ant-form-item-label">{lang.BASE_NAME}</div>
              <SelectOffice
                className={"w100"}
                acceptSetNull={!initLoad}
                state={ddlOffice}
                setState={setDdlOffice}
              />
            </Col>
            <Col xl={16} lg={24} md={24} sm={24} xs={24}>
              <Row className="rowNotMargin" gutter={[12, 12]} justify="end">
                <Col>
                  <Button
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push(
                        `/group/create/new?OfficeId=${ddlOffice.value || ""}`
                      );
                    }}
                  >
                    <PlusOutlined className="" />
                    {COMMON_CONSTANT.ADD}
                  </Button>
                </Col>
                <Col>
                  <Button
                    htmlType="submit"
                    className="buttonPC button--info wAuto"
                    onClick={() => {
                      history.push(
                        `/group/inputfile?OfficeCode=${ddlOffice.value}`
                      );
                    }}
                  >
                    <FileAddOutlined className="" />
                    {lang.FILE}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => handleDownload()}
                    className="buttonPC button--info --todo-- wAuto "
                  >
                    <DownloadOutlined />
                    {lang.DOWNLOAD}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </ContentTitle>

      <Content
        className="site-layout-background"
        style={{
          margin: "24px 0px",
        }}
      >
        {/* Count */}
        <Row justify="space-between ">
          <Col span={12}></Col>
          <Col span={12} className="right" style={{ paddingRight: "20px" }}>
            {/* Count */}
            <Space size={10} align="center ">
              {lang.ACCESS_NUMBERS}
              <span>
                {grid.count}
                {lang.CASE}
              </span>
            </Space>
          </Col>
        </Row>
        {/* Grid  */}
        <Table
          rowKey={(record) => record.id}
          className="gridSearch"
          columns={initColumns}
          dataSource={grid.data}
          pagination={{
            ...CONFIG.paging,
            total: grid.count,
            current: page,
            showSizeChanger: false,
          }}
         scroll={{ x: 'max-content' }}
          onChange={onChangeTable(search_onClick)}
        />
      </Content>
      <Loading show={showLoading} />
    </div>
  );
}

export default Grouplist;
