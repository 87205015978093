import Api from "@src/api";
export const ApiContract = {
  getAdminContract(params) {
    return Api().get("/api/UserRole", { params });
  },
  
  getCompany() {
    return Api().get("/api/Company");
  },
  
  
};
