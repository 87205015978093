import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

const configOptions = (config) => ({
  scales: {
    "left-y-axis": {
      type: "linear",
      position: "left",

      title: {
        display: true,
        text: "心拍数（回/分）",
      },
      ticks: {},
      min: 0,
      max: config.maxHeartRate || 200,
    },
    "right-y-axis": {
      type: "linear",
      position: "right",
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "WBGT超過度(℃)",
      },
      ticks: {
        callback: (v) => Math.round(v * 1000) / 1000 + "°C",
      },

      min: -30,
      max: 30,
    },
  },
});

const ConditionChart = (props) => {
  const { heartRateData, wbgtData, emailAlertData } = props;
  const maxHeartRate = Math.max(...heartRateData) || 0;

  const data = {
    labels: props.labels.length ? props.labels : labels,
    datasets: [
      {
        yAxisID: "left-y-axis",
        type: "line",
        label: "心拍数(回/分)",
        borderWidth: 2,
        borderColor: "#fb839c",
        backgroundColor: "#fb839c",
        data: heartRateData,
        spanGaps: true,
      },
      {
        yAxisID: "right-y-axis",
        type: "line",
        label: "WBGT超過度(℃)",
        borderWidth: 2,
        borderColor: "#62b2ec",
        backgroundColor: "#62b2ec",
        data: wbgtData,
        spanGaps: true,
      },
      {
        yAxisID: "left-y-axis",
        pointStyle: "rect",
        label: "アラートメール発信",
        borderColor: "#e1304a",
        backgroundColor: "#e1304a",
        data: emailAlertData,
        pointRadius: 6,
        pointHoverRadius: 15,
        spanGaps: true,
      },
    ],
  };
  return (
    <Line
      options={configOptions({ maxHeartRate: maxHeartRate > 175 ? maxHeartRate + 25 : 200 })}
      data={data}
    />
  );
};

export default ConditionChart;
